import { gql } from "@apollo/client";

export const GET_USER_CHECKIN_HISTORY = gql`
  query GetUserCheckinHistory($userId: ID!, $page: Int) {
    getUserCheckinHistory(userId: $userId, page: $page) {
      id
      date
      deleteable
      logs
      aula {
        id
        baseDate
        limit
        startTime
        endTime
        coach {
          id
          name
        }
      }
      filial {
        id
        code
        name
      }
      status
      program {
        code
        id
        name
      }
    }
  }
`;