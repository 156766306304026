import * as React from 'react';
import {Select, TextInput,Switch} from 'react-materialize';
import InputMask from "react-input-mask";
import {Formik, Form} from 'formik';
import Cards from 'react-credit-cards-2';
import * as yup from 'yup';
import {CreateSubscription} from '../../../services/account/AccountCheckout.service';
import {validations} from '../../messages';
import {SelectContainer} from './AccountCheckout.style'

import {
  getCardFlag, 
} from '../../components';

export const AccountCheckoutForm = ({setPurchased, selectedPlan, setLoading, formToken}) => {
  const [focused, setFocused] = React.useState(null);
  let installmentsArray = [];
  if(selectedPlan?.installments){
    for(let i = 1; i <= selectedPlan.installments; i++){
      installmentsArray.push(i);
    }
  }
  const checkoutSchema = yup.object().shape({
    showPayment: selectedPlan.store !== 'stripe',
    email: yup
      .string()
      .email()
      .required('Obrigatório'),
    firstName: yup
      .string()
      .required(),
    lastName: yup
      .string()
      .required(),
    cpf: yup
      .string()
      .required()
      .test('cpf', 'Digite um CPF válido', (value) => {
        let strCPF = (value || '').replace(/\D/g, "");
        let Soma;
        let Resto;
        Soma = 0;
        if (strCPF == "00000000000") return false;

        for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

          if ((Resto == 10) || (Resto == 11))  Resto = 0;
          if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;

        Soma = 0;
          for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
          Resto = (Soma * 10) % 11;

          if ((Resto == 10) || (Resto == 11))  Resto = 0;
          if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
          return true;
      })
      .required(),
    phone: yup
      .string()
      .required()
      .test('phone', 'Digite um telefone válido', (value) => {
        let telefone = value || '';
        telefone = telefone.replace(/\D/g,'');
      
        if(!(telefone.length >= 10 && telefone.length <= 11)) return false;
      
        if (telefone.length == 11 && parseInt(telefone.substring(2, 3)) != 9) return false;

        for(var n = 0; n < 10; n++){
          if(telefone == new Array(11).join(n) || telefone == new Array(12).join(n)) return false;
        }
        var codigosDDD = [11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 64, 63, 65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99];
        if(codigosDDD.indexOf(parseInt(telefone.substring(0, 2))) == -1) return false;
        if (telefone.length == 10 && [2, 3, 4, 5, 7].indexOf(parseInt(telefone.substring(2, 3))) == -1) return false;
        
        return true;
      }),
    creditCardNumber: yup
      .string()
      .when("showPayment", {
        is: true,
        then: yup.string().required(validations.required),
      }),
    creditCardName: yup
      .string()
      .when("showPayment", {
        is: true,
        then: yup.string().required(validations.required),
      }),
    creditCardExpired: yup
      .string()
      .when("showPayment", {
        is: true,
        then: yup.string().required(validations.required),
      }),
    creditCardCvv: yup
      .string()
      .when("showPayment", {
        is: true,
        then: yup.string().required(validations.required),
      }),
  });

  return(
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        cpf: '',
        gender: '',
        phone: '',
        creditCardNumber: '',
        creditCardName: '',
        creditCardExpired: '',
        creditCardCvv: '',
        installments: 1
      }}
      validationSchema={checkoutSchema}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const {firstName, lastName, gender, email, cpf, phone, creditCardNumber, creditCardName, creditCardExpired, creditCardCvv, installments} = values;
        const body = {
          checkout: {
            plan_code: selectedPlan.code,
            plan: selectedPlan,
            customer:{
              name: firstName,
              last_name: lastName,
              gender: gender === 'Feminino' ? 'Feminino' : 'Masculino',
              email,
              cpf,
              phone,
            }
          },
          authenticity_token: formToken
        }
        if(selectedPlan.store !== 'stripe'){
          body.checkout.payment = {
            credit_card_number: creditCardNumber,
            credit_card_name: creditCardName,
            expire_date: creditCardExpired,
            cvv: creditCardCvv,
            credit_card_label: getCardFlag(creditCardNumber),
            installments,
          }
        }
        setSubmitting(true);
        CreateSubscription(body, setLoading)
          .then(response => {
            if(selectedPlan.store != 'stripe'){
              setPurchased(true);
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
      >
      {({ errors, touched, values, setValues, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form id="checkout-form" className="checkout-form">
          <div className="row">
            <div className="col s12">
              <div className="flow-text">Você</div>
            </div>
            <div className="col s12">
              <div className="row">
                <div className="col s12">
                  <TextInput
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    inputClassName={!!errors.email && !!touched.email ? 'invalid' : ' '}
                    error={errors.email}
                    required
                    label="E-mail*"
                  />
                </div>
                <div className="col s12">
                  <Switch
                    id="gender-check"
                    name="gender"
                    offLabel="Masculino"
                    onChange={(event) => event.target.checked ? setValues({...values, gender: 'Feminino'}) : setValues({...values, gender: 'Masculino'}) }
                    onLabel="Feminino"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col s6">
                  <TextInput
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    inputClassName={!!errors.firstName && !!touched.firstName ? 'invalid' : ' '}
                    error={errors.firstName}
                    required
                    label="Primeiro Nome*"
                  />
                </div>
                <div className="col s6">
                  <TextInput
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    inputClassName={!!errors.lastName && !!touched.lastName ? 'invalid' : ' '}
                    error={errors.lastName}
                    required
                    label="Sobrenome*"
                  />
                </div>
                <div className="col s6 input-field">
                    <InputMask
                      name="cpf"
                      type="tel"
                      mask={'999.999.999-99'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cpf}
                      className={!!errors.cpf && !!touched.cpf ? 'invalid' : ' '}
                      required
                    />
                    <label htmlFor="cpf">CPF*</label>
                    <span className="helper-text" data-error={errors.cpf}></span>
                </div>
                <div className="col s6 input-field">
                    <InputMask
                      name="phone"
                      type="tel"
                      mask={'(99) 99999-9999'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      className={!!errors.phone && !!touched.phone ? 'invalid' : ' '}
                      required
                    />
                    <label htmlFor="phone">Celular*</label>
                    <span className="helper-text" data-error={errors.phone}></span>
                </div>
              </div>
            </div>
          </div>

          {selectedPlan.store !== 'stripe' && <>
            <div className="row">
              <div className="col s12">
                <div className="flow-text">Pagamento</div>
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="col s12">
                  <Cards
                    number={values.creditCardNumber}
                    name={values.creditCardName}
                    expiry={values.creditCardExpired}
                    cvc={values.creditCardCvv}
                    focused={focused}
                    placeholders={{
                      name: "NOME NO CARTÃO*"
                    }}
                    locale={{ valid: 'validade'}}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col s12 input-field">
                    <InputMask
                      id="creditCardNumber"
                      name="creditCardNumber"
                      type="tel"
                      mask={'9999 9999 9999 9999'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.creditCardNumber}
                      onFocus={() => setFocused('number') }
                      className={!!errors.creditCardNumber && !!touched.creditCardNumber ? 'invalid' : ' '}
                      required
                    />
                    <label htmlFor="creditCardNumber">Número do Cartão*</label>
                    {!!errors.creditCardNumber && <span className="helper-text" data-error={errors.creditCardNumber}></span>}
                  </div>

                <div className="col s12 input-field">
                  <TextInput
                    name="creditCardName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.creditCardName}
                    inputClassName={!!errors.creditCardName && !!touched.creditCardName ? 'invalid' : ' '}
                      error={errors.creditCardName}
                    required
                    label="Nome no Cartão*"
                    onFocus={() => setFocused('name') }
                  />
                </div>

                <div className="col s6 input-field">
                    <InputMask
                      name="creditCardExpired"
                      type="tel"
                      mask={'99/99'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onFocus={() => setFocused('expiry') }
                      value={values.creditCardExpired}
                      className={!!errors.creditCardExpired && !!touched.creditCardExpired ? 'invalid' : ' '}
                      required
                    />
                    <label htmlFor="creditCardExpired">Validade do Cartão*</label>
                    {!!errors.creditCardExpired && <span className="helper-text" data-error={errors.creditCardExpired}></span>}
                </div>

                <div className="col s6 input-field">
                    <InputMask
                      name="creditCardCvv"
                      type="tel"
                      mask={'999'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.creditCardCvv}
                      onFocus={() => setFocused('cvc') }
                      className={!!errors.creditCardCvv && !!touched.creditCardCvv ? 'invalid' : ' '}
                      required
                    />
                    <label htmlFor="creditCardCvv">CVV*</label>
                    {!!errors.creditCardCvv && <span className="helper-text" data-error={errors.creditCardCvv}></span>}
                </div>
                {!!selectedPlan && !!selectedPlan.installments && 
                  <SelectContainer>
                    <Select 
                      name="installments" 
                      className="select-material" 
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.installments}
                      label={'Parcelas'}>
                        {(installmentsArray || []).map((parcela) => <option key={parcela} value={parcela}>{(parcela)}x de R${ (selectedPlan.value/(parcela)).toFixed(2).replace('.',',') }</option>)}
                    </Select>
                  </SelectContainer>
                }
              </div>
            </div>
          </>}
          <button type="submit" disabled={isSubmitting} className="btn btn-primary">
            Assinar agora!
          </button>
      </Form>
      )}
      </Formik>
    );
};