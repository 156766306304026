import styled from 'styled-components';
import {DIMENSION} from '../../constants';

export const Table = styled.table`
  td, th {
    text-align: center;
  }
  td{
    padding: ${DIMENSION.PADDING_DEFAULT}px 0;
  }
`;