import styled from "styled-components";

export const CampaignFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const CampaignFormFooter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;