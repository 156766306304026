import * as React from 'react';

export function LoggedInComponent({currentUser}) : JSX.Element {
  const {name, email, lastName, avatar} = currentUser;
  return(
      <div className="card">
        <div className="card-content">
          <p>Você está logado como <strong>{name} {lastName}</strong></p>
        </div>
      </div>
  )
}