import * as React from 'react';
import {TextInput, Textarea} from 'react-materialize';
import InputMask from 'react-input-mask';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {
  InputEvent,
  WorkoutProps,
  ExerciseWorkoutSelectProps,
  WorkoutGroupProps,
} from '../../types';
import {
  WorkoutContainer,
  WorkoutContainerHead,
  WorkoutContainerHeader,
  WorkoutContainerEmpty,
  WorkoutContainerBody,
  WorkoutExerciseTitle,
} from './WorkoutComponent.style';
import {
  WorkoutToggleContainer
} from './WorkoutGroups.style';

import {WorkoutGroupContainerMinified} from './WorkoutGroups.style';

import {
  ButtonDeleteComponent,
  ButtonToggleContentClean,
  ButtonState,
} from '../button';
import {ButtonRowHeaderContainer} from '../button/Button.style';

import {
  ExerciseContainer,
  ExerciseContainerHeader,
  ExerciseContainerBody,
  ExerciseColumn,
} from '../exercises/ExerciseComponents.style';

import {ExerciseEmptyComponent, ExerciseComponent} from '../exercises';

import {WorkoutRoutineContext} from '../../context/WorkoutRoutineContext';

type WorkoutEmptyComponentProps = {
  workoutGroup: WorkoutGroupProps;
  workoutGroupIndex: number;
};

type UpdateWorkoutProps = {(workout, index): void};
type WorkoutComponentProps = {
  index: number;
  workoutGroupIndex: number;
  workoutGroup: WorkoutGroupProps;
  workout: WorkoutProps;
  errors: any;
  exerciseListDefault: ExerciseWorkoutSelectProps[];
};
export const WorkoutComponent = ({
  index,
  workout,
  workoutGroup,
  workoutGroupIndex,
  errors = {},
  exerciseListDefault,
}: WorkoutComponentProps): JSX.Element => {
  const {
    updateWorkout,
    deleteWorkout,
    createWorkoutExerciseBySelected,
    updateWorkoutpPosition,
  } = React.useContext(WorkoutRoutineContext);
  const onChangeInput = (event) => {
    const newWorkoutValue = {
      ...workout,
      [event.target.name]: event.target.value,
    };
    updateWorkout(newWorkoutValue, index, workoutGroup)
    ;
  };

  const renderExercisesWorkout = () => {
    const {exerciseWorkouts = []} = workout;
    return exerciseWorkouts
      .filter((exercise) => !exercise['_destroy'])
      .map((exercise, exerciseIndex) => (
        <ExerciseComponent
          key={`exercise-${exercise?.id || exercise.tempId}-${exerciseIndex}`}
          exerciseIndex={exerciseIndex}
          workoutGroupIndex={workoutGroupIndex}
          workoutIndex={index}
          workoutExercise={exercise}
          workout={workout}
          workoutGroup={workoutGroup}
          defaultOptions={[...(exerciseListDefault || []), exercise.exercise]}
        />
      ));
  };

  const toggleContentWorkoutGroup = (value) => {
    setToggleContent(value);
  }

  const [toggleContent, setToggleContent] = React.useState(ButtonState.open);

  const switchToggleContentWorkoutGroup = () => {
    const newState =
      toggleContent === ButtonState.open
        ? ButtonState.closed
        : ButtonState.open;
    setToggleContent(newState);
  };

  return (
    <WorkoutContainer>
      <WorkoutContainerHead>
      <WorkoutToggleContainer>
          <i onClick={ () => updateWorkoutpPosition('up',workoutGroup, index )}  className="far fa-long-arrow-up"/>
          <i onClick={ () => updateWorkoutpPosition('down', workoutGroup, index)} className="far fa-long-arrow-down"/>
        </WorkoutToggleContainer>
        <TextInput
          s={8}
          id={`workoutGroup-${workoutGroupIndex}-workout-${index}`}
          name={`name`}
          onChange={onChangeInput}
          value={workout.name}
          placeholder={`Dê um nome para o bloco do treino (Ex: Aquecimento, força etc)`}
          label={null}
          inputClassName={errors?.name ? 'invalid' : ' '}
          error={errors?.name}
        />
        <div className="col s4 input-field">
          <InputMask
            id={`workoutGroup-${workoutGroupIndex}-workout-${index}-time_text`}
            name={`time_text`}
            type="tel"
            mask={'99:99'}
            maskChar="0"
            onChange={onChangeInput}
            value={workout.time_text || ''}
            placeholder={'Defina um tempo'}
            className={errors?.timeText ? 'invalid' : ' '}
          />
          <span className="helper-text" data-error={errors?.timeText}></span>
        </div>
        <ButtonRowHeaderContainer top={'0'} right={'0'}>
          <ButtonToggleContentClean
            onPress={toggleContentWorkoutGroup}
            buttonState={toggleContent}
            title="Minimize o conteúdo para editar mais facilmente"
          />
          <ButtonDeleteComponent
            onPress={() => deleteWorkout(workout, workoutGroup)}
            name="Etapa do treino"
            confirmMessage={'Você tem certeza que deseja apagar essa etapa de treino?'}
            title="Apagar o treino, suas etapas e exercício"
          />
        </ButtonRowHeaderContainer>
      </WorkoutContainerHead>
      {toggleContent === ButtonState.open && (
        <WorkoutContainerBody>
          <Textarea
            s={12}
            id={`workoutGroup-${workoutGroupIndex}-workout-${index}-description`}
            name={`description`}
            onChange={onChangeInput}
            value={workout.description}
            label={'Descreva o bloco de treino ou observações'}
            placeholder={`Escreva aqui suas observacões`}
            className={errors?.description ? 'invalid' : ' '}
            error={errors?.description}
          />
          <div className="col s12">
            <WorkoutExerciseTitle>Exercícios</WorkoutExerciseTitle>
          </div>
          <div className="col s12">
            <ExerciseContainer>
              <ExerciseContainerBody>
                {renderExercisesWorkout()}
                <ExerciseEmptyComponent
                  workoutGroupIndex={workoutGroupIndex}
                  workoutIndex={index}
                  createExerciseComponent={(selected) => createWorkoutExerciseBySelected(selected, workout, workoutGroup)}
                  defaultOptions={exerciseListDefault || []}
                />
              </ExerciseContainerBody>
            </ExerciseContainer>
          </div>
        </WorkoutContainerBody>
      )}
      {toggleContent === ButtonState.closed && (
        <WorkoutGroupContainerMinified
          onClick={switchToggleContentWorkoutGroup}>
          <i className="far fa-ellipsis-h"></i>
        </WorkoutGroupContainerMinified>
      )}
    </WorkoutContainer>
  );
};

export const WorkoutEmptyComponent = ({
  workoutGroup,
  workoutGroupIndex,
}: WorkoutEmptyComponentProps): JSX.Element => {

  const {
    createWorkout

  } = React.useContext(WorkoutRoutineContext);

  return (
    <WorkoutContainer className='clean-top add-new'>
        <WorkoutContainerEmpty onClick={() => createWorkout(workoutGroup)}>
          Adicionar novo bloco de treino
        </WorkoutContainerEmpty>
    </WorkoutContainer>
  );
};
