import * as React from 'react';
import {Switch, Textarea, TextInput} from 'react-materialize';

import Select from '../../../elements/Select';
import {LoadingComponent} from '../../../components/LoadingComponent';

import {CourseContext} from '../../../context/CourseContext';
import {CourseFeaturedComponent} from '../../../components/courses/CourseFeaturedComponent';

export const FormBaseComponent = () => {
  document.title = `${window.account}/info-produtos`;
  const {
    values, 
    handleSubmitCourse, 
    loading, 
    progress, 
    loadingMessage,
    handleChange,
    setProgramsVisible,
    programsVisible,
    errors,
    listOfPrograms,
    handleListPrograms,
    programsSelected,
    handleSelectCategory,
    categoriesSelected,
    categories,
    setCourseActive,
    courseActive,
  } = React.useContext(CourseContext);

  return (
      <form onSubmit={handleSubmitCourse}>
        <LoadingComponent loading={loading} progress={progress} customMessage={loadingMessage} />
        <h1>Info Produto</h1>
        <div className="row">
          <div className="col s12 l8">
            <CourseFeaturedComponent />
          </div>
          <div className="col s12 l4">
            <div className="flow-text">Detalhes do info produto</div>
            <div className="card">
              <div className="card-content">
                <div className="row">
                  <div className="col s12">
                    <div className="row">
                      <TextInput
                          s={12}
                          id="title"
                          name="title"
                          onChange={handleChange}
                          value={values.title || ''}
                          required
                          inputClassName={
                            !!errors.title ? 'invalid' : ' '
                          }
                          error={errors.title}
                          label="Nome do Info Produto *"
                        />
                      <Textarea
                        s={12}
                        id={`description`}
                        name={`description`}
                        onChange={handleChange}
                        value={values.description || ''}
                        label={'Descrição*'}
                        placeholder={`Escreva aqui a descrição do info produto`}
                        className={errors?.description ? 'invalid' : ' '}
                        error={errors?.description}
                        style={{maxHeight: 120}}
                      />
                      <div className="col s12">
                        <Select
                          isMulti={false}
                          options={categories}
                          placeholder={'Selecione uma categoria'}
                          onChange={handleSelectCategory}
                          selectType="select2"
                          value={categoriesSelected}
                        />
                      </div>
                    </div>
                    <div className="row" style={{marginBottom: 40}}>
                      <div className="col s12">
                        <label>Curso Ativo?</label>
                        <Switch
                          id="courseActive"
                          offLabel="Rascunho"
                          onChange={() => setCourseActive(!courseActive)}
                          onLabel="Ativo"
                          checked={courseActive}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col s12">
                        <label>Acesso ao info produto permitido com qualquer plano?</label>
                        <Switch
                          id="programsVisible"
                          offLabel="Off"
                          onChange={() => setProgramsVisible(!programsVisible)}
                          onLabel="On"
                          checked={!programsVisible}
                        />
                      </div>
                      {programsVisible && 
                        <div className="input-field col s12 input-select">
                          <Select
                            options={listOfPrograms}
                            placeholder={'Digite ou selecione os programas'}
                            onChange={handleListPrograms}
                            selectType="select2"
                            value={programsSelected}
                            noOptionsMessage={() => <a href="">Categoria não encontrada. Quer criar agora?</a>}
                          />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <button
              className="btn btn-primary right"
              type="submit"
              onSubmit={handleSubmitCourse}
              disabled={loading}>
              {loading ? 'Salvando...' : `Salvar e continuar`}
            </button>
          </div>
        </div>
        {/* <h1>Aulas</h1>
        <UnitListComponent />
        <UnitEmptyComponent />
        {!!units.length && (
          <div className="row">
            <div className="col s12">
              <button
                className="btn btn-primary"
                type="submit"
                onSubmit={handleSubmit}
                disabled={loading}>
                Salvar
              </button>
            </div>
          </div>
        )} */}
      </form>
  )
}