import * as React from 'react';
import {Line} from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

import {useQuery} from '@apollo/client';

import {client} from '../../../core/stores';
import {QUERY_DASHBOARD_CHECKINS} from '../../../core/graphql/types';


import {GenerateChartJsToCheckins} from './CheckinChartComponent.services';
import {
  DashboardHeader, 
  DashboardHeaderItem, 
  DashboardLabel, 
  DashboardData, 
  DashboardDataComparative,
} from './CheckinChartComponent.style';

import {LoadingContent} from '../LoadingComponent';

interface DashboardCheckins {
  gympassValidatedCurrentMonth: number;
  gympassValidatedLastMonth: number;
  totalCheckinsCurrentMonth: number;
  totalCheckinsLastMonth: number;
}

export const CheckinChart = React.memo((props: any) : JSX.Element => {
  const [dashboardCheckins, setDashboardCheckins] = React.useState({} as DashboardCheckins);
  const {loading, error, data} = useQuery(QUERY_DASHBOARD_CHECKINS, {variables: {days: 60}});

  React.useEffect(()=>{
    if(data?.dashboardCheckins) setDashboardCheckins(data.dashboardCheckins);
  },[data])

  const {
    gympassValidatedCurrentMonth, 
    gympassValidatedLastMonth,
    totalCheckinsCurrentMonth,
    totalCheckinsLastMonth
  } = dashboardCheckins || {};

  if(loading) return <LoadingContent loading={loading} />

  function getComparative(currentValue, lastValue){
    const result = parseInt(currentValue)-parseInt(lastValue);
    let status, signal;
    if(result > 0){
      status = 'increase';
      signal = '+'
    }else if(result < 0){
      status = 'decrease';
      signal = ''
    }
    return <DashboardDataComparative status={status}>{signal||''} {result}</DashboardDataComparative>
  }

  return(
    <div className="card" style={{minHeight: 300, position: 'relative'}}>
      <div className="card-content" style={{position: 'relative'}}>
        {!!loading && <LoadingContent loading={loading} />}
        <DashboardHeader>
          <DashboardHeaderItem>
            <DashboardLabel>Checkins totais</DashboardLabel>
            <DashboardData>{totalCheckinsCurrentMonth || 0}</DashboardData>
            {getComparative(totalCheckinsCurrentMonth, totalCheckinsLastMonth)}
          </DashboardHeaderItem>
            <DashboardHeaderItem>
            <DashboardLabel>Checkins gympass</DashboardLabel>
            <DashboardData>{gympassValidatedCurrentMonth?.length || 0}</DashboardData>
            {getComparative(gympassValidatedCurrentMonth?.length, gympassValidatedLastMonth?.length)}
          </DashboardHeaderItem>
          <DashboardHeaderItem></DashboardHeaderItem>
        </DashboardHeader>
        <Line 
          data={GenerateChartJsToCheckins(dashboardCheckins)}
          options={
            {
              plugins: {
                legend: {
                  position: 'bottom',
                }
              },
              responsive: true, 
              defaultFontSize: 10, 
              layout: {padding: 0}, 
              legend: {position: 'bottom'},
              scales: {
                x: {
                  grid: {
                      display:false
                  }
                },
                y: {
                  grid: {
                      display:true
                  }
                }
            }
            }
          }/>
      </div>
    </div>
  )
});