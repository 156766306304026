import { MUTATION_CREATE_CHECKIN, MUTATION_CREATE_OR_UPDATE_CHECKIN } from "../../core/graphql/types/checkin";
import { client } from "../../core/stores"

export function MutateCreateCheckin(data) : Promise<any> {
  return new Promise((resolve,reject) => {
    client('account').mutate({
      mutation: MUTATION_CREATE_OR_UPDATE_CHECKIN,
      variables: data,
    })
    .then(response => {
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  })
}