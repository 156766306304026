import styled from 'styled-components';
import {COLOR} from '../../constants';

const getColor = (props) => {
  if (props.isDragAccept) {
      return COLOR.PRIMARY;
  }
  if (props.isDragReject) {
      return COLOR.ERROR;
  }
  if (props.isDragActive) {
      return COLOR.PRIMARY;
  }
  return COLOR.SECONDARY_BODY;
}

export const MediaBox = styled.div`
  align-items: center;
  background-color: transparent;
  border-color: ${props => getColor(props)};
  border-radius: 3px;
  border-radius: 2px;
  border-style: dashed;
  border-width: 2px;
  cursor: pointer;
  color: #bdbdbd;
  display: flex;
  flex: 1;
  flex-direction: column;
  outline: none;
  padding: 20px;
  position: relative;
  transition: border .24s ease-in-out;
`;

export const MediaPreviewContainer = styled.div`
  border-radius: 3px;
  cursor: pointer;
  flex-direction: column;
  margin: 2vh 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  & > div {
    display: flex;
    flex-direction: column;
  }
  img, video{
    max-height: 20vw;
  }
`;

export const MediaPreview = styled.div`
  align-items: center;
  background-color: ${COLOR.SECONDARY_BODY};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 3px;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 20vh;
  justify-content: center;
  position: relative;
  img{
    max-height: 20vh;
  }
  svg{
    height: 5vw;
    width: 5vw !important;
  }
`;

export const MediaGroupContainer = styled.div`
  color: #bdbdbd;
  flex: 1;
  position: relative;
`;

export const MediaPreviewSubtitle = styled.div`
  background-color: rgba(0,0,0,0.3);
  bottom: 0;
  box-sizing: border-box;
  color: #FFFFFF;
  font-weight: 700;
  left: 0;
  padding: 3px;
  position: absolute;
  right: 0;
  text-align: center;
`;

export const MediaRemovePreviewButton = styled.a`
  color: ${COLOR.ERROR}CC;
  cursor: pointer;
  height: 100%;
  margin-left: 10px;
  text-decoration: none;
  &:before{
    content: "\f1f8";
    font-family: 'Font Awesome 6 Pro';
    font-weight: bold;
  }
  &:hover{
    color:${({hoverColor}) => hoverColor ? hoverColor : COLOR.ERROR};
  }
`;

