import {gql} from '@apollo/client';
import {IMAGE_TYPE} from './mediaType';

export const PLAN_TYPE = `
  billingTriggerDay
  billingTriggerType
  code
  cover {
    ${IMAGE_TYPE}
  }
  description
  id
  installments
  name
  planType
  recurrence
  recurrenceValue
  status
  value
`;

export const PLAN_ADMIN_TYPE = `
  billingTriggerDay
  billingTriggerType
  cycles
  code
  cover {
    ${IMAGE_TYPE}
  }
  externalPlans {
    id
    store
  }
  id
  installments
  name
  planPrograms {
    code
    filial {
      code
      id
      name
    }
    id
    name
    planId
    programId
  }
  planType
  recurrence
  recurrenceValue
  status
  value
  visibility
  subject
  body
  subjectOnEnd
  bodyOnEnd
`

export const MUTATION_PLAN = gql`
  mutation createOrUpdatePlan(
    $id: ID,
    $name: String!,
    $cover: Upload,
    $code: String,
    $color: String,
    $planType: String!,
    $description: String,
    $billingTriggerDay: String,
    $billingTriggerType: String,
    $cycles: String,
    $installments: String,
    $recurrence: String,
    $recurrenceValue: String,
    $value: String,
    $visibility: String,
    $planPrograms: [PlanProgramsAttributes!],
    $externalPlans: [ExternalStoreAttributes!],
    $subject: String,
    $body: String,
    $subjectOnEnd: String,
    $bodyOnEnd: String,
  ){
    createOrUpdatePlan(input: {
      id: $id,
      name: $name,
      code: $code,
      color: $color,
      cover: $cover,
      planType: $planType,
      description: $description,
      billingTriggerDay: $billingTriggerDay,
      billingTriggerType: $billingTriggerType,
      cycles: $cycles,
      installments: $installments,
      recurrence: $recurrence,
      recurrenceValue: $recurrenceValue,
      value: $value,
      visibility: $visibility,
      planPrograms: $planPrograms,
      externalPlans: $externalPlans,
      subject: $subject,
      body: $body,
      subjectOnEnd: $subjectOnEnd,
      bodyOnEnd: $bodyOnEnd,
    }){
      plan {
        ${PLAN_ADMIN_TYPE}
      }
    }
  }
`