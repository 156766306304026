import { gql } from "@apollo/client";

export const CREATE_OR_UPDATE_USER_MUTATION = gql`
  mutation CreateOrUpdateUser(
    $id: ID
    $name: String!
    $lastName: String!
    $email: String!
    $phone: String
    $cpf: String
    $gender: String
    $birthdate: String
    $gympassId: String
    $height: String
    $avatar: Upload
    $status: String
    $origin: String
  ) {
    createOrUpdateUser(input: {
      id: $id
      name: $name
      lastName: $lastName
      email: $email
      phone: $phone
      cpf: $cpf
      gender: $gender
      birthdate: $birthdate
      gympassId: $gympassId
      height: $height
      avatar: $avatar
      status: $status
      origin: $origin
    }) {
      user {
        id
        name
        lastName
        email
        phone
        cpf
        gender
        birthdate
        gympassId
      }
    }
  }
`