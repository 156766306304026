import * as React from 'react';
import {MutationDeleteProgram} from '../../../services/program/MutationProgram';
import {Table} from './ProgramsTableComponent.style';

export function ProgramsTableComponent({programs = [], userPadawan}) : JSX.Element {
  const [loading, setLoading] = React.useState(false);
  
  const deleteProgram = (program) => {
    if(loading) return false;
    const confirmed = confirm(`Você tem certeza que quer excluir o programa ${program.name}?`);
    if(confirmed){
      setLoading(true);
      MutationDeleteProgram(program)
      .then(({program}) => {
        document.location.reload(true);
      })
      .catch(err => {
        setLoading(false);
        console.log('error => ', err)
      });
    } else {
      setLoading(false);
    }
  }
  return(
    <Table>
      <thead>
        <tr className="center">
          <th>Código</th>
          <th>Nome</th>
          <th>Filial</th>
          <th>Info produtos vinculados</th>
          <th>Açes</th>
        </tr>
      </thead>
      <tbody>
        {programs.map(program => (
          <tr key={program.id}>
            <td><a href={`/${window.account}/admin/programs/${program.id}/${userPadawan ? 'edit' : ''}`}>{program.code}</a></td>
            <td><a href={`/${window.account}/admin/programs/${program.id}/${userPadawan ? 'edit' : ''}`}>{program.name}</a></td>
            <td>{program.filial?.name}</td>
            <td>
              {program.courses?.map(course =>
                <span>{course.name} </span>
              )}
            </td>
            <td>
              <a href={`/${window.account}/admin/programs/${program.id}`}>ver horários</a>
              {!!userPadawan && 
              <>
                {' | '}
                <a href="#" onClick={(event) => {event.preventDefault(); deleteProgram(program) }} className="red-text">apagar</a>
              </>
              }
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}