import {gql} from '@apollo/client';

export const MUTATION_ACCOUNT_PLAN_RELATIONSHIP = gql`
  mutation createOrUpdateAccountPlanRelationship(
    $id: ID,
    $accountId: ID!,
    $accountPlanId: ID!,
    $startPlan: String,
    $discountValue: String,
    $discountType: String,
    $store: String,
    $paymentMethod: String,
    $installments: String
  ){
    createOrUpdateAccountPlanRelationship(input: {
      id: $id,
      accountId: $accountId,
      accountPlanId: $accountPlanId,
      startPlan: $startPlan,
      discountValue: $discountValue,
      discountType: $discountType,
      store: $store,
      paymentMethod: $paymentMethod,
      installments: $installments
    }){
      accountPlanRelationship{
        id,
        accountId,
        accountPlanId,
        status,
        planValue,
        startPlan,
        endPlan,
        installments,
        planType,
        planTypeValue,
        recurrence,
        discountValue,
        discountType,
        totalValue,
        store,
        paymentMethod,
        externalSubscriptionId,
        externalPeriodId,
        token
      }
    }
  }
`;