import { clientAccount } from "../../core/stores";

import { MUTATION_CREATE_REVIEW } from "../../core/graphql/types";

export function MutationCreateReview(reviewForm, setSubmitting) {
  return new Promise((resolve, reject) => {
    const { rating, comment, reviewable: reviewableType, reviewableId } = reviewForm;
    setSubmitting(true);
    if (!rating || !reviewableType || !reviewableId) {
      M.toast({
        html: "Para avaliar, você precisa preencher todos os campos",
        displayLength: 5000
      });
      reject(false);
      setSubmitting(false);
      return;
    }
    if (rating && (rating < 1 || rating > 5)) {
      M.toast({
        html: "Para avaliar, você precisa preencher uma nota válida",
        displayLength: 5000
      });
      reject(false);
      setSubmitting(false);
      return;
    }
    if(comment && comment.length > 140){
      M.toast({
        html: "Para avaliar, você precisa preencher um comentário com no máximo 140 caracteres",
        displayLength: 5000
      });
      reject(false);
      setSubmitting(false);
      return;
    }

    const reviewMutation = {
      rating,
      comment,
      reviewableType,
      reviewableId,
    }

    clientAccount
      .mutate({
        mutation: MUTATION_CREATE_REVIEW,
        variables: reviewMutation
      })
      .then(response => {
        const { data } = response;
        const { review } = data || {};
        M.toast({ html: "Avaliação realizada com sucesso!" });
        setSubmitting(false);
        resolve({ status: "success", review });
      })
      .catch(err => {
        console.log("ERROR ==> ", err);
        setSubmitting(false);
        const errorMessage = err.graphQLErrors
          .map(error => error.message)
          .join(",");
        M.toast({ html: "Houve um erro na sua solicitação." });
        M.toast({ html: errorMessage });
        reject({ status: "error", message: errorMessage });
      });
  });
}
