import styled from 'styled-components';

export const WorkoutGroupTitle = styled.h2`
  color: #777777AA !important;
  font-size: 1.5em;
  font-weight: 900;
  text-align: center;
  color: palevioletred;
  margin-bottom: 15px;
  text-align: left;
`;

export const WorkoutTitle = styled.h3`
  color: #777777AA !important;
  font-size: 1.1em;
  font-weight: 600;
  text-align: center;
  color: palevioletred;
  margin-bottom: 15px;
  text-align: left;
`;

export const WorkoutGroupPreviewContainer = styled.div`
  background-color: #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
  padding: 10px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const WorkoutPreviewContainer = styled.div`
  border-top: 1px solid #00000033;
  border-bottom: 1px solid #00000033;
  padding: 10px 0;
`;

export const WorkoutPreviewDescription = styled.p`
  white-space: pre-line;
`;