import * as React from 'react';
import {Row, Textarea} from 'react-materialize';
import Select from 'react-select';

import {UploadMediaFile} from '../media';

import {AccountSettingsContext} from '../../context/AccountSettingsContext';
import {ColumnContainer, SelectContainer} from './AccountContainer.style';
import { AvatarWrapper } from '../../routes/register/RegisterForm.style';
import { AvatarIconMan, AvatarIconWoman } from '../../routes/register/RegisterForm.icon';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectCategory, SelectSubCategory } from './SelectCategory.component';
import { InputStyled, SelectStyled } from '../../elements/forms/InputContainer';
import { accountTypeValues, statusValues, visibilityValues } from './Account.schema';

export const AccountsGeneralComponent = () : JSX.Element => {
  const {scope, categories: categoriesProps, account} = React.useContext(AccountSettingsContext)

  const categories = categoriesProps?.map( (category: any) => ({
    label: category.name,
    value: category.code
  }))
  
  const [defaultAvatar] = React.useState<number>(Math.random());
  
  const {
    control, 
    setValue,
    getValues,
    formState: {errors}
  } = useFormContext();

  console.log('errors =======>', errors)
  
  const [accountCategory, setAccountCategory] = React.useState<string>('');

  const values = getValues();

  React.useEffect(() => {
    if(values.category) {
      setAccountCategory(values.category as string);
    }
    if(values.subcategory) {
      // setAccountSubCategory(values.subcategory);
    }
  }, [values.category, values.subcategory]);


  const setUploadImage = (file: any) => {
    setValue('avatar', file);
  };

  return(
    <>
      <div className="row">
        <div className="col s12">
          <h2>Configurações gerais</h2>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m6">
          <Controller
            name="avatar"
            control={control}
            render={({field}) => (
              <AvatarWrapper>
              <UploadMediaFile propFile={field.value} setUploadFile={setUploadImage} mediaType='image'>
                {defaultAvatar < 0.5 ? <AvatarIconMan style={{opacity: 0.5, filter: 'grayscale(100%)'}}/> : <AvatarIconWoman style={{opacity: 0.5, filter: 'grayscale(100%)'}}/>}
              </UploadMediaFile>
              <Row>
                <span>Clique na imagem para escolher sua imagem de perfil - sua marca ou imagem profissional que será usada para divulgar seu trabalho</span>
                </Row>
              </AvatarWrapper>
            )}
          />

        <div>

        <Controller
              name="status"
              control={control}
              render={({field, field: {value}}) => (
                <SelectContainer className="col s12">
                  <SelectStyled
                    {...field}
                    onChange={(status) => {
                      field.onChange(status.value);
                    }}
                    value={statusValues.find(status => status.value === value)}
                    label="Status da Conta"
                    placeholder="Selecione um status"
                    disabled={!(scope === 'alderaan')}
                    options={statusValues}
                    className={errors?.bio?.message ? 'invalid' : ' '}
                    />
                </SelectContainer>
              )}
            />
        </div>
        </div>

        <div className="col s12 m6">
          <div className="row">
            <Controller
              name="name"
              control={control}
              render={({field}) => (
                <InputStyled 
                  {...field}
                  s={12}
                  error={errors?.name?.message} 
                  label="Nome" 
                  placeholder="Nome"
                  className={errors?.name ? 'invalid' : ' '} 
                />
              )}
            />

            <Controller
              name="code"
              control={control}
              render={({field}) => (
                <InputStyled 
                  {...field}
                  s={12}
                  error={errors?.code?.message} 
                  label="Código da conta" 
                  placeholder="Código da conta"
                  disabled={scope !== 'alderaan'}
                  className={errors?.code ? 'invalid' : ' '} 
                />
              )}
            />
            <Controller
              name="bio"
              control={control}
              render={({field, field: {value}}) => (
                <Textarea
                  {...field}
                  s={12}
                  error={errors?.bio?.message}
                  value={value || ''}
                  label="Descrição"
                  placeholder={account?.accountType === 'personal' ? "Descreva sua formação, especialidades e experiências" : "Descreva os serviços que oferece, seus diferenciais e como pode ajudar"}
                  className={errors?.bio?.message ? 'invalid' : ' '}
                />
              )}
            />
            <Controller
              name="category"
              control={control}
              render={({field: {ref, onChange, value}}) => (
                <SelectCategory
                  ref={ref}
                  key={value}
                  label="Área de atuação"
                  placeholder="Escolha uma opção"
                  defaultOptions={categories}
                  errors={errors?.category?.message}
                  value={categories.find(category => category.value == value)}
                  onSelect={({value}: {value: string}) => {
                    onChange(value);
                    setAccountCategory(value);
                  }}
                />
                )}
            />
            {accountCategory.length > 0 && (
            <Controller
              name="subcategory"
              control={control}
              render={({field: {ref, onChange, value}}) => (
                <SelectSubCategory
                  ref={ref}
                  label="Detalhe um pouco mais"
                  placeholder="Escolha uma opção"
                  categoryCode={accountCategory}
                  // value={subcategories.find(subcategory => subcategory.code === value)}
                  errors={errors?.subcategory?.message}
                  onSelect={({value}: {value: string}) => {
                    onChange(value);
                  }}
                />
                )}
              />
          )}
          </div>
          {scope === 'alderaan' && (
            <ColumnContainer>
              <div>
              <Controller
                  name="accountType"
                  control={control}
                  render={({field, field: {value}}) => (
                      <SelectStyled
                        {...field}
                        onChange={(status) => {
                          field.onChange(status.value);
                        }}
                        value={accountTypeValues.find(type => type.value === value)}
                        label="Tipo de Conta"
                        placeholder="Selecione um tipo"
                        disabled={!(scope === 'alderaan')}
                        options={accountTypeValues}
                        className={errors?.accountType?.message ? 'invalid' : ' '}
                        />
                  )}
                />

              </div>
              <div>

              <Controller
                  name="visibility"
                  control={control}
                  render={({field, field: {value}}) => (
                      <SelectStyled
                        {...field}
                        value={visibilityValues.find(type => type.value === value)}
                        onChange={(status) => {
                          field.onChange(status.value);
                        }}
                        label="Visibilidade da Conta"
                        placeholder="Selecione..."
                        disabled={!(scope === 'alderaan')}
                        options={visibilityValues}
                        className={errors?.visibility?.message ? 'invalid' : ' '}
                        />
                  )}
                />
              </div>
            </ColumnContainer>
          )}
        </div>
      </div>
    </>
  )
}