import * as React from 'react';
import {Select, TextInput, Switch} from 'react-materialize';
import {FilialContext} from '../../../context/admin/filials/Filial.context'
import { SelectStyled } from '../../../elements/forms/InputContainer';

export function FilialFormComponent() : JSX.Element {
  const {
    account,
    filial, 
    errors, 
    handleChange, 
    scope,
    loading,
    visibilities,
    handleSelectFilialType,
    handleSelectVisibility,
    handleZipcode,
    handleSubmit,
    visibility,
  } = React.useContext(FilialContext);
  console.log("account.accountType", account.accountType)
  return(
      <form action="" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col s12">
            <div className="card">
              <div className="card-content">
                <div className="card-title">Informações</div>

                <div className="row">
                  <div className="col s6 m3">
                    <label htmlFor="filialType">Tipo</label>
                    <Switch
                      offLabel="Físico"
                      onLabel="Online"
                      id="filialType"
                      name="filialType"
                      checked={filial.filialType == 'online'}
                      onChange={() => handleSelectFilialType({value: filial.filialType == 'online' ? 'place' : 'online'})}
                    />
                  </div>
                </div>

                <div className="row">
                  <TextInput 
                    id='name'
                    label='Nome'
                    name='name'
                    s={12}
                    m={8}
                    value={filial.name}
                    error={errors.name}
                    onChange={handleChange}
                    inputClassName={errors?.name ? 'invalid' : ' '}
                  />
                  <TextInput
                    id='code'
                    label='Código'
                    name='code'
                    s={12}
                    m={4}
                    value={filial.code}
                    error={errors.code}
                    onChange={handleChange}
                    inputClassName={errors?.code ? 'invalid' : ' '}
                    disabled={scope == 'edit'}
                  />
                </div>

                <div className="row">
                  <div className="col s6 m3">
                    <SelectStyled
                      options={visibilities}
                      placeholder={'Visibilidade'}
                      label={'Visibilidade'}
                      onChange={handleSelectVisibility}
                      selectType="select"
                      isMulti={false}
                      value={visibility}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        {filial.filialType == 'place' && (
          <div className="row">
            <div className="col s12">
              <div className="card">
                <div className="card-content">
                  <div className="card-title">Endereço</div>
                  <div className="row">
                    <TextInput 
                      id='zipcode'
                      label='CEP'
                      name='zipcode'
                      type='tel'
                      onBlur={handleZipcode}
                      s={8}
                      m={3}
                      value={filial.zipcode}
                      error={errors.zipcode}
                      onChange={handleChange}
                      inputClassName={errors?.zipcode ? 'invalid' : ' '}
                    />
                    <div className="col s2 m2 zipcode-consult">
                      <a href="#" onClick={(event)=> {event.preventDefault(); handleZipcode({target:{value: filial.zipcode}})}} className={`btn ${loading ? 'disabled' : ''}`}>{loading ? 'Processando...' : 'Consultar'}</a>
                    </div>
                  </div>
                  <div className="row">
                    <TextInput
                      id='street'
                      label='Rua/Avenida'
                      name='street'
                      s={9}
                      value={filial.street}
                      error={errors.street}
                      onChange={handleChange}
                      inputClassName={errors?.street ? 'invalid' : ' '}
                      disabled={loading}
                    />
                    <TextInput
                      id='number'
                      label='Número'
                      name='number'
                      s={3}
                      value={filial.number}
                      error={errors.number}
                      onChange={handleChange}
                      inputClassName={errors?.number ? 'invalid' : ' '}
                    />
                    <TextInput
                      id='complement'
                      label='Complemento'
                      name='complement'
                      s={6}
                      value={filial.complement}
                      error={errors.complement}
                      onChange={handleChange}
                      inputClassName={errors?.complement ? 'invalid' : ' '}
                    />
                    <TextInput
                      id='neighborhood'
                      label='Bairro'
                      name='neighborhood'
                      s={6}
                      value={filial.neighborhood}
                      error={errors.neighborhood}
                      onChange={handleChange}
                      inputClassName={errors?.neighborhood ? 'invalid' : ' '}
                      disabled={loading}
                    />
                    <TextInput
                      id='city'
                      label='Cidade'
                      name='city'
                      s={6}
                      value={filial.city}
                      error={errors.city}
                      onChange={handleChange}
                      inputClassName={errors?.city ? 'invalid' : ' '}
                      disabled={loading}
                    />
                    <TextInput
                      id='state'
                      label='Estado'
                      name='state'
                      s={6}
                      value={filial.state}
                      error={errors.state}
                      onChange={handleChange}
                      inputClassName={errors?.state ? 'invalid' : ' '}
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
        </div>
        )}
        {account.accountType != 'personal' && (
        <div className="row">
          <div className="card">
            <div className="card-content">
            <div className="card-title">Integração com Pass Checkins</div>
              <div className="row">
                <div className="col s12"><h4>Gympass</h4></div>
                <div className="col s12">
                  <TextInput
                    id='gymId'
                    label='ID da academia'
                    name='gymId'
                    s={6}
                    value={filial.gymId}
                    error={errors.gymId}
                    onChange={handleChange}
                    inputClassName={errors?.gymId ? 'invalid' : ' '}
                    disabled={loading}
                  />
                  <TextInput
                    id='integrationtoken'
                    label='Token de integração'
                    name='token'
                    s={6}
                    value={filial.token}
                    error={errors.token}
                    onChange={handleChange}
                    inputClassName={errors?.token ? 'invalid' : ' '}
                    disabled={loading}
                  />
                  <div className="col s6">
                    <Select 
                      id='passIntegration'
                      label='Versão de integração com Gympass'
                      name='passIntegration'
                      s={6}
                      value={filial.passIntegration}
                      error={errors.passIntegration}
                      onChange={handleChange}
                      disabled={loading}
                    >
                      <option value=""></option>
                      <option value="gympass_v1">V1</option>
                      <option value="gympass_v2">V2</option>
                    </Select>
                  </div>
                  <div className="col s12"><h4>Total pass</h4></div>
                  <TextInput
                    id='totalpassToken'
                    label='Token de integração'
                    name='totalpassToken'
                    s={6}
                    value={filial.totalpassToken}
                    error={errors.totalpassToken}
                    onChange={handleChange}
                    inputClassName={errors?.totalpassToken ? 'invalid' : ' '}
                    disabled={loading}
                  />
                  <TextInput
                    id='totalpassServiceProvider'
                    label='Código do serviço'
                    name='totalpassServiceProvider'
                    s={6}
                    value={filial.totalpassServiceProvider}
                    error={errors.totalpassServiceProvider}
                    onChange={handleChange}
                    inputClassName={errors?.totalpassServiceProvider ? 'invalid' : ' '}
                    disabled={loading}
                  />
                  <TextInput
                    id='totalpassBearerToken'
                    label='Token de Booking Totalpass'
                    name='totalpassBearerToken'
                    s={6}
                    value={filial.totalpassBearerToken}
                    error={errors.totalpassBearerToken}
                    onChange={handleChange}
                    inputClassName={errors?.totalpassBearerToken ? 'invalid' : ' '}
                    disabled={loading}
                  />
                  <TextInput
                    id='totalpassPlanCode'
                    label='Código do plano Totalpass'
                    name='totalpassPlanCode'
                    s={6}
                    value={filial.totalpassPlanCode}
                    error={errors.totalpassPlanCode}
                    onChange={handleChange}
                    inputClassName={errors?.totalpassPlanCode ? 'invalid' : ' '}
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        )}

        <div className="row">
          <div className="col s12"><button onSubmit={handleSubmit} type="submit" className='btn btn-primary' disabled={loading}>{loading ? 'Processando...' : 'Salvar'}</button></div>
        </div>
      </form>
  )
}