import * as React from 'react';
import {Line} from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

import {GenerateChartJsToUserPlans} from './UserPlansCartComponent.service';
import {
  DashboardHeader, 
  DashboardHeaderItem, 
  DashboardLabel, 
  DashboardData, 
  DashboardDataComparative,
} from './CheckinChartComponent.style';

import {LoadingContent} from '../LoadingComponent';

export const UserPlanChart = ({loading, dashboardPlans = {}} : {loading: boolean, dashboardPlans: any}) : JSX.Element => {

  const {
    lastDaysUserPlans, 
    lastDaysUserPlansTotal, 
  } = dashboardPlans || {};

  function getComparative(currentValue, lastValue){
    const result = parseInt(currentValue)-parseInt(lastValue);
    let status, signal;
    if(result > 0){
      status = 'increase';
      signal = '+'
    }else if(result < 0){
      status = 'decrease';
      signal = '-'
    }
    return <DashboardDataComparative status={status}>{signal||''} {result}</DashboardDataComparative>
  }
  return(
    <div className="card" style={{minHeight: 300, position: 'relative'}}>
      <div className="card-content">
        {!!loading && <LoadingContent loading={loading} />}
        <DashboardHeader>
          <DashboardHeaderItem>
            <DashboardLabel>Novos planos</DashboardLabel>
            <DashboardData>{lastDaysUserPlansTotal || 0}</DashboardData>
          </DashboardHeaderItem>
          <DashboardHeaderItem></DashboardHeaderItem>
        </DashboardHeader>
        <Line 
          data={GenerateChartJsToUserPlans(dashboardPlans)} 
          options={
            {
              maxHeight: 350,
              responsive: true, 
              defaultFontSize: 10, 
              layout: {padding: 0}, 
              legend: {position: 'bottom'},
              scales: {
                x: {
                  grid: {
                    display:false
                  }
                },
                y: {
                  grid: {
                    display:true
                  }
                }
            }
            }
          }/>
      </div>
    </div>
  )
}