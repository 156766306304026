import { gql } from "@apollo/client";

export const MUTATION_CREATE_REVIEW = gql`
  mutation createReview(
    $rating: String!,
    $comment: String,
    $reviewableType: String!,
    $reviewableId: ID,
  ){
    createReview(input: {
      rating: $rating,
      comment: $comment,
      reviewableType: $reviewableType,
      reviewableId: $reviewableId,
    }){
      review{
        id,
        rating,
        comment,
        reviewableType,
        reviewableId,
      }
    }
  }
`;