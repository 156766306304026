import * as React from 'react';
import {TextInput, Select} from 'react-materialize';
import {SelectContainer} from './ProgramForm.style';
import {MutationProgram} from '../../../services/program/MutationProgram'

function ProgramForm({program, filials, action = 'Atualizar'}) : JSX.Element {
  document.title = `${window.account}/programas/${program.code}`

  const [programState, setProgramState] = React.useState(program);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [advancedOptions, setAdvancedOptions] = React.useState(false);

  const handleChange = ({target}) => {
    const newProgram = {...programState, [target.name]: target.value}
    setProgramState(newProgram);
  }

  const showFilials = React.useMemo(() => {
    return !!filials && filials?.length > 1
  }, [filials])
  
  const handleSubmit = React.useCallback((event) => {
    event.preventDefault();
    setLoading(true);
    setErrors({});
    console.log('handleSubmit ', programState);
    if(!programState.name || !programState.filialId){
      const newErrors = {
        name: !programState.name,
        filialId: !programState.filialId
      }
      setErrors(newErrors);
      setLoading(false);
      return false;
    }

    MutationProgram(programState, setLoading)
    .then(({program : programUpdated}) => {
      setErrors({});
      setLoading(false);
      setProgramState(programUpdated);
      if(!program.id) window.history.replaceState("object or string", null, `/${window.account}/admin/programs/${programUpdated.id}/edit`);
    })
    .catch(err => {
      if(err.name){
        setErrors({name: true})
      }
      setLoading(false);
    })
  },[programState]);

  React.useEffect(() => {
    const {passTypeNumber, programId, maximumCheckinsPerDay, filialId} = program;
    if(passTypeNumber || programId || maximumCheckinsPerDay) setAdvancedOptions(true);

    if(!filialId || !programState.filialId){
      const newProgram = { ...programState, filialId: filials[0].id }
      setProgramState(newProgram);
    }
  }, [])

  return(
    <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col s12">
        <div className="card">
          <div className="card-content">
            <div className="row">
              <TextInput 
                s={12} 
                m={showFilials ? 6 : 12}
                id="name"
                name="name"
                label="Nome no programa de treino*" 
                value={programState.name || ''} 
                error={errors.name} 
                inputClassName={errors?.name ? 'invalid' : ' '} 
                onChange={handleChange}
              />
              {showFilials && <div className="col s12 m6">
                <SelectContainer>
                  <div className="row">
                    <Select
                      s={12}
                      name="filialId"
                      id="filialId"
                      onChange={handleChange}
                      value={programState.filialId || filials[0].id}
                      className={errors?.filialId ? 'invalid' : ' '} 
                      label="Filial*"
                    >
                      {(filials||[]).map( filial => <option key={filial.id} value={filial.id}>{filial.name}</option>)}
                    </Select>
                  </div>
                </SelectContainer>
              </div>}
            </div>
            {!advancedOptions && <div className="row">
              <div className="col s12">
                <a href="#" onClick={() => setAdvancedOptions(!advancedOptions)}>Configurações avançadas</a>
              </div>
            </div>}
            {!!advancedOptions && <>
            <div className="row">
              <div className="col s12">
                <div className="flow-text">Mais configurações</div>
              </div>
            </div>
            <div className="row">
              <TextInput 
                s={6} 
                id="code"
                name="code"
                label="Código do programa" 
                value={programState.code || ''} 
                error={errors.code} 
                inputClassName={errors?.code ? 'invalid' : ' '} 
                disabled={!!program.code}
                onChange={handleChange}
              />
              <TextInput 
                s={6} 
                id="maximumCheckinsPerDay"
                name="maximumCheckinsPerDay"
                label="Máx. checkins/dia" 
                value={programState.maximumCheckinsPerDay || ''} 
                error={errors.maximumCheckinsPerDay} 
                inputClassName={errors?.maximumCheckinsPerDay ? 'invalid' : ' '} 
                onChange={handleChange}
                type="tel"
              />
            </div>
            <div className="row">
              <div className="col s12">
                <div className="flow-text">Configurações Gympass</div>
              </div>
            </div>
            <div className="row">
              <TextInput 
                s={6} 
                id="passTypeNumber"
                name="passTypeNumber"
                label="Pass type number" 
                value={programState.passTypeNumber || ''} 
                error={errors.passTypeNumber} 
                inputClassName={errors?.passTypeNumber ? 'invalid' : ' '} 
                onChange={handleChange}
                type="tel"
              />
              <TextInput 
                s={6} 
                id="programId"
                name="programId"
                label="ID do programa" 
                value={programState.programId || ''} 
                error={errors.programId} 
                inputClassName={errors?.programId ? 'invalid' : ' '} 
                onChange={handleChange}
                type="tel"
              />
            </div>
            </>}
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col s12"><button className="right btn btn-primary" type="submit" disabled={loading}>{loading ? 'Enviando...' : action}</button></div>
    </div>
    </form>
  )
}

export {ProgramForm};