import * as React from 'react';
import {CourseCategoriesTableComponent} from '../../../components/courses/CourseCategoriesTableComponent';
import Breadcrumb from '../../../components/Breadcrumb';

function CourseCategories({courseCategories}) : JSX.Element {
  document.title = `${window.account}/categorias de info produto`;
  const BreadcumbsData = [
    {
      url: `/${window.account}/admin/course-categories/`,
      icon: 'graduation-cap',
      label: 'Info Produtos',
    },
    {
      label: 'Categorias',
      url: null
    }
  ]
  return(
    <>
    <div className="row">
      <div className="col s12">
        <Breadcrumb data={BreadcumbsData} />
      </div>
    </div>
    <div className="row">
      <div className="col s12">
        <h1>Categorias de Info Produtos</h1>
      </div>
    </div>
    <div className="row">
      <div className="col s12"><a href={`/${window.account}/admin/course-categories/new`} className="right btn btn-primary">Criar nova categoria</a></div>
    </div>
    <div className="card">
      {courseCategories.length ? <CourseCategoriesTableComponent categories={courseCategories} /> : (
        <div className="card-content">
          <div className="flow-text">Você não criou nenhuma categoria ainda</div>
          <p>Crie agora mesmo. As categorias ajudam a organizar e divulgar seu conteúdo</p>
        </div>
      )}
    </div>
    </>
  )
}

export default CourseCategories;