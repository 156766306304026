import axios from 'axios';

export function GetPlanInfo(planCode, setLoading) : Promise<any>{
  return new Promise((resolve, reject) => {
    setLoading(true);
    axios.get(`?plan_code=${planCode}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(({data}) => {
      const {plan} = data;
      if(!plan){
        reject('Plano não encontrado');
      } else {
        document.title = `${plan.name} - ${document.title}`;
        window.dataLayer.push({
          'event': 'ConsultCheckout',
          'status': '200',
          'planCode': planCode
        });
        resolve({plan});
      }
        

      resolve(data);
    })
    .catch((error) => {
      M.toast({html: error.message })
      console.log(' error no plano : ', error)
      window.dataLayer.push({
        'event': 'ConsultCheckout',
        'status': '404',
        'planCode': planCode
      });
      reject(error);
    })
    .finally(() => {
      setLoading(false);
    });
  });
}

function setPurchaseDataLayer(checkoutForm){
  return window.dataLayer.push({
    event: 'purchase',
    ecommerce:{
      transaction_id: checkoutForm.checkout.customer.email,
      value: checkoutForm.checkout.plan.value,
      currency: 'BRL',
      products:[{
        affiliation: 'Checkout Millbody',
        id: checkoutForm.checkout.plan_code,
        name: checkoutForm.checkout.plan.name,
        price: checkoutForm.checkout.plan.value,
        quantity: 1,
      }],
      items: [{
        affiliation: 'Checkout Millbody',
        id: checkoutForm.checkout.plan_code,
        name: checkoutForm.checkout.plan.name,
        price: checkoutForm.checkout.plan.value,
        quantity: 1,
      }]
    }
  });
}

function setPaymentInfoDataLayer(checkoutForm){
  return window.dataLayer.push({
    event: 'add_payment_info',
    ecommerce:{
      currency: 'BRL',
      products:[{
        affiliation: 'Checkout Millbody',
        id: checkoutForm.checkout.plan_code,
        name: checkoutForm.checkout.plan.name,
        price: checkoutForm.checkout.plan.value,
        quantity: 1,
      }],
      items: [{
        affiliation: 'Checkout Millbody',
        id: checkoutForm.checkout.plan_code,
        name: checkoutForm.checkout.plan.name,
        price: checkoutForm.checkout.plan.value,
        quantity: 1,
      }]
    }
  });
}

export function CreateSubscription(checkoutForm, setLoading) : Promise<any>{
  return new Promise((resolve, reject) => {
    setLoading(true);
    axios.post('', checkoutForm, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(({data}) => {
      if(checkoutForm.checkout.plan.store != 'stripe'){
        setPurchaseDataLayer(checkoutForm);
        resolve(data);
      } else {
        const {checkout_type, url} = data;
        if(checkout_type == 'redirect'){
          setPaymentInfoDataLayer(checkoutForm);
          window.location.replace(url);
        } else {
          reject(data);
        }
      }
    })
    .catch((error) => {
      M.toast({html: error.message })
      console.log(' error no plano : ', error)
      reject(error);
    })
    .finally(() => {
      setLoading(false);
    });
  });
}

export const SetStyle = ({colors, dimensions, fontSizes}) => {
  const checkoutApp = document.getElementById('checkout-app');
  let style = document.createElement('style');
  let css = `
    body, input, select, p, span {
      color: ${colors.text || '#333333'};
    }
    body, #checkout-app {
      background-color: ${colors.background || '#EEEEEE'} !important;
    }
    .container {
      background-color: ${colors.body || '#EEEEEE'};
      padding: ${dimensions.marginDefault || 10}px !important;
    }
    .btn {
      box-sizing: border-box !important;
      padding: 0 ${dimensions.marginDefault || 10}px !important;
    }
    .btn-primary{
      background-color: ${colors.primary || '#039be5'};
      border-radius: ${dimensions.borderRadius || 4}px;
      color: ${colors.primaryText || '#FFF'} 
    }
    a{
      color: ${colors.primary || '#039be5'} !important;
    }
    .checkout-form .input-field {
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      width: 100%;
    }
    .errors {
      font-size: ${fontSizes.small || '10'}px;
      padding: 0 ${dimensions.marginDefault || 10}px !important;
      margin-top: -15px;
    }
    h1{
      font-size: ${fontSizes.h1 || '32'}px;
      font-weight: 900;
    }
    h2{
      font-size: ${fontSizes.h2 || '28'}px;
      font-weight: 900;
    }
    .flow-text{
      font-weight: 900;
    }
    .form-select .input-field{
      width: 100%;
    }
    .col > .col {
      padding: 0px !important;
    }
    input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
      border-bottom: 1px solid ${colors.primary || '#039be5'};
      box-shadow: 0 1px 0 0 ${colors.primary || '#039be5'};
    }
    input:not([type]):focus:not([readonly])+label, input[type=text]:not(.browser-default):focus:not([readonly])+label, input[type=password]:not(.browser-default):focus:not([readonly])+label, input[type=email]:not(.browser-default):focus:not([readonly])+label, input[type=url]:not(.browser-default):focus:not([readonly])+label, input[type=time]:not(.browser-default):focus:not([readonly])+label, input[type=date]:not(.browser-default):focus:not([readonly])+label, input[type=datetime]:not(.browser-default):focus:not([readonly])+label, input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label, input[type=tel]:not(.browser-default):focus:not([readonly])+label, input[type=number]:not(.browser-default):focus:not([readonly])+label, input[type=search]:not(.browser-default):focus:not([readonly])+label, textarea.materialize-textarea:focus:not([readonly])+label {
      color: ${colors.primary || '#039be5'};
    }
    .errors{
      color: red;
    }
  `;
  style.appendChild(document.createTextNode(css));
  checkoutApp.prepend(style);
}