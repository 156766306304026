import {gql} from '@apollo/client';
import {EXERCISE_TYPE} from './exercises';
import { AUDIO_TYPE, IMAGE_TYPE, VIDEO_TYPE } from './mediaType';

export * from './alderaan/accountPlanType';
export * from './alderaan/accountPlanRelationshipType';
export * from './accountType';
export * from './courseCategory';
export * from './exercises';
export * from './users';
export * from './dashboards';
export * from './courses';
export * from './units';
export * from './program';
export * from './plan';
export * from './programClass';
export * from './auth.type';
export * from './admin/filial.type';
export * from './review.type';
export * from './accountCategory.type';

export const EXERCISE_WORKOUT_TYPE = `
  id
  load
  notes
  repetitions
  rounds
  time
  timeText
  exercise{
    ${EXERCISE_TYPE}
  }
`;

export const WORKOUT_TYPE = `
  id
  name
  checkinEnabled
  description
  ordering
  time
  timeText
  exerciseWorkouts{
    ${EXERCISE_WORKOUT_TYPE}
  }
`;

export const WORKOUT_GROUP_TYPE = `
  id
  name
  description
  video{
    ${VIDEO_TYPE}
  }
  audio{
    ${AUDIO_TYPE}
  }
  cover{
    ${IMAGE_TYPE}
  }
  attachment{
    ${VIDEO_TYPE}
  }
  workouts{
    ${WORKOUT_TYPE}
  }
`;

export const WORKOUT_GROUP_ROUTINE = `
  id
  name
  startDate
  endDate
  cycleType
  target
  showOnlyOnScheduledDays
  programIds
  programsSelected{
    id
    label
    value
  }
  usersTargetIds
  weekDayIds
  usersTarget {
    id
    name
    email
    label
    value
  }
  workoutGroups {
    ${WORKOUT_GROUP_TYPE}
  }
`;

export const MUTATION_WORKOUT_GROUP_ROUTINE = gql`
  mutation CreateOrUpdateWorkoutGroupRoutine(
    $id: ID,
    $name: String!,
    $startDate: String,
    $endDate: String,
    $cycleType: String!,
    $isDraft: Boolean,
    $showOnlyOnScheduledDays: Boolean,
    $target: String!,
    $commentEnable: Boolean,
    $programIds: [ID!],
    $usersTargetIds: [ID!],
    $weekDayIds: [ID!],
  ){
    workoutGroupRoutine( input: { data: {
        id: $id,
        name: $name,
        startDate: $startDate,
        endDate: $endDate,
        cycleType: $cycleType,
        isDraft: $isDraft,
        showOnlyOnScheduledDays: $showOnlyOnScheduledDays,
        target: $target,
        commentEnable: $commentEnable,
        programIds: $programIds,
        usersTargetIds: $usersTargetIds,
        weekDayIds: $weekDayIds,
      }
    }){
      clientMutationId
      workoutGroupRoutine{
        ${WORKOUT_GROUP_ROUTINE}
      }
    }
  }
`;

export const MUTATION_WORKOUT_GROUP_BY_ROUTINE = gql`
  mutation CreateOrUpdateWorkoutGroupByRoutine(
    $workoutGroupRoutineId: ID!,
    $id: ID,
    $name: String!,
    $cover: Upload,
    $video: Upload,
    $audio: Upload,
    $attachment: Upload,
    $workoutsAttributes: [WorkoutInput!],
    $_destroy: Boolean,
  ){
    workoutGroupByRoutine( input: { data: {
        workoutGroupRoutineId: $workoutGroupRoutineId,
        id: $id,
        name: $name,
        cover: $cover,
        video: $video,
        audio: $audio,
        attachment: $attachment,
        workoutsAttributes: $workoutsAttributes,
        _destroy: $_destroy
      }
    }){
      clientMutationId
      workoutGroup{
        ${WORKOUT_GROUP_TYPE}
      }
    }
  }
`;