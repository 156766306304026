import * as React from 'react';
import Select from '../../elements/Select';

import {Card} from '../../elements';


import {PlanContext} from '../../context/PlanContext';

export function PlanFormExternalPlansComponent() : JSX.Element {

  const {
    externalPlans,
    externalPlansOptions,
    handleSelectExternalPlans,
    externalStoreEnabled,
  } = React.useContext(PlanContext);

  return(
      <div className="row">
        <div className="col s12">
          <Card title={'Cobranças recorrente e automática'}>
          {externalStoreEnabled ? 
          (<>
            <div className="row">
              <div className="col s12">
                <label htmlFor="">Selecione os provedores que quer conectar</label>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                  <Select
                    options={externalPlansOptions}
                    placeholder={'Digite/Selecione um provedor'}
                    onChange={handleSelectExternalPlans}
                    selectType="select2"
                    value={externalPlans}
                  />
                </div>
            </div>
            {externalPlansOptions.length < 2 && (
              <div className="row">
                <div className="col s12">
                  <p>Se quiser adicionar um novo provedor de pagamento, acesse as configurações de sua conta na opção "Pagamentos"</p>
                </div>
              </div>
            )}
          </>) : (
            <div className="row">
              <div className="col s12">
                <p>Você não tem nenhum provedor para pagamentos on-line conectado à sua conta. Acesse suas configurações de sua conta na opção "Pagamentos" para cadastrar.</p>
                <p>Ou se quiser, contacte nosso time para te ajudar.</p>
              </div>
            </div>
          )
          }
          </Card>
        </div>
      </div>
  )
}