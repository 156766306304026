import * as React from 'react';
import {Select} from 'react-materialize';

import {AccountSettingsContext} from '../../context/AccountSettingsContext';


export function AccountCheckinsComponent() : JSX.Element {
  const {account, updateAccountFromEvent} = React.useContext(AccountSettingsContext);
  const {daysAheadCheckin, hoursAheadCheckin, hoursBeforeRemoveCheckin} = account;
  function renderDaysOptions(){
    const options = [];
    for(let i = 1; i <= 8; i++){
      options.push(<option value={i} key={i}>{i}</option>);
    }
    return options;
  }

  function renderHoursOptions(){
    const options = [];
    for(let i = 1; i <=24; i++){
      options.push(<option key={i} value={i}>{i}</option>)
    }
    return options;
  }

  return(
    <>
      <div className="row">
        <div className="col s12">
          <h2>Regras para Reserva/Checkin</h2>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <h3>
            Reservar aula ou fazer checkin
          </h3>
        </div>
        <div className="col s12">
          <p>Defina o intervelo antecipado para seus clientes/alunos fazerem checkins. Permitir que façam reserva nas aulas no dia anterior, ou em dias anteriores. Faça a combinação de dias e horas de antecedência.</p>
        </div>
      </div>
      <div className="row">
        <div className="col s6">
          <Select
            id="daysAheadCheckin"
            name="daysAheadCheckin"
            value={daysAheadCheckin}
            onChange={updateAccountFromEvent}
            label="Dias de antecedência"
          >
            <option>-</option>
            {renderDaysOptions()}
          </Select>
        </div>

        <div className="col s6">
          <Select
            id="hoursAheadCheckin"
            name="hoursAheadCheckin"
            value={hoursAheadCheckin}
            onChange={updateAccountFromEvent}
            label="Horas de antecedência"
          >
            <option>-</option>
            {renderHoursOptions()}
          </Select>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <h3>Apagar reservar/checkin</h3>
        </div>
        <div className="col s12">
          <p>Defina com qual antecedência seus clientes/alunos podem cancelar uma reserva. Aqui você define o mínimo de antecedência o cliente/aluno poderá cancelar. Menor que esse intervalo não será possível cancelar</p>
        </div>
      </div>
      <div className="row">
        <div className="col s6">
          <Select
            id="hoursBeforeRemoveCheckin"
            name="hoursBeforeRemoveCheckin"
            value={hoursBeforeRemoveCheckin}
            onChange={updateAccountFromEvent}
            label="Horas de antecedência"
          >
            <option>-</option>
            {renderHoursOptions()}
          </Select>
        </div>
      </div>
    </>
  )
}