import {client} from '../../core/stores';

import {QUERY_GET_PROGRAM_CLASS} from '../../core/graphql/types';

type QueryProgramsClassReturn = {
  status: string;
  data: any[]
  }

type QueryProgramsClassFunction = { ( programId : string|number) : Promise<QueryProgramsClassReturn>; }

export function QueryProgramsClass(programId) : QueryProgramsClassFunction<QueryProgramsClassReturn> {
  const variables = { programId };
  return new Promise<QueryProgramsClassReturn>((resolve, reject) => {
    client('account').query({query: QUERY_GET_PROGRAM_CLASS, variables})
    .then( ({data: {programClasses} }) => resolve({status: 'success', programClasses}) )
    .catch( err => {
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro ao tentar carrega a lista de Horários' });
      M.toast({ html: errorMessage });
      return reject(reject({status: 'error', message: errorMessage}));
    })
  })
}