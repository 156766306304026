import * as React from 'react';
import {Switch, Textarea, TextInput} from 'react-materialize';
import {CourseModuleContext} from '../../context/CourseModuleContext';
import {CourseContext} from '../../context/CourseContext';
import {
  CourseModuleContainer, 
  CourseModuleContainerHead, 
  CourseModuleContainerMinified,
  CourseModuleContentEmpty,
} from './CourseModuleComponent.style';

import {
  ButtonDeleteComponent,
  ButtonToggleContentClean,
  ButtonState,
} from '../button';

import {UnitEmptyComponent} from '../../components/courses/UnitComponents';
import {UnitListComponent} from '../../components/courses/UnitListComponent';

import {ButtonRowHeaderContainer} from '../button/Button.style';

export function CourseModuleComponent() : JSX.Element {
  const {courseModule, index, errors} = React.useContext(CourseModuleContext);
  const {deleteCourseModule, setCourseModule} = React.useContext(CourseContext);
  const [toggleContent, setToggleContent] = React.useState(ButtonState.open);
  const onDeleteCourseModule = () => {
    deleteCourseModule(courseModule);
  };

  const toggleContentCourseModule = (value = undefined) => {
    if(value === undefined) {
      toggleContent === ButtonState.open ? setToggleContent(ButtonState.closed) : setToggleContent(ButtonState.open)
    } else {
      setToggleContent(value);
    }
  };
  const handleChange = ({target}) => {
    const newCourseModule = {...courseModule, [target.name]: target.value};
    setCourseModule(newCourseModule)
  }

  return(
    <li>
      <CourseModuleContainer>
        <CourseModuleContainerHead>
          <TextInput
              s={12}
              id={`coursemodule-${index}`}
              name={`title`}
              onChange={handleChange}
              value={courseModule.title || ''}
              placeholder={`Escreva o título deste módulo`}
              inputClassName={errors?.title ? 'invalid' : ' '}
              error={errors?.title}
              label={null}
            />
          <ButtonRowHeaderContainer>
            <ButtonToggleContentClean
              onPress={toggleContentCourseModule}
              buttonState={toggleContent}
              title="Minimize o conteúdo para editar mais facilmente"
            />
            <ButtonDeleteComponent
              onPress={onDeleteCourseModule}
              name="Módulo"
              title="Apagar módulo"
            />
          </ButtonRowHeaderContainer>
        </CourseModuleContainerHead>
        {toggleContent === ButtonState.open && (
          <>
          <div className="row">
            <Textarea
              s={12}
              id={`courseModule-${index}-description`}
              name={`description`}
              onChange={handleChange}
              value={courseModule.description || ''}
              label={'Descrição'}
              placeholder={`Escreva aqui a descrição/detalhes deste módulo`}
              className={errors?.description ? 'invalid' : ' '}
              error={errors?.description}
              style={{maxHeight: 190}}
            />
          </div>

          <UnitListComponent />
          <UnitEmptyComponent />
        </>
      )}
      {toggleContent === ButtonState.closed && (
        <CourseModuleContainerMinified
          onClick={() => toggleContentCourseModule()}>
          <small>ver detalhes <strong>...</strong></small>
        </CourseModuleContainerMinified>
      )}
      </CourseModuleContainer>
    </li>
  )
}

export function CouseModuleEmptyComponent() : JSX.Element {
  const {createCourseModule} = React.useContext(CourseContext);

  return(
    <CourseModuleContainer className='clean-top add-new' onClick={createCourseModule}>
      <CourseModuleContentEmpty>
        Adicionar novo módulo
      </CourseModuleContentEmpty>
    </CourseModuleContainer>
  )
}