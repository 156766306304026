import { gql } from '@apollo/client';

export const CREATE_OR_UPDATE_CAMPAIGN_MUTATION = gql`
  mutation CreateOrUpdateCampaign(
    $id: ID,
    $title: String!,
    $description: String!, 
    $status: String, 
    $activity: String, 
    $templateType: String, 
    $frequency: String, 
    $time: String, 
    $notificationChannel: String,
    $senderEmail: String,
    $senderName: String,
    $toBcc: String
    ) {
    createOrUpdateCampaign(
      input: {
        id: $id, 
        title: $title, 
        description: $description, 
        status: $status, 
        activity: $activity, 
        templateType: $templateType, 
        frequency: $frequency, 
        time: $time, 
        notificationChannel: $notificationChannel,
        senderEmail: $senderEmail,
        senderName: $senderName,
        toBcc: $toBcc
      }
    ) {
      campaign {
        id
      }
    }
  }
`;