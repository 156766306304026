import styled from 'styled-components';

export const NavContainer = styled.nav`
  color: #333333;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 10px;
  width: 100%;
  a {
    color: #333333;
  }
  ul {
    width: 100%;
    transition: transform 0.3s ease-in-out;
  }
  li {
    max-width: 100%;
  }
  & > ul > li {
    padding: 0 10px;
    position: relative;
    ul {
      display: none;
    }
    &.ativo {
      ul {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        @media (max-width: 768px) {
          max-width: 90%;
        }
        li {
          text-transform: uppercase;
          width: 100%;
          &.ativo {
            a {
              font-weight: bold;
            }
          }
        }
      }
    }
    &.submenu ::after {
      font-family: 'Material Icons';
      content: "keyboard_arrow_right";
      float: right;
      position: absolute;
      right: 0px;
      top: 0px;
      transform: rotate(90deg);
    }
    &.submenu.ativo ::after {
      transform: rotate(-90deg);
    }
  }
  ul.submenu > li > a{
    font-size: 0.77em;
  }
  li a{
    align-items: center;
    display: flex;
    font-size: 0.88em;
    gap: 16px;
    width: 100%;
    & :hover {
      background-color: transparent;
    }
    i {
      align-items: center;
      display: flex;
      width: 30px;
    }
    & > span {
      width: 130px;
    }
  }
  @media (max-width: 768px) {
    height: 160px;
    width: 100%;
    img {
      height: 100px;
      width: 100px;
    }
    &::after {
      background-color: #F9F9F9;
      content: '\f0c9';
      font-family: 'Font Awesome 6 Pro';
      font-size: 1.66em;
      font-weight: bold;
      margin-bottom: 10px;
      padding: 20px;
      position: absolute;
      top: 10px;
      z-index: 9;
    }
    &.ativo::after {
      content: '\f00d';
      font-family: 'Font Awesome 6 Pro';
    }
    & > ul {
      background-color: #F9F9F9;
      bottom: 0px;
      display: table;
      padding: 20px 0;
      position: absolute;
      top: 105px;
      transform: translateX(-1000px);
      z-index: 99;
      max-width: 90%;
      &::before {
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
    &.ativo {
      ul {
        transform: translateX(0px);
      }
    }
    &.ativo::before {
      background-color: rgba(0,0,0,0.5);
      bottom: 0px;
      content: '';
      display: block;
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      z-index: 9;
    }
  }
`;

export const AvatarElement = styled.img`
  border-radius: 10px;
  height: 180px;
  margin: 0 auto;
  padding: 10px;
  width: 180px;
`;

