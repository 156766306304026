import styled from 'styled-components';

export const FormAuthElement = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 520px;
  padding: 0 30px;
  h3{
    font-size: 2rem;
    font-weight: 600;
    margin-top: 5px;
    text-align: center;
  }
  label:not(.active) {
    left: 1.33rem;
  }
`;