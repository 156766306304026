import styled from 'styled-components';

import {COLOR, DIMENSION} from '../../constants';

export const UnitContainer = styled.div`
  background-color: ${COLOR.BODY};
  border: 1px solid #EDEDED;
  padding: 15px;
  padding-top: calc(1vw + 100px);
  position: relative;
  margin: 0 0 2vh 0;
  &.clean-top{
    padding-top: 15px;
  }
  &.add-new{
    background-color: #E6F4F177;
    cursor: pointer;
  }
`;

export const UnitContainerHead = styled.div`
  background-color: ${({errors}) =>  errors ? 'rgba(255,0,0,.2)' :`#E6F4F1`};
  display: flex;
  left: 0;
  padding: 5px;
  padding-left: 20px;
  padding-right: 70px;
  position: absolute;
  right: 0;
  top: 0;
  input{
    background-color: transparent !important;
    border-bottom: 1px solid #CCC !important;
  }
  &:before{
    color: #CCCCCC;
    cursor: grab;
    content: '\f58e';
    font-family: 'Font Awesome 6 Pro';
    left: 10px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
  }
  .select-dropdown{
    background-color: ${COLOR.BODY} !important;
  }
  .select-wrapper .caret{
    z-index: 1;
  }
  select{
    display: none;
  }
  @media screen and (max-width: 768px){
    &{
      padding-right: 40px;
    }
  }
`;

export const UnitContainerContentEmpty = styled.div`
    color: #777777;    
    font-size: 1.33em;
    text-align: center;
    &:before{
      content: '\f067';
      font-family: 'Font Awesome 6 Pro';
      margin-right: 10px;
    }
`;

export const UnitContainerMinified = styled.div`
  background-color: rgba(255,255,255,0.4);
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 20%) 0 0 4px;
  color: #CCCCCC;
  cursor: pointer;
  font-size: 1.33em;
  height: 30px;
  text-align: center;
  width: 100%;
`;