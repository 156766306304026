import * as React from 'react'
import Select2 from 'react-select';
import styled from 'styled-components';
import makeAnimated from 'react-select/animated'
import {Select as SelectMaterialize} from 'react-materialize';
import {messages} from '../../utils';

const animatedComponents = makeAnimated()

type OptionsProps = {
  value: string | number;
  label: string;
}

type OnChangeProps = { (values) : void };

type SelectProps = {
  selectType: 'select2' | 'materialize' | '',
  placeholder?: string,
  onChange: OnChangeProps,
  options: Array<OptionsProps>,
  value?: (string | number | OptionsProps)[],
  label?: string;
  isMulti?: boolean;
  s?: number;
  m?: number;
  l?: number;
}

class Select extends React.Component<SelectProps, SelectProps> {
  constructor(props){
    super(props);
    const {value} = props;
    this.state = { selectType: 'select2', options: [], value}
  }
  static getDerivedStateFromProps(props: SelectProps, state: SelectProps) : SelectProps {
    let newsState = state;
    const { selectType, options } = props;
    if(options !== state.options) newsState = {...newsState, options};
    if(selectType !== state.selectType) newsState = {...newsState, selectType}
    return newsState;
  }


  renderSelectMaterilize({onChange, options, placeholder, value, label, name, s,m,l, ...otherProps} : SelectProps) : JSX.Element {
    return (
      <SelectMaterialize
        multiple={false}
        onChange={onChange}
        value={value}
        label={label}
        name={name}
        s={s}
        m={m}
        l={l}
        {...otherProps}
      >
        <option disabled value="">
          {this.props.placeholder ? this.props.placeholder : messages.form.select.placeholder}
        </option>
        {options.map((item) => (
            <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </SelectMaterialize>
    )
  }

  renderSelect2({onChange, options, placeholder, isMulti=true ,value, ...props} : SelectProps) : JSX.Element {
    return <Select2 components={animatedComponents} isMulti={isMulti} options={options} placeholder={placeholder} onChange={onChange} value={value || options.filter(opt => ( value || [] ).includes(opt.value))} {...props} />
  }

  render() {
    const { options, selectType, placeholder } = this.props

    return selectType === 'select2'
      ? this.renderSelect2(this.props)
      : this.renderSelectMaterilize(this.props)
  }
}

export default Select;

export const SelectContainer = styled.div`
  select {
    display: none;
  }
`;
