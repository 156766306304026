import React from "react"
import { AvatarComponent } from "../../horarios/components/avatar.component"
import { ProfileSidebar as ProfileSidebarComponent, ProfileSidebarHeader, ProfileSidebarItemHeader, ProfileSidebarItemHeaderDescription, ProfileSidebarItemHeaderTitle, ProfileSidebarName } from "../show.style"
import { useProfile } from "../profile.context";

const TRANSLATE_ORIGIN : Record<string, string> = {
  panel: 'Painel Administrativo',
  app: 'Aplicativo',
  checkout: 'Página de Checkout',
  external: 'Externo',
  whatsapp: 'Whatsapp Bot',
}

export const ProfileSidebar = () => {
  const {account,aluno, setActiveOption} = useProfile();
  
  return (
    <ProfileSidebarComponent>
      <ProfileSidebarHeader>
        <span>
          Informações do Aluno
        </span>
        <div onClick={() => setActiveOption('edit')}>
          <i className="fa fa-pen"></i>
        </div>
      </ProfileSidebarHeader>
      <AvatarComponent aluno={aluno} size={230} hideName={true} noLink />
      {/* Formulário de Edição */}
      <ProfileSidebarName>{aluno.getShortName}</ProfileSidebarName>

      <ProfileSidebarItemHeader>      

      <ProfileSidebarItemHeaderTitle>
          <i className="fa-light fa-right-to-bracket"></i>
          Origem
        </ProfileSidebarItemHeaderTitle>
        <ProfileSidebarItemHeaderDescription>
          {TRANSLATE_ORIGIN[aluno.relationship?.origin]}
        </ProfileSidebarItemHeaderDescription>

        <ProfileSidebarItemHeaderTitle>
          <i className="fa-light fa-handshake"></i>
          Entrou para {account.name}:
        </ProfileSidebarItemHeaderTitle>
        <ProfileSidebarItemHeaderDescription>
          {aluno.relationship?.registeredAt}
        </ProfileSidebarItemHeaderDescription>

        <ProfileSidebarItemHeaderTitle>
          <i className="fa-light fa-user-tag"></i>
          Status:
        </ProfileSidebarItemHeaderTitle>
        <ProfileSidebarItemHeaderDescription>
          {aluno.relationship?.status}
        </ProfileSidebarItemHeaderDescription>

        <ProfileSidebarItemHeaderTitle>
          <i className="fa-light fa-envelope"></i>
          Email:
        </ProfileSidebarItemHeaderTitle>
        <ProfileSidebarItemHeaderDescription>
          {aluno.email}
        </ProfileSidebarItemHeaderDescription>
        <ProfileSidebarItemHeaderTitle>
          <i className="fa-light fa-phone"></i>
          Telefone:
        </ProfileSidebarItemHeaderTitle>
        <ProfileSidebarItemHeaderDescription>
          {aluno.phone}
        </ProfileSidebarItemHeaderDescription>
        <ProfileSidebarItemHeaderTitle>
          <i className="fa-light fa-id-card"></i>
          CPF:
        </ProfileSidebarItemHeaderTitle>
        <ProfileSidebarItemHeaderDescription>
          {aluno.cpf}
        </ProfileSidebarItemHeaderDescription>
        <ProfileSidebarItemHeaderTitle>
          <i className="fa-light fa-venus-mars"></i>
          Gênero:
        </ProfileSidebarItemHeaderTitle>
        <ProfileSidebarItemHeaderDescription>
          {aluno.gender}
        </ProfileSidebarItemHeaderDescription>
        {!!aluno.gympassId && <><ProfileSidebarItemHeaderTitle>
          <i className="fa-light fa-id-card"></i>
          Gympass ID:
        </ProfileSidebarItemHeaderTitle>
        <ProfileSidebarItemHeaderDescription>
          {aluno.gympassId}
        </ProfileSidebarItemHeaderDescription></>}

        {!!aluno?.id && <ProfileSidebarItemHeaderTitle>
          <a href={`/${account.code}/admin/alunos/${aluno.id}/reset-password`} data-confirm="Tem certeza que quer solicitar uma nova senha para o e-mail do usuário?" style={{display: 'flex', gap: 8}}>
            <i className="fa-light fa-lock"></i>
              Enviar requisição para nova senha?
          </a>
        </ProfileSidebarItemHeaderTitle>}
      </ProfileSidebarItemHeader>

    </ProfileSidebarComponent>
  )
}