import * as React from 'react';

import {CourseProvider} from '../../../context/CourseContext';
import {FormBaseComponent} from './FormBaseComponent';

interface CourseProps {
  id: number|string;
  title: string;
  description: string;
}

const CourseForm = ({course, programs, courseCategories}) => {
  document.title = `${window.account}/info-produtos`;
  return (
    <CourseProvider course={course} programs={programs} courseCategories={courseCategories}>
      <FormBaseComponent />
    </CourseProvider>
  )
}

export default CourseForm;