import styled from 'styled-components';


export const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
`;

export const ActionButton = styled.a<{danger?: boolean}>`
  align-items: center;
  background-color: #FFFFFF;
  cursor: pointer;
  color: ${({danger}) => danger ? '#f44336' : '#006bf3'};
  display: flex;
  font-size: 0.66em;
  gap: 1rem;
  height: 30px;
  justify-content: center;
  padding: 0 20px;
  &:hover {
    background-color: ${({danger}) => danger ? '#f44336' : '#006bf3'};
    color: #FFFFFF;
  }
  i{
    font-size: 1em;
  }
`;


export const PlanLabel = styled.small<{color: string}>`
  background-color: ${({color}) => color};
  border-radius: 5px;
  padding: 10px;
`;
