export const IMAGE_TYPE = `
  uri
  uriOriginal
  height
  width
`;

export const VIDEO_TYPE = `
  uri
`;

export const AUDIO_TYPE = `
  uri
`;

