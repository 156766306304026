import * as React from 'react';
import { Row } from 'react-materialize';

import { Controller } from "react-hook-form";
import { AvatarWrapper, FormWrapper, View } from './RegisterForm.style';
import { UploadMediaFile } from '../../components/media';
import { RegisterFormContext } from './RegisterForm.context';
import { AvatarIconMan, AvatarIconWoman } from './RegisterForm.icon';
import { InputMaskStyled, InputStyled, SelectStyled } from '../../elements/forms/InputContainer';
import Select from '../../elements/Select';
import { StateEnum } from './Register.schema';

function RegisterFormStep1() {

  const { 
    errors, 
    avatar,
    control, 
    setValue,
    step,
    isSubmitting,
    onHandleSubmit,
    watch,
    token,
  } = React.useContext(RegisterFormContext);

  const [defaultAvatar] = React.useState<number>(Math.random());

  // React.useEffect(() => {
  //   if(token) {
  //     setValue('password', token);
  //   }
  // }, [token]);

  const name = watch('name');
  const email = watch('email');
  const phone = watch('phone');
  const password = watch('password');

  const isValid = name && email && phone && password?.length > 7;

  if(step == 1) {
    return (
      <View>
        <h1>Comece agora a transformar sua carreira</h1>
        {/* <h2>Você</h2> */}
          <FormWrapper onSubmit={onHandleSubmit}>
            <div>
            <AvatarWrapper>
              <UploadMediaFile propFile={avatar} setUploadFile={(file) => setValue('avatar', file)} mediaType='image'>
                {defaultAvatar < 0.5 ? <AvatarIconMan style={{opacity: 0.5, filter: 'grayscale(100%)'}}/> : <AvatarIconWoman style={{opacity: 0.5, filter: 'grayscale(100%)'}}/>}
                </UploadMediaFile>
                <Row>
                  <span>Clique na imagem para escolher sua imagem de perfil - sua imagem profissional que será usada para divulgar seu trabalho</span>
                </Row>
              </AvatarWrapper>
            </div>
            <div className="form-inputs" style={{flex: 1}}>
            <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                      <InputStyled 
                        label="Nome e Sobrenome*"
                        {...field}
                        autoComplete='name'
                        placeholder='Nome e Sobrenome'
                        className={errors.name?.message ? 'invalid' : ''}
                        error={errors.name?.message}
                        s={12}
                      />
                  )}
              />
              <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <div className='input-field'>
                    <InputStyled 
                      {...field}
                      label="Email*"
                      placeholder='Email'
                      type="email"
                      className={errors.email?.message ? 'invalid' : ''}
                      error={errors.email?.message}
                      s={12}
                      />
                    </div>
                    )}
                />
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <div className='input-field'>
                      <InputMaskStyled
                        {...field}
                        type="tel"
                        placeholder="Celular"
                        label="Celular*"
                        mask="(99) 99999-9999"
                        />
                    </div>
                  )}
                />
                {!token?.length && <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                      <InputStyled 
                        {...field} 
                        placeholder='Crie sua senha' 
                        label="Crie sua senha*" 
                        type="password"
                        password 
                        auto-complete='new-password' 
                        className={errors.password?.message ? 'invalid' : ''}
                        error={errors.password?.message}
                        />
                      )}
                />}
                <p>
                <span className="helper thin">{!isValid && "Preencha os campos obrigatórios para continuar"}</span>
                </p>
                <button
                className="btn btn-primary right"
                type="submit"
                onClick={onHandleSubmit}
                disabled={isSubmitting || !isValid}>
                {isSubmitting ? 'Enviando...' : `Continuar`}
              </button>
            </div>
          </FormWrapper>
      </View>
    );
  }
}

export {RegisterFormStep1};
