import {AUTHOR_TYPE} from './users';

export const COMMENTABLE_TYPE = `
  id
  author{
    ${AUTHOR_TYPE}
  }
  commentableId
  commentableType
  message
`;