import * as React from 'react';
import {PlanFormComponent} from '../../../components/plans/PlanFormComponent';
import {PlanContextProvider} from '../../../context/PlanContext'
import Breadcrumb from '../../../components/Breadcrumb';

const BreadcumbsData = [
  {
    url: null,
    icon: 'cog',
    label: 'Configurações',
  },
  {
    url: `/${window.account}/admin/plans/`,
    label: 'Planos',
  },
  {
    label: 'Novo plano',
    url: null
  }
]

function PlanNew({plan, action, programs, externalStoreEnabled, externalStoreOptions}) : JSX.Element {
  document.title = `${window.account}/planos/novo plano`

  return(
    <PlanContextProvider plan={plan} programs={programs} externalStoreEnabled={externalStoreEnabled} externalStoreOptions={externalStoreOptions}>
      <div className="row">
        <div className="col s12">
          <Breadcrumb data={BreadcumbsData} />
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <h1>Novo plano</h1>
        </div>
      </div>
      <PlanFormComponent />
    </PlanContextProvider>
  )
}

export default PlanNew;