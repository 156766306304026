import styled from 'styled-components';

export const PlanFormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin: 1rem 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
    & > div {
      flex: 1;
    }
`;