import * as React from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {CourseModuleComponent} from './CourseModuleComponent';
import {CourseContext} from '../../context/CourseContext';
import {CourseModuleContext, CourseProvider} from '../../context/CourseModuleContext';

const CourseModuleItem = SortableElement(({cm, moduleIndex}) => {
  const {setCourseModule} = React.useContext(CourseContext);
 return (
  <CourseProvider courseModule={cm} index={moduleIndex} setCourseModule={setCourseModule}>
    <CourseModuleComponent />
  </CourseProvider>
    )
});

const CourseModuleList = SortableContainer(({courseModules}) => {
  
  return (
  <ul>
    {courseModules.map((cm, index) => <CourseModuleItem key={`coursemodule-${cm.id || cm.tempId}`} cm={cm} index={index} moduleIndex={index} />)}
  </ul>)
});

export const CourseModuleListComponent = () : JSX.Element => {
  const {courseModules, onCourseModuleSortEnd} = React.useContext(CourseContext);
  return(
    <CourseModuleList 
      courseModules={courseModules.filter((cm) => !cm?._destroy)}
      onSortEnd={onCourseModuleSortEnd}
      pressDelay={200}
    />
  )
}