import React from 'react';
import {validations} from '../messages';

export const ValidateForm = (values) => {
  const {firstName, lastName, gender, cpf, phone, email} = values;

  const errors = {};
  if (!email) {
    errors.email = validations.required;
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
  ) {
    errors.email = validations.invalid_email;
  }
  if(!firstName) errors.firstName = validations.required;
  if(!lastName) errors.lastName = validations.required;
  if(!cpf) errors.cpf = validations.required;
  if(!phone) errors.phone = validations.required;
  
  return errors;
}

export function getCardFlag(cardnumber) {
  var cardnumber = cardnumber.replace(/[^0-9]+/g, '');

  var cards = {
      visa      : /^4[0-9]{12}(?:[0-9]{3})/,
      mastercard : /^5[1-5][0-9]{14}/,
      diners_club    : /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      american_express      : /^3[47][0-9]{13}/,
      discover  : /^6(?:011|5[0-9]{2})[0-9]{12}/,
      hipercard  : /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      elo        : /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      jcb        : /^(?:2131|1800|35\d{3})\d{11}/,       
      aura      : /^(5078\d{2})(\d{2})(\d{11})$/     
  };

  for (var flag in cards) {
      if(cards[flag].test(cardnumber)) {
          return flag;
      }
  }       

  return false;
}