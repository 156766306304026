import {gql} from '@apollo/client';

export const MUTATION_ACCOUNT_PLAN = gql`
  mutation createOrUpdateAccountPlan(
    $id: ID,
    $code: String,
    $name: String!,
    $status: String,
    $maxInstallments: String!,
    $recurrence: String!,
    $recurrenceQuantity: String!,
    $value: String!,
    $planType: String!,
    $planTypeValue: String,
    $accountType: String,
    $billingTriggerType: String,
    $billingTriggerDay: String,
    $cycles: String
  ){
    createOrUpdateAccountPlan(input: {
      id: $id,
      code: $code,
      name: $name,
      status: $status,
      maxInstallments: $maxInstallments,
      recurrence: $recurrence,
      recurrenceQuantity: $recurrenceQuantity,
      value: $value,
      planType: $planType,
      planTypeValue: $planTypeValue,
      accountType: $accountType,
      billingTriggerType: $billingTriggerType,
      billingTriggerDay: $billingTriggerDay,
      cycles: $cycles
    }){
      accountPlan{
        id,
        code,
        name,
        status,
        maxInstallments,
        recurrence,
        recurrenceQuantity,
        value,
        planType,
        planTypeValue,
        accountType,
        billingTriggerType,
        billingTriggerDay,
        cycles
      }
    }
  }
`;