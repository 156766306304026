import * as React from 'react';
import {ProgramClassList} from '../../components/programClasses/ProgramClassList';
import { ProgramScheduleForm } from '../../components/programClasses/ProgramScheduleForm';
import {ProgramsContextProvider} from '../../context/ProgramsContext';

import Breadcrumb from '../../components/Breadcrumb';

function ProgramShow({program, filials, user, programClasses, coaches}){
  document.title = `${window.account}/admin/${program.code}`;

  const BreadcumbsData = [
    {
      url: `/${window.account}/admin/workout-routines/`,
      icon: 'stopwatch',
      label: 'Treinos',
    },
    {
      url: `/${window.account}/admin/programs/`,
      label: 'Programas',
    },
    {
      label: `${program.name}`,
      url: null
    }
  ]

  const programClassesState = React.useMemo(() => {
    const listWeekDays = [];
    const today = new Date();
    for(let i = 0; i < 7; i++){
      today.setDate(today.getDate() + (i === 0 ? 0 : 1) )
      const index = today.getDay();
      listWeekDays.push(programClasses[index])
    }
    return listWeekDays;
  }, [programClasses])
  return(
    <ProgramsContextProvider user={user} filials={filials} program={program} coaches={coaches}>
      <div className="row">
        <div className="col s12">
          <Breadcrumb data={BreadcumbsData} />
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <h1>Programa {program.name}</h1>
          {['admin','manager'].indexOf(user.relationship.rule) > -1 && <a href={`/${window.account}/admin/programs/${program.id}/edit`}>editar programa</a>}
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <div className="flow-text">Horários de aulas</div>
        </div>
      </div>
      <ProgramClassList programClasses={programClassesState} />
      <ProgramScheduleForm />
    </ProgramsContextProvider>
  )
}

export default ProgramShow;