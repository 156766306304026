import * as React from 'react';
import {TextInput} from 'react-materialize';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {
  WorkoutGroupContainer,
  WorkoutGroupContainerHead,
  WorkoutGroupContainerMinified,
  WorkoutGroupContentEmpty,
  WorkoutToggleContainer
} from './WorkoutGroups.style';
import {WorkoutComponent, WorkoutEmptyComponent} from './WorkoutComponent';
import {WorkoutGroupMediaComponent} from './WorkoutMediaComponent';
import {
  InputEvent,
  WorkoutGroupProps,
  ExerciseWorkoutSelectProps,
} from '../../types';

import {WorkoutContainerHead} from './WorkoutComponent.style';
import {
  ButtonDeleteComponent,
  ButtonToggleContentClean,
  ButtonState,
} from '../button';
import {ButtonRowHeaderContainer} from '../button/Button.style';
import {TranslateMediaValueToFileProps} from '../../../utils';
import {WorkoutRoutineContext} from '../../context/WorkoutRoutineContext';

type OnChangeProps = {(event, index?): void};

type DeleteWorkoutGroupProps = {
  (workoutGroup: WorkoutGroupComponentProps, workoutIndex: number): void;
};

type WorkoutGroupComponentProps = {
  index: number;
  updateWorkoutGroup: OnChangeProps;
  values: WorkoutGroupProps;
  workoutGroup: WorkoutGroupProps;
  errors: any;
  deleteWorkoutGroup: DeleteWorkoutGroupProps;
  exerciseListDefault: ExerciseWorkoutSelectProps[];
  setShowDetails: (value: boolean) => void;
};

export const WorkoutGroupComponent = ({
  index,
  workoutGroup,
  errors = {},
  exerciseListDefault,
  setShowDetails
}: WorkoutGroupComponentProps): JSX.Element => {
  const {
    updateWorkoutGroup,
    deleteWorkoutGroup,
  } = React.useContext(WorkoutRoutineContext);

  const onChangeInput = (event) => {
    const newWorkoutGroup = {
      ...workoutGroup,
      ...{[event.target.name]: event.target.value},
    };
    updateWorkoutGroup(newWorkoutGroup);
  };

  const WorkoutItem = (({workout, workoutIndex}) => {
    return (
      <WorkoutComponent
        index={workoutIndex}
        workoutGroupIndex={index}
        workoutGroup={workoutGroup}
        workout={workout}
        errors={(errors?.workoutsAttributes || [])[workoutIndex]}
        exerciseListDefault={exerciseListDefault}
      />
    )
  });

  const RenderWorkoutForms = React.useMemo( () => (({workouts}) =>  {
    return (
      <ul>
        {(workouts || [])
          .map(
            (workout, workoutIndex): JSX.Element => {
              return(
              <li key={`workout-${workout?.id|| workout.tempId}-${workoutIndex}-${workoutIndex}`}>
                <WorkoutItem workout={workout} workoutIndex={workoutIndex} />
              </li>)
            }
          )}
      </ul>
    );
  }), [workoutGroup]);

  const {onWorkoutSortEnd, updateWorkoutGroupPosition} = React.useContext(WorkoutRoutineContext);
  
  const [toggleContent, setToggleContent] = React.useState(ButtonState.open);
  const toggleContentWorkoutGroup = (value) => {
    setToggleContent(value);
    setShowDetails(value == ButtonState.open ? true : false);
  };

  const switchToggleContentWorkoutGroup = () => {
    const newState =
      toggleContent === ButtonState.open
        ? ButtonState.closed
        : ButtonState.open;
    setToggleContent(newState);
    setShowDetails(ButtonState.open ? true : false);
  };

  const handleMediaUpdate = (mediaType, file) => {
    const newWorkoutValue = {...workoutGroup};
    switch(mediaType){
      case 'image':
        newWorkoutValue['cover'] = file;
        break;
      case 'video':
        newWorkoutValue['video'] = file;
        break;
      case 'audio':
        newWorkoutValue['audio'] = file;
        break;
      case 'attachment':
        newWorkoutValue['attachment'] = file;
        break;
    }
    updateWorkoutGroup(newWorkoutValue);
  };

  return (
    <WorkoutGroupContainer>
      <div className={'WorkoutGroupContainer'} style={{flex: 1, }}>
      <WorkoutGroupContainerHead>
        <WorkoutToggleContainer>
          <i onClick={ () => updateWorkoutGroupPosition('up',workoutGroup, index )}  className="far fa-long-arrow-up"/>
          <i onClick={ () => updateWorkoutGroupPosition('down', workoutGroup, index)} className="far fa-long-arrow-down"/>
        </WorkoutToggleContainer>
        <TextInput
          s={12}
          id={`workoutGroup-${index}`}
          name={`name`}
          onChange={onChangeInput}
          value={workoutGroup.name}
          placeholder={`Escreva o nome do treino para criar um treino novo para esta rotina`}
          inputClassName={errors?.name ? 'invalid' : ' '}
          error={errors?.name}
          label={null}
        />
        <ButtonRowHeaderContainer>
          <ButtonToggleContentClean
            onPress={toggleContentWorkoutGroup}
            buttonState={toggleContent}
            title="Minimize/Maximize o conteúdo para editar mais facilmente"
          />
          <ButtonDeleteComponent
            onPress={() => deleteWorkoutGroup(workoutGroup)}
            name="Treino"
            title="Apagar o treino, suas etapas e exercício"
          />
        </ButtonRowHeaderContainer>
      </WorkoutGroupContainerHead>
      {toggleContent === ButtonState.open && (
        <div className="groupContent">
          <WorkoutGroupMediaComponent
            handleMediaUpdate={handleMediaUpdate}
            image={TranslateMediaValueToFileProps(workoutGroup.cover)}
            video={TranslateMediaValueToFileProps(workoutGroup.video, workoutGroup.externalVideoUrl)}
            audio={TranslateMediaValueToFileProps(workoutGroup.audio)}
            attachment={TranslateMediaValueToFileProps(workoutGroup.attachment)}
          />
          <div className="row">
            <div className="col s12">
              <div className="flow-text">Blocos do Treino</div>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <RenderWorkoutForms
                workouts={workoutGroup?.workouts?.filter((workout) => !workout?._destroy)}
                onSortEnd={ (data) => onWorkoutSortEnd({workoutGroup, ...data})}
                pressDelay={200}
              />
              <WorkoutEmptyComponent
                workoutGroup={workoutGroup}
                workoutGroupIndex={index}
              />
            </div>
          </div>
        </div>
      )}
      {toggleContent === ButtonState.closed && (
        <WorkoutGroupContainerMinified
          onClick={switchToggleContentWorkoutGroup}>
          <i className="far fa-ellipsis-h"></i>
        </WorkoutGroupContainerMinified>
      )}
      </div>
    </WorkoutGroupContainer>
  );
};

export const WorkoutGroupEmptyComponent = () : JSX.Element => {
  const {createWorkoutGroup} = React.useContext(WorkoutRoutineContext);
  return (
    <WorkoutGroupContainer className='clean-top add-new' onClick={createWorkoutGroup}>
      <WorkoutGroupContentEmpty>
        Adicionar novo Treino
      </WorkoutGroupContentEmpty>
    </WorkoutGroupContainer>
  );
};
