import * as React from 'react';
import {InputEvent} from '../types';

import {LinkComponent} from './Link.style';

type LinkProp = {
  className?: string;
  title: string;
  onPress: InputEvent;
  children?: JSX.Element;
}
export const Link = ({className, title, onPress, children} : LinkProp) : JSX.Element => {
  const onClick = (event) => {
    event.preventDefault();
    if(onPress) onPress(event);
  }
  return (
    <LinkComponent href="#" onClick={onClick} className={className}>{title || children}</LinkComponent>
  )
}