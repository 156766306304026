import React, { useEffect } from 'react';
import { Controller, Form, useForm } from 'react-hook-form';
import { ButtonStyled, InputStyled } from '../../../../elements/forms/InputContainer';
import { Row, Textarea } from 'react-materialize';
import { useMutation } from '@apollo/client';
import { CREATE_MESSAGE_MUTATION } from '../../mutations';
import { useProfile } from '../profile.context';
import { LoadingContent } from '../../../../components';
import { NewMessageRow } from './Inbox.style';

export const NewMessage = ({onClose}: {onClose: () => void}) => {
  const { control, handleSubmit, formState: { errors } } = useForm();
  const {aluno} = useProfile();

  const [createMessage, {loading, error, data}] = useMutation(CREATE_MESSAGE_MUTATION);

  const onSubmit = (data: any) => {
    createMessage({
      variables: {
        to: aluno.email,
        subject: data.subject,
        body: data.body
      }
    });
  }

  useEffect(() => {
    if (data) {
      M.toast({html: 'Mensagem enviada com sucesso'});
      onClose();
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      M.toast({html: 'Erro ao enviar mensagem'});
    }
  }, [error]);

  console.log("error", error);
  console.log("data", data);
  console.log("loading", loading);

  if (loading) return <LoadingContent loading={loading} />;

  return (
    <div>
      <Form control={control} onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Controller
            name="subject"
            control={control}
            render={({ field }) => 
              <InputStyled 
                {...field}
                label="Assunto"
                s={12}
                error={errors?.subject?.message}
                placeholder="Digite o assunto da mensagem"
              />
            }
          />
        </Row>
        <Row>
          <Controller
            name="body"
            control={control}
              render={({field, field: {value}}) => (
                <Textarea
                  {...field}
                  s={12}
                  error={errors?.bio?.message}
                  value={value || ''}
                  label="Mensagem"
                  placeholder="Digite a mensagem"
                  className={errors?.bio?.message ? 'invalid' : ' '}
                />
              )}
            />
        </Row>
        <NewMessageRow>
          <ButtonStyled type="submit">Enviar</ButtonStyled>
            <a onClick={onClose} style={{color: 'red'}}>Cancelar</a>
        </NewMessageRow>
      </Form>
    </div>
  )
}