import { z } from 'zod';

export const AccountSettingsSchema = z.object({
  id: z.union([z.string(), z.null(), z.number()]).optional(),
  code: z.string().min(1, {message: 'Campo obrigatório'}).optional(),
  name: z.string().min(1, {message: 'Campo obrigatório'}),
  bio: z.any().transform((data) => data || '').optional(),
  senderEmail: z.string().email({message: 'Email inválido'}).optional(),
  senderName: z.string().min(1, {message: 'Campo obrigatório'}).optional(),
  // athletesLabel: z.string().nullish(),
  minimumCheckinsGympass: z.union([z.string(), z.null(), z.number()]).optional(),
  minimumCheckinsToPoints: z.union([z.string(), z.null(), z.number()]).optional(),
  // pointsLabel: z.string().nullish(),
  vindiApiKey: z.union([z.string(), z.null()]).optional(),
  registerEnabled: z.boolean().optional(),
  registerStatus: z.string().min(1, {message: 'Campo obrigatório'}).optional(),
  requiresPlanOnSignup: z.boolean().optional(),
  hoursAheadCheckin: z.union([z.string(), z.null(), z.number()]).optional(),
  daysAheadCheckin: z.union([z.string(), z.null(), z.number()]).optional(),
  hoursBeforeRemoveCheckin: z.union([z.string(), z.null(), z.number()]).optional(),
  securityCheckout: z.union([z.boolean(), z.null()]).optional(),
  recaptchaSecretKey: z.union([z.string().min(1, {message: 'Campo obrigatório'}), z.null()]).optional(),
  mundipaggSecretKey: z.union([z.string(), z.null()]).optional(),
  stripeSecretKey: z.union([z.string().min(1, {message: 'Campo obrigatório'}), z.null()]).optional(),
  externalStoreFavorite: z.string().min(1, {message: 'Campo obrigatório'}).optional(),
  phone: z.string().min(1, {message: 'Campo obrigatório'}).optional(),
  city: z.string().min(1, {message: 'Campo obrigatório'}).optional(),
  state: z.string().min(1, {message: 'Campo obrigatório'}).optional(),
  avatar: z.any().optional(),
  status: z.enum(['ativado', 'pendente', 'desativado', 'arquivado']).optional(),
  category: z.string().min(1, {message: 'Campo obrigatório'}).optional(),
  subcategory: z.union([z.string(), z.null()]).optional(),
  accountType: z.enum(['personal', 'gym']).optional(),
  visibility: z.enum(['public', 'private']).optional(),
  features: z.union([z.array(z.string()), z.null()]).optional(),
  accountBilling: z.object({
    billingType: z.enum(['individual', 'company']).nullish(),
    documentId: z.string().min(1, {message: 'Campo obrigatório'}).nullish(),
    phone: z.string().min(1, {message: 'Campo obrigatório'}).nullish(),
    email: z.string().email({message: 'Email inválido'}).nullish(),
    zipcode: z.string().min(1, {message: 'Campo obrigatório'}).nullish(),
    neighborhood: z.string().min(1, {message: 'Campo obrigatório'}).nullish(),
    addressLine1: z.string().min(1, {message: 'Campo obrigatório'}).nullish(),
    addressLine2: z.string().min(1, {message: 'Campo obrigatório'}).nullish(),
    city: z.string().min(1, {message: 'Campo obrigatório'}).nullish(),
    state: z.string().min(1, {message: 'Campo obrigatório'}).nullish(),
  }).nullish(),
});

export const statusValues = [
  {label: 'Ativado', value: 'ativado'},
  {label: 'Pendente', value: 'pendente'},
  {label: 'Desativado', value: 'desativado'},
  {label: 'Arquivado', value: 'arquivado'},
];

export const accountTypeValues = [
  {label: 'Personal', value: 'personal'},
  {label: 'Gym', value: 'gym'},
];

export const visibilityValues = [
  {label: 'Público', value: 'public'},
  {label: 'Privado', value: 'private'},
];