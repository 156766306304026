import * as React from 'react';
import {FilialContextProvider} from '../../../context/admin/filials/Filial.context';
import Breadcrumb from '../../../components/Breadcrumb';
import { FilialFormComponent } from '../../../components/admin/filials/FilialFormComponent';

const BreadcumbsData = (account) => [
  {
    url: null,
    icon: 'map-pin',
    label: `${account.accountType == 'personal' ? 'Locais' : 'Filials'}`,
  },
]

function FilialForm({filial, account, scope}) : JSX.Element {
  return(
    <FilialContextProvider filial={filial} account={account} scope={scope}>
      <div className="row">
        <div className="col s12">
          {/* <Breadcrumb data={BreadcumbsData(account)} /> */}
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <h1>{account.accountType == 'personal' ? 'Local' : 'Filial'}</h1>
        </div>
      </div>
      <FilialFormComponent />
    </FilialContextProvider>
  )
}

export default FilialForm;