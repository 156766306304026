import styled from 'styled-components';
import {COLOR, DIMENSION} from '../constants';

export const TabsHeaderList = styled.ul`
  align-items: center;
  display: flex;
  font-size: 1.33em;
  margin-bottom: ${DIMENSION.MARGIN_DEFAULT}px;
  @media screen and (max-width: 768px){
    font-size: 1.33vh;
  }
`;

export const TabHeaderItem = styled.li`
  background-color: ${props => props.active ? '#FFF' : 'inherit'};
  border-bottom: 5px solid;
  border-color: ${props => props.active ? COLOR.PRIMARY : COLOR.TEXT+'33'};
  cursor: pointer;
  color: ${props => {
    if(props.active) {
      return '#777';
     } else if (props.errors){
       return 'red';
     } else {
       return '#AAA';
     } 
    }
  };
  font-weight: ${props => props.active ? 700 : 300};
  padding: 30px ${DIMENSION.PADDING_DEFAULT}px;
`;