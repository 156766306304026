import * as React from 'react';
import {Switch, TextInput, Textarea} from 'react-materialize';
import {UploadMediaFile} from '../media';
import {TranslateMediaValueToFileProps} from '../../../utils';
import {RadioGroupContainer} from './PlanFormComponent.style';
import InputColor from 'react-input-color';
import {Card, RadioGroup} from '../../elements';

import {PlanContext} from '../../context/PlanContext';

export function PlanFormInfoComponent() : JSX.Element {
  const {
    errors,
    plan,
    setUploadImage,
    handleChange,
  } = React.useContext(PlanContext);
  
  const [enabledColor, setEnabledColor] = React.useState(!!plan.color)
  function setColor(color: string) {
    handleChange({target: {name: 'color', value: color.hex}})
  }
  
  const PlanInfo = (
    <>
      <p>
        <span>Informações do plano</span>
      <br/>
        {!!plan.code && <small><span>código do plano:</span> <code>{plan.code}</code></small>}
      </p>
    </>
  )
  return(
      <div className="row">
        <div className="col s12">
          <Card title={PlanInfo}>
          <div className="row">
            <div className="col s12 m6">
              <UploadMediaFile mediaType='image' propFile={TranslateMediaValueToFileProps(plan.cover)} setUploadFile={setUploadImage}/>
              <label>Habilitar cor</label>
              <div className="flex" style={{
                display: 'flex',
                gap: '10px',
              }}>
                <Switch
                  id="enabledColor"
                  name="enabledColor"
                  checked={enabledColor}
                  onChange={() => setEnabledColor(prev => !prev)}
                />
                {enabledColor && <InputColor
                  initialValue="#5e72e4"
                  onChange={setColor}
                  initialValue={plan.color || ''}
                  placement="right"
                />}
              </div>
            </div>
            <TextInput 
                s={12}
                m={6}
                id="name"
                name="name"
                label="Nome do plano*" 
                value={plan.name || ''} 
                error={errors.name} 
                inputClassName={errors?.name ? 'invalid' : ' '} 
                onChange={handleChange}
              />
            <Textarea
              s={12}
              m={6}
              id='description'
              name='description'
              placeholder='Descreva o plano, o que você oferece...'
              label='Descrição do plano'
              value={plan.description}
              onChange={handleChange}
            />
          </div>
          <div className="row">
            <div className="col s12">
              <RadioGroupContainer>
                <RadioGroup
                    id='planType'
                    label="Tipo do plano"
                    name="planType"
                    onChange={handleChange}
                    options={[
                      {
                        label: 'Plano Comum',
                        value: 'normal'
                      },
                      {
                        label: 'Por checkin',
                        value: 'checkin'
                      },
                      {
                        label: 'Gympass',
                        value: 'gympass'
                      },
                      {
                        label: 'Totalpass',
                        value: 'totalpass'
                      },
                    ]}
                    value={plan.planType}
                    withGap
                  />
                </RadioGroupContainer>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <RadioGroupContainer>
                <RadioGroup
                    id='visibility'
                    name="visibility"
                    label="Visibilidade do plano"
                    helper='Escolha este plano só pode ser aplicado pelo painel ou se é público. Planos públicos podem ser contratados pelo seu site, por exemplo'
                    onChange={handleChange}
                    options={[
                      {
                        label: 'Público',
                        value: 'everywhere'
                      },
                      {
                        label: 'Apenas interno',
                        value: 'only_team'
                      },
                    ]}
                    value={plan.visibility}
                    withGap
                  />
                </RadioGroupContainer>
            </div>
          </div>
          </Card>
        </div>
      </div>
  )
}