import { gql } from "@apollo/client";

export const CREATE_ACCOUNT_MUTATION = gql`
  mutation createAccount(
    $avatar: Upload,
    $name: String!
    $lastName: String!
    $email: String!
    $phone: String!,
    $password: String!
    $atuation_type: String!
    $category: String
    $subcategory: String
    $cref: String,
    $cpf: String,
    $zipcode: String,
    $street: String,
    $number: String,
    $complement: String,
    $neighborhood: String,
    $city: String,
    $state: String,
  ) {
    createAccount(input: {
      name: $name
      lastName: $lastName
      email: $email
      password: $password
      atuationType: $atuation_type
      category: $category
      subcategory: $subcategory
      cref: $cref
      cpf: $cpf
      avatar: $avatar
      phone: $phone
      zipcode: $zipcode
      street: $street
      number: $number
      complement: $complement
      neighborhood: $neighborhood
      city: $city
      state: $state

    }) {
      account {
        id
        name
        code
        owner {
          id
          name
          lastName
          email
          phone
          token
        }
      }
    }
  }
`;