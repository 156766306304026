import { gql } from "@apollo/client";

export const SEND_REPLY_MESSAGE_MUTATION = gql`
  mutation sendReplyMessage($messageId: ID!, $body: String!) {
    sendReplyMessage(input: {messageId: $messageId, body: $body}) {
      message {
        id
      }
    }
  }
`;

export const CREATE_MESSAGE_MUTATION = gql`
  mutation createMessage($to: String!, $subject: String, $body: String!) {
    createMessage(input: {to: $to, subject: $subject, body: $body}) {
      message {
        id
        subject
        body
        sentAt
        from {
          id
          name
        }
      }
    }
  }
`;