import * as React from 'react';
import { MutationSignUp } from '../../services/auth/MutationSignUp';
import { MutationSignIn } from '../../services/auth/MutationSignIn';

export const AuthContext = React.createContext({});

export const AuthProvider = ({ children, currentUser : currentUserProps, authType: authTypeProps, account }) => {
  const [currentUser, setCurrentUser] = React.useState( currentUserProps || {});
  const [loading, setLoading] = React.useState(false);
  const [authType, setAuthType] = React.useState(authTypeProps || 'signup');

  const reviewSignIn = function(formulario){
    const postData = {
    authenticity_token: window.authenticity_token,
      user:{
        email: formulario.email,
        password: formulario.password,
        rememberMe: 'true',
      }
    };
    const form = document.createElement('form');
    const redirectUrl = `/${account}/sign-in/`;
    form.method = 'POST';
    form.action = '/users/sign_in';
    form.classList.add('hidden');
    form.target = '_blank';
    
    const authenticityTokenInput = document.createElement('input');
    authenticityTokenInput.type = 'hidden';
    authenticityTokenInput.name = 'authenticity_token';
    authenticityTokenInput.value = postData.authenticity_token;
    form.appendChild(authenticityTokenInput);
  
    const emailInput = document.createElement('input');
    emailInput.type = 'email';
    emailInput.name = 'user[email]';
    emailInput.value = postData.user.email;
    form.appendChild(emailInput);
  
    const passwordInput = document.createElement('input');
    passwordInput.type = 'password';
    passwordInput.name = 'user[password]';
    passwordInput.value = postData.user.password;
    form.appendChild(passwordInput);
  
    const rememberMeInput = document.createElement('input');
    rememberMeInput.type = 'hidden';
    rememberMeInput.name = 'user[remember_me]';
    rememberMeInput.value = postData.user.rememberMe;
    form.appendChild(rememberMeInput);
  
    const redirectInput = document.createElement('input');
    redirectInput.type = 'hidden';
    redirectInput.name = 'redirect_to';
    redirectInput.value = redirectUrl;
    
    form.appendChild(redirectInput);
    document.body.appendChild(form);
    form.submit();
  
    setTimeout(() => {
      form.remove();
    }, 1000);
  
  }

  const AuthFormSubmit = ({event}) => {
    event.preventDefault();
    if(loading) return;
    const {target} = event;
    const formulario = {};
  
    for(let i = 0; i < target.elements.length; i++){
      const element = target.elements[i];
      if(element.name) formulario[element.name] = element.value;
    }
  
    if(authType == 'signup'){
      if(formulario.name.split(' ').length < 2) return M.toast({html: 'Digite nome e sobrenome'});
      if( !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formulario.email)) ) return M.toast({html: 'Digite um email válido'});
      if(formulario.password.length < 6) return M.toast({html: 'Digite uma senha com no mínimo 6 caracteres'});
      MutationSignUp(formulario, setLoading)
      .then(({user}) =>{
        reviewSignIn(formulario)
        setCurrentUser(user);
      });
    } else if(authType == 'signin'){
      if( !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formulario.email)) ) return M.toast({html: 'Digite um email válido'});
      if(formulario.password.length < 6) return M.toast({html: 'Digite uma senha com no mínimo 6 caracteres'});
      MutationSignIn(formulario, setLoading)
      .then(({user}) =>{
        reviewSignIn(formulario)
        setCurrentUser(user);
      });
    }
  }

  return(
    <AuthContext.Provider 
    value={{
      currentUser, 
      setCurrentUser,
      loading,
      setLoading,
      authType,
      setAuthType,
      AuthFormSubmit,
      account,
      }}>
      {children}
    </AuthContext.Provider>
  )
}