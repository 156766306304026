import {ExerciseWorkoutProps, ExerciseWorkoutMutationProps, WorkoutGroupProps, WorkoutGroupMutationProps} from '../../application/types';

export function TranslateWorkoutGroupToMutation(workoutGroup: WorkoutGroupProps): WorkoutGroupMutationProps{
  const {id, name, description, cover, video, attachment, audio, workouts = [], _destroy} = workoutGroup;
  return {
    id, name, description, cover: cover instanceof File ? cover : undefined, video: video instanceof File ? video : undefined, attachment: attachment instanceof File ? attachment : undefined, audio: audio instanceof File ? audio : undefined,  _destroy, workoutsAttributes: workouts.map(
      (w, index) => (
        {
          id: w.id, 
          name: w.name, 
          _destroy: w._destroy,
          description: w.description,
          timeText: w.time_text,
          checkinEnabled: w.checkinEnabled,
          order: index,
          exerciseWorkoutsAttributes: (w.exerciseWorkouts||[]).map((exerciseWorkout, exerciseIndex) => TranslateExerciseWorkoutToMutation(exerciseWorkout, exerciseIndex))
        }
      ))
  }
}

export function TranslateExerciseWorkoutToMutation(exerciseWorkout: ExerciseWorkoutProps, exerciseIndex: Integer) : ExerciseWorkoutMutationProps {
  const {id,exerciseId,repetitions,rounds,notes,load,_destroy,timeText, exercise } = exerciseWorkout;
  let exerciseIdFiltered = exerciseId;
  let time;
  if(!exerciseId && exercise && exercise.id) exerciseIdFiltered = exercise.id;
  if(!exerciseId && exercise && exercise.value) exerciseIdFiltered = exercise.value;
  if(timeText) {
    const tempo_em_texto = timeText.split(":")
	  	if(tempo_em_texto.length > 1){
	  		const minutos = parseInt(tempo_em_texto[0])*60
	  		const segundos = parseInt(tempo_em_texto[1])
	  		time = `${minutos + segundos}`

      } else{
	  		time = `${tempo_em_texto[0]}`

      }
  }
  return {
    id,
    exerciseId: exerciseIdFiltered,
    load,
    notes,
    order: exerciseIndex,
    repetitions,
    rounds,
    timeText,
    time,
    _destroy,
  }

}