import * as React from 'react';
import {
  WorkoutGroupTitle,  
  WorkoutGroupPreviewContainer, 
  WorkoutTitle, 
  WorkoutPreviewContainer, 
  WorkoutPreviewDescription,
} from './workoutGroups.component.style';
import { WorkoutGroupContainerMinified } from './WorkoutGroups.style';

export const WorkoutGroupPreview = ({workoutGroup, showDetails}) => {
  const {workouts } = workoutGroup;
  return (
    <WorkoutGroupPreviewContainer className="workout-group-preview" style={{flex: 1}}>
      <WorkoutGroupTitle className="workout-group-preview__title">
        {workoutGroup.name} {!!workoutGroup.id && <a href={`/${window.account}/admin/workout-groups/${workoutGroup.id}`} target='_blank' ><i className={`fa-brands fa-chromecast fa-2xs`} /></a>}
      </WorkoutGroupTitle>
      {!!showDetails && <div className="workout-group-preview__description">
        {workoutGroup.workouts.map(({id, name, description, exerciseWorkouts}) => {
          return (
            <WorkoutPreviewContainer key={id}>
            <WorkoutTitle>
              {name}
            </WorkoutTitle>
            <WorkoutPreviewDescription>{description}</WorkoutPreviewDescription>
            {!!exerciseWorkouts?.length && <strong>Exercícios:</strong>}
            {!!exerciseWorkouts?.length && exerciseWorkouts.map(({exercise}) => {
              return (
                <div>
                  {exercise.label}
                </div>
              )
            })}            
            </WorkoutPreviewContainer>
          );
        })}
      </div>}
      {!showDetails && <WorkoutGroupContainerMinified><i className="far fa-ellipsis-h" /></WorkoutGroupContainerMinified>}
    </WorkoutGroupPreviewContainer>
  ) 
}