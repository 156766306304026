import * as React from 'react';
import { ReactSortable } from "react-sortablejs";

import Switches from '../elements/Switches'

const Accordion: React.FC = ({data}) => {

  const [state, setState] = React.useState([
    { id: 1, name: "shrek" }
  ]);


  React.useEffect(()=>{
      const elems = document.querySelectorAll('.collapsible');
      M.Collapsible.init(elems, {
          accordion: false
      });
  }, [])
  
  const renderMaterilizeAccordion = () =>{

  }

  const  renderReactSortable = () => {

  }

  return (
   <ul className="collapsible  expandable" >
      {data.map((item, index) => (
        <li key={index}>
          <div className="collapsible-header">{item.title}</div>
          <div className="collapsible-body">{item.content}</div>
        </li>
      ))}
    </ul>
  );
}

export default Accordion;