import {z} from 'zod';

export const RegisterFormSchema = z
        .object({
          avatar: z.any().nullish(),
          name: z.string().refine(
            (data) => {
              const nameRaw = data.trim().split(' ');
              return nameRaw.length > 1;
            },
            { message: "Você precisa preencher Nome e Sobrenome" }
          ),
          email: z.string().email("Email inválido"),
          phone: z.string().min(11).refine(
            (data) => {
              const phoneRaw = data.trim().replace(/\D/g, '');
              return phoneRaw.length === 11;
            },
            { message: "Número de telefone inválido" }
          ).default(''),
          city: z.string().nullish(),
          state: z.enum(['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']).nullish(),
          atuation_type: z.enum(['presencial', 'online', 'presencial_online']).nullish(),
          category: z.string().min(1, {message: "Selecione uma categoria"}).nullish(),
          subcategory: z.string().min(1, {message: "Selecione uma subcategoria"}).nullish(),
          cref: z.string().min(3, {message: "CREF inválido"}).nullish(),
          password: z.string().min(8, {message: "Sua senha deve ter pelo menos 8 caracteres"}),
          street: z.string().min(1, {message: "Rua inválida"}).nullish(),
          number: z.string().min(1, {message: "Número inválido"}).nullish(),
          complement: z.string().nullish(),
          neighborhood: z.string().min(1, {message: "Bairro inválido"}).nullish(),
          zipcode: z.string().min(1, {message: "CEP inválido"}).nullish(),
          cpf: z.string().min(1, {message: "CPF inválido"}).nullish(),
      })

export type RegisterFormProps = z.infer<typeof RegisterFormSchema>;

export const StateEnum = z.enum(['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']).Values;