import styled from 'styled-components';

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ProfileSidebar = styled.div`
  align-items: center;
  background-color: #F9F9F9;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  margin-right: 20px;
  min-width: 250px;
  padding: 20px;
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export const ProfileSidebarHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid #E0E0E0;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;
  margin-top: -20px;
  margin-bottom: 20px;
  padding: 20px;
  width: calc(100% + 40px);
  & > div {
    cursor: pointer;
  }
`;

export const ProfileSidebarName = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
`;

export const ProfileSidebarItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ProfileSidebarItemHeaderTitle = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
`;

export const ProfileSidebarItemHeaderDescription = styled.span`
  color: #777777;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  width: 100%;
`;