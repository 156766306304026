import * as React from 'react';
import {TextInput, Switch, Select} from 'react-materialize';

import {AccountSettingsContext} from '../../context/AccountSettingsContext';
import {SelectContainer} from './AccountContainer.style';

export function AccountRegisterComponent() : JSX.Element {
  const {account, updateAccount, updateAccountFromEvent} = React.useContext(AccountSettingsContext);
  const {registerEnabled, requiresPlanOnSignup, registerStatus} = account;
  const handleChange = ({target}) => {
    updateAccount({[target.name]: !!target.checked})
  }

  const REGISTER_STATUSES = ['ativo', 'pendente', 'inativo', 'cancelado'];

  return(
    <>
      <div className="row">
        <div className="col s12">
          <h2>Novos Cadastros</h2>
        </div>
      </div>
      <div className="row">
          <div className="col s12">
            <label htmlFor="registerEnabled">Permitir cadastro de novos usuários?</label>
            <Switch
              offLabel="Não"
              onLabel="Sim"
              id="registerEnabled"
              name="registerEnabled"
              onChange={handleChange}
              checked={registerEnabled || false}
            />
          </div>
      </div>
      {!!registerEnabled && (
        <>
        <div className="row">
          <div className="col s12">
            <h3>Defina os padrões de novos cadastros</h3>
          </div>
        </div>
          <div className="row">
            <SelectContainer>
              <Select
                id="registerStatus"
                name="registerStatus"
                onChange={updateAccountFromEvent}
                value={registerStatus}
                label="Qual o status de novos cadastros?"
              >
                {REGISTER_STATUSES.map( (item) => <option key={item} value={item}>{item}</option>)}
              </Select>
            </SelectContainer>
            <div className="col s12">
              <label htmlFor="requiresPlanOnSignup">Exigir a escolha de um plano ao se cadastrar?</label>
              <Switch
                offLabel="Não"
                onLabel="Sim"
                id="requiresPlanOnSignup"
                name="requiresPlanOnSignup"
                onChange={handleChange}
                checked={requiresPlanOnSignup || false}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}