import * as React from 'react';

import {
  LoadingContainer, 
  LoadingProgressContainer, 
  LoadingProgress,
  LoadingContentContainer
} from './LoadingComponent.style';
import {messages} from '../../utils';

import LoadingImage from '../../assets/images/loading.gif';

import {WorkoutRoutineContext, WorkoutRoutineGlobalContext} from '../context/WorkoutRoutineContext';

type LoadingProps = {
  loading?: boolean;
  progress?: number;
  customMessage?: string;
}
const LoadingComponent = ({loading: loadingGlobal, progress: progressGlobal, customMessage}: LoadingProps) : JSX.Element => {
  const {
    loading,
    progress
  } = React.useContext(WorkoutRoutineContext);

  return(
    <LoadingContainer loading={loadingGlobal !== undefined ? (loadingGlobal || '').toString() : (loading || '').toString() }>
      {customMessage ? customMessage : messages.server.loading }
      <LoadingProgressContainer>
        <LoadingProgress progress={progressGlobal !== undefined ? progressGlobal : progress} />
      </LoadingProgressContainer>
    </LoadingContainer>
  )
}

export const LoadingContent = ({loading}) : JSX.Element => {
  return(
    <LoadingContentContainer>
      <img src={LoadingImage} />
    </LoadingContentContainer>
  )
}

const LoadingComponentGlobal = WorkoutRoutineGlobalContext(LoadingComponent)

export { LoadingComponentGlobal as LoadingComponent}