interface HandleZipcodeProps {
  event: React.ChangeEvent<HTMLInputElement>;
  callback: (data: any) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const handleZipcode = ({event, callback, loading, setLoading}: HandleZipcodeProps) => {
  const {value} = event.target;
  const zipcode = (value).replace(/\D/g, '');
  if(zipcode.length == 8 && !loading){
    setLoading(true);
    fetch(`https://viacep.com.br/ws/${zipcode}/json/`)
      .then(response => response.json())
      .then(data => {
        const {bairro : neighborhood, localidade : city, logradouro : street, uf : state} = data;
        callback({neighborhood, city, street, state});
      })
      .finally(() => setLoading(false));
  }
}