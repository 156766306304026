import React, { useState } from 'react';
import { ProfileControllerContainer, ProfileControllerHeader, ProfileControllerOption } from './ProfileController.style';
import { ProfilePlan } from './ProfilePlan.component';
import { ProfileCheckin } from '../checkins/ProfileCheckin.component';
import { ProfileProvider, useProfile } from '../profile.context';
import { ProfileInbox } from '../inbox/Inbox.component';
import { ProfilePoints } from '../points/Points.component';
import { ProfileEdit } from '../form/ProfileEdit.component';


interface ProfileControllerProps {
  aluno: any;
  account: any;
}

const ProfileControllerMenu = () => {
  const {activeOption, setActiveOption, account, aluno} = useProfile();
  return (
    <ProfileControllerHeader>
      {!!aluno?.id && <>
        <ProfileControllerOption active={activeOption === 'planos'} onClick={() => setActiveOption('planos')}>
          <i className="fa-light fa-file-signature" />
          <span>Planos</span>
        </ProfileControllerOption>

        {account.features.includes('checkins') && <ProfileControllerOption active={activeOption === 'checkins'} onClick={() => setActiveOption('checkins')}>
          <i className="fa fa-check " />
          <span>Checkins</span>
        </ProfileControllerOption>}

        {account.features.includes('communication') && <ProfileControllerOption active={activeOption === 'inbox'} onClick={() => setActiveOption('inbox')}>
          <i className="fa-light fa-envelope" />
          <span>Caixa de Entrada</span>
        </ProfileControllerOption>}

        {account.features.includes('points') && <ProfileControllerOption active={activeOption === 'pontos'} onClick={() => setActiveOption('pontos')}>
          <i className="fa-light fa-coins" />
          <span>Pontos</span>
        </ProfileControllerOption>}
      </>}
        <ProfileControllerOption active={activeOption === 'edit'} onClick={() => setActiveOption('edit')}>
          <i className="fa-light fa-pen" />
          <span>Editar</span>
        </ProfileControllerOption>

      </ProfileControllerHeader>
  )
}


export const ProfileController = () => {
  const {activeOption} = useProfile();
  return (
      <ProfileControllerContainer>
        <ProfileControllerMenu />
        {activeOption === 'planos' && <ProfilePlan />}
        {activeOption === 'checkins' && <ProfileCheckin />}
        {activeOption === 'inbox' && <ProfileInbox />}
        {activeOption === 'pontos' && <ProfilePoints />}
        {activeOption === 'edit' && <ProfileEdit />}
    </ProfileControllerContainer>
  )
}