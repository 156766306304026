import * as React from 'react';
import {TextInput, Textarea} from 'react-materialize';
import InputMask from 'react-input-mask';
import {
  ExerciseRow,
  ExerciseColumn,
  ExerciseActionButton,
  ExerciseContainerContentEmpty,
  ExerciseContainer,
  ExerciseContainerHeader,
  ExerciseBody,
  ExerciseSelectContainer,
} from './ExerciseComponents.style';
import {
  SelectValueProps,
  ExerciseWorkoutProps,
  WorkoutProps,
  WorkoutGroupProps,
} from '../../types';
import {ExerciseSelect} from './ExerciseSelect';
import {WorkoutToggleContainer} from '../workouts/WorkoutGroups.style'
import {
  ButtonDeleteComponent,
  ButtonToggleContentClean,
  ButtonState,
} from '../button';
import {ButtonRowHeaderContainer} from '../button/Button.style';

import {WorkoutRoutineContext} from '../../context/WorkoutRoutineContext';

type CreateExerciseComponentProps = (value?: SelectValueProps) => void;

type ExerciseEmptyComponentProps = {
  createExerciseComponent: CreateExerciseComponentProps;
  defaultOptions: SelectValueProps[];
  workoutIndex: number;
  workoutGroupIndex: number;
};

export const ExerciseEmptyComponent = ({
  workoutIndex,
  workoutGroupIndex,
  createExerciseComponent,
  defaultOptions,
}: ExerciseEmptyComponentProps): JSX.Element => {
  const onSelect = (value, action) => {
    createExerciseComponent(value);
    return value;
  };

  const disabled = {style: {opacity: 0.5}};

  return (
    <ExerciseContainer className="add-new">
      <ExerciseContainerContentEmpty onClick={() => createExerciseComponent()}>
        Adicionar exercício
      </ExerciseContainerContentEmpty>
    </ExerciseContainer>
  );
};
type UpdateExerciseWorkoutProps = {
  (exerciseWorkout: ExerciseWorkoutProps, index: number): void;
};
type ExerciseComponentProps = {
  workoutExercise?: ExerciseWorkoutProps;
  workout: WorkoutProps;
  workoutGroup: WorkoutGroupProps;
  exerciseIndex: number;
  defaultOptions: SelectValueProps[];
  workoutIndex: number;
  workoutGroupIndex: number;
};

export const ExerciseComponent = ({
  workoutExercise,
  workoutIndex,
  workoutGroupIndex,
  exerciseIndex,
  defaultOptions,
  workout,
  workoutGroup,
}: ExerciseComponentProps): JSX.Element => {
  const {deleteWorkoutExercise, updateWorkoutExercise, updateExerciseWorkoutPosition} = React.useContext(
    WorkoutRoutineContext,
  );

  const onSelect = (selected, action) => {
    const newExerciseWorkout = {
      ...workoutExercise,
      exerciseId: selected.value,
      exercise: selected,
    };
    updateWorkoutExercise(
      newExerciseWorkout,
      exerciseIndex,
      workout,
      workoutGroup,
    );
    if (action) action(selected);
    return selected;
  };

  const onChangeComponent = (event) => {
    const newExerciseWorkout = {
      ...workoutExercise,
      [event.target.name]: event.target.value,
    };
    updateWorkoutExercise(
      newExerciseWorkout,
      exerciseIndex,
      workout,
      workoutGroup,
    );
  };

  const [toggleContent, setToggleContent] = React.useState(ButtonState.open);

  const switchToggleContentExercise = () => {
    const newState =
      toggleContent === ButtonState.open
        ? ButtonState.closed
        : ButtonState.open;
    setToggleContent(newState);
  };

  return (
    <ExerciseRow>
      <ExerciseContainerHeader>
        <WorkoutToggleContainer>
          <i onClick={ () => updateExerciseWorkoutPosition('up',workout, exerciseIndex, workoutGroup, workoutIndex )}  className="far fa-long-arrow-up"/>
          <i onClick={ () => updateExerciseWorkoutPosition('down', workout, exerciseIndex, workoutGroup, workoutIndex)} className="far fa-long-arrow-down"/>
        </WorkoutToggleContainer>
        <ExerciseSelectContainer>
          <ExerciseSelect
            defaultOptions={defaultOptions}
            onSelect={onSelect}
            selectValue={workoutExercise.exercise}
          />
        </ExerciseSelectContainer>
        <ButtonRowHeaderContainer top={'0'} right={'0'}>
          <ButtonToggleContentClean
            onPress={switchToggleContentExercise}
            buttonState={toggleContent}
            title="Minimize o conteúdo para editar mais facilmente"
          />
          <ButtonDeleteComponent
            onPress={() =>
              deleteWorkoutExercise(workoutExercise, workout, workoutGroup)
            }
            name="Exercício"
            confirmMessage={'Você tem certeza que deseja apagar este exercício?'}
            title="Apagar o exercício do bloco de treino"
          />
        </ButtonRowHeaderContainer>
      </ExerciseContainerHeader>
      {toggleContent === ButtonState.open && (
      <ExerciseBody>
        <ExerciseColumn flex={1} col={6}>
          <TextInput
            noLayout={true}
            id={`workoutGroup[${workoutGroupIndex}]workout[${workoutIndex}]-exercise[${exerciseIndex}]-rounds`}
            name={`rounds`}
            placeholder="Séries"
            value={workoutExercise.rounds || ''}
            onChange={onChangeComponent}
          />
        </ExerciseColumn>
        <ExerciseColumn flex={1} col={6}>
          <TextInput
            noLayout={true}
            id={`workoutGroup[${workoutGroupIndex}]workout[${workoutIndex}]-exercise[${exerciseIndex}]-reps`}
            name={`repetitions`}
            validate={(workoutExercise.repetitions || '').length < 144}
            data-length={144}
            placeholder="Reps"
            value={workoutExercise.repetitions || ''}
            onChange={onChangeComponent}
          />
        </ExerciseColumn>
        <ExerciseColumn flex={1} col={6}>
          <TextInput
            noLayout={true}
            id={`workoutGroup[${workoutGroupIndex}]workout[${workoutIndex}]-exercise[${exerciseIndex}]-carga`}
            name={`load`}
            validate={(workoutExercise.load || '').length < 144}
            data-length={144}
            placeholder="Carga"
            value={workoutExercise.load || ''}
            onChange={onChangeComponent}
          />
        </ExerciseColumn>
        <ExerciseColumn flex={1} col={6}>
          <div className="input-field">
            <InputMask
              id={`workoutGroup[${workoutGroupIndex}]workout[${workoutIndex}]-exercise[${exerciseIndex}]-tempo`}
              name={`timeText`}
              type="tel"
              mask={'99:99'}
              maskChar="0"
              onChange={onChangeComponent}
              value={workoutExercise.timeText || ''}
              placeholder="Tempo"
            />
          </div>
        </ExerciseColumn>
        <ExerciseColumn flex={'0 0 100%'} col={12}>
          <Textarea
            noLayout={true}
            id={`workoutGroup[${workoutGroupIndex}]workout[${workoutIndex}]-exercise[${exerciseIndex}]-notes`}
            name={`notes`}
            onChange={onChangeComponent}
            value={workoutExercise.notes || ''}
            // label={'Descreva o bloco de treino ou observações'}
            placeholder="Observações"
            // className={errors?.description ? 'invalid' : ' '}
            // error={errors?.description}
          />
        </ExerciseColumn>
      </ExerciseBody>
      )}
    </ExerciseRow>
  );
};
