import { z } from "zod";

export const planSchema = z.object({
  id: z.union([z.string(), z.number()]).optional(),
  planId: z.union([z.string(), z.number()]),
  status: z.string().nullish(),
  installments: z.union([z.number(), z.string()]).nullish(),
  paymentMethod: z.enum(['dinheiro', 'credito', 'debito', 'cheque', 'pix', 'transferencia', 'outros']).nullish(),
  discount: z.union([z.number(), z.string()]).optional(),
  discountType: z.enum(['percentual', 'fixed']).nullish(),
  total: z.union([z.number(), z.string()]).nullish(),
  startDate: z.union([z.date(), z.string()]).nullish(),
  endDate: z.union([z.date(), z.string()]).nullish(),
}).refine((data) => {
  if (data.installments && parseInt(data.installments) > 1) {
    return !!data.paymentMethod;
  }
  return true;
}, {
  message: 'Método de pagamento é obrigatório',
  path: ['paymentMethod'],
});