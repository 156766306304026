import * as React from 'react';
import {ProgramsTableComponent} from '../../components/programas';
import Breadcrumb from '../../components/Breadcrumb';

function Programs({programs, user}) : JSX.Element {
  document.title = `${window.account}/programas de treino`;
  const relationship = React.useMemo(() => (user.relationship), [user])
  const userPadawan = React.useMemo(() => (['admin', 'manager'].indexOf(relationship?.rule) > -1 ), [relationship]);

  const BreadcumbsData = [
    {
      url: `/${window.account}/admin/workout-routines/`,
      icon: 'stopwatch',
      label: 'Treinos',
    },
    {
      label: 'Programas de treino',
      url: null
    }
  ]
  return(
    <>
    <div className="row">
      <div className="col s12">
        <Breadcrumb data={BreadcumbsData} />
      </div>
    </div>
    <div className="row">
      <div className="col s12">
        <h1>Programas de Treino</h1>
      </div>
    </div>
    {['admin', 'manager'].indexOf(relationship?.rule) > -1 && 
    <div className="row">
      <div className="col s12"><a href={`/${window.account}/admin/programs/new`} className="right btn btn-primary">Criar programa de treino</a></div>
    </div>}
    <div className="card">
      <ProgramsTableComponent programs={programs} userPadawan={userPadawan} />
    </div>
    </>
  )
}

export default Programs;