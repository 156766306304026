import * as React from 'react';

import {
  InputEvent,
} from '../../types';

import {
  UnitContainer, 
  UnitContainerHead, 
  UnitContainerMinified,
  UnitContainerContentEmpty,
} from './UnitComponents.style';

import {Textarea, TextInput} from 'react-materialize';

import Select from '../../elements/Select';

import {
  ButtonDeleteComponent,
  ButtonToggleContent,
  ButtonState,
  ButtonToggleContentClean,
} from '../button';

import {ButtonRowHeaderContainer} from '../button/Button.style';
import {UnitMediaComponent} from './UnitMediaComponent';

import {TranslateMediaValueToFileProps} from '../../../utils';
import {CourseModuleContext} from '../../context/CourseModuleContext';

type UnitEmptyComponentProps = {
  createUnit: InputEvent;
}

interface UnitProps {
  id: number|string;
  title: string;
  description: string;
  unitType: 'Free'|'Premium';
  cover?: Object | any;
  poster?: Object | any;
  video?: Object | any;
  audio?: Object | any;
}

interface UnitErrorsProps {
  title?: string;
  description?: string;
}

interface UnitComponentProps {
  unit: UnitProps;
  index: number;
}

export const UnitComponent = ({unit, index, errors} : UnitComponentProps) => {
  // setUnit={setUnit} deleteUnit={deleteUnit} errors={(errors?.units||[])[units.indexOf(unit)]}
  const {setUnit, deleteUnit, units, index: courseModuleIndex} = React.useContext(CourseModuleContext);

  if(!unit.unitType) unit.unitType = 'Premium';
  const onDeleteUnit = () => {
    deleteUnit(unit);
  };

  const [toggleContent, setToggleContent] = React.useState(ButtonState.open);

  React.useEffect(()=> {
    if(errors) {
      setToggleContent(ButtonState.open);
      return;
    }
    if(units?.length > 1){
      setToggleContent(ButtonState.closed)
    }
  },[errors])

  const toggleContentUnit = (value) => {
    
    if(value === undefined) {
      toggleContent === ButtonState.open ? setToggleContent(ButtonState.closed) : setToggleContent(ButtonState.open)
    } else {
      setToggleContent(value);
    }
  };

  const handleChange = ({target}) => {
    const newUnit = {...unit, [target.name]: target.value};
    setUnit(newUnit, index);
  }

  const handleMediaUpdate = (mediaType, file) => {
    const newUnit = {...unit, [mediaType]: file}
    setUnit(newUnit, index);
  }
  return (
    <li>
    <UnitContainer>
      <UnitContainerHead className="row" errors={errors}>
        <TextInput
            s={6}
            l={10}
            id={`coursemodule-${courseModuleIndex}-unit-${index}`}
            name={`title`}
            onChange={handleChange}
            value={unit.title || ''}
            placeholder={`Escreva o título dessa aula`}
            inputClassName={errors?.title ? 'invalid' : ' '}
            error={errors?.title}
            label="Título da aula"
          />
          <Select
            s={6}
            l={2}
            name='unitType'
            selectType='materialize'
            label='Tipo de aula'
            options={[{value: 'Free', label: 'Gratuita'}, {value: 'Premium', label: 'Premium'}]}
            placeholder={'Selectione o tipo da aula'}
            onChange={handleChange}
            value={unit.unitType}
          />
        <ButtonRowHeaderContainer>
          <ButtonToggleContentClean
            onPress={toggleContentUnit}
            buttonState={toggleContent}
            title="Minimize o conteúdo para editar mais facilmente"
          />
          <ButtonDeleteComponent
            onPress={onDeleteUnit}
            name="Aula"
            title="Apagar a aula"
          />
        </ButtonRowHeaderContainer>
      </UnitContainerHead>
      {toggleContent === ButtonState.open && (
        <>
          <UnitMediaComponent
            handleMediaUpdate={handleMediaUpdate}
            cover={TranslateMediaValueToFileProps(unit.cover)}
            poster={TranslateMediaValueToFileProps(unit.poster)}
            video={TranslateMediaValueToFileProps(unit.video)}
            audio={TranslateMediaValueToFileProps(unit.audio)}
          />
          <div className="row">
            <Textarea
              s={12}
              id={`unit-description`}
              name={`description`}
              onChange={handleChange}
              value={unit.description || ''}
              label={'Descrição*'}
              placeholder={`Escreva aqui a descrição/detalhes de sua aula`}
              className={errors?.description ? 'invalid' : ' '}
              error={errors?.description}
              style={{maxHeight: 190}}
            />
          </div>
        </>
      )}
      {toggleContent === ButtonState.closed && (
        <UnitContainerMinified
          onClick={() => toggleContentUnit()}>
          <small>ver detalhes <strong>...</strong></small>
        </UnitContainerMinified>
      )}
    </UnitContainer>
    </li>
  )
}

export const UnitEmptyComponent = () => {
  const {createNewUnit, index} = React.useContext(CourseModuleContext);
  return (
    <UnitContainer className='clean-top add-new'>
      <UnitContainerContentEmpty onClick={createNewUnit}>
        Adicionar nova aula
      </UnitContainerContentEmpty>
    </UnitContainer>
  )
};