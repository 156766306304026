import {formatDate} from '../Date';
import {TranslateWorkoutGroupToMutation} from './TranslateWorkout';
import {TargetProps, WorkoutRoutineStateProps} from '../../application/types';

import {validations} from '../../application/messages';

import {client} from '../../core/stores';
import {MUTATION_WORKOUT_GROUP_ROUTINE, MUTATION_WORKOUT_GROUP_BY_ROUTINE} from '../../core/graphql/types';

export function MutateWorkoutGroupRoutine(workoutRoutineState : WorkoutRoutineStateProps, state : WorkoutRoutineStateProps, values, setSubmitting, isDraft = true, setProgress) : Promise{
  return new Promise((resolve,reject) => {
    setSubmitting(true);
    const {startDate, endDate, selectedPrograms, selectedUsers, workoutTarget} = state;
    values = {...values, target: workoutTarget};
    if(startDate) values = {...values, startDate: formatDate(startDate)};
    if(endDate) values = {...values, endDate: formatDate(endDate)}; 
    if(isDraft == false) values = {...values, isDraft: false}
  
    if(values.target === TargetProps.users && !selectedUsers){
      M.toast({html: 'Se a rotina de treino é para uma pessoa ou grupo, você precisa selecionar um ou mais usuários', displayLength: 5000})
      reject(false);
      return false;
    }
    if(!values.id && workoutRoutineState.id) values = {...values, id: workoutRoutineState.id};
    if(!values.name) values = {...values, name: workoutRoutineState.name};
    if(!values.target) values = {...values, target: workoutRoutineState.target};
    if(!values.cycleType) values = {...values, cycleType: workoutRoutineState.cycleType};

    if(values.target === TargetProps.programs) values = {...values, programIds: selectedPrograms ? selectedPrograms : workoutRoutineState.programIds, usersTargetIds: []};
    if(values.target === TargetProps.users) values = {...values, usersTargetIds: selectedUsers ? selectedUsers : workoutRoutineState.usersTargetIds, programIds: []};
  
    if(workoutRoutineState && workoutRoutineState.workoutGroups) values = {...values, workoutGroups: (workoutRoutineState.workoutGroups || []).map(wg => TranslateWorkoutGroupToMutation(wg))}
    const errors = ValidateMutationWorkoutGroupRoutine(values);
    if(Object.entries(errors).length !== 0) {
      setSubmitting(false);
      reject(errors);
      return false;
    }

    client('account').mutate({
        mutation: MUTATION_WORKOUT_GROUP_ROUTINE, 
        variables: values, 
        context: {
          fetchOptions: {
            useUpload: true,
            onProgress: (ev: ProgressEvent) => {
              if(setProgress) setProgress(ev.loaded / ev.total)
            },
            onAbortPossible: (abortHandler: any) => {
            }
          }
        }
    })
      .then(response => {
        const {data} = response;
        const {workoutGroupRoutine: {workoutGroupRoutine}} = data || {};
        M.toast({html: 'Sua rotina foi salva com sucesso!'});
        setSubmitting(false);
        resolve({status: 'success', workoutGroupRoutine})
      })
      .catch(err => {
        const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
        M.toast({ html: 'Houve um erro na sua solicitação.' })
        M.toast({ html: errorMessage })
        setSubmitting(false);
        reject({status: 'error', message: errorMessage})
      });
  })
}

export function MutateWorkoutGroupByRoutine(workoutRoutine, workoutGroup, setSubmitting, setProgress ) : Promise {
  return new Promise((resolve, reject) => {
    setSubmitting(true);
    const errors = {};
    const {id, name, cover, video, attachment, workouts, _destroy} = workoutGroup;
    if(!name && !video && !cover && !attachment && !workouts.length) errors.name = validations.required;

    if(Object.entries(errors).length !== 0) {
      setSubmitting(false);
      reject(errors);
      return false;
    }
    client('account').mutate({
      mutation: MUTATION_WORKOUT_GROUP_BY_ROUTINE,
      variables: { 
        workoutGroupRoutineId: workoutRoutine.id,
        ...TranslateWorkoutGroupToMutation(workoutGroup),
      },
      context: {
        fetchOptions: {
          useUpload: true,
          onProgress: (ev: ProgressEvent) => {
            if(setProgress) setProgress(ev.loaded / ev.total)
          },
          onAbortPossible: (abortHandler: any) => {
          }
        }
      }
    })
    .then(response => {
      const {data} = response;
      const {workoutGroupByRoutine: {workoutGroup}} = data || {};
      M.toast({html: `Treino ${workoutGroup.name} salvo com sucesso!`});
      setSubmitting(false);
      resolve({status: 'success', workoutGroup})
    })
    .catch(err => {
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro na sua solicitação.' })
      M.toast({ html: errorMessage })
      setSubmitting(false);
      reject({status: 'error', message: errorMessage})
    });
  })
}

export function MutateWorkoutRoutine(workoutRoutine : WorkoutRoutineStateProps, setSubmitting, setProgress) : Promise{
  return new Promise((resolve,reject) => {
    setSubmitting(true);
    let values = workoutRoutine;
    const {name, startDate, endDate, usersTargetIds} = workoutRoutine;
    if(!name) values = {...values, name: 'Nova rotina de treino'};
    if(startDate) values = {...values, startDate: formatDate(startDate)};
    if(endDate) values = {...values, endDate: formatDate(endDate)}; 
    values = workoutRoutine.workoutGroups && workoutRoutine.workoutGroups.length > 0 ? {...values, isDraft: false} : {...values, isDraft: true}
  
    if(values.target === TargetProps.users && !(usersTargetIds || usersTargetIds.length)){
      M.toast({html: 'Se a rotina de treino é para uma pessoa ou grupo, você precisa selecionar um ou mais usuários', displayLength: 5000})
      reject(false);
      return false;
    }
    
    if(values.target === TargetProps.programs) values = {...values, usersTargetIds: []};
    if(values.target === TargetProps.users) values = {...values, programIds: []};
    const errors = ValidateMutationWorkoutGroupRoutine(values);
    if(Object.entries(errors).length !== 0) {
      setSubmitting(false);
      reject(errors);
      return false;
    }
    delete values.workoutGroups;
    client('account').mutate({
        mutation: MUTATION_WORKOUT_GROUP_ROUTINE, 
        variables: values, 
        context: {
          fetchOptions: {
            useUpload: true,
            onProgress: (ev: ProgressEvent) => {
              if(setProgress) setProgress(ev.loaded / ev.total)
            },
            onAbortPossible: (abortHandler: any) => {
            }
          }
        }
    })
      .then(response => {
        const {data} = response;
        const {workoutGroupRoutine: {workoutGroupRoutine}} = data || {};
        M.toast({html: 'Sua rotina foi salva com sucesso!'});
        setSubmitting(false);
        resolve({status: 'success', workoutGroupRoutine})
      })
      .catch(err => {
        const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
        M.toast({ html: 'Houve um erro na sua solicitação.' })
        M.toast({ html: errorMessage })
        setSubmitting(false);
        reject({status: 'error', message: errorMessage})
      });
  })
}

export function ValidateMutationWorkoutGroupRoutine(values, notify = true){
  const errors = {};
  //validar  workoutgroupRoutine
  if(values.target === TargetProps.users && !(values.usersTargetIds || []).length) errors['target'] = validations.required;
  //validar workoutgroups
  if((values.workoutGroups || []).length){
    values.workoutGroups.map( (workoutGroup, index) => {
      //validar workouts
      if((workoutGroup.workoutsAttributes || []).length){
        workoutGroup.workoutsAttributes.map((workout, workoutIndex) => {
          //observações
          if(!workout.description && !(workout.exerciseWorkoutsAttributes || []).length) {
            if(!errors['workoutGroups']) errors['workoutGroups'] = [];
            if(!errors['workoutGroups'][index]) errors['workoutGroups'][index] = {};
            if(!errors['workoutGroups'][index]['workoutsAttributes']) errors['workoutGroups'][index]['workoutsAttributes'] = [];
            if(!errors['workoutGroups'][index]['workoutsAttributes'][workoutIndex]) errors['workoutGroups'][index]['workoutsAttributes'][workoutIndex] = {}
            errors['workoutGroups'][index]['workoutsAttributes'][workoutIndex]['description'] = validations.workout_description_or_exercise_required;
          }

          if(workout.timeText && !validarTempo(workout.timeText)){
            if(!errors['workoutGroups']) errors['workoutGroups'] = [];
            if(!errors['workoutGroups'][index]) errors['workoutGroups'][index] = {};
            if(!errors['workoutGroups'][index]['workoutsAttributes']) errors['workoutGroups'][index]['workoutsAttributes'] = [];
            if(!errors['workoutGroups'][index]['workoutsAttributes'][workoutIndex]) errors['workoutGroups'][index]['workoutsAttributes'][workoutIndex] = {}
            errors['workoutGroups'][index]['workoutsAttributes'][workoutIndex]['timeText'] = validations.invalid_time;
          }
        })
      }

    })
  }
  if(notify && Object.entries(errors).length !== 0) M.toast({html: 'Você precisa preencher os campos obrigatórios. Confira os campos marcados em vermelho'})
  return errors;
}

function validarTempo(tempo = ''){
  const tempoArray = tempo.split(":");
  if(tempoArray.length <= 1) return false;
  const numb = tempo.replace(/[^0-9]/g,'');
  if(numb.length < 3) return false;
  return true;
}