import * as React from 'react';

import ImageTip from '../../../assets/images/tip-pagamento-recorrente.png';

import {RecurringPaymentTipContainer, RecurringPaymentTipContent} from './RecurringPaymentTip.style';

export const RecurringPaymentTip = (props) : JSX.Element => {
  return(
    <RecurringPaymentTipContainer>
      <img src={ImageTip} alt="Confirgure agora seu pagamento recorrente"/>
      <RecurringPaymentTipContent>
          Você ainda não está utilizando recursos de pagamentos recorrentes. É automático. É fácil. É melhor para você!
      </RecurringPaymentTipContent>
    </RecurringPaymentTipContainer>
  )
}