import styled from "styled-components";

export const InputWrapper = styled.div`
  position: relative;
  .input-icon {
    color: #9e9e9e;
    position: absolute;
    right: 10px;
    top: 15px;
  }
`;