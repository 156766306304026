import * as React from 'react';
import { TextInput } from "react-materialize";
import { InputWrapper } from "./input.component.style";
import { useState } from "react";

interface InputProps extends React.HTMLProps<HTMLInputElement>{
  password?: boolean;
  error?: string;
  [key: string]: any;
}
export function InputComponent({password, ...props} : InputProps){
  const [showPassword, setShowPassword] = useState(false);
  if(password){
    return (
      <InputWrapper>
        <TextInput
          {...props}
          type={showPassword ? 'text' : 'password'}
          className={props.error ? 'invalid' : ''}
          error={props.error}
          />
          <div className="input-icon" onClick={() => setShowPassword(prev => !prev)}>
          {!showPassword ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}
          </div>
      </InputWrapper>
    )
  }
  return (
    <TextInput
      {...props}
      className={props.error ? 'invalid' : ''}
      error={props.error}
    />
)
}

