import * as React from 'react';
import AsyncSelect from 'react-select/async';
import { AsyncPaginate } from 'react-select-async-paginate';

import {QueryGetExercises} from '../../../services';
import {OnSelectProps, SelectValueProps} from '../../types';
import {
  ExerciseSelectOption, 
  ExerciseSelectOptionThumb, 
  ExerciseSelectOptionContent,
  ExerciseSelectOptionTitle,
  ExerciseSelectContainer,
} from './ExerciseComponents.style';

type ExerciseSelectProps = {
  onSelect: OnSelectProps,
  defaultOptions: SelectValueProps[];
  selectValue: SelectValueProps;
}

export const ExerciseSelect = ({onSelect, defaultOptions, selectValue} : ExerciseSelectProps) : JSX.Element => {
  const [value, setValue] = React.useState(selectValue);
  const [inputSearch, setInputSearch] = React.useState('');
  const onChange = (value) => {
    onSelect(value, setValue);
  }


  async function loadOptions(search, loadedOptions, { page }) {
    let hasMore;
    setInputSearch(search);
    if(search.length >= 2 || page > 1){
      const response = await QueryGetExercises(page, search)
      .then(({data}) => {
        const {list, totalPages} = data;
        const exerciseTranslated = list.map( exercise => ({
          animatedImage: exercise.animatedImage,
          image: exercise.image,
          label: exercise.name, 
          value: exercise.id
        }) );
        hasMore = (totalPages > page);
        return exerciseTranslated;
      })
      .catch(err => console.log('error ', err));
      return {
        options: response,
        hasMore,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: defaultOptions,
        hasMore: true,
        additional: {
          page: page + 1,
        },
      }
    }
  }
  
  function TranslateSelectValue(selected){
    if(!selected.value){
      return {
        animatedImage: selected.animatedImage,
        image: selected.image,
        label: selected.name, 
        value: selected.id
      }
    } else {
      return selected
    }
  }

  const OptionComponent = ({innerProps, innerRef, data}) => {
    return(
      <ExerciseSelectOption {...innerProps}>
        {!!data.animatedImage?.uriOriginal && 
          <ExerciseSelectOptionThumb>
            <img src={data.animatedImage?.uriOriginal} />
          </ExerciseSelectOptionThumb>
        }
        {!data.animatedImage?.uriOriginal && !!data.image?.uri && 
          <ExerciseSelectOptionThumb>
            <img src={data.image?.uri} />
          </ExerciseSelectOptionThumb>
        }
        <ExerciseSelectOptionContent>
          <ExerciseSelectOptionTitle>{data.label}</ExerciseSelectOptionTitle>
        </ExerciseSelectOptionContent>
      </ExerciseSelectOption>
    )
  };

  const LoadingMessage = (props) => {
    if(inputSearch.length < 3){
      return(
        <ExerciseSelectOption>
          <ExerciseSelectOptionContent>
            <span>Digite 3 carecteres ou mais para iniciar a busca.</span>
          </ExerciseSelectOptionContent>
        </ExerciseSelectOption>
      )
    } else {
      return(
        <ExerciseSelectOption>
          <ExerciseSelectOptionContent>
            <span>Carregando... </span>
          </ExerciseSelectOptionContent>
        </ExerciseSelectOption>
      )
    }
  }

  const NoOptionsMessage = (props) => (
    <ExerciseSelectOption>
      <ExerciseSelectOptionContent>
        <span>Nenhum exercício encontrado!</span>
      </ExerciseSelectOptionContent>
    </ExerciseSelectOption>
  );
  
  return(
    <ExerciseSelectContainer>
      <AsyncPaginate
        placeholder={'Selecione/digite o exercício'}
        defaultOptions={defaultOptions}
        value={TranslateSelectValue(value)}
        loadOptions={loadOptions}
        onChange={onChange}
        components={{ 
          Option: OptionComponent,
          LoadingMessage: LoadingMessage,
          NoOptionsMessage: NoOptionsMessage
        }}
        additional={{
          page: 1,
        }}
        shouldLoadMore={(scrollHeight, clientHeight, scrollTop) => (clientHeight*2 < scrollTop || (scrollHeight === clientHeight) ) }
      />
    </ExerciseSelectContainer>
  )
}