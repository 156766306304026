import * as React from 'react';
import {AccountSettingsProvider, AccountSettingsContext} from '../../context/AccountSettingsContext';

import {
  AccountContainerContent, 
  AccountContainerController, 
  AccountContainerElement, 
  ControllerList, 
  ControllerListItem
} from './AccountContainer.style';

import {AccountsGeneralComponent} from './AccountGeneralComponent';
import {AccountEmailComponent} from './AccountEmailComponent';
import {AccountRegisterComponent} from './AccountRegisterComponent';
import {AccountCheckinsComponent} from './AccountCheckinsComponent';
import {AccountPaymentComponent} from './AccountPaymentComponent';
import {AccountBillingComponent} from './AccountBilling.component';
import {AccountStripePending} from '../dashboards/TipsComponents';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { AccountSettingsSchema } from './Account.schema';
import { AccountResourcesComponent } from './AccountResourcesComponent';

const AccountControllerComponent = () : JSX.Element => {
  const {activeTab, scope, account, LIST_TABS, handleTab} = React.useContext(AccountSettingsContext);
  let listTabs : any[] = [];
  if(scope === 'alderaan'){
    listTabs = LIST_TABS || [];
  } else {
    listTabs = LIST_TABS?.filter((tab) => tab.scope === 'general') || [];
  }
  if(!account?.features?.includes('checkin') && scope !== 'alderaan'){
    listTabs = listTabs.filter((tab) => tab.value !== 'checkins');
  }
  if(account?.accountType === 'personal' && scope !== 'alderaan'){
    listTabs = listTabs.filter((tab) => tab.value !== 'emails');
  }
  return(
    <AccountContainerController>
      <ControllerList>
        {listTabs.map(({label, value}) => (
          <ControllerListItem active={value === activeTab} onClick={ () => handleTab(value)} key={value}>{label}</ControllerListItem>)
        )}
        {
          scope == 'alderaan' && (
            <>
              <ControllerListItem active={'Planos' === activeTab} onClick={ () => window.open(`/alderaan/accounts/${account.id}/plans`, '_self')} key={'planos'}>Planos</ControllerListItem>
              <ControllerListItem active={'Faturas' === activeTab} onClick={ () => window.open(`/alderaan/accounts/${account.id}/billings`, '_self')} key={'billings'}>Faturas</ControllerListItem>
            </>
          )
        }
      </ControllerList>
    </AccountContainerController>
  )
}


const AccountContainerContentComponent = () : JSX.Element => {
  const {activeTab, loading, createOrEditAccount} = React.useContext(AccountSettingsContext)
  function renderContent(){
    switch(activeTab){
      case 'Envio de e-mails':
      case 'emails':
        return <AccountEmailComponent />;
      case 'Geral':
      case 'geral':
        return <AccountsGeneralComponent />;
      case 'Novos Cadastros':
      case 'register':
        return <AccountRegisterComponent />;
      case 'Checkins':
      case 'checkins':
        return <AccountCheckinsComponent />;
      case 'Pagamentos':
      case 'payments':
        return <AccountPaymentComponent />;
      case 'billing':
        return <AccountBillingComponent />;
      case 'general':
        return <AccountsGeneralComponent />;
      case 'resources':
        return <AccountResourcesComponent />;
        // return <div>Resources</div>;
      default:
        document.title = `${window.account?.code}/configurações - Geral`
        return <AccountsGeneralComponent />;
    }
  }
  return(
    <AccountContainerContent>
      {renderContent()}
      <div className="col right"><button type="submit" disabled={loading} className="btn btn-primary">Atualizar</button></div>
    </AccountContainerContent>
  )
}

interface AccountContainerProps {
  account: any;
  scope: string;
  path: string;
  accountStripeInfo: any;
}

const AccountSettingsForm = (props: AccountContainerProps) => {
  const {loading, createOrEditAccount} = React.useContext(AccountSettingsContext);
  const methods = useForm(
    {
      resolver: zodResolver(AccountSettingsSchema),
      mode: "onChange",
      defaultValues: {
        ...props?.account,
        avatar: props?.account?.avatar || {},

      }
    }
  );

  return(
    <FormProvider {...methods}>
      <AccountContainerElement 
        method="POST" 
        onSubmit={methods.handleSubmit(createOrEditAccount)}
      >
          <AccountControllerComponent />
          <AccountContainerContentComponent />
      </AccountContainerElement>
    </FormProvider>
  )
}

export function AccountContainer(props: AccountContainerProps) : JSX.Element {
  const {accountStripeInfo} = props;
  const {requirements} = accountStripeInfo || {};
  return(
    <AccountSettingsProvider {...props}>
      {!!requirements?.currentlyDue.length && (<AccountStripePending account={props.account}/>)}
      <AccountSettingsForm {...props} /> 
    </AccountSettingsProvider>
  );
}