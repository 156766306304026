import * as React from 'react';
import AsyncSelect from 'react-select/async';
import {OnSelectProps, SelectValueProps, TargetProps} from '../../types';
import {
  UserSelectOption, 
  UserSelectOptionContent,
  UserSelectOptionTitle,
  UserSelectContainer,
} from './SelectCategory.style';
import { useAccountCategories, useAccountSubCategories } from '../../../services/account/useAccountCategories';

type CategorySelectProps = {
  label?: string;
  placeholder?: string;
  onSelect: OnSelectProps,
  defaultOptions: SelectValueProps[];
  value: SelectValueProps;
  errors?: any;
  isMulti?: boolean;
  emptyCallback?: () => void;
}

function convertDataToCategorySelect(category: any){
  if(category.code) {
  return {
      label: category.name, 
      value: category.code
    }
  } else {
    return category
  }
}

const LoadingMessage = (props: any) => {
  return(
    <UserSelectOption>
      <UserSelectOptionContent>
        <span>Carregando... </span>
      </UserSelectOptionContent>
    </UserSelectOption>
  )
}

const NoOptionsMessage = (props: any) => {
return (
  <UserSelectOption>
    <UserSelectOptionContent>
      <span>Nenhuma categoria encontrada!</span>
    </UserSelectOptionContent>
  </UserSelectOption>
  )
};

const loadOptions = (inputValue: any, callback: any) => {
  useAccountCategories()
  .then(({data}: any) => {
    const categories = data?.map( cat => (convertDataToCategorySelect(cat)) )
    callback(categories)
  })
  .catch(err => console.log('error ', err))
}

const OptionComponent = ({innerProps, innerRef, data}: any) => {
return(
  <UserSelectOption {...innerProps}>
    <UserSelectOptionContent>
      <UserSelectOptionTitle>{data.label}</UserSelectOptionTitle>
    </UserSelectOptionContent>
  </UserSelectOption>
)
};

export const SelectCategory = React.forwardRef(({
  onSelect, 
  defaultOptions, 
  value : valueProps, 
  errors, 
  isMulti, 
  emptyCallback, 
  placeholder,
  label
} : CategorySelectProps) : JSX.Element => {
  const [value, setValue] = React.useState(valueProps);
  const [options] = React.useState(defaultOptions?.map( option => convertDataToCategorySelect(option)))
  const onChange = (value: any) => {
    setValue(value);
    onSelect(value);
  }

  
  return(
    <UserSelectContainer error={!!errors?.message ? 'error' : ''}>
      {!!label && <label>{label}</label>}
      <AsyncSelect
        defaultOptions={options}
        placeholder={placeholder || 'Selecione/digite a categoria'}
        onChange={onChange}
        components={{ 
          Option: OptionComponent,
          LoadingMessage: LoadingMessage,
          NoOptionsMessage: NoOptionsMessage
        }}
        loadOptions={loadOptions}
        isSearchable
        isMulti={isMulti}
        value={value}
      />
    </UserSelectContainer>
  )
})

export const SelectSubCategory = React.forwardRef(({
  onSelect, 
  categoryCode, 
  defaultOptions: defaultOptionsProps, 
  selectValue, 
  errors, 
  isMulti, 
  label, 
  placeholder,
  ...otherProps
} : CategorySelectProps & {categoryCode: string}, ref) : JSX.Element => {
  const [defaultOptions, setDefaultOptions] = React.useState(defaultOptionsProps || []);
  const [value, setValue] = React.useState(selectValue);

  const onChange = (value) => {
    setValue(value);
    onSelect(value);
  }
  React.useEffect(() => {
    useAccountSubCategories(categoryCode)
      .then(({data}) => {
        if(data.length > 0) {
          const subcategories = data?.map(cat => convertDataToCategorySelect(cat))
          setDefaultOptions(subcategories)
        }
      })
      .catch(err => console.log('error ', err))
  }, [categoryCode]);

  return(
    <UserSelectContainer error={!!errors?.message ? 'error' : ''}>
      {!!label && <label>{label}</label>}
      <AsyncSelect
        defaultOptions={defaultOptions}
        options={defaultOptions}
        placeholder={placeholder || 'Selecione/digite a subcategoria'}
        onChange={onChange}
        components={{ 
          Option: OptionComponent,
          LoadingMessage: LoadingMessage,
          NoOptionsMessage: NoOptionsMessage
        }}
        isMulti={isMulti}
        value={value}
        {...otherProps}

      />
    </UserSelectContainer>
  )
})