import { useState, useEffect } from 'react';

export function useNavState<T>(key: string, initialValue?: T) : [T, (value: T) => void] {
  const [value, setValue] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get(key) || initialValue;
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (value) params.set(key, value);
    else params.delete(key);
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.pushState({}, '', newUrl);
    window.addEventListener('popstate', () => {
      const params = new URLSearchParams(window.location.search);
      console.log('params', params);
      setValue(params.get(key) || initialValue);
    });
    
    return () => {
      window.removeEventListener('popstate', () => {
        const params = new URLSearchParams(window.location.search);
        setValue(params.get(key) || initialValue);
      });
    }
  }, [value, key]);

  return [value, setValue];
}