import * as React from 'react';
import {RadioContainer} from './Radio.style';

type RadioProps = {
  id?: string;
  name: string;
  label: string;
  checked: boolean;
  onChange?: void;
  value: string;
}
const Radio = ({id, name, label, checked, onChange, value} : RadioProps) : JSX.Element => {
  const [checkValue, setCheckValue] = React.useState(false);

  React.useEffect(() => {
    if(checkValue !== checked) setCheckValue(checked)
  })

  const handleChange = (event) => {
    setCheckValue(true);
    onChange(event);
  }

  return (
      <label htmlFor={id}>
        <input id={id} name={name} className="with-gap" type="radio" checked={checkValue} onChange={handleChange} value={value} />
        <span>{label}</span>
      </label>
  );
}

export default Radio;

interface RadioGroupProps {
    label?: string;
    name?: string;
    options: Array<{ label: string; value: string }>;
    value?: string;
    withGap?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    radioClassNames?: string;
    helper?: string;
};

export const RadioGroup = ({label, name, options, value, withGap, onChange, disabled, radioClassNames, helper} : RadioGroupProps) : JSX.Element => {
  return(
    <RadioContainer>
      <label>{label}</label>
      <small>{helper}</small>
      <div>
      {options.map(op => (
        <Radio key={op.value} name={name} value={op.value} label={op.label} id={`${name}-${op.value}`} onChange={onChange} checked={value == op.value} />
      ))}
      </div>
    </RadioContainer>
  )
}
