import {clientAccount} from '../../core/stores';
import {MUTATION_SIGN_IN} from '../../core/graphql/types';

export function MutationSignIn(authForm, setSubmitting): Promise {
  return new Promise((resolve,reject) => {
    const {email, password} = authForm;
    setSubmitting(true);
    if(!email || !password){
      M.toast({html: 'Para entrar, você precisa preencher todos os campos', displayLength: 5000})
      reject(false);
      setSubmitting(false);
      return;
    }
    if(email && !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email))){
      M.toast({html: 'Para entrar, você precisa preencher um email válido', displayLength: 5000})
      reject(false);
      setSubmitting(false);
      return;
    }
    if(password && password.length < 6){
      M.toast({html: 'Para entrar, sua senha precisa ter no mínimo 6 caracteres', displayLength: 5000})
      reject(false);
      setSubmitting(false);
      return;
    }

    clientAccount.mutate({
      mutation: MUTATION_SIGN_IN,
      variables: authForm,
    })
    .then(response => {
      const {data} = response;
      const {login} = data || {};
      M.toast({html: 'Login realizado com sucesso!'});
      setSubmitting(false);
      resolve({status: 'success', user: login})
    })
    .catch( err => {
      console.log('ERROR ==> ', err)
      setSubmitting(false);
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro na sua solicitação.' })
      M.toast({ html: errorMessage })
      reject({status: 'error', message: errorMessage})
    });
  });
}