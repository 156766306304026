import React from 'react';
import { AccountSettingsContext } from '../../context/AccountSettingsContext';
import { Checkbox, Row } from 'react-materialize';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

const FEATURES_LABELS = {
  dashboard: 'Dashboard',
  reviews: 'Avaliações',
  users: 'Usuários',
  treinos: 'Rotinas de Treino',
  social: 'Social',
  communication: 'Comunicação',
  checkins: 'Check-ins',
  points: 'Pontos',
  filials: 'Filiais',
  'info-products': 'Info Produtos',
  banners: 'Banners',
  app: 'App',
  mass_actions: 'Ações em Massa',
}

export const AccountResourcesComponent = () => {
  const {account, features} = React.useContext(AccountSettingsContext);
  const {control, formState: {errors}, getValues} = useFormContext();
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'features',
  })
  console.log('errors', errors);
  return(
    <Row>
      <Controller
        control={control}
        name="features"
        render={({field, field: {value}}) => {
          return (
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
              {features.map((feature) => (
                <Checkbox key={feature}
                  value={feature}
                  label={FEATURES_LABELS[feature]}
                  checked={account?.features?.includes(feature)}
                  onChange={(val) => {
                    if(val.target.checked){
                      append(feature)
                    } else {
                      remove(feature)
                    }
                  }}
                />
              ))}
            </div>
          )
        }}
      />

    </Row>
  )
}