import styled from 'styled-components';
import {COLOR} from '../../constants';

export const UserSelectOption = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
  &:hover{
    background-color: rgba(0,0,0,0.05);
  }
`;

export const UserSelectContainer = styled.div`
  div[class*="-control"]{
    ${({error}) => error ? 'border-color: red !important' : ''}
  }
`;

export const UserSelectOptionThumb = styled.div`
`;

export const UserSelectOptionContent = styled.div`
  align-items: center;
  flex-direction: column;
`;

export const UserSelectOptionTitle = styled.div`
  padding-left: 10px;
  font-size: 1.11em;
`;