import * as React from 'react';

interface CardProps {
  children: JSX.Element | JSX.Element[]
  title?: string | JSX.Element;
}

export function Card({children, title} : CardProps) : JSX.Element {
  return(
    <div className="row">
      <div className="col s12">
        {!!title && (
          <div className="row">
            <div className="col s12">
              <div className="flow-text">{title}</div>
            </div>
          </div>
        )}
        <div className="card">
          <div className="card-content">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}