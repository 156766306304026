import {client, clientAlderaan} from '../../core/stores';
import {ExerciseProps, InputEvent} from '../../application/types';
import {MUTATION_ALDERAAN_ACCOUNT, MUTATION_ACCOUNT} from '../../core/graphql/types';
import { de } from 'date-fns/locale';

export function MutationAccount(account, scope, setSubmitting, setProgress = null): Promise {
  return new Promise((resolve,reject) => {
    const {name} = account;
    if(!name){
      M.toast({html: 'Para salvar um exercício, você precisa preencher o nome', displayLength: 5000})
      reject(false);
      return false;
    }
    const mutationClient = scope === 'alderaan' ? clientAlderaan : client('account');
    const query = scope === 'alderaan' ? MUTATION_ALDERAAN_ACCOUNT : MUTATION_ACCOUNT;
    if(scope !== 'alderaan'){
      delete account.id;
      delete account.accountType;
      delete account.code;
      delete account.status;
    }
    if(scope != 'alderaan') account.externalStoreFavorite = 'Stripe'
    
    if(account.accountBilling){
      delete account.accountBilling.id;
      delete account.accountBilling.accountId;
      delete account.accountBilling.createdAt;
      delete account.accountBilling.updatedAt;
      delete account.accountBilling.__typename;
    }
    if(!(account.avatar instanceof File)) delete account.avatar;

    Object.keys(account).forEach( k => {
      if(typeof account[k] === 'number'){
       return account[k] = '' + account[k]; 
      }
    })

    mutationClient.mutate({
      mutation: query, 
      variables: account,
      context: {
        fetchOptions: {
          useUpload: true,
          onProgress: (ev: ProgressEvent) => {
            if(setProgress) setProgress(ev.loaded / ev.total)
          },
          onAbortPossible: (abortHandler: any) => {
          }
        }
      },
    })
    .then(response => {
      const {data} = response;
      const {updateAccount, createOrUpdateAccount} = data || {};
      const {account} = updateAccount || createOrUpdateAccount;
      M.toast({html: 'Conta atualizada com sucesso!'});
      setSubmitting(false);
      resolve({status: 'success', account})
    })
    .catch( err => {
      console.log('ERROR ==> ', err)
      setSubmitting(false);
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro na sua solicitação.' })
      M.toast({ html: errorMessage })
      reject({status: 'error', message: errorMessage})
    });
  })
}