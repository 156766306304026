import * as React from 'react';
import Select from '../../elements/Select';

import {Card} from '../../elements';


import {PlanContext} from '../../context/PlanContext';

export function PlanFormProgramsComponent() : JSX.Element {

  const {
    planPrograms,
    programsOptions,
    handleSelectPlanPrograms,
  } = React.useContext(PlanContext);

  return(
      <div className="row">
        <div className="col s12">
          <Card title={'Quais programas de treino esse plano dá acesso'}>
          <div className="row">
            <div className="col s12">
              <label htmlFor="">Programas de treino</label>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
                <Select
                  options={programsOptions}
                  placeholder={'Digite/Selecione um programa de treino'}
                  onChange={handleSelectPlanPrograms}
                  selectType="select2"
                  value={planPrograms}
                />
              </div>
          </div>
          </Card>
        </div>
      </div>
  )
}