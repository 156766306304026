import * as React from 'react';
import {TextInput} from 'react-materialize';

import {RadioGroupContainer} from './PlanFormComponent.style';
import {Card, RadioGroup} from '../../elements';
import Select from '../../elements/Select';

import {PlanContext} from '../../context/PlanContext';


const BILLING_TRIGGER = [
  {
    label: 'Ao iniciar o plano (pré-pago)',
    value: 'inicio_do_periodo'
  },
  {
    label: 'Após o período (pós-pago)',
    value: 'fim_do_periodo'
  },
  {
    label: 'Dia fixo do mês',
    value: 'dia_do_mes'
  },
];

const TRIGGER_DAY_LABEL = {
  inicio_do_periodo: 'Dias após iniciar o plano',
  fim_do_periodo: 'Núm. de dias após vender o plano',
  dia_do_mes: 'Dia do mês',
}

export function PlanFormPaymentComponent() : JSX.Element {

  const {
    errors,
    plan,
    billingTrigger,
    handleSelect,
    handleChange,
  } = React.useContext(PlanContext);

  return(
      <div className="row">
        <div className="col s12">
          <Card title='Informações financeiras'>
            <div className="row">
              <div className={`col s12 ${plan.recurrence !== '' ? 'm6' : ''}`}>
                  {/* <div className="col s12">
                    <small>Seu plano é único ou se renova automaticamente? Escolha a recorrência de seu plano</small>
                  </div> */}
                <RadioGroupContainer>
                  <RadioGroup
                      id='recurrence'
                      name="recurrence"
                      label='Recorrência do plano'
                      onChange={handleChange}
                      options={[
                        {
                          label: 'Nenhuma',
                          value: ''
                        },
                        {
                          label: 'Mês',
                          value: 'month'
                        },
                        {
                          label: 'Dia',
                          value: 'day'
                        },
                        {
                          label: 'Semana',
                          value: 'week'
                        },
                      ]}
                      value={plan.recurrence}
                      withGap
                    />
                  </RadioGroupContainer>
              </div>
            </div>
            
            <div className="row">
            {(plan.recurrence !== '' || plan.planType === 'checkin') &&(
              <TextInput 
                s={3}
                m={2}
                id="recurrenceValue"
                name="recurrenceValue"
                label="Quantidade" 
                type='tel'
                value={`${plan.recurrenceValue || ''}`} 
                error={errors.recurrenceValue} 
                inputClassName={errors?.recurrenceValue ? 'invalid' : ' '} 
                onChange={handleChange}
              />
              )}
              <TextInput 
                s={3}
                id="value"
                name="value"
                label="Valor (R$)" 
                type='tel'
                value={plan.value || ''} 
                error={errors.value} 
                inputClassName={errors?.value ? 'invalid' : ' '} 
                onChange={handleChange}
              />
              {plan?.value > 0 && <TextInput 
                s={4}
                m={2}
                id="installments"
                name="installments"
                label="Máx. parcelas" 
                type='tel'
                value={`${plan.installments || ''}`} 
                error={errors.installments} 
                inputClassName={errors?.installments ? 'invalid' : ' '} 
                onChange={handleChange}
              />}
            </div>

            <div className="row">
              <div className="col s12">
                <div className="flow-text"><small>Recorrência da Cobrança</small></div>
              </div>
            </div>
            <div className="row">
              <div className="col s12 m4" style={{marginBottom: 20}}>
                <Select
                  isMulti={false}
                  options={BILLING_TRIGGER}
                  placeholder={'Ciclo de cobranças'}
                  onChange={handleSelect}
                  selectType="select2"
                  value={billingTrigger}
                />
              </div>
              <TextInput
                s={6}
                m={4}
                id="billingTriggerDay"
                name="billingTriggerDay"
                label={TRIGGER_DAY_LABEL[plan.billingTriggerType]}
                type='tel'
                value={`${plan.billingTriggerDay || ''}`} 
                error={errors.billingTriggerDay} 
                inputClassName={errors?.billingTriggerDay ? 'invalid' : ' '} 
                onChange={handleChange}
              />
              <TextInput
                s={6}
                m={4}
                id="cycles"
                name="cycles"
                label={'Limite de renovações'}
                type='tel'
                value={`${plan.cycles || ''}`} 
                error={errors.cycles} 
                inputClassName={errors?.cycles ? 'invalid' : ' '} 
                onChange={handleChange}
              />
            </div>
          </Card>
        </div>
      </div>
  )
}