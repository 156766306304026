import * as React from 'react';
import AsyncSelect from 'react-select/async';

import {OnSelectProps, SelectValueProps} from '../../types';
import {
  UserSelectOption, 
  UserSelectOptionThumb, 
  UserSelectOptionContent,
  UserSelectOptionTitle,
  UserSelectContainer,
} from '../users/UserSelect.style';

interface ProgramProps {
  id: string | number;
  name: string;
  filial: {name: string};
}

type UserSelectProps = {
  onSelect: OnSelectProps,
  defaultOptions: SelectValueProps[];
  selectValue: SelectValueProps;
}

export const ProgramSelect = ({onSelect, defaultOptions, selectValue} : UserSelectProps) : JSX.Element => {
  const [value, setValue] = React.useState(selectValue);
  const [inputSearch, setInputSearch] = React.useState('');
  const [options, setOptions] = React.useState(defaultOptions.map( option => convertDataToUserSelect(option)))
  const onChange = (value) => {
    setValue(value);
    onSelect(value);
  }

  function convertDataToUserSelect(program){
    if(!program.value){
      return {
        value: program.id,
        label: `${program.name} - ${program.filial.name}`,
      }
    } else {
      return program
    }
  }

  const OptionComponent = ({innerProps, innerRef, data}) => {
    return(
      <UserSelectOption {...innerProps}>
        {!!data.image && 
          <UserSelectOptionThumb>
            <img src={data.image?.uri} width="75" height="75" />
          </UserSelectOptionThumb>
        }
        <UserSelectOptionContent>
          <UserSelectOptionTitle>{data.label}</UserSelectOptionTitle>
        </UserSelectOptionContent>
      </UserSelectOption>
    )
  };

  const LoadingMessage = (props) => {
    if(inputSearch.length < 3){
      return(
        <UserSelectOption>
          <UserSelectOptionContent>
            <span>Digite 3 carecteres ou mais para iniciar a busca.</span>
          </UserSelectOptionContent>
        </UserSelectOption>
      )
    } else {
      return(
        <UserSelectOption>
          <UserSelectOptionContent>
            <span>Carregando... </span>
          </UserSelectOptionContent>
        </UserSelectOption>
      )
    }
  }

  const NoOptionsMessage = (props) => (
    <UserSelectOption>
      <UserSelectOptionContent>
        <span>Nenhum usuário encontrado!</span>
      </UserSelectOptionContent>
    </UserSelectOption>
  );
  return(
    <UserSelectContainer>
      <AsyncSelect
        defaultOptions={options}
        placeholder={'Todos os programas de treino'}
        onChange={onChange}
        components={{ 
          Option: OptionComponent,
          LoadingMessage: LoadingMessage,
          NoOptionsMessage: NoOptionsMessage
        }}
        isSearchable
        isMulti
        value={value}
      />
    </UserSelectContainer>
  )
}