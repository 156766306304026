import styled from 'styled-components';
import {COLOR} from '../constants';

export const LoadingContainer = styled.div`
  align-items: center;
  background-color: rgba(0,0,0,0.4);
  bottom: 0;
  color: #DDDDDD;
  display: ${({loading}) => loading ? 'flex' : 'none'};
  font-size: 2vw;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
`;

export const LoadingProgressContainer = styled.div`
  background-color: #33333355;
  border: 2px solid #BBBBBBB;
  border-radius: 8px;
  height: 30px;
  overflow: hidden;
  position: relative;
  width: 40%;
`;

export const LoadingProgress = styled.div`
  background-color: ${COLOR.PRIMARY};
  border-radius: 0 8px 8px 0;
  box-shadow:rgba(0,0,0,.2) 2px 0 10px;
  bottom: 0;
  height: 100%;
  position: absolute;
  left: 0;
  right: ${({progress=0}) => (1-progress)*100}%;
  top: 0;
`;


export const LoadingContentContainer = styled.div`
  align-items: center;
  background-color: rgba(0,0,0,0.2);
  backdrop-filter: blur(3px);
  border-radius: 3px;
  color: #DDDDDD;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  img{
    max-width: 50px;
  }
`;