import React from "react";
import { Row } from "react-materialize";
import { Table, TableBody, TableData, TableHeader, TableHeaderContainer, TableRow } from "../../../components";
import { ProfilePlanCardStatus } from "../alunos/components/ProfilePlan.style";
import { ButtonStyled } from "../../../elements/forms/InputContainer";
import { ActionButton, ActionsContainer } from "../alunos/index.style";
import { useProfile } from "../alunos/profile.context";
import { format } from "date-fns";

const TRANSLATE_STATUS : Record<string, string> = {
  draft: "Rascunho",
  active: "Ativo",
  inactive: "Inativo",
  archived: "Arquivado",
  finished: "Finalizado",
}

const TRANSLATE_TRIGGER : Record<string, string> = {
  checkin: "Checkin",
  status: "Mudança de Status",
  gympass: "Gympass",
  totalpass: "Totalpass",
  plan: "Plano",
  birthday: "Aniversário",
  subscription: "Assinatura",
  purchase: "Compra",
  lead: "Lead",
  form: "Formulário",
  event: "Evento",
  end_plan: "Término do Plano",
  single_dispatch: "Uma vez",
  start_plan: "Início do Plano",
  new_register: "Novo Cadastro",
  moviment_points: "Movimentação de Pontos",
}

const TRANSLATE_SEGMENTATION : Record<string, string> = {
  status: "Status",
  plan: "Plano",
  plan_type: "Tipo de Plano",
  gympass: "Gympass",
  totalpass: "Totalpass",
  all: "Todos",
  active: "Ativos",
  inactive: "Inativos",
}

const TRANSLATE_FREQUENCY : Record<string, string> = {
  daily: "Diário",
  weekly: "Semanal",
  monthly: "Mensal",
  hourly: "Hora",
  minute: "Minuto",
  one_time: "Uma vez",
  single_dispatch: "Uma vez",
}


export default function Index({ campaigns }: { campaigns: any }) {
  return (
    <div>
      <Row>
        <h1>Mensagens Automáticas</h1>
        <a className="btn btn-primary" href={`/${window.account}/admin/campaigns/new`}>Nova Mensagem</a>
      </Row>
        <div className="col s12">
          <div className="card">
            <Table>
              <TableHeaderContainer>
                <TableRow>
                  <TableHeader className="center">Criado em</TableHeader>
                  <TableHeader>Título</TableHeader>
                  <TableHeader>Status</TableHeader>
                  <TableHeader>Disparo</TableHeader>
                  <TableHeader>Segmentação</TableHeader>
                  <TableHeader>Frequência</TableHeader>
                  <TableHeader>Hora do Envio</TableHeader>
                  <TableHeader>Ações</TableHeader>
                </TableRow>
              </TableHeaderContainer>
              <TableBody>
                {campaigns.map((campaign: any) => (
                  <TableRow key={campaign.id}>
                    <TableData basis={45} className="center" style={{padding: 20}}>{format(new Date(campaign.createdAt), 'dd/MM/yyyy')}</TableData>
                    <TableData mobileTitle basis={100} style={{padding: 20}}><a href={`/${window.account}/admin/campaigns/${campaign.id}/edit`}>{campaign.title}</a></TableData>
                    <TableData basis={45}>{!!campaign.status && <ProfilePlanCardStatus status={campaign.status}>{TRANSLATE_STATUS[campaign.status]}</ProfilePlanCardStatus>}</TableData>
                    <TableData before="Disparo: " basis={45} className="center">{TRANSLATE_TRIGGER[campaign.activity]}</TableData>
                    <TableData before="Segmentação: " basis={45} className="center">{TRANSLATE_SEGMENTATION[campaign.templateType]}</TableData>
                    <TableData before="Frequência: " basis={45} className="center">{TRANSLATE_FREQUENCY[campaign.frequency]}</TableData>
                    <TableData basis={45}>{campaign.time}</TableData>
                    <TableData basis={45}>
                      <ActionsContainer>
                        <ActionButton
                          href={`/${window.account}/admin/campaigns/${campaign.id}/edit`}
                          className="btn btn-small"
                        >
                         <i className="fa fa-pen" />
                        </ActionButton>
                        <ActionButton
                          data-confirm="Tem certeza que deseja excluir esta mensagem automática?" 
                          data-method="delete" 
                          danger
                          className="btn btn-small btn-danger"
                          href={`campaigns/${campaign.id}`}
                        >
                          <i className="fa fa-trash red-text" />
                        </ActionButton>
                      </ActionsContainer>
                    </TableData>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
    </div>
  )
}