import * as React from 'react';
import { Card, SelectContainer } from '../../../elements';
import AsyncSelect from 'react-select/async';
import { useForm, Controller, Form } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { checkinSchema } from './checkin.schema';
import { SelectUser } from '../../../components/users/SelectUser';
import { TextInput, Checkbox } from 'react-materialize';
import { QueryPrograms } from '../../../../services/program/QueryProgram';
import { QueryProgramsClass } from '../../../../services/programClass/QueryProgramClass';
import { useCreateCheckin } from './checkin.service';
import { AsyncSelectStyled, InputStyled } from '../../../elements/forms/InputContainer';

interface CheckinFormProps {
  userId?: string | number;
  users?: any;
  filials: any;
  action?: any;
  hideHeader?: boolean;
  callBack?: () => void;
  checkin: any;
}

export default function CheckinForm({users, filials, userId, hideHeader, callBack, checkin} : CheckinFormProps) {
  const [programs, setPrograms] = React.useState([]);
  const [programClasses, setProgramClasses] = React.useState([]);
  const [isCreatingUser, setIsCreatingUser] = React.useState(false);
  const {isLoading, createOrUpdateCheckin, data, error} = useCreateCheckin();
  const genders = [{
    value: 'Masculino',
    label: 'Masculino',
  },
  {
    value: 'Feminino',
    label: 'Feminino',
  
  },
  {
    value: '-',
    label: '-',
  }]

  const {
    register, 
    control, 
    watch,
    formState: { errors },
    setValue,
    reset
  } = useForm({
    resolver: zodResolver(checkinSchema),
    mode: 'onBlur',
  });

  React.useEffect(() => {
    if(userId){
      setValue('userId', userId);
    }
  }, [userId])

  React.useEffect(() => {
    if(checkin && checkin.id){
      const checkinData = {
        id: checkin?.id,
        userId: userId,
        name: checkin?.name,
        lastName: checkin?.lastName,
        email: checkin?.email,
        gender: checkin?.gender,
        token: checkin?.token,
        forceCheckin: checkin?.forceCheckin,
        programClass: {
          value: checkin?.aula?.id,
          label: checkin?.aula?.startTime,
        },
        filial: {
          value: checkin?.filial?.id,
          label: checkin?.filial?.name,
        },
        program: {
          value: checkin?.program?.id, 
          label: checkin?.program?.name,
        }
      }
      reset(checkinData);
    }
  }, [checkin])

  const userSelected = watch('userId');
  const filialSelected = watch('filial');
  const programSelected = watch('program');
  const programClassSelected = watch('programClass');
  const genderSelected = watch('gender');

  const isShowingMore = !!userSelected || !!genderSelected?.value;

  React.useEffect(() => {
    if(filialSelected){
      loadProgramsOptions();
    }
  }, [filialSelected])

  React.useEffect(() => {
    if(programSelected){
      loadProgramClassOptions();
    }
  }, [programSelected])

  React.useEffect(() => {
    if(data?.status === 'success'){
      M.toast({html: "Checkin realizado com sucesso! Verfique o Status do Checkin na lista de Checkins"})
      if(callBack){
        callBack();
      } else {
        M.toast({html: "Abrindo lista de Checkins..."})
        window.location.href = `/${window.account}/admin/checkins/`;
      }
    }
  },[data]);

  const onSubmit = ({data}) => {
    createOrUpdateCheckin(data);
  }
  
  const loadProgramsOptions = () => {
    if(!!filialSelected){
      QueryPrograms(filialSelected.value)
        .then(({programs}) => {
          setPrograms(programs.map(program => ({
            value: program.id,
            label: program.name,
          })));
        })
        .catch(err => console.log('error ', err))
    }
  }

  const loadProgramClassOptions = () => {
    if(!!programSelected){
      QueryProgramsClass(programSelected.value)
        .then(({programClasses}) => {
          setProgramClasses(programClasses.map(programClass => ({
            value: programClass.id,
            label: programClass.startTime,
          })));
          })
        .catch(err => console.log('error ', err))
    }
  }

  const usersOptions = users?.map((user: any) => ({
    avatar: user.image,
    value: user.id,
    shortName: `${user.name} ${user.lastName}`,
    email: user.email,
    id: user.id,
    label: `${user.name} ${user.lastName} - ${user.email}`,
  }));

  return (
    <Form
      control={control}
      onSubmit={onSubmit}
    >
      {!!hideHeader && <>
      <h1>Novo Checkin</h1>
      <div className="row">
        <div className="col s12">
          <div className="flow-text">Identifique o Usuário</div>
        </div>
      </div>
      </>}
      {!userId &&<Card>
        <div className="row">
          <div className="col s12">
          <SelectContainer className="row">
            {!isCreatingUser && !!users && <Controller 
              control={control}
              name={'userId'}
              render={({field: {ref, onChange, value}}) => (
                  <SelectUser
                    label="Usuário"
                    defaultOptions={usersOptions}
                    errors={errors?.userId}
                    value={value}
                    onSelect={({value}) => {
                      onChange(value);
                    }}
                    emptyCallback={() => setIsCreatingUser(true)}
                  />
                )}
              />}
            </SelectContainer>
            
            {isCreatingUser && (
              <div className="row">
                <TextInput 
                  s={12}
                  {...register('email')}
                  label="Email"
                  type="email"
                  inputClassName={
                    !!errors?.email ? 'invalid' : ' '
                  }
                  />
                <TextInput 
                  s={6}
                  {...register('name')}
                  label="Nome"
                  type="text"
                  inputClassName={
                    !!errors?.name ? 'invalid' : ' '
                  }
                />
                <TextInput 
                  s={6}
                  {...register('lastName')}
                  label="Sobrenome"
                  type="text"
                  inputClassName={
                    !!errors?.lastName ? 'invalid' : ' '
                  }/>

                  <div className="col s6">
                    <Controller 
                        control={control}
                        name="gender"
                        render={({field: {ref, onChange, value}}) => (
                          <AsyncSelect
                            ref={ref}
                            isSearchable={false}
                            defaultOptions={genders}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                  </div>
              </div>)}
          </div>
        </div>
      </Card>}
      {isShowingMore && (
        <>
        <div className="row">
          <div className="col s12">
            <div className="flow-text">Escolha uma Aula</div>
          </div>
        </div>
          <Card>
            <div className="row">
              <div className="col s6">
                <Controller 
                  control={control}
                  name="filial"
                  render={({field: {ref, onChange, value}}) => {
                    return(
                    <AsyncSelectStyled
                      cacheOptions
                      isSearchable={false}
                      defaultOptions={filials.map(filial => (
                        {
                          value: filial.id,
                          label: filial.name,
                        }
                      ))}
                      onChange={(value) => {
                        onChange(value)
                      }}
                      value={value}
                      placeholder="Selecione uma filial"
                      label="Filial"
                    />
                  )}}
                />
              </div>
              <div className="col s6">
              <Controller 
                  control={control}
                  name="program"
                  render={({field: {ref, onChange, value}}) => (
                    <AsyncSelectStyled
                      ref={ref}
                      isSearchable={false}
                      defaultOptions={programs}
                      placeholder={filialSelected ? 'Selecione o programa' : " - "}
                      onChange={onChange}
                      value={value}
                      disabled={!filialSelected}
                      label="Programa"
                    />
                  )}
                />
              </div>
              </div>
              <div className="row">
              <div className="col s6">
              <Controller 
                  control={control}
                  name="programClass"
                  render={({field: {ref, onChange, value}}) => (
                    <AsyncSelectStyled
                      ref={ref}
                      isSearchable={false}
                      defaultOptions={programClasses}
                      placeholder={programSelected ? 'Selecione um horário' : " - "}
                      label="Horário"
                      onChange={onChange}
                      value={value}
                      disabled={!programSelected}
                    />
                  )}
                />
              </div>
              <InputStyled 
                  s={6}
                  {...register('token')}
                  label="Token/Gympass Id"
                  placeholder="Digite o Token/Gympass Id"
                  type="text"
                  inputClassName={
                    !!errors?.token ? 'invalid' : ' '
                  }
                />
            </div>
            <div className="row">
              <div className="col s12">
                <Controller 
                  name='forceCheckin'
                  control={control}
                  render={({field: {ref, onChange, value}}) => (
                    <Checkbox 
                      onChange={onChange}
                      value={value}
                      label="Forçar Checkin"
                    />
                  )}
                />
              </div>
            </div>
          </Card>
        </>
        )}
        <input type="submit" disabled={!programClassSelected?.value || isLoading} className="btn btn-primary" value="Criar Checkin" />
    </Form>
  );
}