import styled from 'styled-components';

export const MainChartComponent = styled.div`
  display: flex;
  justify-content: stretch;
  position: relative;
  .col{
    display: flex;
    justify-content: stretch;
    .card{
      flex: 1;
      max-width: 100%;
    }
  }
  @media screen and (max-width: 768px){
    flex-direction: column;
  }
`;