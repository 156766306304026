import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { GET_MESSAGE_BY_ID } from '../../querys';
import { useProfile } from '../profile.context';
import { Row } from 'react-materialize';
import { MessageViewBody, MessageViewHeader, MessageViewReply, MessageViewSubject } from './Inbox.style';
import { Controller, Form, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { ButtonStyled, InputStyled } from '../../../../elements/forms/InputContainer';
import { SEND_REPLY_MESSAGE_MUTATION } from '../../mutations';
import { LoadingContent } from '../../../../components';

interface MessageViewProps {
  message: any;
  onClose?: () => void;
}

const schema = z.object({
  body: z.string().min(3, {message: 'Você precisa escrever uma mensagem para enviar'}),
});

const ReplyComponent = ({message}: MessageViewProps) => {
  return (
    <div>
      <MessageViewHeader>
        <i className="fa-solid fa-reply" ></i>
        <MessageViewSubject>{message.subject}</MessageViewSubject>
      </MessageViewHeader>
      <MessageViewBody>
        <p>{message.body}</p>
      </MessageViewBody>
    </div>
  )
}

export const MessageView = ({message, onClose}: MessageViewProps) => {
  const {aluno} = useProfile();

  const {
    control,
    handleSubmit,
    formState: {errors}
  } = useForm({
    resolver: zodResolver(schema)
  });

  const {data, loading, error} = useQuery(GET_MESSAGE_BY_ID, {
    variables: {
      messageId: message.id,
      userId: aluno.id
    }
  });

  const [sendMessage, {data: dataSend, loading: loadingSend}] = useMutation(SEND_REPLY_MESSAGE_MUTATION);

  const onSubmit = (data: any) => {
    sendMessage({
      variables: {
        messageId: message.id,
        body: data.body
      }
    });
  }

  useEffect(() => {
    if (dataSend) {
      M.toast({html: 'Mensagem enviada com sucesso'});
      onClose();
    }
  }, [dataSend]);

  useEffect(() => {
    if (error) {
      M.toast({html: 'Erro ao enviar mensagem'});
    }
  }, [error]);

  if (loading || loadingSend) return <LoadingContent loading={loading || loadingSend} />;

  return (
    <div>
      <MessageViewHeader>
        <i className="fa fa-arrow-left" onClick={onClose}></i>
        <MessageViewSubject>{message.subject}</MessageViewSubject>
      </MessageViewHeader>
      <MessageViewBody>
        <p>{message.body}</p>
      </MessageViewBody>
      <MessageViewReply>
        {data?.getMessageById?.replies.map((reply: any) => (
          <ReplyComponent key={reply.id} message={reply} />
        ))}
        <Form control={control} onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Controller
              name="body"
            control={control}
            render={({field}) => (
              <InputStyled 
                {...field}
                placeholder="Digite sua mensagem"
                label="Mensagem"
                s={12}
                className={errors.body ? 'invalid' : ''}
                error={errors.body?.message}
                style={{minHeight: 100, verticalAlign: 'top'}}
              />
              )}
            />
          </Row>
          <Row>
            <ButtonStyled type="submit">Enviar</ButtonStyled>
          </Row>
        </Form>
      </MessageViewReply>
    </div>
  )
}