import * as React from 'react';
import {PlanFormComponent} from '../../../components/plans/PlanFormComponent';
import {PlanContextProvider} from '../../../context/PlanContext';
import Breadcrumb from '../../../components/Breadcrumb';

function PlanEdit({plan, action, programs, externalStoreEnabled, externalStoreOptions}) : JSX.Element {
  document.title = `${window.account}/planos/${plan.code}`;

  const BreadcumbsData = [
    {
      url: null,
      icon: 'cog',
      label: 'Configurações',
    },
    {
      url: `/${window.account}/admin/plans/`,
      label: 'Planos',
    },
    {
      label: plan.name,
      url: null
    }
  ]

  return(
    <PlanContextProvider plan={plan} programs={programs} externalStoreEnabled={externalStoreEnabled} externalStoreOptions={externalStoreOptions}>
      <div className="row">
        <div className="col s12">
          <Breadcrumb data={BreadcumbsData} />
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <h1>Editar plano</h1>
        </div>
      </div>
      <PlanFormComponent />
    </PlanContextProvider>
  )
}

export default PlanEdit;