import {validations} from '../../application/messages';
import {client} from '../../core/stores';
import {ExerciseProps, InputEvent} from '../../application/types';
import {MUTATION_EXERCISE} from '../../core/graphql/types';

export function MutationExercise(exercise: ExerciseProps, setSubmitting: InputEvent , setProgress: InputEvent) : Promise{
  return new Promise((resolve, reject) => {
    const {id, name, description, image, video, recordEnabled, exerciseType} = exercise;
    
    if(!name){
      M.toast({html: 'Para salvar um exercício, você precisa preencher o nome', displayLength: 5000})
      reject(false);
      return false;
    }

    if(!description){
      M.toast({html: 'Para salvar um exercício, você precisa preencher uma descrição/instruções', displayLength: 5000})
      reject(false);
      return false;
    }

    client('account').mutate({
      mutation: MUTATION_EXERCISE, 
      variables: {id, name, description, image: image instanceof File ? image : undefined, video: video instanceof File ? video : undefined, recordEnabled, exerciseType}, 
      context: {
        fetchOptions: {
          useUpload: true,
          onProgress: (ev: ProgressEvent) => {
            if(setProgress) setProgress(ev.loaded / ev.total)
          },
          onAbortPossible: (abortHandler: any) => {
          }
        }
      }
    })
    .then(response => {
      const {data} = response;
      const {setExercise: {exercise}} = data || {};
      console.log('MutationExercise ', response);
      M.toast({html: 'Seu exercício foi salvo com sucesso!'});
      setSubmitting(false);
      resolve({status: 'success', exercise})
    })
    .catch(err => {
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro na sua solicitação.' })
      M.toast({ html: errorMessage })
      setSubmitting(false);
      reject({status: 'error', message: errorMessage})
    });
  })
}