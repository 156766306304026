import * as React from 'react';
import {Switch, Textarea, TextInput} from 'react-materialize';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {UnitComponent} from './UnitComponents';
import {CourseModuleContext} from '../../context/CourseModuleContext';

const UnitItem = SortableElement(({unit, unitIndex, errors}) => {
 return <UnitComponent unit={unit} index={unitIndex} errors={errors} />
});

const UnitList = SortableContainer(({unitList, errors}) => (
  <ul>
    {unitList.map((unit, index) => <UnitItem key={`unit-${unit.id || unit.tempId}-${unit.index}`} unitIndex={index} index={index} unit={unit} errors={errors[index]} />)}
  </ul>
));

export const UnitListComponent = () : JSX.Element => {
  const {courseModule, units, onSortEnd, errors} = React.useContext(CourseModuleContext);
  return(
    <UnitList 
      unitList={units.filter((unit) => !unit?._destroy)}
      errors={courseModule?.errors?.units || []}
      onSortEnd={onSortEnd}
      pressDelay={200}
    />
  )
}