export const messages = {
  server: {
    loading: "Carregando...",
    error_send_data: "Houve um erro ao enviar. Por favor, verifique os erros",
  },
  units: {
    sending_unit: "Enviando as aulas"
  },
  course: {
    updating_course: "Atualizando o info produto...",
    course_updated: "Seu info produto foi atualizado com sucesso!",
  },
  course_modules:{
    updating_course_module: "Atualizando o módulo do info produto...",
    course_module_updated: "Atualizado com sucesso!",
  },
  form: {
    error_on_form: "Houve um erro ao enviar. Por favor, verifique os erros",
    select: {
      placeholder: 'Selecione uma opção',
    }
  }
}