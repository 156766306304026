import * as React from 'react';

import {MutationAccountPlanRelationship} from '../../../services/alderaan/MutationAccountPlanRelationship';

interface AccountPlanType {
  id: string|number;
  code: string;
  name: string;
  status: string;
  maxInstallments: number;
  recurrence: string;
  recurrenceQuantity: number;
  value: number;
  planType: string;
  planTypeValue: number;
  accountType: string;
}

interface AccountPlanRelationshipType {
  id: string|number;
  accountPlanId: string|number;
  accountId?: string|number;
  paymentMethod: string;
  discountValue?: number;
  discountType?: string;
  installments?: number;
  startPlan?: string;
  store?: string;
}

interface AccountPlanRelationshipPreviewType {
  planValue: number;
  discountValue: number;
  recurrence: string;
  planType: string;
  totalValue: number;
}

interface AccountPlanRelationshipContextProps {
  plan: AccountPlanRelationshipType;
  planPreview?: AccountPlanRelationshipPreviewType;
  discountEnabled?: boolean;
  setDiscountEnabled?: (event) => void;
  scope?: 'alderaan' | 'admin';
  loading: boolean;
  errors?: any;
  updateAccountPlanFormEvent: (event) => void;
  createOrEditAccountPlanRelationship: (event) => void;
  planSelected: AccountPlanType;
}

export const AccountPlanRelationshipContext = React.createContext({} as AccountPlanRelationshipContextProps);

interface AccountPlanProviderProps {
  children: React.ReactNode;
  plan?: AccountPlanRelationshipType;
  plans?: AccountPlanType[];
  planPathState: 'new' | 'edit';
  postUrl?: string;
  scope: 'alderaan' | 'admin';
  usersCount: number;
  filialsCount: number;
  planSelected: AccountPlanType;
}

export function AccountPlanProvider({ children, plan: planProps, plans, planPathState, scope, usersCount, filialsCount, account, stores, paymentMethods} : AccountPlanProviderProps ){
  const [plan, setPlan] = React.useState<AccountPlanRelationshipType>(planProps);
  const [planSelected, setPlanSelected] = React.useState<AccountPlanType>({} as AccountPlanType);
  const [planPreview, setPlanPreview] = React.useState({});
  const [discountEnabled, setDiscountEnabled] = React.useState<boolean>(planProps?.discountValue > 0 ? true : false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    if(plan?.accountPlanId){
      const filteredPlans = plans.filter((p) => plan.accountPlanId == p.id);
      setPlanSelected(filteredPlans[0]);
    }
  }, [plan.accountPlanId]);

  React.useEffect(() => {
    if(planSelected?.id){
      const discount = calculateDiscount();
      const subtotal = calculateSubtotalValue();
      const planPreviewRaw = {
        planValue: planSelected.value,
        discount,
        recurrence: planSelected.recurrence,
        recurrenceQuantity: planSelected.recurrenceQuantity,
        type: planSelected.planType,
        typeValue: planSelected.planTypeValue,
        subtotal,
        totalValue: subtotal - parseFloat(discount)
      }
      setPlanPreview(planPreviewRaw);
    }
  }, [planSelected, plan.discountType, plan.discountValue])

  function calculateDiscount() : number{
    switch(plan.discountType){
      case 'fixed':
        return plan.discountValue;
      case 'percentual':
        const subtotal = calculateSubtotalValue();
        return subtotal * (parseFloat(plan.discountValue) / 100);
      default:
        return 0;
    }
  }

  function calculateSubtotalValue() : number{
    let multiplier = 1, multiplierComp = 0, base = 0, typeValue = parseInt(planSelected.planTypeValue);
    switch(planSelected.planType){
      case 'users':
        base = usersCount;
        break;
      case 'filials':
        base = filialsCount;
        break;
    }
    multiplier = parseInt(base / typeValue);
    multiplierComp = base % typeValue > 0 ? 1 : 0;
    return planSelected.value * (multiplier + multiplierComp);
  }

  function createOrEditAccountPlanRelationship(event) : void {
    event.preventDefault();
    setLoading(true);
    if(!discountEnabled){
      delete plan.discountType;
      delete plan.discountValue;
    };
    if(!plan.store) plan.store = stores[0];
    if(!plan.paymentMethod) plan.paymentMethod = paymentMethods[0];
    if(!plan.installments) plan.installments = 1;
    
    MutationAccountPlanRelationship(plan, scope, setLoading)
    .then(({plan: planUpdated}) => {
      setPlan(planUpdated);
      window.location.href= `/alderaan/accounts/${account.id}/plans`;
    })
  }

  function updateAccountPlanFormEvent({target}){
    const {name,value} = target;
    const newPlan = {...plan, [name]: value};
    setPlan(newPlan);
  }

  return(
    <AccountPlanRelationshipContext.Provider 
      value={{
        plan,
        discountEnabled,
        planPreview,
        planSelected,
        setDiscountEnabled,
        updateAccountPlanFormEvent,
        createOrEditAccountPlanRelationship,
        loading,
        scope,
        errors
      }}>
        {children}
    </AccountPlanRelationshipContext.Provider>
  )
}