import * as React from 'react';
import {TranslateMediaValueToFileProps, messages} from '../../utils';
import {MutateCourse, MutateUnitByCourse} from '../../services';
import {arrayMoveImmutable} from 'array-move';

interface CourseModuleContextProps {

}

interface CourseModuleProviderProps {
  children: JSX.Element | JSX.Element[];
  courseModule: any;
  index: number;
  setCourseModule: (value) => void;
}

export const CourseModuleContext = React.createContext({} as CourseModuleContextProps);

export function CourseProvider({children, courseModule, index, setCourseModule} : CourseModuleProviderProps){
  const [errors, setErrors] = React.useState({});
  const [units, setUnits] = React.useState(courseModule?.units || []);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [progress,setProgress] = React.useState<number>(0);
  const [loadingMessage, setLoadingMessage] = React.useState(null);  

  const setUnit = (unit, unitIndex) => {
    const newUnits = [...units];
    newUnits[unitIndex] = unit;
    setUnits(newUnits);
  }

  React.useEffect(() => {
    if(courseModule.units !== units){
      const newCourseModule = {...courseModule, units};
      setCourseModule(newCourseModule);
    }
  }, [units])

  const createNewUnit = () => {
    const newUnit = {
      id: '',
      tempId: new Date().getTime(),
      title: '',
      description: '',
      unitType: 'Premium',
    };
  
    const newUnits = [...units, newUnit];
    setUnits(newUnits)
    setTimeout(() => {
      document
        .getElementById(
          `coursemodule-${index}-unit-${units.length}`,
        )
        .focus();
    }, 10);
  };

  const deleteUnit = (unit, forced = false) => {
    let newUnit = {...unit};
    const unitIndex = units.indexOf(unit)
    if (unit.id && !forced) {
      newUnit = {...unit, '_destroy': true}
      const newUnits = [...units];
      newUnits[unitIndex] = newUnit;
      setUnits(newUnits);
    } else {
      const newUnits = [...units];
      newUnits.splice(unitIndex, 1);
      setUnits(newUnits);
    }
  };

  const onSortEnd = ({oldIndex, newIndex, nodes}) => {
    const newUnits = arrayMoveImmutable(units, oldIndex, newIndex);
    setUnits(newUnits);
  };

  return (
    <CourseModuleContext.Provider 
      value={{
        courseModule,
        index,
        units,
        setUnit,
        createNewUnit,
        deleteUnit,
        onSortEnd,
        errors
      }}>
        {children}
      </CourseModuleContext.Provider>)
}
