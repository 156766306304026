import * as moment from 'moment';

const days = [];
 // Start of Selection
const numberOfDays = 15;
const dateStart = moment().endOf('day');
const dateEnd = moment().subtract(numberOfDays, 'days');
// const dateEnd = moment().subtract(7, 'days');
while (dateStart.diff(dateEnd, 'days') >= 0) {
  days.unshift(dateStart.format('DD/MM'))
  dateStart.subtract(1, 'days')
}

const defaultDatastes = {
    fill: true,
    lineTension: 0.2,
    backgroundColor: "#3cc0f066",
    borderColor: "#3cc0f0",
    borderDash: [],
    borderWidth: 0,
    borderDashOffset: 0.0,
    pointBackgroundColor: "#fff",
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: "rgba(33,150,243,0.4)",
    pointHoverBorderColor: "rgba(220,220,220,1)",
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    spanGaps: false,
}

export function GenerateChartJsToCheckins({checkinsCurrentMonth, checkinsLastMonth, gympassValidatedCurrentMonth, gympassValidatedLastMonth}){
  const checkinsCurrentMonthTotals = [],
        checkinsLastMonthTotals = [],
        gympassValidatedCurrentMonthTotals = [],
        gympassValidatedLastMonthTotals = [];

  if(checkinsCurrentMonth){
    days.map(day => {
      const checkinsCurrentMonthFiltered = checkinsCurrentMonth.filter(checkin => {
        const date = moment(checkin.date, 'YYYY-MM-DD HH:mm:ss Z');
        return date.isValid() && date.format('DD/MM') === day;
      });
  
      const checkinsLastMonthFiltered = checkinsLastMonth.filter(checkin => {
        const date = moment(checkin.date, 'YYYY-MM-DD HH:mm:ss Z').add(1, 'months');
        return date.isValid() && date.format('DD/MM') === day;
      });
  
      const gympassValidatedCurrentMonthFiltered = gympassValidatedCurrentMonth.filter(checkin => {
        const date = moment(checkin.date, 'YYYY-MM-DD HH:mm:ss Z');
        return date.isValid() && date.format('DD/MM') === day;
      });
  
      const gympassValidatedLastMonthFiltered = gympassValidatedLastMonth.filter(checkin => {
        const date = moment(checkin.date, 'YYYY-MM-DD HH:mm:ss Z').add(1, 'months');
        return date.isValid() && date.format('DD/MM') === day;
      });

      checkinsCurrentMonthTotals.push(checkinsCurrentMonthFiltered.length);
      checkinsLastMonthTotals.push(checkinsLastMonthFiltered.length);
      gympassValidatedCurrentMonthTotals.push(gympassValidatedCurrentMonthFiltered.length);
      gympassValidatedLastMonthTotals.push(gympassValidatedLastMonthFiltered.length);
    })
  }
  const dataObject = {
    labels: days,
    datasets: [
      {
        ...defaultDatastes,
        label: "# Checkins este mês",
        backgroundColor: "#3cc0f066",
        borderColor: "#3cc0f0",
        pointHoverBackgroundColor: "rgba(0,169,255,0.4)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        data: checkinsCurrentMonthTotals,
      },
      {
        ...defaultDatastes,
        label: "# Checkins mês passado",
        backgroundColor: "#7FD3F466",
        borderColor: "#7FD3F4",
        pointHoverBackgroundColor: "rgba(127,211,244,0.4)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        data: checkinsLastMonthTotals,
      },
      {
        ...defaultDatastes,
        label: "# Checkins gympass este mês",
        backgroundColor: "#e91e6366",
        borderColor: "#e91e6355",
        pointBackgroundColor: "#e91e6355",
        pointHoverBackgroundColor: "#e91e63",
        data: gympassValidatedCurrentMonthTotals,
      },
    ]
  }
  return dataObject;
}