import styled from 'styled-components';
import {COLOR, DIMENSION} from '../../constants';

export const ExerciseContainer = styled.div`
  background-color: ${COLOR.SECONDARY_BODY}CC;
  padding: 0.5vw;
  border: 1px solid #EDEDED;
  position: relative;
  margin: 0 0 2vh 0;
  &.clean-top{
    padding-top: 15px;
  }
  &.add-new{
    background-color: #FFFFFF;
    box-shadow: rgba(0,0,0,0.05) 0 0 10px;
    cursor: pointer;
  }
`;

export const ExerciseContainerHeader = styled.div`
  /* background: #E6F4F1; */
  display: flex;
  padding: 5px;
  padding-left: 60px;
  padding-right: 70px;
  position: relative;
  width: 100%;
  input{
    background-color: transparent !important;
    border-bottom: 1px solid #CCC !important;
  }
  &:before{
    color: #CCCCCC;
    cursor: grab;
    font-family: 'Font Awesome 6 Pro';
    left: 10px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
  }
  .select-dropdown{
    background-color: ${COLOR.BODY} !important;
  }
  .select-wrapper .caret{
    z-index: 1;
  }
  select{
    display: none;
  }
  @media screen and (max-width: 768px){
    &{
      padding-right: 40px;
    }
  }
`;

export const ExerciseBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 40px;
  padding-right: 60px;
`;

export const ExerciseContainerBody = styled.div`
  display:flex;
  flex-direction: column;
`;

export const ExerciseRow = styled.div`
  border-bottom: 1px solid #CCCCCC;
  position: relative;
  margin-bottom: 20px;
`;

export const ExerciseContainerContentEmpty = styled.div`
    color: #777777;    
    font-size: 1.33em;
    text-align: center;
    &:before{
      content: '\f067';
      font-family: 'Font Awesome 6 Pro';
      margin-right: 10px;
    }
`;

export const ExerciseColumn = styled.div`
  flex: ${props => props.flex? props.flex : 1};
  margin-right: 10px;
  ${({s,m,l}) => {
    if(l || m || s) return `min-width: ${((l||m||s)/12*100) - 1}%;`
  }}
  @media screen and (max-width: 768px){
    min-width: ${({col}) => {
      if(col == '12') return '100%'
      if(col == '6')  return '45%'
    }};
    margin-right: ${({col}) => {
      if(col == '12') return ''
      if(col == '6')  return '1.5%'
    }};
  }
  .input-field{
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export const ExerciseActionButton = styled.div`
  display:flex;
  height: 100%;
  position: absolute;
  right: 15px;
  a{
    align-items: center;
    display: flex;
  }
`;

export const ExerciseSelectOption = styled.div`
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
  &:hover{
    background-color: rgba(0,0,0,0.05);
  }
`;

export const ExerciseSelectContainer = styled.div`
  align-items: center;
  flex: 1;
  display: flex;
  & > div {
    flex: 1;
  }
`;

export const ExerciseSelectOptionThumb = styled.div`
  flex: 1;
`;

export const ExerciseSelectOptionContent = styled.div`
  flex: 3;
  flex-direction: column;
`;

export const ExerciseSelectOptionTitle = styled.div`
  padding-left: 10px;
  font-size: 1.11em;
`;