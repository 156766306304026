import {client} from '../../core/stores';
import {ExerciseProps, InputEvent} from '../../application/types';
import {MUTATION_PROGRAM, MUTATION_DELETE_PROGRAM} from '../../core/graphql/types';

export function MutationProgram(program, setSubmitting: InputEvent, setProgress?:InputEvent ): Promise {
  return new Promise((resolve,reject) => {
    const {name} = program;
    if(!name){
      M.toast({html: 'Para salvar um programa, você precisa preencher o nome', displayLength: 5000})
      const errors = {name: 'Campo obrigatório'}
      reject(errors);
      return false;
    }

    client('account').mutate({
      mutation: MUTATION_PROGRAM, 
      variables: program,
      context: {
        fetchOptions: {
          useUpload: true,
          onProgress: (ev: ProgressEvent) => {
            if(setProgress) setProgress(ev.loaded / ev.total)
          },
          onAbortPossible: (abortHandler: any) => {
          }
        }
      },
    })
    .then(response => {
      const {data} = response;
      const {createOrUpdateProgram} = data || {};
      const {program : programUpdated} = createOrUpdateProgram;
      M.toast({html: 'Programa atualizado com sucesso!'});
      setSubmitting(false);
      resolve({status: 'success', program: programUpdated})
    })
    .catch( err => {
      console.log('ERROR ==> ', err)
      setSubmitting(false);
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro na sua solicitação.' })
      M.toast({ html: errorMessage })
      reject({status: 'error', message: errorMessage})
    });
  })
}

export function MutationDeleteProgram(program, setSubmitting?: InputEvent, setProgress?: InputEvent) : Promise {
  return new Promise((resolve, reject) => {
    client('account').mutate({
      mutation: MUTATION_DELETE_PROGRAM, 
      variables: {id: program.id},
      context: {
        fetchOptions: {
          useUpload: true,
          onProgress: (ev: ProgressEvent) => {
            if(setProgress) setProgress(ev.loaded / ev.total)
          },
          onAbortPossible: (abortHandler: any) => {
          }
        }
      },
    })
    .then(response => {
      const {data} = response;
      const {deleteProgram} = data || {};
      const {program : programUpdated} = deleteProgram;
      M.toast({html: 'Programa apagado com sucesso!'});
      !!setSubmitting && setSubmitting(false);
      resolve({status: 'success', program: programUpdated})
    })
    .catch( err => {
      console.log('ERROR MutationDeleteProgram ==> ', err)
      !!setSubmitting && setSubmitting(false);
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro na sua solicitação.' })
      M.toast({ html: errorMessage })
      reject({status: 'error', message: errorMessage})
    });
  });
}