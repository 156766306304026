import styled from 'styled-components';

export const Badge = styled.span<{type?: 'success' | 'error' | 'warning'}>`
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: ${({type}) => {
    switch (type) {
      case 'success':
        return '#1de9b6AA';
      case 'error':
        return '#ff6e40AA';
      case 'warning':
        return '#ffd54fAA';
      default:
        return '#DDDDDD';
    }
  }};
`;