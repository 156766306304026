import styled from 'styled-components';

export const InboxListItem = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const MessageViewHeader = styled.div`
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
`;

export const MessageViewSubject = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

export const MessageViewBody = styled.div`
  background-color: #f5f5f5;
  padding: 16px;
`;

export const MessageViewReply = styled.div`
  border-bottom: 1px solid #e0e0e0;
  padding: 16px;
`;

export const NewMessageRow = styled.div`
  cursor: pointer;
  display: flex;
  gap: 16px;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;