import React from 'react';
import { AvatarComponent } from '../horarios/components/avatar.component';
import { ActionButton, ActionsContainer, PlanLabel } from './index.style';
import { Table, TableBody, TableData, TableHeader, TableHeaderContainer, TableRow } from '../../../components';

interface AlunosIndexProps {
  account: any;
  alunos: any;
}

export default function AlunosIndex({account, alunos}: AlunosIndexProps) {
  const activePlanLabel = (aluno) => {
    if (aluno.gympassEnabled) {
      return <PlanLabel color={aluno.planColor}>Gympass</PlanLabel>
    } else if (aluno.totalpassEnabled) {
      return <PlanLabel color={aluno.planColor}>Totalpass</PlanLabel>
    } else if (aluno.hasActivePlan) {
      return <PlanLabel color={aluno.planColor}>Plano Normal</PlanLabel>
    }
    return <PlanLabel color="#DDDDDD">Sem Plano</PlanLabel>
  }
  return (
      <div className="col s12">
        <div className="card">
          <Table>
              <TableHeaderContainer>
              <TableRow>
                <TableHeader maxWidth={50}>Foto</TableHeader>
                <TableHeader>Nome</TableHeader>
                <TableHeader>Email</TableHeader>
                <TableHeader>Telefone</TableHeader>
                {account.features.includes("points") && <TableHeader className="center">Pontos</TableHeader>}
                <TableHeader>Tipo de Plano</TableHeader>
                {account.features.includes("checkins") && <TableHeader className="center"><small>Checkins (este mês)</small></TableHeader>}
                <TableHeader>Ações</TableHeader>
              </TableRow>
              </TableHeaderContainer>
            <TableBody>
              {alunos.map((aluno) => (
                <TableRow key={aluno.id}>
                  <TableData maxWidth={50}><AvatarComponent aluno={aluno} size={50} hideName={true} /></TableData>
                  <TableData basis={80} mobileTitle={true} style={{paddingLeft: 10, paddingRight: 10}}><a href={`/${account.code}/admin/alunos/${aluno.id}`}>{aluno.getShortName}</a></TableData>
                  <TableData basis={45}><a href={`/${account.code}/admin/alunos/${aluno.id}`}>{aluno.email}</a></TableData>
                  <TableData basis={45} before="Telefone:">{aluno.phone == '[object Object]' ? '' : aluno.phone}</TableData>
                  {account.features.includes("points") && <TableData className="center" after="ponto(s)" basis={30}>{aluno?.relationship?.points}</TableData>}
                  <TableData className="center" basis={30} align="center">{activePlanLabel(aluno)}</TableData>
                  {account.features.includes("checkins") && <TableData className="center" basis={30} after="checkin(s)">{aluno.checkinsCurrentMonth}</TableData>}
                  <TableData basis={100} align="end">
                    <ActionsContainer>
                      <ActionButton href={`/${account.code}/admin/alunos/${aluno.id}`} className="btn btn-small">
                        Ver
                        <i className="fa-light fa-eye"></i>
                      </ActionButton>
                      <ActionButton
                        href={`/${account.code}/admin/alunos/${aluno.id}`} 
                        data-confirm="Tem certeza que deseja excluir este aluno?" 
                        data-method="delete" 
                        danger
                        className="btn btn-small btn-danger"
                      >
                        Excluir
                        <i className="fa-light fa-trash"></i>
                      </ActionButton>
                    </ActionsContainer>
                  </TableData>
                </TableRow>
              ))}
            </TableBody>
          </Table>        
        </div>
      </div>
  )
}