import { gql } from '@apollo/client';

export const GET_ALUNO_PLANS_QUERY = gql`
  query GetAlunoPlans($alunoId: ID!, $page: Int, $status: String) {
    getAlunoPlans(alunoId: $alunoId, page: $page, status: $status) {
        id
        planId
        name
        planType
        planColor
        store
        installments
        paymentMethod
        discountType
        discount
        total
        subtotal
        status
        startDate
        endDate
        planPayments {
          id
          paymentMethod
          date
          value
          received
        }
    }
  }
`;