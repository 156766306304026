import * as React from 'react';

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    const { options } = this.props;
    const tabs = document.querySelector('.tabs');
    M.Tabs.init(tabs, options);
  }

  render() {
    const { tabs, options } = this.props;
    return (
      <div className="row card">
        <div className="s12 ">
          <ul className="tabs  grey lighten-2 tabs-workout">
            {tabs.map((tab, index) => (
              <li
                key={index}
                className={`
                  tab col s2  tabs-workout__tab
                  ${tab.isActive ? 'active' : ''} 
                `}
              >
                <a className={`${tab.isActive ? 'active' : ''}`} href={`#test${index}`}>
                  <i className="small material-icons">{tab.title.icon}</i>
                  <span>{tab.title.text}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
        {tabs.map((tab, index) => (
          <div key={index} id={`test${index}`} className="col s12 white card-content">
            <div className="card-content">
              {tab.content}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Tabs;
