import * as React from 'react';

import {MutationAccountPlan} from '../../../services/alderaan/MutationAccountPlan';

interface AccountPlanType {
  id: string|number;
  code: string;
  name: string;
  status: string;
  maxInstallments: number;
  recurrence: string;
  recurrenceQuantity: number;
  value: number;
  planType: string;
  planTypeValue: number;
  accountType: string;
  billingTriggerType: 'beginning_of_period' | 'end_of_period' | 'day_of_month';
  billingTriggerDay?: number;
  cycles?: number;
}

interface AccountPlanContextProps {
  plan: AccountPlanType;
  scope?: 'alderaan' | 'admin';
  loading: boolean;
  errors?: any;
  updateAccountPlanFormEvent: (event) => void;
  createOrEditAccountPlan: (event) => void;
}

export const AccountPlanContext = React.createContext({} as AccountPlanContextProps);

interface AccountPlanProviderProps {
  children: React.ReactNode;
  plan?: AccountPlanType;
  planPathState: 'new' | 'edit';
  postUrl?: string;
  scope: 'alderaan' | 'admin'
}

export function AccountPlanProvider({ children, plan: planProps, planPathState, scope} : AccountPlanProviderProps ){
  const [plan, setPlan] = React.useState<AccountPlanType>(planProps);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState({});

  function createOrEditAccountPlan(event) : void {
    event.preventDefault();
    setLoading(true);
    MutationAccountPlan(plan, scope, setLoading, setErrors)
    .then(({plan: planUpdated}) => {
      setPlan(planUpdated);
      if(planPathState == 'new' && scope == 'alderaan'){
        window.history.pushState("object or string", null, `/alderaan/plans/${planUpdated.id}/edit`);
      }
    })
    .catch((err) => {
      console.log('error ', err);
    })
  }

  function updateAccountPlanFormEvent({target}){
    const {name,value} = target;
    const newPlan = {...plan, [name]: value};
    setPlan(newPlan);
  }

  return(
    <AccountPlanContext.Provider 
      value={{
        plan,
        updateAccountPlanFormEvent,
        createOrEditAccountPlan,
        loading,
        scope,
        errors
      }}>
        {children}
    </AccountPlanContext.Provider>
  )
}