import React, { useEffect } from 'react';
import { Controller, Form, useForm } from 'react-hook-form';
import { Row, Textarea } from 'react-materialize';
import { ButtonStyled, InputStyled, SelectStyled } from '../../../../elements/forms/InputContainer';
import { PointsContainer, PointsFormContainer, PointsFormFooter, PointsPreview } from './Points.style';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { ProfileCardPreview } from '../components/ProfilePlan.style';
import { useProfile } from '../profile.context';
import { useMutation } from '@apollo/client';
import { NEW_MOVEMENT_POINTS_MUTATION } from '../../mutations';
import { LoadingContent } from '../../../../components';

const OPERATION_OPTIONS = [
  {label: 'Adicionar', value: 'add'},
  {label: 'Remover', value: 'sub'}
]

const schema = z.object({
  operation: z.enum(['add', 'sub']),
  value: z.union([z.string(), z.number()]),
  obs: z.string().nullish()
})

export const PointsForm = ({onClose}: {onClose: () => void}) => {
  const {aluno} = useProfile();
  const {control, handleSubmit, formState: {errors}, watch} = useForm({
    resolver: zodResolver(schema)
  });

  const [submitPoints, {data, loading, error}] = useMutation(NEW_MOVEMENT_POINTS_MUTATION);

  const value = watch('value');
  const operation = watch('operation');

  const renderPreviewNewBalance = () => {
    let points = parseInt(aluno?.relationship?.points);
    if(!points) points = 0;
    if(!operation) return 0;
    if(!value) return points;
    if (operation === 'add') {
      points += parseInt(value);
    } else {
      points -= parseInt(value);
    }
    return points;
  }

  const onSubmit = (data: any) => {
    submitPoints({
      variables: {
        userId: aluno.id,
        operation: data.operation,
        value: parseInt(data.value),
        obs: data.obs
      }
    });
  }

  useEffect(() => {
    if(data) {
      M.toast({html: 'Nova operação de pontos realizada com sucesso'});
      onClose();
    }
  }, [data]);

  useEffect(() => {
    if(error) {
      M.toast({html: 'Erro ao realizar operação de pontos'});
    }
  }, [error]);

  if(loading) return <LoadingContent loading={loading} />;

  return (
    <div>
      <PointsContainer>
        <PointsFormContainer>
          <Row>
            <div className='flow-text' style={{fontWeight: 500}}>Adicionar/Remover Pontos</div>
          </Row>
            <Form onSubmit={handleSubmit(onSubmit)} control={control} style={{flex: 1, width: '100%'}}>
              <Row>
                <Controller
                  name="operation"
                  control={control}
                  render={({field: {ref, ...field}}) => 
                    <div className="col s12">
                      <SelectStyled
                        {...field} 
                        type="select"
                        label="Operação"
                        error={errors.operation?.message}
                        className={errors.operation?.message ? 'invalid' : ''}
                        placeholder="Você quer Adicionar/Remover Pontos?"
                        options={OPERATION_OPTIONS}
                        value={OPERATION_OPTIONS.find(option => option.value === field.value)}
                        onChange={({value}: {value: string}) => field.onChange(value)}
                      />
                    </div>
                  }
                />
              </Row>
              <Row>
                <Controller
                  name="value"
                  control={control}
                  render={({field: {ref, ...field}}) => 
                    <InputStyled
                      {...field}
                      type="tel"
                      s={12}
                      label="Pontos"
                      placeholder="00"
                      error={errors.points?.message}
                      className={errors.points?.message ? 'invalid' : ''}
                    />
                  }
                />
              </Row>
              <Row>
                <Controller
                  name="obs"
                  control={control}
                  render={({field: {ref, ...field}}) => 
                    <Textarea
                      {...field}
                      label="Observação"
                      placeholder="Descreva a operação realizada para o histórico"
                      s={12}
                    />
                  }
                />
              </Row>
              <Row>
                <PointsFormFooter className="col s12">
                  <ButtonStyled type="submit">Salvar</ButtonStyled>
                  <a onClick={onClose} style={{color: 'red'}}>Cancelar</a>
                </PointsFormFooter>
              </Row>
            </Form>
        </PointsFormContainer>
        <ProfileCardPreview>
          <code>
            <Row>
              <div className="col s12">
                <div className="flow-text" style={{fontWeight: 500}}>Novo Saldo</div>
              </div>
            </Row>
            <Row>
              <div className="col s12">
                {renderPreviewNewBalance()}
                <span>&nbsp;Ponto(s)</span>
              </div>
            </Row>
          </code>

        </ProfileCardPreview>
      </PointsContainer>
    </div>
  );
};