import {gql} from '@apollo/client';
import {CHECKIN_INDEX} from './checkinIndex';
import {COMMENTABLE_TYPE} from './comments';
import {IMAGE_TYPE} from './mediaType';
import {USER_PLAN_TYPE} from './userPlan';
import { USER_TYPE } from './users';

export const QUERY_DASHBOARD_CHECKINS = gql`
  query DashboardCheckins($days: Int, $filialsCode: [String!]){
    dashboardCheckins(days: $days, filialsCode: $filialsCode){
      checkinsCurrentMonth{
        ${CHECKIN_INDEX}
      }
      checkinsLastMonth{
        ${CHECKIN_INDEX}
      }
      gympassValidatedCurrentMonth{
        ${CHECKIN_INDEX}
      }
      gympassValidatedLastMonth{
        ${CHECKIN_INDEX}
      }
      totalCheckinsCurrentMonth
      totalCheckinsLastMonth
    }
  }
`;

export const QUERY_DASHBOARD_COMMENTS = gql`
  query DashboardComments($days: Int, $filialsCode: [String!]){
    dashboardComments(days: $days, filialsCode: $filialsCode){
      lastDaysCommentsByGroup{
        commentableType
        comments{
          ${COMMENTABLE_TYPE}
        }
      }
      lastDaysComments{
        ${COMMENTABLE_TYPE}
      }
      lastDaysCommentsTotal
    }
  }
`;

export const QUERY_DASHBOARD_PLANS = gql`
  query DashboardPlans($days: Int, $filialsCode: [String!]){
    dashboardComments(days: $days, filialsCode: $filialsCode){
      lastDaysComments{
        ${COMMENTABLE_TYPE}
      }
      lastDaysCommentsTotal
    }
  }
`;

export const QUERY_DASHBOARD_USERS = gql`
  query DashboardUsers($days: Int, $filialsCode: [String!]){
    dashboardUsers(days: $days, filialsCode: $filialsCode){
      lastDaysUserRelationships{
        createdAt
        id
        rule
        status
      }
      lastDaysUserRelationshipsTotal
    }
  }
`;

export const QUERY_DASHBOARD_ACCOUNT = gql`
  query Account{
    account{
      app{
        id
        appId
        appLabel
        description
        email
        icon{
          ${IMAGE_TYPE}
        }
        shortDescription
        versionLabel
      }
      code
      hasMundipaggApi
      hasVindiApi
      id
      name
      registerEnabled
      requiresPlanOnSignup
      senderEmail
      senderName
      totalPlans
      totalUsers
      totalUsersActive
      totalMales
      totalFemales
    }
  }
`;

export const QUERY_GET_DASHBOARDS = gql`
  query getDashboards($days: Int, $filialsCode: [String!]){
    dashboardPlans(days: $days, filialsCode: $filialsCode){
      lastDaysUserPlans{
        ${USER_PLAN_TYPE}
      }
      lastDaysUserPlansTotal
    }
    account{
      app{
        id
        appId
        appLabel
        description
        email
        icon{
          ${IMAGE_TYPE}
        }
        shortDescription
        versionLabel
      }
      accountBilling{
        billingType
        documentId
        phone
        email
      }
      accountStripeInfo{
        type
        businessProfile{
          mcc
          name
          supportEmail
          supportPhone
          supportUrl
          url
        }
        requirements{
          currentlyDue
        }
      }
      code
      hasMundipaggApi
      hasVindiApi
      externalStoreEnabled
      id
      name
      registerEnabled
      requiresPlanOnSignup
      senderEmail
      senderName
      totalPlans
      totalUsers
      totalUsersActive
      totalMales
      totalFemales
      billingPending
    }
  }
`;