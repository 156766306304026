import styled from 'styled-components';
import {COLOR} from '../../constants';

const getColor = (props) => {
  if (props.isDragAccept) {
      return COLOR.PRIMARY;
  }
  if (props.isDragReject) {
      return COLOR.ERROR;
  }
  if (props.isDragActive) {
      return COLOR.PRIMARY;
  }
  return COLOR.SECONDARY_BODY;
}

export const WorkoutGroupMediaBox = styled.div`
  align-items: center;
  background-color: #fafafa;
  border-color: ${props => getColor(props)};
  border-radius: 3px;
  border-radius: 2px;
  border-style: dashed;
  border-width: 2px;
  cursor: pointer;
  color: #bdbdbd;
  display: flex;
  flex: 1;
  flex-direction: column;
  outline: none;
  padding: 20px;
  transition: border .24s ease-in-out;
`;

export const WorkoutGroupMediaPreviewContainer = styled.div`
  border-radius: 3px;
  margin: 2vh 0;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  & > div {
    display: flex;
    flex-direction: column;
  }
  img, video{
    max-height: 20vw;
  }
`;

export const WorkoutGroupMediaPreview = styled.div`
  background-color: ${COLOR.SECONDARY_BODY};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 3px;
  height: 20vw;
`;