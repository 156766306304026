import styled from 'styled-components';
import {COLOR, DIMENSION} from '../../constants';
import {Modal} from 'react-materialize';

export const ModalContainer = styled(Modal)`
  max-height: 85% !important;
  @media screen and (max-width: 992px){
    width: 90% !important;
  }

  .modal-footer{
    display: none;
  }
`;

export const SelectContainer = styled.div`
  select {
    display: none;
  }
`;

export const CheckboxContainer = styled.div`
  margin-right: ${DIMENSION.MARGIN_DEFAULT}px;
`;

export const CheckboxContainerList = styled.div`
  display: flex;
  @media screen and (max-width: 768px){
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const LinkDelete = styled.a`
  color: ${COLOR.CLOSE}99;
  &:hover{
    color: ${COLOR.CLOSE}CC;
  }
`;

export const FormFooter = styled.div`
  align-items: center;
  display: flex;
  padding: ${DIMENSION.PADDING_DEFAULT*3}px ${DIMENSION.PADDING_DEFAULT}px ${DIMENSION.PADDING_DEFAULT}px ${DIMENSION.PADDING_DEFAULT}px;
  justify-content: space-between;
  button {
    order: 1;
  }
  a{
    flex: 1;
    order: 0;
  }
  @media screen and (max-width: 768px){
    flex-direction: column;
    a {
      margin-top: ${DIMENSION.MARGIN_DEFAULT*2}px;
      order: 3;
    }
  }
`;

export const CloseButton = styled.a`
  color: ${COLOR.TEXT}AA;
  display: flex;
  font-size: 1.33em;
  position: absolute;
  right: 10px;
  top: 10px;
  &:hover{
    color: ${COLOR.TEXT}FF;
  }
`;