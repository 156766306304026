import * as React from 'react';
import {CourseContext} from '../../context/CourseContext';
import {UploadMediaFile} from '../../components/media';
import {TranslateMediaValueToFileProps} from '../../../utils';

export function CourseFeaturedComponent(){
  const {values, setUploadCover, setUploadPoster} = React.useContext(CourseContext);

  return(
    <div>
      <div className="flow-text">Imagens para divlugar seu curso</div>
      <div className="card">
        <div className="card-content">
          <div className="row">
            <div className="col s12">
              <div className="col l7 s12">
                <div className="flow-text">Capa</div>
                <UploadMediaFile mediaType='image' propFile={TranslateMediaValueToFileProps(values.cover)} setUploadFile={setUploadCover}/>
              </div>
              <div className="col l5 s12">
                <div className="flow-text">Poster</div>
                <UploadMediaFile mediaType='image' propFile={TranslateMediaValueToFileProps(values.poster)} setUploadFile={setUploadPoster} customMessage='Clique ou arraste sua imagem aqui' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}