import * as zod from 'zod';
export const checkinSchema = zod.object({
  id: zod.union([zod.string(), zod.number()]).optional(),
  classDate: zod.string().optional(),
  programClass: zod.object({
    value: zod.string(),
    label: zod.string(),
  }),
  name: zod.string().optional(),
  email: zod.string().email().optional(),
  lastName: zod.string().optional(),
  gender: zod.string().optional(),
  token: zod.string().optional(),
  filial: zod.object({
    value: zod.union([zod.string(), zod.number()]),
    label: zod.string(),
  }).optional(),
  userId: zod.union([zod.string(), zod.number()]).optional(),
  schedule: zod.string().optional(),
  forceCheckin: zod.boolean().optional(),
  program: zod.object({
    value: zod.union([zod.string(), zod.number()]),
    label: zod.string(),
  }).optional(),
});