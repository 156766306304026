import * as React from 'react';
import {UploadMediaFile} from '../media';
import {FileProps} from '../../types';

type HandleMediaUpdateProps = { (mediaType: 'image' | 'video', file: FileProps) : void; }

type MediaComponentProps = {
  handleMediaUpdate: HandleMediaUpdateProps;
  image?: FileProps;
  video?: FileProps;
}

export const MediaComponent = ({handleMediaUpdate, image: propImage, video: propVideo} : MediaComponentProps) : JSX.Element => {
  const [image,setImage] = React.useState(propImage);
  const [video,setVideo] = React.useState(propVideo);

  const setUploadImage = (file) => { setImage(file); handleMediaUpdate('image', file) }
  const setUploadVideo = (file) => { setVideo(file); handleMediaUpdate('video', file) }
  return(
    <div className="row">
      <div className="col s12">
        <div className="flow-text">Imagem / Vídeo</div>
      </div>
      <div className="col s6">
        <UploadMediaFile mediaType='image' propFile={image} setUploadFile={setUploadImage}/>
      </div>
      <div className="col s6">
        <UploadMediaFile mediaType='video' propFile={video} setUploadFile={setUploadVideo} />
      </div>
    </div>
  )
}