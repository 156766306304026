import * as React from 'react';

import {MutateWorkoutGroupRoutine, QueryGetExercises} from '../../../services';

import {WorkoutRoutineGroupProps, WorkoutGroupProps} from '../../types';
import {
  WorkoutGroupComponent,
  WorkoutGroupEmptyComponent,
} from '../../components/workouts/WorkoutGroups';

import {WorkoutRoutineContext, WorkoutRoutineGlobalContext} from '../../context/WorkoutRoutineContext';
import { WorkoutGroupPreview } from '../../components/workouts/workoutGroups.component';

function EditWorkoutRoutine({}: WorkoutRoutineGroupProps): JSX.Element {

  const {
    createOrUpdateWorkoutGroupRoutine,
    createWorkoutGroup,
    workoutRoutine,
    loading,
    errors,
  } = React.useContext(WorkoutRoutineContext);
  const [showDetails, setShowDetails] = React.useState(true);

  const {workoutGroups = []} = workoutRoutine || {};
  const [exerciseListDefault, setExerciseListDefault] = React.useState([]);

  React.useEffect(() => {
    if (workoutGroups.length === 0) createWorkoutGroup(false);
  }, [workoutGroups]);

  React.useEffect(() => {
    QueryGetExercises()
      .then(({data}) => {
        const {list} = data;
        const exerciseTranslated = list.map((exercise) => ({
          label: exercise.name,
          value: exercise.id,
          animatedImage: exercise.animatedImage,
          image: exercise.image,
        }));
        setExerciseListDefault(exerciseTranslated);
      })
      .catch((err) => {
        console.log('error ', err);
      });
  }, []);
  
  function RenderWorkoutGroupsForms(): JSX.Element {
    return (
      <>
        {(workoutGroups || [])
          .filter((group) => !group['_destroy'])
          .map(
            (group, index): JSX.Element => (
              <div className="group" key={`${group.id || group.tempId}-${index}`} style={{display: 'flex'}}>
                <WorkoutGroupComponent
                  index={index}
                  workoutGroup={group}
                  setShowDetails={setShowDetails}
                  exerciseListDefault={exerciseListDefault}
                  errors={(errors.workoutGroups || [])[index]}
                />
                <WorkoutGroupPreview showDetails={showDetails} workoutGroup={group} />
              </div>
            ),
          )}
      </>
    );
  }

  function SubmitWorkoutRoutine(event) {
    event.preventDefault();
    createOrUpdateWorkoutGroupRoutine();
  }
  return (
    <form className="form-workout-groups" onSubmit={SubmitWorkoutRoutine}>
      {RenderWorkoutGroupsForms()}
      <WorkoutGroupEmptyComponent />
      <div className="row">
        <div className="col s12">
          <button
            className="btn btn-primary"
            type="submit"
            onSubmit={SubmitWorkoutRoutine}
            disabled={loading}>
            Publicar alterações
          </button>
        </div>
      </div>
    </form>
  );
};

export default WorkoutRoutineGlobalContext(EditWorkoutRoutine);
