import * as React from 'react';
import {UploadMediaFile} from '../media';
import {FileProps} from '../../types';

type HandleMediaUpdateProps = { (mediaType: 'cover' | 'poster' | 'video' | 'audio', file: FileProps) : void; }

type UnitMediaComponentProps = {
  handleMediaUpdate: HandleMediaUpdateProps;
  cover?: FileProps;
  poster?: FileProps;
  video?: FileProps;
  audio?: FileProps;
}

export const UnitMediaComponent = ({handleMediaUpdate, cover: propCover, poster: propPoster, video: propVideo, audio: propAudio} : UnitMediaComponentProps) : JSX.Element => {
  const [cover,setCover] = React.useState(propCover);
  const [poster,setPoster] = React.useState(propPoster);
  const [video,setVideo] = React.useState(propVideo);
  const [audio,setAudio] = React.useState(propAudio);

  const setUploadCover = (file) => { setCover(file); handleMediaUpdate('cover', file) }
  const setUploadPoster = (file) => { setPoster(file); handleMediaUpdate('poster', file) }
  const setUploadVideo = (file) => { setVideo(file); handleMediaUpdate('video', file) }
  const setUploadAudio = (file) => { setAudio(file); handleMediaUpdate('audio', file) }
  return(
    <div className="row">
      <div className="col s12">
        <div className="flow-text">Arquivos de Mídia do Info Produto</div>
      </div>
      <div className="row">
          <div className="col s12 m6">
            <UploadMediaFile mediaType='video' propFile={video} setUploadFile={setUploadVideo} />
          </div>
          <div className="col s12 m6">
            <UploadMediaFile mediaType='audio' propFile={audio} setUploadFile={setUploadAudio} />
          </div>
          <div className="col s12 m6">
              <UploadMediaFile mediaType='image' propFile={cover} setUploadFile={setUploadCover}/>
          </div>
          <div className="col s12 m6">
            <UploadMediaFile mediaType='image' propFile={poster} setUploadFile={setUploadPoster} customMessage='Clique ou arraste sua imagem aqui'/>
          </div>
      </div>
    </div>
  )
}