import * as React from 'react';
import { Table, TableRow, TableHeaderContainer, TableHeader, TableBody, TableData } from '../../../components';
import { Image } from '../horarios/components/image.component';
import { ActionButton, ActionsContainer } from '../alunos/index.style';

const TRANSLATE_STATUS: Record<string, string> = {
  'active': 'Ativo',
  'inactive': 'Inativo',
  'draft': 'Rascunho',
  'archived': 'Arquivado',
  'deleted': 'Deletado'
}

export default function Courses({courses}: {courses: any[]}) {
  return (
    <div className='col s12'>
      <div className="card">      
      <Table>
        <TableHeaderContainer>
          <TableRow>
            <TableHeader maxWidth={50} className='center'>Poster</TableHeader>
            <TableHeader>Info Produto</TableHeader>
            <TableHeader className='center'>Módulos</TableHeader>
            <TableHeader className='center'>Aulas</TableHeader>
            <TableHeader className='center'>Status</TableHeader>
            <TableHeader className='center'>Comentários</TableHeader>
            <TableHeader className='center'>Conclusões</TableHeader>
            <TableHeader className='center'>Favoritos</TableHeader>
            <TableHeader className='center'>Ações</TableHeader>
          </TableRow>
        </TableHeaderContainer>
        <TableBody>
          {courses.map(course => (
            <TableRow key={course.id}>
              <TableData maxWidth={200} className='center' basis={100}><Image source={course.poster} size={200} /></TableData>
              <TableData basis={100}><a href={`/${window.account}/admin/courses/${course.id}`}>{course.title}</a></TableData>
              <TableData className='center' basis={40} after="módulo(s)">{course.courseModulesCount}</TableData>
              <TableData className='center' basis={40} after="aula(s)">{course.unitsCount}</TableData>
              <TableData className='center' basis={40}>{TRANSLATE_STATUS[course.status]}</TableData>
              <TableData className='center' basis={40} after="comentário(s)">{course.unitCommentsCount}</TableData>
              <TableData className='center' basis={40} after="conclusão(ões)">{course.userCompletionsCount}</TableData>
              <TableData className='center' basis={40} after="favorito(s)">{course.userFavoriteContentsCount}</TableData>
              <TableData basis={100}>
                <ActionsContainer>
                  <ActionButton href={`/${window.account}/admin/courses/${course.id}/edit`} className="btn btn-small">
                    Editar
                    <i className="fa-light fa-pencil"></i>
                  </ActionButton>
                  <ActionButton
                    href={`/${window.account}/admin/courses/${course.id}`} 
                    data-confirm="Tem certeza que deseja excluir este curso?" 
                    data-method="delete" 
                    danger
                    className="btn btn-small btn-danger"
                  >
                    Excluir
                    <i className="fa-light fa-trash"></i>
                  </ActionButton>
                </ActionsContainer>
              </TableData>
            </TableRow>
          ))}
        </TableBody>
        </Table>
      </div>
    </div>
  )
} 