import {gql} from '@apollo/client';
import {IMAGE_TYPE, VIDEO_TYPE} from './mediaType';
export const EXERCISE_TYPE = `
  id
  category
  description
  name
  image{
    ${IMAGE_TYPE}
  }
  animatedImage{
    ${IMAGE_TYPE}
  }
  video{
    ${VIDEO_TYPE}
  }
  externalVideoUrl
  recordEnabled
  exerciseType
`;

export const QUERY_GET_EXERCISES = gql`
  query GetExercises($page: Int, $search: String){
    getExercises(page: $page, search: $search){
      list {
        ${EXERCISE_TYPE}
      }
      page
      totalPages
    }
  }
`;

export const MUTATION_EXERCISE = gql`
  mutation setExercise(
    $id: ID,
    $name: String!,
    $description: String!,
    $image: Upload,
    $video: Upload,
    $recordEnabled: Boolean,
    $exerciseType: ExerciseTypeOptionsEnum,
  ){
    setExercise(input: {
      id: $id,
      name: $name,
      description: $description,
      image: $image,
      video: $video,
      recordEnabled: $recordEnabled,
      exerciseType: $exerciseType
    }){
      exercise{
        ${EXERCISE_TYPE}
      }
    }
  }
`;