import React from 'react';
import { ProfileSidebar } from './components/profileSidebar.component';
import { ProfileContainer } from './show.style';
import { ProfileController } from './components/ProfileController.component';
import { ApolloProvider } from '@apollo/client';
import { client } from '../../../../core/stores';
import { ProfileProvider } from './profile.context';

interface ShowAlunoProps {
  aluno: any;
  account: any;
  filials: any;
}

export default function ShowAluno({aluno, account, filials}: ShowAlunoProps) {
  return (
    <ApolloProvider client={client('account')}>
      <ProfileProvider account={account} aluno={aluno} filials={filials}> 
        <ProfileContainer>
          <ProfileSidebar />
          <ProfileController />
        </ProfileContainer>
      </ProfileProvider>
    </ApolloProvider>
  )
}