import * as React from 'react';
import {Switch, Select, TextInput} from 'react-materialize';

import {AccountSettingsContext} from '../../context/AccountSettingsContext';
import {CodeContainer} from './AccountContainer.style';
import {MessageAlert} from '../Message.style';

export function AccountPaymentComponent() : JSX.Element {
  const {account, updateAccountFromEvent, updateAccount, errors, scope, accountStripeInfo, accountReauth} = React.useContext(AccountSettingsContext);
  const {stripeSecretKey, mundipaggSecretKey, accountNotificationKey, externalStoreFavorite, securityCheckout, recaptchaSecretKey} = account;
  const [stripeEnabled, setStripeEnabled] = React.useState(!!stripeSecretKey);
  const [mundipaggEnabled, setMundipaggEnabled] = React.useState(!!mundipaggSecretKey);
  const {requirements} = accountStripeInfo || {};
  const handleChange = ({target}) => {
    updateAccount({[target.name]: !!target.checked})
  }

  return(
    <>
    <div className="row">
      <div className="col s12">
        <h2>Configuração de seus pagamentos recorrentes</h2>
      </div>
    </div>
    {!stripeSecretKey && (
      <div className="row">
        <div className="col s12">
          <a href={`/${account.code}/admin/stripe/create`} className='btn btn-primary'>Habilite sua conta para receber pagamentos agora!</a>
        </div>
      </div>
    )}
    {!!stripeSecretKey && !!requirements?.currentlyDue.length && (
      <div className="row">
        <div className="col s12">
          <MessageAlert type="alert">
            <i class="fas fa-exclamation-triangle"></i> Falta pouco para você começar a receber pagamentos!<br/>
            <a href={accountReauth}>Clique aqui e finalize o envio de seus documentos</a>!
          </MessageAlert>
        </div>
      </div>
    )}
    {!!stripeSecretKey && !requirements?.currentlyDue.length && (
      <div className="row">
        <div className="col s12">
        <MessageAlert type="success">
        <i class="fad fa-check fa-lg"></i> Você está habilitado para receber pagamentos recorrentes!
        </MessageAlert>
        </div>
      </div>
    )}
    <div className="row">
      <div className="col s12">
        <label htmlFor="securityCheckout">Habilitar Checkout Transparente Seguro?</label>
          <Switch
            offLabel="Não"
            onLabel="Sim"
            id="securityCheckout"
            name="securityCheckout"
            checked={securityCheckout}
            onChange={handleChange}
          />
      </div>
    </div>
    {!!securityCheckout && <div className="row">
      <TextInput 
        s={12} 
        id="recaptchaSecretKey" 
        name="recaptchaSecretKey"
        value={recaptchaSecretKey} 
        error={errors.recaptchaSecretKey} 
        label="Chave Secreta Recaptcha" 
        type='password'
        inputClassName={errors?.recaptchaSecretKey ? 'invalid' : ' '} 
        onChange={updateAccountFromEvent}
      />
    </div>}
    {scope === 'alderaan' && <div className="row">
      <div className="col s12 m6">
        <label htmlFor="mundipaggEnabled">Habilitar Mundipagg</label>
        <Switch
          offLabel="Off"
          onLabel="On"
          id="mundipaggEnabled"
          name="mundipaggEnabled"
          checked={mundipaggEnabled}
          onChange={() => setMundipaggEnabled(!mundipaggEnabled)}
        />
        {mundipaggEnabled && <div className="row">
          <TextInput 
            s={12} 
            id="mundipaggSecretKey" 
            name="mundipaggSecretKey"
            value={mundipaggSecretKey} 
            error={errors.mundipaggSecretKey} 
            label="Chave de API Mundipagg" 
            type='password'
            inputClassName={errors?.mundipaggSecretKey ? 'invalid' : ' '} 
            onChange={updateAccountFromEvent}
          />
          <div className="col s12">
            <CodeContainer>
            <code><small>https://payment.millbody.com/{account.code}/mundipagg/{accountNotificationKey}</small></code>
            </CodeContainer>
          </div>
          <div className="col s12">
            <small>Use essa URL nas configurações do campo 'Webhook' na Vindi e/ou Mundipagg</small>
          </div>
        </div>}
      </div>
    </div>}
    {scope === 'alderaan' && stripeEnabled && mundipaggEnabled && !!stripeSecretKey && !!mundipaggSecretKey && (
    <div className="row">
      <div className="col s12">
        <Select
            id="externalStoreFavorite"
            name="externalStoreFavorite"
            value={externalStoreFavorite}
            onChange={updateAccountFromEvent}
            label="Pagamento preferencial"
          >
            <option value="">Selecione</option>
            <option value="Stripe">Stripe</option>
            <option value="Mundipagg">Mundipagg</option>
          </Select>
      </div>
    </div>
    )}
    </>
  );
}