import * as React from 'react';
import {Formik, Field, Form} from 'formik';

import Radio from '../../elements/Radio';
import Select from '../../elements/Select';
import {UserSelect} from '../../components/users';
import {ProgramSelect} from '../../components/programas';
import {Card, Link} from '../../elements';
import {formatDate} from '../../../services/Date';
import {
  SelectRangeProps,
  TabTypes,
  TargetProps,
  WorkoutRoutineProps,
} from '../../types';

import {DatePicker} from '../../components/DatePicker';
import {RadioGroup, TextInput} from 'react-materialize';
import {WorkoutRoutineTarget, WeekDaysGroup, WeekDaysItem, CreateWorkoutRoutineContainer} from './CreateWorkoutRoutine.style';
import {WorkoutRoutineContext, WorkoutRoutineGlobalContext} from '../../context/WorkoutRoutineContext';

const CreateWorkoutRoutine = ({programs, users, daysWeek, changeTab} : WorkoutRoutineProps) => {

  const {
    workoutRoutine,
    loading,
    errors,
    setWorkoutRoutine,
    createOrUpdateWorkoutGroupRoutine,
  } = React.useContext(WorkoutRoutineContext);

  const {
    startDate, 
    endDate, 
    programIds, 
    programsSelected, 
    usersTargetIds, 
    usersTarget, 
    weekDayIds,
    target,
  } = workoutRoutine || {};

  const [showDaysOfWeek, setShowDaysOfWeek] = React.useState<boolean>(!!weekDayIds?.length);

  React.useEffect(() => {
    let workoutRoutineUpdated = {...workoutRoutine}
    if(!workoutRoutine.cycleType) workoutRoutineUpdated = {...workoutRoutineUpdated, cycleType: 'date'};
    if(!startDate) workoutRoutineUpdated = {...workoutRoutineUpdated, startDate: formatDate(new Date()), endDate: formatDate(new Date())};
    if(workoutRoutineUpdated !== workoutRoutine) setWorkoutRoutine(workoutRoutineUpdated);
  }, [])

  const handleSelect = ({startDate, endDate}: SelectRangeProps): void => {
    const startDateTranslated = formatDate(startDate),
          endDateTranslated = formatDate(endDate);
    let workoutRoutineUpdated = {...workoutRoutine, startDate: startDateTranslated, endDate: endDateTranslated};
    setWorkoutRoutine(workoutRoutineUpdated);
  };

  const handleListUsers = (values) => {
    const newUsersTargetIds = {
      usersTargetIds: (values || [{}]).map((val) => val.value),
      usersTarget: values,
    };
    let workoutRoutineUpdated = {...workoutRoutine, ...newUsersTargetIds};
    setWorkoutRoutine(workoutRoutineUpdated);
  };

  const handleListPrograms = (values) => {
    const programIdsUpdated = (values || [{}]).map((val) => val.value);
    const programsSelectedUpdated = values;
    
    let workoutRoutineUpdated = {...workoutRoutine, programIds: programIdsUpdated, programsSelected: programsSelectedUpdated};
    setWorkoutRoutine(workoutRoutineUpdated);
  };

  const onChangeRoutineTarget = (event): void => {
    let workoutRoutineUpdated = {...workoutRoutine, target: event.target.value};
    setWorkoutRoutine(workoutRoutineUpdated);
  };

  const setDateRange = (): SelectRangeProps => {
    const dateRange = {startDate: new Date(), endDate: new Date()};
    if (startDate)
      dateRange.startDate =
        startDate instanceof Date
          ? startDate
          : new Date(`${startDate}T00:00:01-03:00`);
    if (endDate)
      dateRange.endDate =
        endDate instanceof Date
          ? endDate
          : new Date(`${endDate}T00:00:01-03:00`);
    return dateRange;
  };

  const handleChange = (event) => {
    let workoutRoutineUpdated = {...workoutRoutine, [event.target.name]: event.target.value};
    setWorkoutRoutine(workoutRoutineUpdated);
  }

  const handleDaysChange = (event) => {
    let weekDays = workoutRoutine.weekDayIds || [];
    const value = event.target.value;
    const index = weekDays.indexOf(value);
    if( index > -1){
      weekDays.splice(index, 1);
    } else {
      weekDays.push(event.target.value);
    }
    let workoutRoutineUpdated = {...workoutRoutine, weekDayIds: weekDays};
    setWorkoutRoutine(workoutRoutineUpdated);
  }
  return ( 
    <Card>
      <Formik
        initialValues={{
          id: workoutRoutine.id || '',
          name: workoutRoutine.name || '',
          startDate: formatDate(workoutRoutine.startDate),
          endDate: formatDate(workoutRoutine.endDate),
          cycleType: workoutRoutine.cycleType || 'date',
          target: workoutRoutine.target || TargetProps.programs,
          commentEnable: workoutRoutine.commentEnable || true,
          usersTargetIds: workoutRoutine.usersTargetIds || [],
          programIds: workoutRoutine.programIds || [],
          weekDayIds: weekDayIds || [],
          isDraft: workoutRoutine.isDraft,
        }}
        onSubmit={() => {
          createOrUpdateWorkoutGroupRoutine(() => {changeTab(TabTypes.edit)});
        }}>
        {({
          values,
          touched,
          handleSubmit,
        }) => (
          <Form className="form-workout-group-routine">
            <div className="row">
              <TextInput
                s={12}
                id="name"
                name="name"
                onChange={handleChange}
                onBlur={handleChange}
                value={workoutRoutine.name}
                inputClassName={
                  !!errors.name && !!touched.name ? 'invalid' : ' '
                }
                error={errors.name}
                label="Nome da rotina de treino"
              />
            </div>
            <div className="row">
              <div className="col s12">
                <div id="cycle_types" className="section">
                  <h5>Qual tipo de treino?</h5>
                  <RadioGroup
                    label="Período (data)"
                    name="workout_routine_type"
                    onChange={(event) => console.log('radioGroup ', event)}
                    options={[{label: 'Período (data)', value: 'date'}]}
                    value="date"
                    withGap
                  />
                  <div className="subtitle">
                    Escolha um período de datas de quando o treino estará
                    disponível
                  </div>
                  <DatePicker
                    onSelect={handleSelect}
                    selectRange={setDateRange()}
                  />
                </div>
              </div>
              <div className="col s12">
                {!showDaysOfWeek && <Link className="item-title" title="Restringir por dia da semana" onPress={() => setShowDaysOfWeek(true) }/>}
                {!!showDaysOfWeek && (
                <>
                  <Link className="item-title" title="Não restringir por dia da semana" onPress={() => { setWorkoutRoutine({...workoutRoutine, weekDayIds: []}); setShowDaysOfWeek(false) }}/>
                  <WeekDaysGroup className="input-field">
                    {daysWeek.map((day) => (
                    <WeekDaysItem key={day.id} className='week-days-group'>
                      <label><Field type='checkbox' name='weekDayIds' value={`${day.id}`} onChange={handleDaysChange} checked={ (workoutRoutine.weekDayIds||[]).findIndex(element => element == day.id) > -1} /><span>{day.short}</span></label>
                    </WeekDaysItem>
                    ))}
                  </WeekDaysGroup>
                </>)}
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <h5>Para quem é o treino?</h5>
                <div id="targets" className="section">
                  <WorkoutRoutineTarget
                    className="row"
                    active={target === TargetProps.programs ? true : false}>
                    <div className="col s12">
                      <Radio
                        value={TargetProps.programs}
                        label="Para alunos ativos nos programas de treino abaixo"
                        checked={target === TargetProps.programs}
                        name="workout_group_routine"
                        onChange={onChangeRoutineTarget}
                      />
                      <div className="subtitle">
                        Deixe esse treino disponível para programas de treinos
                        (treinos em grupo)
                      </div>
                    </div>
                    <div className="input-field col s12 input-select">
                      <ProgramSelect
                        defaultOptions={programs}
                        onSelect={handleListPrograms}
                        selectValue={programsSelected}
                      />
                    </div>
                  </WorkoutRoutineTarget>

                  <WorkoutRoutineTarget
                    className="row"
                    active={target === TargetProps.users ? true : false}>
                    <div className="col s12">
                      <Radio
                        value={TargetProps.users}
                        label="Para uma pessoa ou grupo"
                        name="workout_group_routine"
                        checked={target === TargetProps.users}
                        onChange={onChangeRoutineTarget}
                      />
                      <div className="subtitle">
                        Esse treino é para um ou mais atletas que você
                        especificar
                      </div>
                    </div>
                    <div className="input-field col s12 input-select">
                      <UserSelect
                        defaultOptions={users}
                        onSelect={handleListUsers}
                        selectValue={usersTarget}
                      />
                    </div>
                  </WorkoutRoutineTarget>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onSubmit={handleSubmit}
                  disabled={loading}>
                  Salvar & continuar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
}

export default WorkoutRoutineGlobalContext(CreateWorkoutRoutine);
