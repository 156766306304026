export const BASE_COLOR = {
  NEUTRAL: '#F2F2F2',
  FORBIDDEN: '#fc5756',
  SUCCESS: '#7ddd46',
}
export const COLOR = {
  ALERT: '#fdbe30',
  BODY: '#FFFFFF',
  CLOSE: BASE_COLOR.FORBIDDEN,
  ERROR: BASE_COLOR.FORBIDDEN,
  SUCCESS: BASE_COLOR.SUCCESS,
  PRIMARY: '#34b7e5',
  SECONDARY_BODY: BASE_COLOR.NEUTRAL,
  TEXT: '#333333',
}