import * as React from 'react';

import {LoadingComponent} from '../../components/LoadingComponent';

import {CourseContext} from '../../context/CourseContext';
import {CourseModuleComponent, CouseModuleEmptyComponent} from '../../components/courseModules/CourseModuleComponent';
import {CourseModuleListComponent} from '../../components/courseModules/CourseModuleListComponent';

export const CourseModulesBaseComponent = () => {
  const {
    values, 
    handleSubmitCourseModules,
    loading, 
    progress, 
    loadingMessage,
  } = React.useContext(CourseContext);
  
  document.title = `${window.account}/curso ${values.title}`;

  return (
    <>
      <h1>{values.title}</h1>
      <form onSubmit={handleSubmitCourseModules}>
        <LoadingComponent loading={loading} progress={progress} customMessage={loadingMessage} />
        <CourseModuleListComponent />
        <CouseModuleEmptyComponent />
        <div className="row">
          <div className="col s12">
            <button
              className="btn btn-primary right"
              type="submit"
              onSubmit={handleSubmitCourseModules}
              disabled={loading}>
              {loading ? 'Salvando...' : `Salvar`}
            </button>
          </div>
        </div>
      </form>
      </>
  )
}