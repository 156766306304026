import styled from 'styled-components';
import {COLOR, DIMENSION} from '../../constants';

export const ProgramClassListContainer = styled.ul`
  background-color: #AAAAAA77;
  display: flex;
  overflow-x: auto;
  padding: ${DIMENSION.PADDING_DEFAULT}px !important;
  width: 100%;
`;

export const ProgramClassItemElement = styled.li`
  background-color: ${COLOR.BODY};
  box-shadow: rgba(0,0,0,0.15) 0 0 15px;
  border-radius: 3px;
  margin-right: ${DIMENSION.MARGIN_DEFAULT}px;
  min-width: 300px;
  padding: 0 ${DIMENSION.PADDING_DEFAULT}px ${DIMENSION.PADDING_DEFAULT}px ${DIMENSION.PADDING_DEFAULT}px;
  width: 300px;
  @media screen and (max-width: 768px){
    min-width: 300px;
    width: 300px;
  }
`;

export const ProgramClassHeader = styled.div`
  font-size: 1.33em;
  font-weight: 900;
  margin-bottom: ${DIMENSION.MARGIN_DEFAULT}px;
  padding: ${DIMENSION.PADDING_DEFAULT}px;
  text-align: center;
`;

export const ProgramClassHour = styled.li`
  align-items: center;
  background-color: #EFEFEF;
  display: flex;
  margin-bottom: ${DIMENSION.MARGIN_DEFAULT}px;
  overflow: hidden;
  padding: ${DIMENSION.PADDING_DEFAULT}px;
  position: relative;
`;

export const ProgramClassSchedule = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${DIMENSION.MARGIN_DEFAULT}px;
`;

export const ProgramClassCoach = styled.div`
  align-items: center;
  display: flex;
  flex: 2;
  img{
    margin-right: ${DIMENSION.MARGIN_DEFAULT}px;
  }
`;

export const EditButtonElement = styled.a`
  color: ${COLOR.TEXT}77;
  display: flex;
  padding: 3px;
  position: absolute;
  right: ${DIMENSION.MARGIN_DEFAULT}px;
  top: calc(50% - 15px);
`;

export const SelectContainer = styled.div`
  select {
    display: none;
  }
`;