import {validations} from '../../application/messages';

import {client} from '../../core/stores';
import {MUTATION_COURSE, MUTATION_UNIT_BY_COURSE_MODULE, MUTATION_COURSE_MODULE} from '../../core/graphql/types';

export function MutateCourse(course, setSubmitting, setProgress) : Promise {
  return new Promise((resolve, reject) => {
    setSubmitting(true);
    const errors = {};
    const {id, title, description, status, cover, poster, premiumAccessThroughAnyPlan, programIds, courseCategoryId} = course;
    if(!title) errors['title'] = validations.required;
    if(!description) errors['description'] = validations.required;
    course.units.map( (unit,index) => {
      if(!unit.title || !unit.description){
        if(!errors['units']) errors['units'] = [];
        if(!errors['units'][index]) errors['units'][index] = {};
        if(!unit.title) errors['units'][index]['title']  = validations.required;
        if(!unit.description) errors['units'][index]['description']  = validations.required;
      }
    })
    if(Object.entries(errors).length !== 0) {
      setSubmitting(false);
      reject(errors);
      return false;
    }

    const variables = { id, title, description, courseCategoryId, status, premiumAccessThroughAnyPlan, programIds, cover: cover instanceof File ? cover : undefined, poster: poster instanceof File ? poster : undefined };
    client('account').mutate({
      mutation: MUTATION_COURSE,
      variables,
      context: {
        fetchOptions: {
          useUpload: true,
          onProgress: (ev: ProgressEvent) => {
            if(setProgress) setProgress(ev.loaded / ev.total)
          },
          onAbortPossible: (abortHandler: any) => {
          }
        }
      }
    })
    .then(({data}) => {
      const {course} = data;
      setSubmitting(false);
      resolve({status: 'success', ...course})
    })
    .catch(err => {
      console.log('error => ', err)
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro na sua solicitação.' })
      M.toast({ html: errorMessage })
      setSubmitting(false);
      reject({status: 'error', message: errorMessage})
    });
  })
}

export function MutateCourseModule(course, courseModule, setSubmitting, setProgress, setErrors) : Promise {
  return new Promise((resolve, reject) => {
    setSubmitting(true);
    const errors = {};
    const {id, title, description, order, _destroy} = courseModule;
    if(!title) errors['title'] = validations.required;
    courseModule.units.forEach( (unit,index) => {
      if(!unit.title || !unit.description){
        if(!errors['units']) errors['units'] = [];
        if(!errors['units'][index]) errors['units'][index] = {};
        if(!unit.title) errors['units'][index]['title']  = validations.required;
        if(!unit.description) {
          errors['units'][index]['description']  = validations.required;
          M.toast({ html: 'Há aulas sem descrição. Por favor, corrija' })
        }
      }
    });
    if(Object.entries(errors).length !== 0) {
      setSubmitting(false);
      console.log('errors => ', errors)
      setErrors(errors);
      reject(errors);
    } else {

      client('account').mutate({
          mutation: MUTATION_COURSE_MODULE,
          variables: { courseId: course.id, id, title, description, order, _destroy },
          context: {
            fetchOptions: {
              useUpload: true,
              onProgress: (ev: ProgressEvent) => {
                if(setProgress) setProgress(ev.loaded / ev.total)
              },
            onAbortPossible: (abortHandler: any) => {
            }
          }
        }
      })
      .then(({data}) => {
        const {courseModule} = data;
        setSubmitting(false);
        resolve({status: 'success', ...courseModule})
      })
      .catch(err => {
        const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
        console.log('errorMessage => ', errorMessage, err)
        M.toast({ html: 'Houve um erro na sua solicitação.' })
        M.toast({ html: errorMessage })
        setSubmitting(false);
        reject({status: 'error', message: errorMessage})
      });
    }
  })
}

export function MutateUnitByCourseModule(courseModule, unit, setSubmitting, setProgress ) : Promise {
  return new Promise((resolve, reject) => {
    setSubmitting(true);
    const errors = {};

    const {id, title, description, cover, poster, video, audio, order, unitType, _destroy} = unit;
    if(!title) errors['title'] = validations.required;
    if(!description) errors['description'] = validations.required;
    if(!unitType) errors['unitType'] = validations.required;

    if(Object.entries(errors).length !== 0) {
      setSubmitting(false);
      reject(errors);
      return false;
    }

    client('account').mutate({
      mutation: MUTATION_UNIT_BY_COURSE_MODULE,
      variables: { courseModuleId: courseModule.id, id, title, description, unitType, _destroy, order, cover: cover instanceof File ? cover : undefined, poster: poster instanceof File ? poster : undefined, video: video instanceof File ? video : undefined, audio: audio instanceof File ? audio : undefined },
      context: {
        fetchOptions: {
          useUpload: true,
          onProgress: (ev: ProgressEvent) => {
            if(setProgress) setProgress(ev.loaded / ev.total)
          },
          onAbortPossible: (abortHandler: any) => {
          }
        }
      }
    })
    .then(({data}) => {
      const {unitByCourseModule} = data;
      setSubmitting(false);
      resolve({status: 'success', ...unitByCourseModule})
    })
    .catch(err => {
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro na sua solicitação.' })
      M.toast({ html: errorMessage })
      setSubmitting(false);
      reject({status: 'error', message: errorMessage})
    });

  })
}