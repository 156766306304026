import styled from 'styled-components';
import {COLOR, DIMENSION} from '../../constants';

export const WorkoutGroupContainer = styled.div`
  background-color: ${COLOR.BODY};
  border-radius: 10px;
  box-shadow: rgba(0,0,0,0.15) 0 0 25px;
  display: flex;
  flex: 3;
  overflow: hidden;
  padding: 0 5px 15px;
  padding-top: calc(1vw + 100px);
  position: relative;
  margin: ${DIMENSION.MARGIN_DEFAULT}px 0 2vh 0;
  &.clean-top{
    padding: 15px;
  }
  &.add-new{
    background-color: #ADD0FF88;
    cursor: pointer;
    border: 1px dashed #CCCCCC;
  }
`;

export const WorkoutToggleContainer = styled.div`
 display: flex;
 left:15px;
 position: absolute;
 margin-top:: -10px !important;
 top: 50%;
 i{
  color: rgba(34,134,168,0.4);
  cursor: pointer;
 }

 `;

export const WorkoutGroupContainerHead = styled.div`
  background: #ADD0FF88;
  cursor: grab;
  left: 0;
  padding: 5px;
  padding-left: 50px;
  position: absolute;
  right: 0;
  top: 0;
  input{
    background-color: transparent !important;
    border-bottom: 1px solid #CCC !important;
  }
  &:before{
    color: rgba(34,134,168,0.4);
    cursor: grab;
    font-family: 'Font Awesome 6 Pro';
    left: 10px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
  }
`;

export const WorkoutGroupContainerMinified = styled.div`
  background-color: rgba(255,255,255,0.4);
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 20%) 0 0 4px;
  color: #CCCCCC;
  cursor: pointer;
  flex: 1;
  font-size: 1.33em;
  height: 30px;
  text-align: center;
  width: 100%;
`;

export const WorkoutGroupContentEmpty = styled.div`
  color: #777777;    
    font-size: 1.33em;
    text-align: center;
    &:before{
      content: '\f067';
      font-family: 'Font Awesome 6 Pro';
      margin-right: 10px;
    }
`;