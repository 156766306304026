import * as React from 'react';
import {UploadMediaFile} from '../media';
import {FileProps} from '../../types';

type HandleMediaUpdateProps = { (mediaType: 'image' | 'video' | 'attachment' | 'audio', file: FileProps) : void; }

type WorkoutGroupMediaComponentProps = {
  handleMediaUpdate: HandleMediaUpdateProps;
  image?: FileProps;
  video?: FileProps;
  audio?: FileProps;
  attachment?: FileProps;
}

export const WorkoutGroupMediaComponent = ({handleMediaUpdate, image: propImage, video: propVideo, audio: propAudio, attachment: propAttachment} : WorkoutGroupMediaComponentProps) : JSX.Element => {
  const [image,setImage] = React.useState(propImage);
  const [video,setVideo] = React.useState(propVideo);
  const [audio,setAudio] = React.useState(propAudio);
  const [attachment,setAttachment] = React.useState(propAttachment);

  const setUploadImage = (file) => { setImage(file); handleMediaUpdate('image', file) }
  const setUploadVideo = (file) => { setVideo(file); handleMediaUpdate('video', file) }
  const setUploadAudio = (file) => { setAudio(file); handleMediaUpdate('audio', file) }
  const setUploadAttachment = (file) => { setAttachment(file); handleMediaUpdate('attachment', file) }
  return(
    <div className="row">
      <div className="col s12 m6">
        <div className="flow-text">Vídeo</div>
        <UploadMediaFile mediaType='video' propFile={video} setUploadFile={setUploadVideo} />
      </div>
      <div className="col s12 m6">
        <div className="flow-text">Audio</div>
        <UploadMediaFile mediaType='audio' propFile={audio} setUploadFile={setUploadAudio}/>
      </div>
      <div className="col s12 m6">
        <div className="flow-text">Imagem</div>
        <UploadMediaFile mediaType='image' propFile={image} setUploadFile={setUploadImage} />
      </div>
      <div className="col s12 m6">
        <div className="flow-text">Anexo</div>
        <UploadMediaFile mediaType='attachment' propFile={attachment} setUploadFile={setUploadAttachment} />
      </div>
    </div>
  )
}