import * as React from 'react';
import {Checkbox, Select, TextInput} from 'react-materialize';

import InputMask from 'react-input-mask';

import {ProgramsContext} from '../../context/ProgramsContext';

import {
  ModalContainer,
  CloseButton,
  SelectContainer, 
  CheckboxContainer, 
  CheckboxContainerList, 
  FormFooter, 
  LinkDelete,
} from './ProgramScheduleForm.style';

export function ProgramScheduleForm() : JSX.Element{

  const {
    showModal, 
    closeModal, 
    schedule, 
    setSchedule, 
    coaches,
    loading,
    mutationSchedule,
    errors,
    setErrors,
    deleteSchedule,
  } = React.useContext(ProgramsContext);
  const [showAdvancedOptions, setShowAdvancedOptions] = React.useState(false);
  const {weekDays} = schedule;

  React.useEffect(() => {
    if(showModal) {
      document.getElementById('startTime').focus();
      const newSchedule = {...schedule};
      setSchedule(newSchedule);
    } else {
      setShowAdvancedOptions(false);
    }
  }, [showModal])
  
  function handleChange({target}){
    const newSchedule = {...schedule, [target.name]: target.value};
    if(errors[target.name]){
      const newErrors = {...errors};
      delete newErrors[target.name];
      setErrors(newErrors);
    }
    setSchedule(newSchedule);
  }

  function handleChangeSelect({target}){
    const {checked, value} = target;
    const weekDaysFiltered = weekDays.filter(w => w.value == value);
    const weekDayIndex = weekDays.map(w => `${w.value}`).indexOf(`${value}`);
    let newWeekDays = [...weekDays];
    if(checked === false){
      if(weekDays[weekDayIndex].id){
        newWeekDays[weekDayIndex] = {...newWeekDays[weekDayIndex], _destroy: true}
      } else {
        newWeekDays = weekDays.filter(w => w.value != value);
      }
    } else{
      if(weekDaysFiltered.length && weekDays[weekDayIndex].id){
        delete newWeekDays[weekDayIndex]._destroy;
      } else {
        newWeekDays.push({id: '', value})
      }
    }
    const newSchedule = {...schedule, weekDays: newWeekDays}
    setSchedule(newSchedule);
  }

  function renderWeekDays(){
    const daysInWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
    const listDays = [];
    for(let i = 0; i < 7; i++){
      const checked = !!(weekDays|| []).filter( item => item?.value == i && item?._destroy !== true)?.length;
      listDays.push(<CheckboxContainer key={`weekday-${i}`}><Checkbox id={`weekday-${i}`} label={daysInWeek[i]} value={`${i}`} checked={checked} onChange={handleChangeSelect}/></CheckboxContainer>)
    }
    return listDays;
  }

  return(
    <ModalContainer 
      actions={[]}
      options={{
        preventScrolling: true,
        onCloseEnd: closeModal
      }}
      open={showModal}
    >
      <CloseButton href="#" onClick={(event) => {event.preventDefault(); closeModal()}} ><i className={`fas fa-times`}></i></CloseButton>
      <form onSubmit={mutationSchedule}>
        <div className="row">
          <div className="col s12">
            <h1>{schedule.id ? 'Editar horário' : 'Criar horário'}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col s6">
            <label
              htmlFor={`startTime`}>
              Início
            </label>
            <InputMask
              id={`startTime`}
              name={`startTime`}
              type="tel"
              mask={'99:99'}
              maskChar="0"
              onChange={handleChange}
              value={schedule.startTime || ''}
              placeholder={'10:10'}
              className={errors?.startTime ? 'invalid' : ' '}
            />
            <span className="helper-text" data-error={errors?.startTime}></span>
          </div>
          <div className="col s6">
            <label
              htmlFor={`startTime`}>
              Final
            </label>
            <InputMask
              id={`endTime`}
              name={`endTime`}
              type="tel"
              mask={'99:99'}
              maskChar="0"
              onChange={handleChange}
              value={schedule.endTime || ''}
              placeholder={'10:10'}
              className={errors?.endTime ? 'invalid' : ' '}
            />
            
            <span className="helper-text" data-error={errors?.endTime}></span>
          </div>
        </div>
        <div className="row">
          <TextInput 
            id="limit"
            name="limit"
            s={4}
            value={schedule.limit || ''}
            inputClassName={errors?.limit ? 'invalid' : ' '}
            label="Limite"
            onChange={handleChange}
            type="tel"
          />
          <div className="col s8">
            <SelectContainer>
              <Select
                id="userId"
                name="userId"
                s={12}
                onChange={handleChange}
                value={`${schedule.userId}`}
                label="Treinador(a)"
              >
                <option value="">-</option>
                {(coaches||[]).map( (coach) => <option key={coach.id} value={`${coach.id}`}>{coach.fullName}</option>)}
              </Select>
            </SelectContainer>
          </div>
        </div>
        {!(weekDays?.length > 1 || showAdvancedOptions) && <div className="row">
          <div className="col s12">
            <a href="#" onClick={() => setShowAdvancedOptions(true)}>Ver opções avançadas</a>
          </div>
        </div>}
        {(weekDays?.length > 1 || showAdvancedOptions) && (
          <div className="row">
            <div className="col s12">
              <CheckboxContainerList>
                {renderWeekDays()}
              </CheckboxContainerList>
            </div>
          </div>
        )}
        <FormFooter className="row">
          <button className="btn btn-primary right" type="submit" disabled={loading}>{loading ? 'Enviando...' : 'Salvar'}</button>
          {!!schedule.id && <LinkDelete href="#" onClick={(event) => {event.preventDefault(); deleteSchedule(schedule) }} className="left">Apagar horário</LinkDelete>}
        </FormFooter>
      </form>
    </ModalContainer>
  )
}