import * as React from 'react';

import {InputEvent, PlanInterface, SelectValueProps} from '../types';
import {MutationPlan} from '../../services/plan/MutationPlan';


const BILLING_TRIGGER = [
  {
    label: 'Ao iniciar o plano (pré-pago)',
    value: 'inicio_do_periodo'
  },
  {
    label: 'Após o período (pós-pago)',
    value: 'fim_do_periodo'
  },
  {
    label: 'Dia fixo do mês',
    value: 'dia_do_mes'
  },
];


interface ProgramsContextProps {
  plan: PlanInterface;
  setPlan: InputEvent;
  programs: any[];
  externalStoreEnabled: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelect: (value : SelectValueProps) => void;
  handleSelectPlanPrograms: (value : SelectValueProps[]) => void;
  handleSelectExternalPlans: (value : SelectValueProps[]) => void;
  handleSubmit: (e: React.SyntheticEvent) => void;
  externalPlansOptions: SelectValueProps[];
  billingTrigger: SelectValueProps;
  planPrograms: SelectValueProps[];
  externalPlans: SelectValueProps[];
  programsOptions: SelectValueProps[];
  loading: boolean;
  setLoading?: InputEvent;
  errors: any;
  setErrors: InputEvent;
  setUploadImage: (value: any) => void;
}

interface PlanContextProviderProps {
  children: React.ReactNode | React.ReactNode[];
  plan: any;
  programs: any[];
  externalStoreEnabled: boolean;
  externalStoreOptions: [string];
}

export const PlanContext = React.createContext({} as ProgramsContextProps);

export function PlanContextProvider({ children, plan: planProps, programs, externalStoreEnabled, externalStoreOptions} : PlanContextProviderProps){
  const [plan, setPlan] = React.useState<PlanInterface>(planProps || {} as PlanInterface);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [billingTrigger, setBillingTrigger] = React.useState<SelectValueProps>({} as SelectValueProps);
  const [planPrograms, setPlanPrograms] = React.useState<SelectValueProps[]>([] as SelectValueProps[]);
  const [externalPlans, setExternalPlans] = React.useState<SelectValueProps[]>([] as SelectValueProps[]);
  
  const programsOptions = React.useMemo<SelectValueProps[]>(() => {
    return programs.map( p => ({value: `${p.id}`, label: `${p.name} (${p.filial.name})`}))
  }, [programs])

  const handleChange = ({target}) => {
    const value = target.type == 'tel' ? (target.value.match(/[\d\.|,]+/) || [])[0] || '' : target.value;
    const newPlan = {...plan, [target.name]: value};
    setPlan(newPlan);
  }

  const handleSelect = (selected) => {
    const {value} = selected;
    const newPlan = {...plan, billingTriggerType: value};
    setPlan(newPlan);
    setBillingTrigger(selected);
  }

  const handleSelectPlanPrograms = (selecteds) => {
    setPlanPrograms(selecteds);
  }
  
  const handleSelectExternalPlans = (selecteds) => {
    setExternalPlans(selecteds);
  }

  const setUploadImage = (file) => {
    setPlan({...plan, cover: file});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if(loading) return false;

    setLoading(true);

    const excludedPlanPrograms = plan.planPrograms.filter( pp => planPrograms.map(p => p.value).indexOf(`${pp.programId}`) === -1 );
    const selectedPlanPrograms = plan.planPrograms.filter(ep => planPrograms.map(op => op.value).indexOf(`${ep.programId}`) > -1);
    const newPlanPrograms = planPrograms.filter( ep => plan.planPrograms.map(p => `${p.programId}`).indexOf(`${ep.value}`) === - 1 );

    const planProgramsFiltered = [
      ...selectedPlanPrograms.map( ep => ({id: `${ep.id}`, programId: `${ep.programId}`})), 
      ...excludedPlanPrograms.map(ep => ({id: `${ep.id}`, _destroy: true})),
      ...newPlanPrograms.map(pp => ({id: '', programId: `${pp.value}`}))
    ];

    const excludedExternalPlans = plan.externalPlans.filter(ep => externalPlans.map(op => op.value).indexOf(ep.store) === -1);
    const selectedExternalPlans = plan.externalPlans.filter(ep => externalPlans.map(op => op.value).indexOf(ep.store) > -1);
    const newExternalPlans = externalPlans.filter( ep => plan.externalPlans.map(p => p.store).indexOf(`${ep.value}`) === - 1 );
    const externalPlansFiltered = [
      ...selectedExternalPlans.map( ep => ({id: `${ep.id}`, store: ep.store})), 
      ...excludedExternalPlans.map(ep => ({id: `${ep.id}`, store: ep.store, _destroy: true})), 
      ...newExternalPlans.map(ep => ({id: '', store: ep.value})) 
    ];

    const cycle = plan?.cycles > 0 ? plan.cycles : '0';
    const billingTriggerDay = plan?.billingTriggerDay ? plan.billingTriggerDay : '0';
    const recurrenceValue = !plan?.recurrenceValue || plan.recurrenceValue <= 0 ? 1 : plan.recurrenceValue;
    const installments = !plan?.installments || plan.installments <= 0 ? 1 : plan.installments;
    const confirmed = planProgramsFiltered.length ? true : confirm('Seu plano não dá acesso a nenhum programa de treino. Quer salvar mesmo assim?')
    if(confirmed){
      const newPlan = {...plan, planPrograms: planProgramsFiltered, externalPlans: externalPlansFiltered, cycle, billingTriggerDay, recurrenceValue, installments}
      MutationPlan(newPlan, setLoading)
      .then(({plan: planUpdated}) => {
        if(!plan.id) window.history.replaceState("object or string", null, `/${window.account}/admin/plans/${planUpdated.id}/edit`);
        setPlan(planUpdated);
      })
      .catch(err => {
        setErrors(err);
        console.log('MutationPlan ', err)
      })
    } else {
      setLoading(false);
    }
  }

  const externalPlansOptions = [
    {label: 'mundipagg', value: 'mundipagg'},
    {label: 'vindi', value: 'vindi'},
    {label: 'stripe', value: 'stripe'},
  ].filter( e => externalStoreOptions.indexOf(e.value) > -1)

  React.useEffect(() => {
    if(!billingTrigger.value && plan.billingTriggerType){
      const billingFiltered = BILLING_TRIGGER.filter( b => b.value === plan.billingTriggerType )
      setBillingTrigger(billingFiltered[0]);
    } else if (!plan.billingTriggerType){
      setBillingTrigger(BILLING_TRIGGER[0]);
    }

    if(!planPrograms.length && plan.planPrograms.length){
      const newPlanPrograms = plan.planPrograms.filter(pp => !!pp && !!pp?.filial).map( pp => ({value: `${pp.programId}`, label: `${pp.name} (${pp.filial.name})`}));
      setPlanPrograms(newPlanPrograms)
    }

    if(!externalPlans.length && plan.externalPlans.length){
      const newExternalPlans = plan.externalPlans.map(ep => ({label: ep.store, value: ep.store}))
      setExternalPlans(newExternalPlans);
    }
  },[]);
  
  return(
    <PlanContext.Provider
      value={{
        plan,
        setPlan,
        programs,
        planPrograms,
        programsOptions,
        externalStoreEnabled,
        handleChange,
        billingTrigger,
        handleSelect,
        handleSelectPlanPrograms,
        handleSelectExternalPlans,
        externalPlans,
        externalPlansOptions,
        loading,
        setLoading,
        errors,
        setErrors,
        handleSubmit,
        setUploadImage
      }}
    >
      {children}
    </PlanContext.Provider>
  )
}

export const usePlanContext = () => {
  return React.useContext(PlanContext);
}