import * as React from 'react';
import Breadcrumb from '../../../components/Breadcrumb';
import {formatDate} from '../../../../services/Date';
import {DatePicker} from '../../../components/DatePicker';
import {ReportMainDash} from '../../../components/reports/ReportsComponents';

import {SelectRangeProps} from '../../../types';

function Reports({report}): JSX.Element {
  const cumbs = [
    {
      url: `/${window.account}/admin/reports/`,
      icon: 'file-chart-line',
      label: 'Relatórios',
    },
  ];

  const [startDate, setStartDate] = React.useState(report?.fromDate || '');
  const [endDate, setEndDate] = React.useState(report?.toDate || '');

  const handleSelect = ({startDate, endDate}: SelectRangeProps): void => {
    const startDateTranslated = formatDate(startDate),
      endDateTranslated = formatDate(endDate);
      setStartDate(startDateTranslated);
      setEndDate(endDateTranslated);
  };

  const handleConfirm = () => {
    window.open(`${window.location.pathname}?from_date=${startDate}&to_date=${endDate}`,'_self');
  }

  const setDateRange = (): SelectRangeProps => {
    const dateRange = {startDate: new Date(), endDate: new Date()};
    if (startDate)
      dateRange.startDate =
        startDate instanceof Date
          ? startDate
          : new Date(`${startDate}T00:00:01-03:00`);
    if (endDate)
      dateRange.endDate =
        endDate instanceof Date
          ? endDate
          : new Date(`${endDate}T00:00:01-03:00`);
    return dateRange;
  };
  return (
    <>
      <div className="row">
        <div className="col s12">
          <Breadcrumb data={cumbs} />
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <DatePicker onSelect={handleSelect} selectRange={setDateRange()} handleConfirm={handleConfirm} />
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <div className="card">
            <div className="card-content">
              <ReportMainDash report={report} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reports;
