import * as React from 'react';
import {useDropzone} from 'react-dropzone';
import {humanFileSize} from '../../../utils';

import {MediaBox, MediaPreview, MediaGroupContainer, MediaPreviewContainer, MediaPreviewSubtitle} from './MediaComponents.style';

export const UploadVideoFile = (props) => {
  const [files, setFiles] = React.useState([]);

  React.useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'video/*',
    maxFiles: 1,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });
  
  const thumbs = files.map(file => (
    <div key={file.path}>
      <MediaPreview>
        <video controls>
          <source src={file.preview} type="video/webm" />
        </video>
      </MediaPreview>
      <MediaPreviewSubtitle>
        {file.path} - {humanFileSize(file.size)}
      </MediaPreviewSubtitle>
    </div>
  ));

  const renderEmptyPreview = () => {
    return(
      <MediaPreview>
        <i className='fal fa-play'></i>
      </MediaPreview>
    )
  }

  return(
    <MediaGroupContainer>
      <MediaPreviewContainer>
        {!!thumbs.length && thumbs}
        {!thumbs.length && renderEmptyPreview()}
      </MediaPreviewContainer>
      <MediaBox {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
        <section className="container">
          <div>
            <input {...getInputProps()} />
            <p>Clique para selecionar ou arraste seu vídeo aqui.</p>
          </div>
        </section>
      </MediaBox>
    </MediaGroupContainer>
  );
}