import styled from 'styled-components';
import {RadioGroup} from 'react-materialize';
import {DIMENSION} from '../../constants';


export const RadioGroupContainer = styled.div`
  label{
    margin-right: ${DIMENSION.MARGIN_DEFAULT}px;
  }
  @media screen and (max-width: 768px){
    &{
      display: flex;
      flex-direction: column;
    }
    label{
      margin-bottom: ${DIMENSION.MARGIN_DEFAULT}px;
    }
  }
`;