import * as React from 'react';
import {
  CommentAvatar,
  CommentContainer,
  CommentContent,
  CommentContentAuthor,
} from './CommentComponent.style';

import {AuthorProps} from '../../types';
import AuthorDefaultImage from '../../../assets/images/default-user.png'

interface CommentProps {
  id: string|number;
  message: string;
  author: AuthorProps;
  commentableType?: string;
  commentableId?: string;
}
interface CommentComponentProps{
  comment: CommentProps;
  showType?: boolean;
}
export const CommentComponent = ( {comment, showType} : CommentComponentProps ) : JSX.Element => {
  const {message, author} = comment;
  return(
    <CommentContainer>
      <CommentAvatar>
        <img src={author.avatar.uri || AuthorDefaultImage} alt={author.name} width="75" height="75"/>
      </CommentAvatar>
      <CommentContent>
        <CommentContentAuthor>{author.name}:</CommentContentAuthor>
        {message}
      </CommentContent>
    </CommentContainer>
  )
}