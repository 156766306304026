import {USER_TYPE} from './users';
import {PLAN_TYPE} from './plan';
export const USER_PLAN_TYPE = `
  discount
  endDate
  id
  installments
  paymentMethod
  planValue
  startDate
  status
  store
  total
`;