import * as React from 'react';

import {WorkoutRoutineContext, WorkoutRoutineGlobalContext} from '../context/WorkoutRoutineContext';

type BreadcrumbProps={
  data?:BreadcrumbItem[];
}

type BreadcrumbItem ={
  url:string,
  icon?: string,
  label: string
}

const Breadcrumb = ({data} : BreadcrumbProps) => {

  const {
    workoutRoutinePath
  } = React.useContext(WorkoutRoutineContext);
  
  const BreadcrumData = [
    {
      label: 'Treinos',
      url: `/${window.account}/admin/workout-routines/`,
      icon: 'stopwatch'
    },
    {
      label: workoutRoutinePath === 'new' ? 'Criar Rotina de Treinos' : 'Editar Rotina de Treinos',
      url: null,
    },
    ];

    return (
      <nav className="breadcrumb" aria-label="breadcrumb">
        <div className="nav-wrapper">
          <div className="col s12">
            {(data || BreadcrumData).map((item, index) => (
              <a key={`breadcrumb-${index}`} className="breadcrumb" href={item.url}>
                {!!item?.icon && <span className="breadcrumb-icon">
                  <i className={`fal fa-${item.icon}`}></i>&nbsp;
                </span>}
                <span>{item.label}</span>
              </a>
            )
            )}
          </div>
        </div>
      </nav>
    );
}

export default WorkoutRoutineGlobalContext(Breadcrumb);
