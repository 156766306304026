import { gql } from '@apollo/client';

export const GET_USER_INBOX = gql`
  query GetUserInbox($userId: ID!) {
    getUserInbox(userId: $userId) {
      id
      from {
        id
        name
      }
      to {
        id
        name
      }
      subject
      body
      sentAt
      replies {
        id
      }
    }
  }
`;

export const GET_MESSAGE_BY_ID = gql`
  query GetMessageById($messageId: ID!, $userId: ID!) {
    getMessageById(messageId: $messageId, userId: $userId) {
      id
      from {
        id
        name
      }
      to {
        id
        name
      }
      subject
      body
      sentAt
      replies {
        id
        from {
          id
          name
        }
        to {
          id
          name
        }
        subject
        body
        sentAt
      }
    }
  }
`;