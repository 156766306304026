import styled from 'styled-components';
import {COLOR, DIMENSION} from '../../constants';

export const DashboardHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: ${DIMENSION.MARGIN_DEFAULT}px;
`;

export const DashboardHeaderItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: ${DIMENSION.MARGIN_DEFAULT}px;
`;

export const DashboardLabel = styled.span`
  font-size: 0.88em;
  font-weight: 300;
`;

export const DashboardData = styled.span`
  font-size: 2em;
  font-weight: 300;
`;

export const DashboardDataComparative = styled.span`
  color: ${({status}) => {
    switch(status){
      case 'increase':
        return COLOR.SUCCESS;
      case 'decrease':
        return COLOR.ERROR;
      default:
        return COLOR.TEXT;
    }
  }};
  font-size: 0.88em;
`;