import * as React from 'react';
import {
  ProgramClassListContainer, 
  ProgramClassItemElement, 
  ProgramClassHeader, 
  ProgramClassHour,
  ProgramClassSchedule,
  ProgramClassCoach,
  EditButtonElement,
} from './ProgramClassList.style';
import {ProgramScheduleForm} from './ProgramScheduleForm';

import {ProgramsContext} from '../../context/ProgramsContext';

const ProgramClassItemComponent = ({item: {short, value, list}}) => {
  const {userIsManager, program, createOrUpdateSchedule} = React.useContext(ProgramsContext)
  return(
    <ProgramClassItemElement>
      <ProgramClassHeader>
        {short}
      </ProgramClassHeader>
      <ul>
      {list.map(item => (
        <ProgramClassHour key={item.programClass.id}>
          <ProgramClassSchedule>
            <span>{item.programClass.startTime}</span>
            <span>{item.programClass.endTime}</span>
          </ProgramClassSchedule>
          {!!item?.coach?.avatar && <ProgramClassCoach><img src={item.coach.avatar.uri} alt={item.coach.name} width="50" height="50" /><span>{item.coach.name}</span></ProgramClassCoach>}
          {userIsManager && <EditButtonElement href="#" onClick={() => createOrUpdateSchedule(item.programClass)}><i className="material-icons">edit</i></EditButtonElement>}
        </ProgramClassHour> )
      )}
      {userIsManager && <ProgramClassHour style={{alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent'}}><a href="#" onClick={() => createOrUpdateSchedule({programId: program.id, weekDays: [{id: '', value}]})} className="btn btn-primary">Novo horário</a></ProgramClassHour>}
      </ul>
    </ProgramClassItemElement>
  )
}

export function ProgramClassList({programClasses}) : JSX.Element {
  return(
      <ProgramClassListContainer>
        {programClasses.map( p => <ProgramClassItemComponent key={p.short} item={p} />)}
      </ProgramClassListContainer>
  )
}