import styled from 'styled-components';
import {COLOR, DIMENSION} from '../../../constants' // '../../constants';

export const SelectContainer = styled.div`
  select {
    display: none;
  }
`;

export const AccountPlanFormElement = styled.form`
  background-color: ${COLOR.BODY};
  /* display: flex; */
  h2 {
    font-size: 1.88em;
    font-weight: 700;
  }
  h3{
    font-size: 1.33em;
    font-weight: 300;
  }
  select {
    display: none;
  }
  @media screen and (max-width: 768px){
    &{
      flex-direction: column;
    }
    & > div, & ul {
      padding-bottom: 0px;
      padding-top: 0px;
      margin: 0px;
    }
  }
`;