import React, { Component } from "react";
import Breadcrumb from '../../components/Breadcrumb'

const BreadcumbsData = [
  {
    label: 'Treinos',
    url: '#',
  },
  {
    label: 'Criar Treinos',
    url: null
  }
]
class WorkoutRoutineIndex extends Component {
  render() {
    return (
 <React.Fragment>
        <div className="row col s12">
        <Breadcrumb data={BreadcumbsData}
        />
        </div>
        <div className="row col s12" id="workout-routine-group-form">
            <ul className="tabs grey lighten-2 tabs-workout">
               <li className="tab col active  workout_group_routines tabs-workout__tab">
                 <a href="#" className=" active">
                    <i className="small material-icons">settings</i>
                    <span>Rotina de treino</span>
                 </a>

               </li>
                <li className="tab col  tabs-workout__tab">
                    <a href="#" className="disabled active">
                      <i className="small material-icons">edit</i>
                      <span>Edição do treino</span>
                    </a>
                </li>
            </ul>
            <div className="row workout_groups nested-attributes " id="workout-routine-group-form">
              <div className="col s12 ">
                <form action="" className="form-workout-group-routine">                  
                  <div className="card">
                    <div className="card-content">
                      <div className="row">
                        <div className="input-field col s12">
                          <input  id="first_name" type="text" className="validate" />
                          <label htmlFor="first_name">Nome do treino</label>
                        </div>
                      </div>
                      <div id="cycle_types" className="section">
                        <h5>Qual tipo de treino?</h5>
                        <div className="row">
                          <div className="col s12">
                            <input className="with-gap" name="group3" type="radio" checked />
                            <span>Período 1111113</span>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </form>
              </div>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default WorkoutRoutineIndex;
