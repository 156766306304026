import * as React from 'react';
import AsyncSelect from 'react-select/async';

import {QueryGetAlunos} from '../../../services';
import {WorkoutRoutineContext} from '../../context/WorkoutRoutineContext';
import {OnSelectProps, SelectValueProps, TargetProps} from '../../types';
import {
  UserSelectOption, 
  UserSelectOptionThumb, 
  UserSelectOptionContent,
  UserSelectOptionTitle,
  UserSelectContainer,
} from './UserSelect.style';

type UserSelectProps = {
  onSelect: OnSelectProps,
  defaultOptions: SelectValueProps[];
  selectValue: SelectValueProps;
}

export const UserSelect = ({onSelect, defaultOptions, selectValue} : UserSelectProps) : JSX.Element => {
  const [value, setValue] = React.useState(selectValue);
  const [inputSearch, setInputSearch] = React.useState('');
  const [options, setOptions] = React.useState(defaultOptions.map( option => convertDataToUserSelect(option)))
  const onChange = (value) => {
    setValue(value);
    onSelect(value);
  }

  const {
    errors,
    workoutRoutine,
  } = React.useContext(WorkoutRoutineContext);

  function convertDataToUserSelect(user){
    return {
      image: user.avatar,
      label: `${user.shortName} - ${user.email}`, 
      value: user.id
    }
  }

  const loadOptions = (inputValue, callback) => {
    setInputSearch(inputValue)
    if(inputValue.length >= 3){
      QueryGetAlunos(1, inputValue)
      .then(({data}) => {
        const {list, page, totalPages} = data;
        const usersTranslated = list.map( user => (convertDataToUserSelect(user)) )
        callback(usersTranslated)
      })
      .catch(err => console.log('error ', err))
    }
  }

  const OptionComponent = ({innerProps, innerRef, data}) => {
    return(
      <UserSelectOption {...innerProps}>
        {!!data.image && 
          <UserSelectOptionThumb>
            <img src={data.image?.uri} width="75" height="75" />
          </UserSelectOptionThumb>
        }
        <UserSelectOptionContent>
          <UserSelectOptionTitle>{data.label}</UserSelectOptionTitle>
        </UserSelectOptionContent>
      </UserSelectOption>
    )
  };

  const LoadingMessage = (props) => {
    if(inputSearch.length < 3){
      return(
        <UserSelectOption>
          <UserSelectOptionContent>
            <span>Digite 3 carecteres ou mais para iniciar a busca.</span>
          </UserSelectOptionContent>
        </UserSelectOption>
      )
    } else {
      return(
        <UserSelectOption>
          <UserSelectOptionContent>
            <span>Carregando... </span>
          </UserSelectOptionContent>
        </UserSelectOption>
      )
    }
  }

  const NoOptionsMessage = (props) => (
    <UserSelectOption>
      <UserSelectOptionContent>
        <span>Nenhum usuário encontrado!</span>
      </UserSelectOptionContent>
    </UserSelectOption>
  );
  
  return(
    <UserSelectContainer error={!!errors.target && workoutRoutine.target === TargetProps.users && workoutRoutine.usersTargetIds.length == 0 ? 'error' : ''}>
      <AsyncSelect
        defaultOptions={options}
        placeholder={'Selecione/digite o nome/email do(s) usuário(s)'}
        onChange={onChange}
        components={{ 
          Option: OptionComponent,
          LoadingMessage: LoadingMessage,
          NoOptionsMessage: NoOptionsMessage
        }}
        loadOptions={loadOptions}
        isSearchable
        isMulti
        value={value}
      />
    </UserSelectContainer>
  )
}