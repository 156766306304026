import React, { useEffect, useMemo } from 'react';
import { useProfile } from '../profile.context';
import { Controller, Form, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Row } from 'react-materialize';
import { AvatarWrapper } from '../../../register/RegisterForm.style';
import { UploadMediaFile } from '../../../../components/media';
import { AvatarComponent } from '../../horarios/components/avatar.component';
import { ProfileFormColumn, ProfileFormFooter, ProfileFormRow } from './ProfileForm.syle';
import { ButtonStyled, InputMaskStyled, InputStyled, SelectStyled } from '../../../../elements/forms/InputContainer';
import { useMutation } from '@apollo/client';
import { CREATE_OR_UPDATE_USER_MUTATION } from '../../mutations/user.mutation';
import { LoadingContent } from '../../../../components';

const schema = z.object({
  avatar: z.any().nullish(),
  name: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  phone: z.string().min(1).nullish(),
  status: z.string().min(1).nullish(),
  cpf: z.string().nullish(),
  gender: z.enum(['Masculino', 'Feminino', '-']).nullish(),
  birthdate: z.string().nullish(),
  gympassId: z.string().nullish(),
  height: z.string().min(1).nullish(),
})

const STATUS_OPTIONS = [
  {label: 'Lead', value: 'lead'},
  {label: 'Ativo', value: 'ativo'},
  {label: 'Pendente', value: 'pendente'},
  {label: 'Cancelado', value: 'cancelado'},
  {label: 'Inativo', value: 'inativo'},
]

export const ProfileEdit = () => {
  const { account, aluno } = useProfile();
  const {control, handleSubmit, formState: {errors}, reset, setValue} = useForm({
    resolver: zodResolver(schema)
  });

  console.log('errors =====>', errors);

  const [createOrUpdateUser, {loading, data, error}] = useMutation(CREATE_OR_UPDATE_USER_MUTATION);

  const alunoValues = useMemo(() => {
    return {
      ...aluno,
      status: aluno?.relationship?.status
    }
  }, [aluno])

  useEffect(() => {
    if(alunoValues){
      reset(alunoValues)
    }
  }, [alunoValues]);

  const onSubmit = (data: any) => {
    const variables = {
      ...data,
      origin: 'panel'
    }
    if (!variables?.avatar?.path) {
      delete variables.avatar
    }
    createOrUpdateUser({
      variables,
    })
  }

  const setUploadImage = (file: any) => {
    if(file?.path || file?.uri) {
      setValue('avatar', file);
    }
  };

  useEffect(() => {
    if(data && data.createOrUpdateUser){
      M.toast({html: 'Perfil atualizado com sucesso'});
      const user = data.createOrUpdateUser.user;
      window.location.href = `/${account.code}/admin/alunos/${user.id}`;
    }
  }, [data]);

  useEffect(() => {
    if(error){
      M.toast({html: 'Erro ao atualizar o perfil'});
    }
  }, [error]);

  if(loading) return <LoadingContent loading={loading} />

  return (
    <div>
      <Row>
        <div className='flow-text' style={{fontWeight: 600}}>{aluno?.id ? 'Editar Perfil' : 'Criar Perfil'}</div>
      </Row>
      <Row>
        <Form control={control} onSubmit={handleSubmit(onSubmit)}>
          <ProfileFormRow>
            <ProfileFormColumn>
                <AvatarWrapper>
                  <UploadMediaFile propFile={aluno?.avatar} setUploadFile={setUploadImage} mediaType='image'>
                    <AvatarComponent aluno={aluno} size={230} hideName={true} noLink />
                </UploadMediaFile>
                <Row>
                  <em>Clique ou arraste uma imagem para escolher uma nova foto de perfil</em>
                </Row>
              </AvatarWrapper>
            <Row style={{width: '100%'}}>
              <Controller
                name="status"
                control={control}
                render={({field}) => (
                  <SelectStyled
                    {...field}
                    s={12}
                    label='Status'
                    options={STATUS_OPTIONS}
                    value={STATUS_OPTIONS.find(option => option.value === field.value)}
                    onChange={({value} : {value: string}) => field.onChange(value)}
                    className={errors.status ? 'invalid' : ''}
                    error={errors.status?.message}
                  />
                )}
              />
            </Row>
            </ProfileFormColumn>
            <ProfileFormColumn style={{flex: 2}}>
              <Controller
                name="name"
                control={control}
                render={({field}) => (
                  <InputStyled 
                    {...field} 
                    s={12}
                    label='Nome'
                    placeholder='Nome'
                    className={errors.name ? 'invalid' : ''}
                    error={errors.name?.message}
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                render={({field}) => (
                  <InputStyled 
                    {...field} 
                    s={12}
                    label='Sobrenome'
                    placeholder='Sobrenome'
                    className={errors.lastName ? 'invalid' : ''}
                    error={errors.lastName?.message}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({field}) => (
                  <InputStyled 
                    {...field} 
                    s={12}
                    label='Email'
                    type='email'
                    placeholder='Email'
                    className={errors.email ? 'invalid' : ''}
                    error={errors.email?.message}
                  />
                )}
              />
              <Controller
                name="phone"
                control={control}
                render={({field}) => {
                  let value = String(field.value).match(/[0-9]+/g)?.join('');
                  if(!value?.length) {
                    value = '55'
                  }
                  return(
                  <InputMaskStyled 
                    {...field} 
                    s={12}
                    label='Telefone'
                    mask={'+99 (99) 99999-9999'}
                    type='tel'
                    value={value}
                    placeholder='Telefone'
                    className={errors.phone ? 'invalid' : ''}
                    error={errors.phone?.message}
                  />
                )}}
              />
              <Controller
                name="cpf"
                control={control}
                render={({field}) => (
                  <InputMaskStyled 
                    {...field} 
                    s={12}
                    label='CPF'
                    type='tel'
                    mask={'999.999.999-99'}
                    placeholder='CPF'
                    className={errors.cpf ? 'invalid' : ''}
                    error={errors.cpf?.message}
                  />
                )}
              />
              <Controller
                name="gympassId"
                control={control}
                render={({field}) => (
                  <InputStyled 
                    {...field} 
                    s={12}
                    type='tel'
                    label='Gympass ID'
                    placeholder='Gympass ID'
                    className={errors.gympassId ? 'invalid' : ''}
                    error={errors.gympassId?.message}
                  />
                )}
              />
              <ProfileFormFooter style={{flexDirection: 'column'}}>
                <ButtonStyled type='submit'>{aluno?.id ? 'Salvar' : 'Criar'}</ButtonStyled>
                <a onClick={() => {
                  window.history.back();
                  setTimeout(() => {
                    window.location.reload()
                  }, 500)
                  }} style={{color: 'red'}}>Cancelar</a>
              </ProfileFormFooter>
            </ProfileFormColumn>
          </ProfileFormRow>
        </Form>
      </Row>
    </div>
  )
}