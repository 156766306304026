import { z } from 'zod';

export const CategorySchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1),
  description: z.string().min(1).optional(),
  parent_id: z.union([z.string().min(1), z.number()]).optional(),
})

export type CategoryFormProps = z.infer<typeof CategorySchema>;
