import styled from 'styled-components';
import {DIMENSION} from '../../constants';

export const CommentContainer = styled.div`
  display: flex;
  margin-bottom: ${DIMENSION.MARGIN_DEFAULT}px;
  padding: 5px;
`;

export const CommentAvatar = styled.div`
  margin-right: ${DIMENSION.MARGIN_DEFAULT}px;
  img{
    border-radius: 50%;
    display: block;
    height: 50px;
    min-height: 50px;
    overflow: hidden;
    width: 50px;
    min-width: 50px;
  }
`;

export const CommentContent = styled.div`
`;

export const CommentContentAuthor = styled.span`
  font-weight: 700;
  margin-right: 10px;
`;