import React, { useEffect, useState } from 'react';
import { Switch } from 'react-materialize';
import { usePlanContext } from '../../context/PlanContext';
import { InputStyled } from '../../elements/forms/InputContainer';
import { Editor } from '@tinymce/tinymce-react';

export const PlanFormEmail = () => {
  const [notificationEmail, setNotificationEmail] = useState(false);

  const {plan, handleChange} = usePlanContext();

  useEffect(() => {
    if(plan?.subject || plan?.body) {
      setNotificationEmail(true);
    }
  }, [])

  return (
    <div className="row">
      <div className="col s12">
        <div className="card">
          <div className="card-content">
            <div>
              <label htmlFor="securityCheckout">Personalizar E-mail ao ativar o plano?</label>
              <Switch
                offLabel="Não"
                onLabel="Sim"
                id="notificationEmail"
                name="notificationEmail"
                checked={notificationEmail}
                onChange={() => setNotificationEmail(!notificationEmail)}
              />
            </div>

            {notificationEmail && (
              <>
                <div className="row" style={{marginTop: 40}}>
                  <div className="col s12">
                    <div className="flow-text">Personalizar E-mail de Notificação de Novo Plano</div>
                  </div>
                </div>
                <div className="row" style={{flex: 1}}>
                  <InputStyled
                    id="subject"
                    name="subject"
                    label="Assunto"
                    s={12}
                    placeholder="Assunto"
                    value={plan?.subject}
                    onChange={handleChange}
                  />
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="body">Conteúdo</label>
                    <Editor
                      apiKey="uf8080t2ry6l3tf8a65rx8jewpqri1l0f2xmpht44kfpekud"
                      init={{
                        height: 500,
                        menubar: true,
                        language: 'pt_BR',
                        plugins: [
                          'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                          'insertdatetime', 'media', 'table', 'help', 'wordcount'
                        ],
                        toolbar:
                          'undo redo | formatselect | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                      }}
                      id="body"
                      placeholder="Seu e-mail de notificação"
                      value={plan?.body}
                      onEditorChange={(value) => handleChange({target: {name: 'body', value}})}
                    />
                  </div>
                </div>

                <div className="row" style={{marginTop: 40}}>
                  <div className="col s12">
                    <div className="flow-text">Personalizar E-mail de Notificação de Fim do Plano</div>
                  </div>
                </div>
                <div className="row" style={{flex: 1}}>
                  <InputStyled
                    id="subjectOnEnd"
                    name="subjectOnEnd"
                    label="Assunto"
                    s={12}
                    placeholder="Assunto"
                    value={plan?.subjectOnEnd}
                    onChange={handleChange}
                  />
                </div>
                <div className="row">
                  <div className="col s12">
                    <label htmlFor="bodyOnEnd">Conteúdo</label>
                    <Editor
                      apiKey="uf8080t2ry6l3tf8a65rx8jewpqri1l0f2xmpht44kfpekud"
                      init={{
                        height: 500,
                        menubar: true,
                        language: 'pt_BR',
                        plugins: [
                          'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                          'insertdatetime', 'media', 'table', 'help', 'wordcount'
                        ],
                        toolbar:
                          'undo redo | formatselect | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                      }}
                      id="bodyOnEnd"
                      placeholder="Seu e-mail de notificação"
                      value={plan?.bodyOnEnd}
                      onEditorChange={(value) => handleChange({target: {name: 'bodyOnEnd', value}})}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};