import * as React from 'react';
import {TextInput} from 'react-materialize';
import {Link} from '../../elements';
import {FormAuthElement} from './SignUpAuthComponent.style';
import {SignInAuthMiniComponent} from './SignInAuthComponent';

export const SignUpAuthMiniComponent = ({setComponentActivated}) : JSX.Element => {
  return(
    <div className='center'>
      <span>Não tem uma conta? <Link onPress={()=> setComponentActivated('signup')}>Faça seu cadastro!</Link></span>
    </div>
  )
}

export const SignUpAuthComponent = ({componentActivated, setComponentActivated, handleSubmit, loading, account}) : JSX.Element => {
  const [signup, setSignup] = React.useState({})
  const [errors, setErrors] = React.useState({})

  function updateFormValue({target}){
    const {value, name} = target;
    switch(name){
      case 'name':
        setErrors({...errors, name: undefined});
        break;
      case 'email':
        setErrors({...errors, email: undefined});
        break;
    }
    setSignup({...signup, [name]: value})
  }

  function validateInputElement({target}){
    const {value, name} = target;
    switch(name){
      case 'name':
        if(value.split(' ').length < 2) setErrors({...errors, name: 'Digite nome e sobrenome'});
        break;
      case 'email':
        if( !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) ) setErrors({...errors, email: 'Digite um email válido'});
        break;
    }
  }

  const submitSignUp = (event) => {
    handleSubmit({event, authType: componentActivated});
  }
  return(
   <div>
    <div className="card">
      <div className="card-content">
        <FormAuthElement className="SignUpAuth" onSubmit={submitSignUp} action="POST">
          <div className="row">
          <div className="col s12 center"><img src="https://millbody.com/wp-content/uploads/2023/06/logo-personal-millbody.svg" alt="Millbody Account" width="120" /></div>
            <div className="col s12">
              <h3>Faça seu cadastro com {account.name}</h3>
              <p className='center'>É simples, fácil e prático. Tenha acesso à maior plataformas de Educadores Físicos e Academias do Brasil!</p>
            </div>
          </div>
          <div className="row">
            <TextInput 
              name="name" 
              label="Nome e Sobrenome" 
              value={signup.name || ''} 
              s={12}
              onChange={updateFormValue}
              onBlur={validateInputElement}
              error={errors.name}
              inputClassName={errors?.name ? 'invalid' : ' '}  
            />
            <TextInput 
              name="email" 
              label="E-mail" 
              value={signup.email || ''}  
              s={12} 
              type="email"
              onChange={updateFormValue}
              onBlur={validateInputElement}
              error={errors.email}
              inputClassName={errors?.email ? 'invalid' : ' '}
            />
            <TextInput 
              name="password" 
              label="Crie sua senha" 
              value={signup.password || ''} 
              type="password"  
              s={12} 
              onChange={updateFormValue}
              onBlur={validateInputElement}
              error={errors.password}
              inputClassName={errors?.password ? 'invalid' : ' '}
            />
          </div>
          <div className="row">
            <div className="col s12 center">
              Ao se cadastrar, você aceita os <a href="https://millbody.com/privacidade/" target='_blank'>termos de uso e a política de privacidade</a> do site.
            </div>
          </div>
          <div className="row">
            <div className="col s12 center"><button type="submit" onSubmit={submitSignUp} disabled={loading} className="btn btn-primary">Entrar</button></div>
          </div>
        </FormAuthElement>
      </div>
    </div>
    <div className="card">
      <div className="card-content">
        <SignInAuthMiniComponent setComponentActivated={setComponentActivated} />
      </div>
    </div>
    </div>
  )
}