export function ConsultCEP(cep: string) : Promise<{
  neighborhood: string;
  city: string;
  state: string;
  street: string;
}> {
  return new Promise((resolve, reject) => {
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then(response => response.json())
      .then(data => {
        const response = {
          neighborhood: data.bairro,
          city: data.localidade,
          state: data.uf,
          street: data.logradouro,
        }
        resolve(response);
      })
      .catch(error => reject(error));
  });

}