import { gql } from "@apollo/client";

export const MUTATION_CREATE_CHECKIN = gql`
  mutation createCheckin(
    $id: ID,
    $userId: ID,
    $name: String,
    $lastName: String,
    $email: String,
    $gender: String,
    $token: String,
    $forceCheckin: Boolean,
    $programClassId: ID,
  ) {
    createOrUpdateCheckin(input: {
      id: $id,
      userId: $userId,
      name: $name,
      lastName: $lastName,
      email: $email,
      gender: $gender,
      token: $token,
      forceCheckin: $forceCheckin,
      programClassId: $programClassId,
  }) {
      message
      status
  }
  }

`

export const MUTATION_CREATE_OR_UPDATE_CHECKIN = gql`
  mutation createOrUpdateCheckin(
    $id: ID,
    $userId: ID,
    $name: String,
    $lastName: String,
    $email: String,
    $gender: String,
    $token: String,
    $forceCheckin: Boolean,
    $programClassId: ID,
  ) {
    createOrUpdateCheckin(input: {
      id: $id,
      userId: $userId,
      name: $name,
      lastName: $lastName,
      email: $email,
      gender: $gender,
      token: $token,
      forceCheckin: $forceCheckin,
      programClassId: $programClassId,
  }) {
      message
      status
  }
  }

`