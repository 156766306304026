import styled from 'styled-components';
import {COLOR, DIMENSION} from '../../constants';

export const CreateWorkoutRoutineContainer = styled.div`
  background-color: ${COLOR.BODY};
  padding: ${DIMENSION.PADDING_DEFAULT}px;
`;

export const WorkoutRoutineTarget = styled.div`
  opacity: ${props => props.active ? 1 : 0.5 };
  .input-select {
    display: ${props => props.active ? 'block' : 'none'};
  }
`;

export const WeekDaysGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const WeekDaysItem = styled.div`
  margin-right: 15px;
  margin-bottom: 15px;
  span{
    padding-left: 25px;
  }
`;