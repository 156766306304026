import {client, clientAlderaan} from '../../core/stores';
import {MUTATION_ACCOUNT_PLAN} from '../../core/graphql/types';

export function MutationAccountPlan(plan, scope, setSubmitting, setErrors): Promise {
  return new Promise((resolve,reject) => {
    const {name, billingTriggerType} = plan;
    if(!name || !billingTriggerType){
      const error = {};
      if(!name) error.name = 'Para salvar um plano, você precisa preencher o nome';
      if(!billingTriggerType) error.billingTriggerType = 'Para salvar um plano, você precisa escolher o tipo de cobrança';

      M.toast({html: 'Para salvar um plano, você precisa preencher os campos obrigatórios', displayLength: 5000})
      setErrors(error)
      reject(error);
      setSubmitting(false);
      return;
    }
    const mutationClient = scope === 'alderaan' ? clientAlderaan : client('account');
    
    Object.keys(plan).forEach( k => {
      if(typeof plan[k] === 'number'){
       return plan[k] = '' + plan[k]; 
      }
    })

    mutationClient.mutate({
      mutation: MUTATION_ACCOUNT_PLAN, 
      variables: plan,
    })
    .then(response => {
      const {data} = response;
      const {createOrUpdateAccountPlan} = data || {};
      M.toast({html: 'Plano atualizado com sucesso!'});
      setSubmitting(false);
      const {accountPlan: plan} = createOrUpdateAccountPlan;
      resolve({status: 'success', plan})
    })
    .catch( err => {
      console.log('ERROR ==> ', err)
      setSubmitting(false);
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro na sua solicitação.' })
      M.toast({ html: errorMessage })
      reject({status: 'error', message: errorMessage})
    });
  })
}