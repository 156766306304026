import React, { useState } from 'react';

import { useProfile } from '../profile.context';
import { useQuery } from '@apollo/client';
import { GET_USER_INBOX } from '../../querys';
import { Row, Table } from 'react-materialize';
import { TableBody, TableData, TableHeader, TableHeaderContainer, TableRow } from '../../../../components';
import { format, formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { InboxListItem } from './Inbox.style';
import { MessageView } from './MessageView.component';
import { ButtonStyled } from '../../../../elements/forms/InputContainer';
import { NewMessage } from './newMessage.component';

export const ProfileInbox = () => {
  const {aluno} = useProfile();
  const [showMessageView, setShowMessageView] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const {data, loading, error, refetch} = useQuery(GET_USER_INBOX, {
    variables: {
      userId: aluno.id
    }
  });

  const [showNewMessage, setShowNewMessage] = useState(false);

  const handleMessageView = (message: any) => {
    setShowMessageView(true);
    setSelectedMessage(message);
  }

  const onCloseMessageView = () => {
    setShowMessageView(false);
    setSelectedMessage(null);
    refetch();
  }

  const onCloseNewMessage = () => {
    setShowNewMessage(false);
    refetch();
  }

  return (
    <div>
      <Row>
        <div className='flow-text' style={{fontWeight: 600}}>Caixa de Entrada</div>
      </Row>
      {showNewMessage && !showMessageView && <NewMessage onClose={onCloseNewMessage} />}
      {!showNewMessage && !showMessageView && <Row>
        <ButtonStyled onClick={() => setShowNewMessage(true)}>Nova Mensagem</ButtonStyled>
      </Row>}
      {showMessageView && <MessageView message={selectedMessage} onClose={onCloseMessageView} />}
      {!showMessageView && !showNewMessage && data?.getUserInbox?.length > 0 && <Table>
        <TableHeaderContainer>
          <TableRow>
            <TableHeader style={{maxWidth: 100, width: 100}}>De</TableHeader>
            <TableHeader className="center">Assunto</TableHeader>
            <TableHeader className="center" colSpan={2}>Mensagem</TableHeader>
            <TableHeader className="center">Data</TableHeader>
          </TableRow>
        </TableHeaderContainer>
        <TableBody>
          {data?.getUserInbox?.map((message: any) => (
              <TableRow key={message.id} style={{cursor: 'pointer', borderBottom: '1px solid #e0e0e0'}} onClick={() => handleMessageView(message)}>
                <TableData>{message.from.name}</TableData>
                <TableData><InboxListItem className="center" style={{fontWeight: 600}}>{message.subject}</InboxListItem></TableData>
                <TableData basis={90} style={{paddingTop: 20, paddingBottom: 20}} className="center"><InboxListItem style={{maxWidth: 350}}>{message.body}</InboxListItem></TableData>
                <TableData className="center">{message?.replies?.length > 0 && `(${message?.replies?.length + 1})`}</TableData>
                <TableData>{formatDistance(new Date(message.sentAt), new Date(), {addSuffix: true, locale: ptBR})}</TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>}
      {!showMessageView && !showNewMessage && data?.getUserInbox?.length === 0 && <div>Não há mensagens na Caixa de Entrada deste usuário</div>}
    </div>
  )
}