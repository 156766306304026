import * as React from 'react';

import CreateWorkoutRoutine from './CreateWorkoutRoutine';
import EditWorkoutRoutine from './EditWorkoutRoutine';
import {RoutineFormContainer} from './WorkoutRoutineForm.style';

import {TabsHeader} from '../../components';
import {LoadingComponent} from '../../components/LoadingComponent';
import Breadcrumb from '../../components/Breadcrumb';

import {TabTypes, WorkoutRoutineProps} from '../../types';

import {WorkoutRoutineProvider} from '../../context/WorkoutRoutineContext';

function WorkoutRoutineForm({
  daysWeek,
  users,
  path,
  programs,
  tabActive,
  workoutRoutine,
}: WorkoutRoutineProps): JSX.Element {
  const [activeTab, setActiveTab] = React.useState(tabActive || 'config');

  React.useEffect( () => {
    if(tabActive) setActiveTab(tabActive)
  }, [tabActive, workoutRoutine]);

  const TabsList = [
    {
      icon: 'cog',
      label: 'Configurações do treino',
      value: 'config'
    },
    {
      icon: 'pen',
      label: 'Edição do treino',
      value: 'edit'
    }
  ]
  
  const onChangeTab = (tabValue: TabTypes) => {
    setActiveTab(tabValue);
  }
  return (
    <WorkoutRoutineProvider workoutRoutineState={workoutRoutine} workoutRoutinePathState={path}>
      <div>
        <div className="row">
            <div className="header">
                <Breadcrumb />
            </div>
        </div>
        <TabsHeader tabs={TabsList} onPress={onChangeTab} tabActive={activeTab} />
        <RoutineFormContainer>
            <LoadingComponent />
          {activeTab === 'config' ? (
              <CreateWorkoutRoutine 
                daysWeek={daysWeek} 
                users={users} 
                programs={programs} 
                changeTab={onChangeTab}
              />
              ) : (
              <EditWorkoutRoutine />
              )}
        </RoutineFormContainer>
      </div>
    </WorkoutRoutineProvider>
  );
}

export default WorkoutRoutineForm;
