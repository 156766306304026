import { useState } from "react";
import { QueryGetAlunos } from "../../../../services";
import { MutateCreateCheckin } from "../../../../services/checkin/createCheckin.mutation";

interface CheckinFormProps {
  users: any;
  query: string;
}

export function convertDataToUserSelect(user){
  return {
    value: user.id,
    label: `${user.name} ${user.lastName} - ${user.email}`,
  }
}
export async function checkinUserFilter(query, callback) {
    if(query?.length >= 3) {
      QueryGetAlunos(1, query)
      .then(({data}) => {
        const {list, page, totalPages} = data;
        const usersTranslated = list.map( user => (convertDataToUserSelect(user)) )
        callback(usersTranslated)
      })
    }
}

export async function getProgramsFromFilial(filialId, callback) {
  QueryGetAlunos(1, '')
  .then(({data}) => {
    const {list, page, totalPages} = data;
    callback(list)
  })
}


export async function createCheckin(data){
  const variables = {
    userId: data.userId,
    name: data.name,
    lastName: data.lastName,
    email: data.email,
    gender: data.gender,
    token: data.token,
    forceCheckin: data.forceCheckin,
    programClassId: data.programClass.value,
  };

  const response = await MutateCreateCheckin(variables);
  const responseData = response?.data?.createCheckin;
  if(responseData?.status === 'success'){
    M.toast({html: "Checkin realizado com sucesso!"})
    window.location.href = `/${window.account}/admin/checkins/`;
  } else {
    M.toast({html: "Erro ao realizar checkin!"})
    M.toast({html: responseData.message})
  }
}

export const useCreateCheckin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const createOrUpdateCheckin = async (props) => {
    setIsLoading(true);
    const variables = {
      id: props.id,
      userId: props.userId,
      name: props.name,
      lastName: props.lastName,
      email: props.email,
      gender: props.gender,
      token: props.token,
      forceCheckin: props.forceCheckin,
      programClassId: props.programClass.value,
    };
    await MutateCreateCheckin(variables)
    .then(response => {
      if(response?.data?.createOrUpdateCheckin?.status === 'success'){
        setData(response.data.createOrUpdateCheckin);
      } else {
        setError(response?.data?.createOrUpdateCheckin);
      }
    })
    .catch(err => {
      setError(err);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  return {
    createOrUpdateCheckin,
    isLoading,
    data,
    error,
  }
}