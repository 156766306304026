import React from 'react';
import Switches from '../../elements/Switches'
import TextArea from '../../elements/Textarea'

const WorkoutStepTraining = () => {
  return (
    <>
      <div className="col s12">
          <div className="input-field col s12 m8">
            <input id="first_name" type="text" className="validate"/>
            <label htmlFor="first_name">First Name</label>
          </div>
          <div className="input-field col s12 m2">
            <input id="first_name" type="text" className="validate"/>
            <label htmlFor="first_name">First Name</label>
          </div>
          <div className="input-field col s12 m2">
            <div>Habilitar resultados?</div>
              <Switches
                id="Switch-11"
                offLabel=""
                onChange={function noRefCheck(){}}
                onLabel=""/>
          </div>
      </div>
      

    </>
  );
}

export default WorkoutStepTraining;
