import { gql } from '@apollo/client';

export const NEW_MOVEMENT_POINTS_MUTATION = gql`
  mutation newMovementPoints($userId: ID!, $operation: OperationEnum!, $value: Int!, $obs: String) {
    newMovementPoints(input: {
      userId: $userId,
      operation: $operation,
      value: $value,
      obs: $obs
    }) {
      clientMutationId
    }
  }
`;