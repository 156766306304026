import styled from 'styled-components';


interface TableDataProps {
  basis?: number;
  mobileTitle?: boolean;
  after?: string;
  align?: 'start' | 'end' | 'center';
  before?: string;
  maxWidth?: number;
}

export const Table = styled.table`
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

export const TableBody = styled.tbody`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

export const TableRow = styled.tr`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    padding: 10px;
  }
`;

export const TableHeader = styled.th<TableDataProps>`
  ${({maxWidth})=> !!maxWidth && `max-width: ${maxWidth}px`};
  font-weight: bold;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const TableHeaderContainer = styled.thead`
  @media (max-width: 768px) {
    display: none;
  }
`

export const TableData = styled.td<TableDataProps>`
  ${({maxWidth})=> !!maxWidth && `max-width: ${maxWidth}px`};
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    place-self: center;
    gap: 5px;
    ${({basis})=> !!basis && `flex-basis: ${basis}%`};
    ${({mobileTitle})=> !!mobileTitle && `font-size: 1.66em`};
    ${({align})=> {
      if(align === 'center'){
        return `justify-content: center`
      }
      if(!!align){
        return `justify-content: flex-${align}`
      }
    }};
    ${({before})=> {
      if(!!before){
        return `&::before { content: "${before}"; }`
      }
    }};
    ${({after})=> {
      if(!!after){
        return `&::after { content: " ${after}"; }`
      }
    }};
}
`;