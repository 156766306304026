import {gql} from '@apollo/client';
import {AUDIO_TYPE, IMAGE_TYPE, VIDEO_TYPE} from './mediaType';
import {COURSE_TYPE} from './courses';
export const UNIT_TYPE = `
  id
  title
  description
  order
  poster {
    ${IMAGE_TYPE}
  }
  cover {
    ${IMAGE_TYPE}
  }
  video {
    ${VIDEO_TYPE}
  }
  audio {
    ${AUDIO_TYPE}
  }
  unitType
`;

export const MUTATION_UNIT_BY_COURSE_MODULE = gql`
  mutation createOrUpdateUnitByCourseModule(
    $courseModuleId: ID!,
    $id: ID,
    $title: String!,
    $description: String!,
    $unitType: String!,
    $order: Int,
    $poster: Upload,
    $cover: Upload,
    $video: Upload,
    $audio: Upload,
    $_destroy: Boolean,
  ){
    unitByCourseModule(input: {
      courseModuleId: $courseModuleId,
      id: $id,
      title: $title,
      description: $description,
      unitType: $unitType,
      order: $order,
      poster: $poster,
      cover: $cover,
      video: $video,
      audio: $audio,
      _destroy: $_destroy,
    }){
      unit{
        ${UNIT_TYPE}
      }
    }
  }
`;