import styled from 'styled-components';

export const ReviewHeaderElement = styled.a`
  align-items: center;
  color: #222;
  display: flex;
  border-bottom: #DDD 1px solid;
  margin-bottom: 10px;
  padding: 5px 0;
  img {
    margin-right: 10px;
    width: 35px;
  }
  &:hover{
    color: inherit;
  }
`;

export const ReviewFormContainer = styled.div`
  
`;

export const ReviewFormTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  text-align: center;
`;

export const ReviewableCoverElement = styled.div`
  border-radius: 200px;
  box-shadow: rgba(0,0,0,0.15) 0 0 20px;
  overflow:hidden;
  height: 200px;
  margin: 0 auto;
  width: 200px;
  img {
    width: 100%;
  }
`;

export const RatingListElement = styled.ul`
  display: flex;
  list-style: none;
  margin: 0 auto;
  width: 280px;
`;

export const RatingItemElement = styled.li`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  .rating-star{
    color:${({rating, item}) => item <= rating ? '#FF9500' : 'rgba(0, 0, 0, 0.25)'};
    svg{
      font-size: 28px;
      margin: 0px;
    }
  }
  & .rating-text-help {
    font-size: 12px;
    margin-top: 10px;
  }
`;

export const ReviewMessageElement = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 512px;
`;