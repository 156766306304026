import * as React from 'react';
import { Button, Container, TextInput, Textarea } from 'react-materialize';
import { Card, SelectContainer } from '../../elements';
import { InputContainer } from '../../elements/forms/InputContainer';
import Select from '../../elements/Select';
import axios from 'axios';
import { CategoryFormProps, CategorySchema } from './Category.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Form, Controller } from 'react-hook-form';

function CategoryForm({category, categories}) {
  
  const [parentId, setParentId] = React.useState({});

  const { 
    register, 
    handleSubmit, 
    control, 
    getValues,
    setValue,
    formState: { errors, isSubmitting, isValid : isValidForm } } = useForm<CategoryFormProps>({
    resolver: zodResolver(CategorySchema),
    mode: "onChange",
    defaultValues: {
      name: '',
    }
  });

  React.useEffect(() => {
    setValue('parent_id', parentId.value);
  }, [parentId]);


  const categoryList = categories?.map((category) => {
    return {value: category.id, label: category.name}
  })
  const onSubmit = async ({name, description, parent_id}) => {
    const token = 
      document.querySelector('[name=csrf-token]').content
    
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token

    const params = {
      category: {
        name,
        description,
        parent_id,
      }
    }

    if(params.id) {
      const response = await axios.put(`/alderaan/categories/${params.id}`, params)
      if(response.status === 200) {
        M.toast({html: 'Categoria atualizada com sucesso!'})
      }
    } else {
      const response = await axios.post('/alderaan/categories', params)
      if(response.status === 200) {
        window.history.pushState({}, '', `/alderaan/categories/${response.data.id}`)
        setValue('id', response.data.id)
        M.toast({html: 'Categoria criada com sucesso!'})
      }
    }
  }
  
  return (
    <Container>
      <Card>
        <form 
          style={{flex: 1, display: 'flex', flexDirection: 'column'}}
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputContainer>
          <Controller
            name="id"
            control={control}
            render={({ field }) => (
              <TextInput {...field} type='hidden' />
            )}
          />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextInput {...field} id="name" label="Nome*" value={category.name} required />
            )}
          />
          </InputContainer>
          <InputContainer>
           <div className="col 12">
            <SelectContainer>
                  <Select 
                    isMulti={false}
                    selectType="select2" 
                    onChange={setParentId}
                    label="Categoria Mãe" 
                    options={categoryList} 
                    value={parentId}
                />
              </SelectContainer>
           </div>
          </InputContainer>
          <InputContainer>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <Textarea {...field} id="description" label="Descrição" value={category.description} />
              )}
            />
          </InputContainer>
          <Button type={'submit'}>Salvar</Button>
        </form>
      </Card>
    </Container>
  );
}

export default CategoryForm;