import * as React from 'react';

import {PlanContext} from '../../context/PlanContext';

import {PlanFormInfoComponent} from './PlanFormInfoComponent';
import {PlanFormPaymentComponent} from './PlanFormPaymentComponent';
import {PlanFormProgramsComponent} from './PlanFormProgramsComponent';
import {PlanFormExternalPlansComponent} from './PlanFormExternalPlansComponent';
import { PlanFormEmail } from './PlanFormEmail.component';


export function PlanFormComponent() : JSX.Element {

  const {
    plan,
    loading,
    handleSubmit,
  } = React.useContext(PlanContext);

  return(
    <form onSubmit={handleSubmit}>
      <PlanFormInfoComponent />
      <PlanFormProgramsComponent />
      {plan?.planType !== 'gympass' && (
        <>
          <PlanFormPaymentComponent />
          <PlanFormExternalPlansComponent />
        </>
      )}
      <PlanFormEmail />
      <div className="row">
        <div className="col s12"><button className="btn btn-primary right" type="submit" disabled={loading}>Salvar</button></div>
      </div>
    </form>
  )
}