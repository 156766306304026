import {client} from '../../core/stores';

import {QUERY_GET_EXERCISES} from '../../core/graphql/types';

import {ExerciseWorkoutProps} from '../../application/types';

type QueryGetExercisesReturn = {
  status: string;
  data: {
    page: number;
    totalPages: number;
    list: ExerciseWorkoutProps[];
  }
}

type QueryGetExercisesFunction = { ( page: number, search: string) : Promise<QueryGetExercisesReturn>; }

export function QueryGetExercises(page = 1, search = '') : QueryGetExercisesFunction<QueryGetExercisesReturn> {
  const variables = { page, search };
  return new Promise<QueryGetExercisesReturn>((resolve, reject) => {
    client('account').query({query: QUERY_GET_EXERCISES, variables})
    .then( ({data: {getExercises} }) => resolve({status: 'success', data: {...getExercises}}) )
    .catch( err => {
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro ao tentar carrega a lista de exercícios' });
      M.toast({ html: errorMessage });
      return reject(reject({status: 'error', message: errorMessage}));
    })
  })
}