import { client } from "../../../core/stores";
import {MUTATION_FILIAL} from "../../../core/graphql/types";

export function MutationFilial(filial, scope, setSubmitting): Promise {
  return new Promise((resolve,reject) => {
    const {name, filialType, street, number, neighborhood, city, state, zipcode} = filial;
    if(!name){
      M.toast({html: 'Para salvar, você precisa preencher o nome', displayLength: 5000})
      reject(false);
      return;
    }
    if(filialType == 'place' && !street && !number && !neighborhood && !city && !state && !zipcode){
      M.toast({html: 'Para salvar, você precisa preencher o endereço', displayLength: 5000})
      reject(false);
      return;
    }
    client('account').mutate({
      mutation: MUTATION_FILIAL, 
      variables: filial,
    })
    .then(response => {
      const {data} = response;
      const {updateFilial, createOrUpdateFilial} = data || {};
      const {filial} = updateFilial || createOrUpdateFilial;
      M.toast({html: 'Filial atualizada com sucesso!'});
      if(scope == 'new') window.history.pushState("Editar Local", null, `/${window.account}/admin/filials/${filial.code}/edit`);
      setSubmitting(false);
      resolve({status: 'success', filial})
    }
    )
    .catch( err => {
      console.log('ERROR ==> ', err)
      setSubmitting(false);
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro na sua solicitação.' })
      M.toast({ html: errorMessage })
      reject({status: 'error', message: errorMessage})
    });
  })
}