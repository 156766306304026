import * as React from 'react';
import {TextInput, Textarea, Select, Switch} from "react-materialize";
import {Formik, Field, Form} from 'formik';
import * as yup from 'yup';

import {MediaComponent} from "../../../components/media";
import {TranslateMediaValueToFileProps} from '../../../../utils';
import {validations} from '../../../messages';
import {LoadingComponent} from '../../../components/LoadingComponent';
import {MutationExercise} from '../../../../services/exercise/MutationExercise';

const ExerciseForm = ({exercise}) : JSX.Element => {
    const [exerciseValues, setExerciseValues] = React.useState(exercise);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [progress, setProgress] = React.useState<number>(0);
    const [recordEnabled, setRecordEnabled] = React.useState<boolean>(exercise.recordEnabled);
    const [exerciseTypeSelected, setExerciseTypeSelected] = React.useState<string>(exercise.exerciseType);
    const listExerciseType = [{key: 'carga', label: 'Carga'}, {key: 'tempo', label: 'Tempo'}, {key: 'repeticao', label: 'Repetição'}, {key: 'distancia', label: 'Distância'}, {key: 'calorias', label: 'Calorias'}];

    const handleChange = (event) => {
        const newExerciseValues = {...exerciseValues, [event.target.name]: event.target.value};
        setExerciseValues(newExerciseValues);
    }
    const handleMediaUpdate = (mediaType, file) => {
        const newExerciseValues = {...exerciseValues, [mediaType]: file}
        setExerciseValues(newExerciseValues);
    };

    const routineSchema = yup.object().shape({
        name: yup.string().required(validations.required),
        description: yup.string().required(validations.required),
    });

    function reanderExerciseTypeOptions(){
        return listExerciseType.map(e => (<option value={e.key} key={e.key}>{e.label}</option>))
    }

    function updateExerciseByEvent({target}){
        const {value} = target;
        setExerciseTypeSelected(value);
    }
    return(
        <>
        <LoadingComponent loading={loading} progress={progress} />
        <Formik
        initialValues={exercise}
        validationSchema={routineSchema}
        onSubmit={(values, {setSubmitting, setErrors}) => {
            if(recordEnabled && listExerciseType.map(e => e.key).indexOf(exerciseTypeSelected) < 0){
                M.toast({html: 'Ao habilitar para recordes, você precisa escolher um tipo'});
                return setErrors({recordEnabled: 'Ao habilitar para recordes, você precisa escolher um tipo'});
            }
            const exerciseMutationValues = {...exerciseValues, recordEnabled, exerciseType: recordEnabled ? exerciseTypeSelected : undefined};
            console.log('exerciseMutationValues ', exerciseMutationValues)
            setLoading(true);
            MutationExercise(
                exerciseMutationValues,
                setLoading,
                setProgress
            )
            .then(({exercise: exerciseResponse}) => {
                setExerciseValues(exerciseResponse);
                window.history.pushState("object or string", null, `/${window.account}/admin/exercises/${exerciseResponse.id}/edit`);
            })
            .catch( err => console.log('error ', err))
        }}>
        {({
          errors,
          touched,
          handleChange: formikHandleChange,
          handleSubmit,
        }) => (
            <Form className="form-workout-group-routine">
                <div className="row">
                    <div className="col s12">
                        <div className="card">
                            <div className="card-content">
                                <div className="row">
                                    <div className="col s12">
                                        <TextInput
                                            s={12}
                                            id={`name`}
                                            name={`name`}
                                            onChange={(event) => { handleChange(event); formikHandleChange(event)}}
                                            value={exerciseValues?.name}
                                            placeholder={`Nome a ser utilizado no exercicio`}
                                            inputClassName={errors?.name && touched?.name ? 'invalid' : ' '}
                                            error={errors?.name}
                                            label="Nome do exercício*"
                                        />
                                    </div>
                                </div>
                                <>
                                <MediaComponent 
                                    handleMediaUpdate={handleMediaUpdate}
                                    image={TranslateMediaValueToFileProps(exercise?.image)}
                                    video={TranslateMediaValueToFileProps(exercise?.video, exercise?.externalVideoUrl)}
                                />
                                </>
                                <div className="row">
                                    <div className="col s12">
                                        <Textarea
                                            s={12}
                                            id={`description`}
                                            name={`description`}
                                            onChange={(event) => { handleChange(event); formikHandleChange(event)}}
                                            value={exerciseValues?.description}
                                            placeholder={`Adicione uma descrição para o exercicio`}
                                            inputClassName={errors?.description && touched?.description ? 'invalid' : ' '}
                                            error={errors?.description}
                                            label="Descrição*"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s6">
                                        <label htmlFor="recordEnabled">Habilitar para recordes</label>
                                        <Switch
                                        offLabel="Off"
                                        onLabel="On"
                                        id="recordEnabled"
                                        name="recordEnabled"
                                        checked={recordEnabled}
                                        onChange={() => setRecordEnabled(!recordEnabled)}
                                        />
                                    </div>
                                    {recordEnabled && (
                                        <div className="col s6">
                                            <Select
                                            id="exerciseType"
                                            name="exerciseType"
                                            value={exerciseTypeSelected}
                                            onChange={updateExerciseByEvent}
                                            label="Tipo de exercício"
                                            >
                                            <option>-</option>
                                            {reanderExerciseTypeOptions()}
                                            </Select>
                                      </div>
                                    )}                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                    <button
                        className="btn btn-primary"
                        type="submit"
                        onSubmit={handleSubmit}
                        disabled={loading}>
                        Salvar & continuar
                    </button>
                    </div>
                </div>
            </Form>
        )}
        </Formik>
        </>
    )
}

export default ExerciseForm;
