import React from 'react';
import CheckinForm from '../../checkins/CheckinForm';
import { Row } from 'react-materialize';

interface CheckinFormProps {
  userId?: string | number;
  filials: any;
  onClose: () => void;
  checkin: any;
}

export const CheckinFormComponent = ({userId, filials, onClose, checkin} : CheckinFormProps) => {
  return (
    <div style={{marginBottom: 40}}>
      <Row>
        <i className="fa fa-arrow-left" onClick={onClose} style={{cursor: 'pointer'}}></i>
      </Row>
      <Row>
        <div className="col s12">
          <div className="flow-text" style={{fontWeight: 500}}>Adicionar Checkin</div>
        </div>
      </Row>
      <CheckinForm userId={userId} filials={filials} callBack={onClose} checkin={checkin} />
    </div>
  )
}