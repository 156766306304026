import * as React from 'react';
import {useDropzone} from 'react-dropzone';
import {humanFileSize} from '../../../utils';
import {TranslateErrorDropzone} from '../../../utils/translator/TranslateErrorDropzone';
import {UploadMediaProps, FileProps} from '../../types';
import ReactHlsPlayer from '@panelist/react-hls-player';

import {
  MediaBox, 
  MediaPreview, 
  MediaPreviewSubtitle, 
  MediaGroupContainer, 
  MediaPreviewContainer,
  MediaRemovePreviewButton
} from './MediaComponents.style';

import {CSSProperties} from "react";

const labelAligned: CSSProperties = {
  textAlign: "center",
}

const ACCEPT_MEDIA = {
  image: 'image/jpeg, image/png',
  video: 'video/*',
  audio: 'audio/*',
  attachment: 'application/zip, application/pdf'
};

const MAX_SIZE = {
  image: 10485760,
  video: 943718400,
  attachment: 5242880,
}

const ICON = {
  image: 'images',
  video: 'play',
  audio: 'podcast',
  attachment: 'file-pdf',
}

const UX_MESSAGE = {
  image: 'Clique para selecionar ou arraste sua imagem aqui.',
  video: 'Clique para selecionar ou arraste seu vídeo aqui.',
  audio: 'Clique para selecionar ou arraste seu áudio aqui.',
  attachment: 'Clique para selecionar ou arraste seu PDF aqui.'
}

export const UploadMediaFile = ( {setUploadFile, propFile, mediaType = 'image', customMessage, children} : UploadMediaProps) : JSX.Element => {
  const [files, setFiles] = React.useState<FileProps[]>([]);
  const [errors, setErrors] = React.useState('');

  React.useEffect(() => () => {
    files.forEach(file => {

      URL.revokeObjectURL(file.preview)
    });
  }, [files]);

  React.useEffect(()=>{
    if(propFile) setFiles([propFile])
  },[])

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: ACCEPT_MEDIA[mediaType],
    maxFiles: 1,
    maxSize: MAX_SIZE[mediaType],
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      setUploadFile(acceptedFiles[0]);
    }
  });

  const removeAll = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    setFiles([]);
    setUploadFile({});
  }

  const MediaPreviewImage = (file) => {
    return(
        <MediaPreview>
          <img src={file.preview || file.uri} />
          {!!file?.path && !!file.size &&
            <MediaPreviewSubtitle>
              {file.path} - {humanFileSize(file.size)}
              <MediaRemovePreviewButton onClick={removeAll} title='Remover arquivo'/>
            </MediaPreviewSubtitle>
          }
        </MediaPreview>
    )
  }

  React.useEffect(() => {
    if(errors.length) M.toast({html: errors})
  }, [errors])

  if(fileRejections.length) fileRejections.map(({errors: errs}) => {
    if(errs[0]){
      const message = TranslateErrorDropzone(errs[0], mediaType);
      if(message !== errors) setErrors(message)
    }
  })
  const MediaPreviewVideo = (file) => {
    if(file.preview.indexOf('.m3u8') > -1){
      return (
        <MediaPreview>
          <ReactHlsPlayer
            src={file.preview}
            autoPlay={false}
            controls={true}
            width="100%"
            height="100%"
          />
        </MediaPreview>
      )
    } else {
      return(
        <>
          <MediaPreview>
            <video controls>
              <source src={file.preview} type={"video/webm"} />
            </video>
          </MediaPreview>
          {!!file?.path && !!file.size &&
            <MediaPreviewSubtitle>
              {file.path} - {humanFileSize(file.size)}
              <MediaRemovePreviewButton onClick={removeAll} title='Remover arquivo' />
            </MediaPreviewSubtitle>
          }
        </>
      )
    }
  }

  const MediaPreviewAudio = (file) => {
    return(
      <>
        <MediaPreview>
          <audio controls>
            <source src={file.preview} type={"audio/mpeg"} />
          </audio>
        </MediaPreview>
      </>
    )
  }

  const MediaPreviewPdf = (file) => {
      return(
        <>
          <MediaPreview>
            <object data={file.preview} width="100%" height="100%"></object>
          </MediaPreview>
          {!!file?.path && !!file.size &&
            <MediaPreviewSubtitle>
              {file.path} - {humanFileSize(file.size)}
              <MediaRemovePreviewButton onClick={removeAll} title='Remover arquivo' />
            </MediaPreviewSubtitle>
          }
        </>
      )
  }

  const MEDIA_PREVIEW = {
    image: (file) => MediaPreviewImage(file),
    video: (file) => MediaPreviewVideo(file),
    audio: (file) => MediaPreviewAudio(file),
    attachment: (file) => MediaPreviewPdf(file),
  }
  
  const thumbs = React.useMemo(() => files.map((file, index) => (
    <div key={file?.path || index}>
      {MEDIA_PREVIEW[mediaType](file)}
      {!!children && <span>
        {UX_MESSAGE[mediaType]}
      </span>}
    </div>
  )), [files, mediaType, children]);

  const renderEmptyPreview = () => {
    if(children) return children;
    return(
      <MediaPreview className='empty-preview'>
        <i className={`fal fa-${ICON[mediaType]}`}></i>
        <span>
          {UX_MESSAGE[mediaType]}
        </span>
      </MediaPreview>
    )
  }
  
  return(
    <MediaGroupContainer>
      <MediaPreviewContainer {...getRootProps({isDragActive, isDragAccept, isDragReject})} className='media-preview'>
        <section>
            {files.length && (files[0]?.uri || files[0].path) ? thumbs : renderEmptyPreview()}
            <input {...getInputProps()} />
        </section>
      </MediaPreviewContainer>
    </MediaGroupContainer>
  );
}