import * as React from 'react';
import {MutationDeleteProgram} from '../../../services/program/MutationProgram';
import {Table} from './CourseCategoriesTableComponent.style';

export function CourseCategoriesTableComponent({categories = []}) : JSX.Element {
  const [loading, setLoading] = React.useState(false);
  
  const deleteCategory = (category) => {
    if(loading) return false;
    const confirmed = confirm(`Você tem certeza que quer excluir a categoria ${category.name}?`);
    if(confirmed){
      setLoading(true);
      MutationDeleteProgram(category)
      .then(({program}) => {
        document.location.reload(true);
      })
      .catch(err => {
        setLoading(false);
        console.log('error => ', err)
      });
    } else {
      setLoading(false);
    }
  }
  return(
    <Table>
      <thead>
        <tr className="center">
          <th>Código</th>
          <th>Nome</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        {categories.map(category => (
          <tr key={category.id}>
            <td><a href={`/${window.account}/admin/course-categories/${category.id}/edit`}>{category.code}</a></td>
            <td><a href={`/${window.account}/admin/course-categories/${category.id}/edit`}>{category.name}</a></td>
            <td>
              <a href={`/${window.account}/admin/course-categories/${category.id}/edit`}>editar</a>
              {/* <>
                {' | '}
                <a href="#" onClick={(event) => {event.preventDefault(); deleteCategory(category) }} className="red-text">apagar</a>
              </> */}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}