import {gql} from '@apollo/client';

export const MUTATION_FILIAL = gql`
  mutation createOrUpdateFilial(
    $id: ID,
    $name: String!,
    $code: String,
    $status: String,
    $filialType: String!,
    $visibility: String!,
    $street: String,
    $number: String,
    $complement: String,
    $neighborhood: String,
    $city: String,
    $state: String,
    $zipcode: String,
    $token: String
    $gymId: String,
    $passIntegration: String,
    $totalpassToken: String,
    $totalpassServiceProvider: String,
    $totalpassBearerToken: String,
    $totalpassPlanCode: String,
  ){
    createOrUpdateFilial(input: {
      id: $id,
      name: $name,
      code: $code,
      status: $status,
      filialType: $filialType,
      visibility: $visibility,
      street: $street,
      number: $number,
      complement: $complement,
      neighborhood: $neighborhood,
      city: $city,
      state: $state,
      zipcode: $zipcode,
      token: $token,
      gymId: $gymId,
      passIntegration: $passIntegration,
      totalpassToken: $totalpassToken,
      totalpassServiceProvider: $totalpassServiceProvider,
      totalpassBearerToken: $totalpassBearerToken,
      totalpassPlanCode: $totalpassPlanCode,
    }){
      filial{
        id
        name
        code
        status
        filialType
        visibility
        street
        number
        complement
        neighborhood
        city
        state
        zipcode
        token
        gymId
        passIntegration
        totalpassToken
        totalpassServiceProvider
      }
    }
  }
`;