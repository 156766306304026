import styled from 'styled-components';

export const ProfilePlanCardContainer = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
`;

export const ProfilePlanCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ProfilePlanCardTitle = styled.span<{color?: string}>`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  font-size: 1.66rem;
  font-size: 1.66rem;
  font-weight: 600;
  padding: 0 0.5rem;
  &::before {
    background-color: ${({color}) => color};
    border: 1px dashed #CCCCCC;
    border-radius: 3px;
    content: '';
    display: block;
    width: 20px;
  }
`;

export const ProfilePlanCardRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  strong {
    font-weight: 700;
  }
`;

export const PlanCardButton = styled.button`
  align-items: center;
  background-color: #DDDDDD;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
`;

export const ProfilePlanCardStatus = styled.span<{status: string}>`
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: ${({status}) => {
    switch (status) {
      case 'active':
        return '#00ff00AA';
      case 'canceled':
      case 'inactive':
      case 'closed':
        return '#ff0000AA';
      case 'draft':
        return '#DDDDDD';
      default:
        return '#ffff00AA';
    }
  }};
`;

export const ProfilePlanCardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ProfilePlanCardFooter = styled.div`
  align-items: center;
  border-top: 1px solid #e0e0e0;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  padding: 1rem 0;
`;

export const ProfilePlanCardPayment = styled.div`
  background-color: #EEEEEE;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
`;

export const ProfileCardBodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ProfileCardPreview = styled.div`
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  padding: 1rem;
`;