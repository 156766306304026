import React from 'react';

type SwitchesProps = {
  id: string,
  className?: string,
  offLabel: string,
  onLabel: string,
  disabled?:boolean,
  checked?:boolean,
  onChange: () => void
} 


const Switches = ({ id, offLabel, onLabel, ...props }: SwitchesProps ) => {
  return (
    <div className="switch">
      <label htmlFor={id}>
        {offLabel}
        <input id={id} type="checkbox" {...props} />
        <span className="lever" />
        {onLabel}
      </label>
    </div>
  );
};

export default Switches;
