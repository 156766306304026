import {DayWeekProps} from './Date.type';
import {UserProps} from './User.type';
import {WorkoutGroupProps} from './Workouts.type';
import {WorkoutProgramProps, WorkoutGroupRoutineProgramsSelected} from './WorkoutProgram.type';
import {TabTypes} from './Tabs.type';

export type WorkoutRoutineConfigProps = {
  daysWeek: Array<DayWeekProps>,
  listOfUsers: Array<UserProps>,
  listOfPrograms: Array<WorkoutProgramProps>,
  usersTarget?: UserProps[],
}

export type HandleSetRoutineProps = { (values) : void; }

export type ChangeTabProps = { (tabValue: TabTypes) : void }

export enum TargetProps { programs = 'programs', users = 'users' }

export enum CycleTypeProps { user = 'user', date = 'date' }

export type WorkoutRoutineStateProps = {
  id?: string;
  name?: string;
  startDate?: string;
  endDate?:string;
  cycleType?: 'user' | 'date';
  isDraft?: boolean;
  showOnlyOnScheduledDays?: boolean;
  target?: TargetProps;
  commentEnable?: boolean;
  programIds?: (string|number)[];
  programsSelected: WorkoutGroupRoutineProgramsSelected[];
  showDaysOfWeek?: boolean;
  usersTargetIds?: (string|number)[];
  usersTarget?: UserProps[];
  weekDayIds?: (string|number)[];
  workoutGroups: WorkoutGroupProps[];
}

export type WorkoutRoutineProps = {
  daysWeek: Array<DayWeekProps>;
  users: Array<UserProps>;
  path: 'new'| 'edit';
  programs: WorkoutProgramProps[],
  programsSelected: WorkoutGroupRoutineProgramsSelected[];
  usersTarget?: UserProps[],
  handleSetRoutine: HandleSetRoutineProps,
  workoutRoutineState: WorkoutRoutineStateProps,
  changeTab: ChangeTabProps,
  tabActive?: TabTypes,
  setComponentLoading: {(value:boolean) : void}
  setComponentProgress: {(value:number) : void}
}

export type WorkoutRoutineGroupProps = {
  handleSetRoutine: HandleSetRoutineProps,
  workoutRoutineState: WorkoutRoutineStateProps,
  changeTab: ChangeTabProps,
  tabActive?: TabTypes,
  setComponentLoading: {(value:boolean) : void}
  setComponentProgress: {(value:number) : void}
}