import {gql} from '@apollo/client';

export const PROGRAM_CLASS_TYPE = `
  id
  startTime
  endTime
  limit
  weekDays {
    id
    short
    value
    weekDayId
  }
`

export const MUTATION_PROGRAM_CLASS = gql`
  mutation createOrUpdateProgramClass(
    $id: ID,
    $programId: ID!,
    $startTime: String!,
    $endTime: String!,
    $userId: ID,
    $limit: String,
    $classDays: [ClassDayAttributes!]
  ){
    createOrUpdateProgramClass(input: {
      id: $id,
     programId: $programId,
     startTime: $startTime,
     endTime: $endTime,
     userId: $userId,
     limit: $limit,
     classDays: $classDays

    }){
      programClass {
        ${PROGRAM_CLASS_TYPE}
      }
    }
  }
`

export const MUTATION_DELETE_PROGRAM_CLASS = gql`
  mutation deleteProgramClass(
    $id: ID!,
  ){
    deleteProgramClass(input: { id: $id }){
      programClass {
        ${PROGRAM_CLASS_TYPE}
      }
    }
  }
`

export const QUERY_GET_PROGRAM_CLASS = gql`
  query getProgramsClass(
    $programId: ID!
  ) {
    programClasses(programId: $programId){
      id
      startTime
      endTime
      limit
    }
  }`;