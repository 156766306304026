import * as moment from 'moment';

const days = [];
const dateStart = moment();
const dateEnd = moment().subtract(7, 'days');
while (dateStart.diff(dateEnd, 'days') >= 0) {
  days.unshift(dateStart.format('DD/MM'))
  dateStart.subtract(1, 'days')
}

const defaultDatastes = {
    fill: true,
    lineTension: 0.2,
    backgroundColor: "#3cc0f066",
    borderColor: "#3cc0f0",
    borderDash: [],
    borderWidth: 0,
    borderDashOffset: 0.0,
    pointBackgroundColor: "#fff",
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: "rgba(33,150,243,0.4)",
    pointHoverBorderColor: "rgba(220,220,220,1)",
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    spanGaps: false,
}

export function GenerateChartJsToUserPlans({lastDaysUserPlans = [] }: {lastDaysUserPlans: any[]}){
  const lastDaysUserPlansTotals: number[] = [];
  if(lastDaysUserPlans){
    days.map(day => {
      const lastDaysUserPlansFiltered = lastDaysUserPlans.filter(userPlan => {
        const date = moment(userPlan.startDate, 'YYYY-MM-DD HH:mm:ss Z');
        return date.isValid() && date.format('DD/MM') === day;
      });

      lastDaysUserPlansTotals.push(lastDaysUserPlansFiltered.length);
    })
  }
  const dataObject = {
    labels: days,
    datasets: [
      {
        ...defaultDatastes,
        label: "# Novos planos",
        backgroundColor: "#3cc0f066",
        borderColor: "#3cc0f0",
        pointHoverBackgroundColor: "rgba(0,169,255,0.4)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        data: lastDaysUserPlansTotals,
      },
    ]
  }
  return dataObject;
}