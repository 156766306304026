import * as React from 'react';

import {Select, TextInput} from 'react-materialize';

import {AccountPlanProvider, AccountPlanContext} from '../../../context/alderaan/AccountPlanContext';
import {AccountPlanFormElement, SelectContainer} from './AccountPlanFormContainer.style';

const AccountPlanFormComponent = () : JSX.Element => {
  document.title = `Administração > Planos > Plano`;

  const {plan, errors, updateAccountPlanFormEvent, scope} = React.useContext(AccountPlanContext)
  const {
    name, 
    code, 
    recurrence, 
    recurrenceQuantity, 
    planType, 
    planTypeValue, 
    status, 
    maxInstallments, 
    accountType, 
    value, 
    billingTriggerType,
    billingTriggerDay,
    cycles,
  } = plan
  return(
    <div className="teste">
      <div className="row">
        <div className="col s12">
          <h2>Plano {name}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <div className="row">
            <TextInput 
              s={12} 
              m={6}
              id="name" 
              name="name"
              value={name || ''} 
              label="Nome" 
              onChange={updateAccountPlanFormEvent}
              error={errors.name}
              inputClassName={errors?.name ? 'invalid' : ' '} 
            />
            <TextInput 
              s={12} 
              m={6}
              id="code" 
              name="code"
              value={code || ''} 
              error={errors.code} 
              label="Código do plano" 
              onChange={updateAccountPlanFormEvent}
            />
          </div>
          <div className="row">
            <div className="col s6">
              <SelectContainer>
                <Select
                  id="recurrence"
                  name="recurrence"
                  onChange={updateAccountPlanFormEvent}
                  value={recurrence || ''}
                  label="Qual o tipo de recorrência?"
                  s={12}
                >
                  <option value="">Selecione o tipo</option>
                  <option value="daily">Dia</option>
                  <option value="weekly">Semana</option>
                  <option value="monthly">Mês</option>
                </Select>
              </SelectContainer>
            </div>

            <TextInput 
              s={6}
              id="recurrenceQuantity" 
              name="recurrenceQuantity"
              value={recurrenceQuantity || ''} 
              error={errors.recurrenceQuantity} 
              label="Quantidade de recorrências" 
              onChange={updateAccountPlanFormEvent}
            />

            <div className="col s12 m6">
              <SelectContainer>
                <Select
                  id="planType"
                  name="planType"
                  onChange={updateAccountPlanFormEvent}
                  value={planType || ''}
                  label="Tipo de plano"
                  s={12}
                >
                  <option value="">Selecione o tipo</option>
                  <option value="revenue_payment_online">Receita Online</option>
                  <option value="users">Usuários</option>
                  <option value="filials">Filiais</option>
                </Select>
              </SelectContainer>
            </div>

            <TextInput 
              s={12}
              m={6}
              id="planTypeValue" 
              name="planTypeValue"
              value={planTypeValue || ''} 
              error={errors.planTypeValue} 
              label="Quantidade" 
              onChange={updateAccountPlanFormEvent}
            />

            <TextInput 
              s={12}
              m={6}
              id="value" 
              name="value"
              value={value || ''} 
              error={errors.value} 
              label="Valor" 
              onChange={updateAccountPlanFormEvent}
            />

            <TextInput 
              s={12}
              m={6}
              id="maxInstallments" 
              name="maxInstallments"
              value={maxInstallments || ''} 
              error={errors.maxInstallments} 
              label="Máx. de Parcelas" 
              onChange={updateAccountPlanFormEvent}
            />
          </div>

          <div className="row">
            <div className="col s12 m6">
              <SelectContainer>
                <Select
                  id="billingTriggerType"
                  name="billingTriggerType"
                  onChange={updateAccountPlanFormEvent}
                  value={billingTriggerType || ''}
                  label="Tipo de Cobrança"
                  error={errors.billingTriggerType}
                  validate={!errors?.billingTriggerType} 
                  s={12}
                >
                  <option value="">Selecione o período</option>
                  <option value="beginning_of_period">Início do Período</option>
                  <option value="end_of_period">Final do Período</option>
                  <option value="day_of_month">Dia do Mês</option>
                </Select>
              </SelectContainer>
            </div>

            {billingTriggerType == 'day_of_month' && (<TextInput 
              s={6}
              m={3}
              id="billingTriggerDay" 
              name="billingTriggerDay"
              value={billingTriggerDay || ''} 
              error={errors.billingTriggerDay} 
              label="Dia do mês" 
              onChange={updateAccountPlanFormEvent}
            />)}
            
            <TextInput 
              s={6}
              m={3}
              id="cycles" 
              name="cycles"
              value={cycles || ''} 
              error={errors.cycles} 
              label="Ciclos" 
              helpText='0 para ciclos sem fim'
              onChange={updateAccountPlanFormEvent}
            />
          </div>

          <div className="row">
            <div className="col s12">
              <SelectContainer>
                <Select
                  id="accountType"
                  name="accountType"
                  onChange={updateAccountPlanFormEvent}
                  value={accountType || ''}
                  label="Qual o tipo da conta?"
                >
                  <option value="">Selecione o tipo da conta</option>
                  <option value="personal">Personal</option>
                  <option value="gym">Gym</option>
                </Select>
              </SelectContainer>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

const AccountPlanForm = (props) => {
  const {loading, createOrEditAccountPlan} = React.useContext(AccountPlanContext);

  function setFormAction(){
    const {plan, scope, planPathState: path} = props;
    if (scope === 'alderaan' && path === 'new'){
      return `/alderaan/plans`
    } else if (scope === 'alderaan' && path === 'edit'){
      return `/alderaan/plans/${plan.id}/`
    }
  }
  return(
    <AccountPlanFormElement method="POST" action={setFormAction()} onSubmit={createOrEditAccountPlan}>
        <AccountPlanFormComponent {...props} />
        <div className="col right"><button type="submit" onSubmit={createOrEditAccountPlan} disabled={loading} className="btn btn-primary">Atualizar</button></div>
    </AccountPlanFormElement>
  )
}

export function AccountPlanFormContainer(props) : JSX.Element {
  return(
    <AccountPlanProvider {...props}>
      <AccountPlanForm {...props} />
    </AccountPlanProvider>
  )
}