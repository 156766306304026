import * as React from 'react';
import {WorkoutRoutineProps, WorkoutRoutineStateProps} from '../types';
import {MutateWorkoutRoutine, MutateWorkoutGroupByRoutine, QueryGetExercises} from '../../services';
import {arrayMoveImmutable} from 'array-move';

interface WorkoutRoutineContextProps {
  workoutRoutine: WorkoutRoutineStateProps;
  setWorkoutRoutine: (workoutRountine: WorkoutRoutineStateProps) => void;
  createOrUpdateWorkoutGroupRoutine: (callback?: () => void) => Promise;
  createWorkoutGroup: (autofocus?: boolean) => void;
  updateWorkoutGroup: (workoutGroup, index?) => void;
  deleteWorkoutGroup: (workoutGroup) => void;
  createWorkout: (workoutGroup) => void;
  updateWorkout: (workout, indexWorkout, workoutGroup) => void;
  deleteWorkout: (workout, workoutGroup) => void;
  createWorkoutExerciseBySelected: (selected, workout, workoutGroup) => void;
  updateWorkoutExercise: (workoutExercise, indexExerciseWorkout, workout, workoutGroup) => void;
  deleteWorkoutExercise: (exerciseWorkout, workout, workoutGroup) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  progress: number;
  setProgress: (value: number) => void;
  errors: object;
  setErrors: (errors: object) => void;
  workoutRoutinePath: 'new' | 'edit';
}

export const WorkoutRoutineContext = React.createContext({} as WorkoutRoutineContextProps);

interface WorkoutRoutineProviderProps {
  children: React.ReactNode;
  workoutRoutineState?: WorkoutRoutineStateProps;
  workoutRoutinePathState?: 'new' | 'edit';
}

export function WorkoutRoutineProvider({children, workoutRoutineState, workoutRoutinePathState} : WorkoutRoutineProviderProps){
  const [loading, setLoading] = React.useState<boolean>(false);
  const [progress,setProgress] = React.useState<number>(0);
  const [workoutRoutine, setWorkoutRoutine] = React.useState({...workoutRoutineState} as WorkoutRoutineStateProps);
  const [errors, setErrors] = React.useState({});
  const [workoutRoutinePath, setWorkoutRoutinePath] = React.useState(workoutRoutinePathState || 'new');
  const [workouts, setWorkouts] = React.useState([]);

  function createOrUpdateWorkoutGroupRoutine(callback): void{
    setLoading(true);
    MutateWorkoutRoutine(workoutRoutine, setLoading, setProgress)
    .then(async ({workoutGroupRoutine}) => {
      setErrors({})
      if(workoutRoutinePath === 'new'){
        window.history.pushState("object or string", null, `/${window.account}/admin/workout-routines/${workoutGroupRoutine.id}/edit`);
        setWorkoutRoutinePath('edit');
      }
      setWorkoutRoutine(workoutGroupRoutine);
      if((workoutRoutine.workoutGroups||[]).length){
        const newWorkoutGroups = [];
        for( const workoutGroup of workoutRoutine.workoutGroups){
          await createOrUpdateWorkoutGroupByRoutine(workoutGroup)
          .then(async (newWorkoutGroup) => {
            const workoutGroupUpdated = {...workoutGroup, ...newWorkoutGroup};
            if(!workoutGroup._destroy) newWorkoutGroups.push(workoutGroupUpdated);
          });
        }
        const newWorkoutRoutine = {...workoutGroupRoutine, workoutGroups: newWorkoutGroups};
        setWorkoutRoutine(newWorkoutRoutine);
      }
      if(callback) callback();
    })
    .catch((err) => {
      setErrors(err);
      setLoading(false);
      console.log('error ', err);
    });
  }

  function createOrUpdateWorkoutGroupByRoutine(workoutGroup) : Promise {
    return new Promise((resolve, reject) => {
      setLoading(true);
      MutateWorkoutGroupByRoutine(workoutRoutine, workoutGroup, setLoading, setProgress)
      .then(({workoutGroup}) => {
        resolve(workoutGroup);
      })
      .catch((err) => {
        reject(err);
      })
    })
  }

  function updateWorkoutGroupPosition(direction, workoutGroup, currentIndex){
    switch(direction){
      case 'up':
        if(currentIndex > 0){
          const newWorkoutGroups = arrayMoveImmutable(workoutRoutine.workoutGroups, currentIndex, currentIndex - 1);
          const newWorkoutRoutine = {...workoutRoutine, workoutGroups: newWorkoutGroups};
          setWorkoutRoutine(newWorkoutRoutine);
        }
      break;
      case 'down':
          if(currentIndex < workoutRoutine.workoutGroups.length - 1){
            const newWorkoutGroups = arrayMoveImmutable(workoutRoutine.workoutGroups, currentIndex, currentIndex + 1);
            const newWorkoutRoutine = {...workoutRoutine, workoutGroups: newWorkoutGroups};
            setWorkoutRoutine(newWorkoutRoutine);
          }
      break;
    }
  }

  function updateWorkoutpPosition(direction, workoutGroup, currentIndex){
    switch(direction){
      case 'up':
        if(workoutGroup?.workouts && currentIndex > 0){
          if(currentIndex > 0){
            const mewWorkouts = arrayMoveImmutable(workoutGroup?.workouts, currentIndex, currentIndex - 1);
            const newWorkoutGroup = {...workoutGroup, workouts: mewWorkouts};
            updateWorkoutGroup(newWorkoutGroup);
          }
        }
      break;
      case 'down':
        if(workoutGroup?.workouts && currentIndex < workoutGroup?.workouts.length - 1){
          const mewWorkouts = arrayMoveImmutable(workoutGroup?.workouts, currentIndex, currentIndex + 1);
          const newWorkoutGroup = {...workoutGroup, workouts: mewWorkouts};
          updateWorkoutGroup(newWorkoutGroup);
        }
      break;
    }
  }

  function updateExerciseWorkoutPosition(direction, workout, currentIndex, workoutGroup, workoutIndex){
    console.log('updateExerciseWorkoutPosition ', direction, currentIndex, workout);
    switch(direction){
      case 'up':
        if(workout?.exerciseWorkouts && currentIndex > 0){
            const mewExerciseWorkouts = arrayMoveImmutable(workout?.exerciseWorkouts, currentIndex, currentIndex - 1);
            const newWorkout = {...workout, exerciseWorkouts: mewExerciseWorkouts};
            updateWorkout(newWorkout, workoutIndex, workoutGroup);
        }
      break;
      case 'down':
        if(workout?.exerciseWorkouts && currentIndex < workout?.exerciseWorkouts.length - 1){
          const mewExerciseWorkouts = arrayMoveImmutable(workout?.exerciseWorkouts, currentIndex, currentIndex + 1);
          const newWorkout = {...workout, exerciseWorkouts: mewExerciseWorkouts};
          updateWorkout(newWorkout, workoutIndex, workoutGroup);
        }
      break;
    }
  }

  const onWorkoutSortEnd = ({workoutGroup, oldIndex, newIndex}) => {
    const newWorkout = arrayMoveImmutable(workoutGroup.workouts, oldIndex, newIndex);
    const newWorkoutGroup = {...workoutGroup, workouts: newWorkout};
    updateWorkoutGroup(newWorkoutGroup);
  }
  function createWorkoutGroup(autoFocus = true){
    let workoutRoutineUpdated = {...workoutRoutine};

    const newWorkoutGroup = {
      id: '',
      tempId: new Date().getTime(),
      name: '',
      description: '',
      workouts: [
        {
          id: '',
          tempId: new Date().getTime(),
          name: '',
          checkinEnabled: false,
          description: '',
        }
      ]
    };
    if(workoutRoutineUpdated.workoutGroups?.length){
      workoutRoutineUpdated.workoutGroups = [...workoutRoutineUpdated.workoutGroups, newWorkoutGroup];
    } else {
      workoutRoutineUpdated.workoutGroups = [newWorkoutGroup]
    }
    setWorkoutRoutine(workoutRoutineUpdated)

    if (autoFocus) {
      setTimeout(() => {
        document.getElementById(`workoutGroup-${workoutRoutineUpdated.workoutGroups.length -1}`).focus();
      }, 100);
    }
  };

  function updateWorkoutGroup(workoutGroup){
    const workoutGroupIndex = workoutRoutine.workoutGroups.map((wg) => wg.tempId||wg.id).indexOf(workoutGroup.tempId||workoutGroup.id);
    const workoutGroupsUpdated = [...workoutRoutine.workoutGroups];
    workoutGroupsUpdated[workoutGroupIndex] = workoutGroup;
    setWorkoutRoutine({...workoutRoutine, workoutGroups: workoutGroupsUpdated})
  }

  function deleteWorkoutGroup(workoutGroup){
    let newWorkoutGroup = {...workoutGroup};
    const index = workoutRoutine.workoutGroups.findIndex( x => x === workoutGroup);
    if (workoutGroup?.id) {
      newWorkoutGroup = {...newWorkoutGroup, '_destroy': true}
      const workoutGroupsUpdated = [...workoutRoutine.workoutGroups];
      workoutGroupsUpdated[index] = newWorkoutGroup;
      setWorkoutRoutine({...workoutRoutine, workoutGroups: workoutGroupsUpdated})
    } else {
      const workoutGroupsUpdated = [...workoutRoutine.workoutGroups];
      workoutGroupsUpdated.splice(index, 1);
      setWorkoutRoutine({...workoutRoutine, workoutGroups: workoutGroupsUpdated})
    }
  }

  function createWorkout(workoutGroup){
    const indexWorkoutGroup = workoutRoutine.workoutGroups.findIndex( x => x === workoutGroup);
    let workoutRoutineUpdated = {...workoutRoutine};

    const newWorkout = {
      id: '',
      tempId: new Date().getTime(),
      name: '',
      checkinEnabled: false,
      description: '',
    };

    workoutRoutineUpdated.workoutGroups[indexWorkoutGroup].workouts 
      ? (workoutRoutineUpdated.workoutGroups[indexWorkoutGroup].workouts = [...workoutRoutineUpdated.workoutGroups[indexWorkoutGroup].workouts, newWorkout] )
      : (workoutRoutineUpdated.workoutGroups[indexWorkoutGroup].workouts = [newWorkout])
    
    setWorkoutRoutine(workoutRoutineUpdated);
    
    setTimeout(() => {
      document
        .getElementById(
          `workoutGroup-${indexWorkoutGroup}-workout-${
            (workoutGroup.workouts || []).length - 1
          }`,
        )
        .focus();
    }, 100);
  };
  

  function updateWorkout(workout, indexWorkout, workoutGroup){
    const indexWorkoutGroup = workoutRoutine.workoutGroups.findIndex( x => x.id === workoutGroup.id);
    let workoutRoutineUpdated = {...workoutRoutine};
    const originalWorkout = workoutRoutine.workoutGroups[indexWorkoutGroup]?.workouts?.filter((w) => !w['_destroy'])[indexWorkout];
    const originalWorkoutGroup = workoutRoutine.workoutGroups[indexWorkoutGroup]
    const indexOriginalWorkout = workoutRoutine.workoutGroups[indexWorkoutGroup]?.workouts?.findIndex( x => x === originalWorkout);
    if(originalWorkoutGroup?.workouts)
      originalWorkoutGroup.workouts[indexOriginalWorkout] = workout;
    updateWorkoutGroup(originalWorkoutGroup);
    // workoutRoutineUpdated.workoutGroups[indexWorkoutGroup].workouts[indexOriginalWorkout] = workout;
    
    // setWorkoutRoutine(workoutRoutineUpdated);
  }

  function deleteWorkout(workout, workoutGroup){
    const indexWorkoutGroup = workoutRoutine.workoutGroups.findIndex( x => x === workoutGroup);
    const indexWorkout = workoutRoutine.workoutGroups[indexWorkoutGroup].workouts.findIndex( x => x === workout);
    let workoutRoutineUpdated = {...workoutRoutine};
    if (workout?.id) {
      workoutRoutineUpdated.workoutGroups[indexWorkoutGroup].workouts[indexWorkout] = {...workoutRoutineUpdated.workoutGroups[indexWorkoutGroup].workouts[indexWorkout], '_destroy': true}
    } else {
      workoutRoutineUpdated.workoutGroups[indexWorkoutGroup].workouts.splice(indexWorkout, 1);
    }
    setWorkoutRoutine(workoutRoutineUpdated);
  }

  function createWorkoutExerciseBySelected(selected = {}, workout, workoutGroup){
    const indexWorkoutGroup = workoutRoutine.workoutGroups.findIndex( x => x === workoutGroup);
    const indexWorkout = workoutRoutine.workoutGroups[indexWorkoutGroup].workouts.findIndex( x => x === workout);

    let workoutRoutineUpdated = {...workoutRoutine};

    const {value} = selected;

    const newExerciseWorkout = {
      id: '',
      tempId: new Date().getTime(),
      exerciseId: value || 'Selecione/Pesquise um exercício',
      exercise: selected,
    };

    const newExerciseWorkouts = [
      ...(workout.exerciseWorkouts || []),
      newExerciseWorkout,
    ];

    const newWorkout = {
      ...workout,
      exerciseWorkouts: newExerciseWorkouts,
    };

    workoutRoutineUpdated.workoutGroups[indexWorkoutGroup].workouts[indexWorkout] = newWorkout;
    setWorkoutRoutine(workoutRoutineUpdated);
  }

  function updateWorkoutExercise(workoutExercise, indexExerciseWorkout, workout, workoutGroup){
    const indexWorkoutGroup = workoutRoutine.workoutGroups.findIndex( x => x === workoutGroup);
    const indexWorkout = workoutRoutine.workoutGroups[indexWorkoutGroup].workouts.findIndex( x => x === workout);
    const originalExerciseWorkout = workoutRoutine.workoutGroups[indexWorkoutGroup].workouts[indexWorkout].exerciseWorkouts.filter((e) => !e['_destroy'])[indexExerciseWorkout];
    const indexGlobalExericseWorkout = workoutRoutine.workoutGroups[indexWorkoutGroup].workouts[indexWorkout].exerciseWorkouts.findIndex( x => x === originalExerciseWorkout);

    let workoutRoutineUpdated = {...workoutRoutine};
    workoutRoutineUpdated.workoutGroups[indexWorkoutGroup].workouts[indexWorkout].exerciseWorkouts[indexGlobalExericseWorkout] = workoutExercise;

    setWorkoutRoutine(workoutRoutineUpdated)
  }

  function deleteWorkoutExercise(exerciseWorkout, workout, workoutGroup){
    const indexWorkoutGroup = workoutRoutine.workoutGroups.findIndex( x => x === workoutGroup);
    const indexWorkout = workoutRoutine.workoutGroups[indexWorkoutGroup].workouts.findIndex( x => x === workout);
    const indexExerciseWorkout = workoutRoutine.workoutGroups[indexWorkoutGroup].workouts[indexWorkout].exerciseWorkouts.findIndex( x => x === exerciseWorkout);


    let workoutRoutineUpdated = {...workoutRoutine};
    if (exerciseWorkout?.id) {
      workoutRoutineUpdated.workoutGroups[indexWorkoutGroup].workouts[indexWorkout].exerciseWorkouts[indexExerciseWorkout] = {...workoutRoutineUpdated.workoutGroups[indexWorkoutGroup].workouts[indexWorkout].exerciseWorkouts[indexExerciseWorkout], '_destroy': true}
    } else {
      workoutRoutineUpdated.workoutGroups[indexWorkoutGroup].workouts[indexWorkout].exerciseWorkouts.splice(indexExerciseWorkout, 1);
    }
    setWorkoutRoutine(workoutRoutineUpdated);  
  }

  return (
    <WorkoutRoutineContext.Provider
      value={{
        workoutRoutine,
        setWorkoutRoutine,
        createOrUpdateWorkoutGroupRoutine,
        createWorkoutGroup,
        updateWorkoutGroup,
        deleteWorkoutGroup,
        createWorkout,
        updateWorkout,
        deleteWorkout,
        createWorkoutExerciseBySelected,
        updateWorkoutExercise,
        deleteWorkoutExercise,
        loading,
        setLoading,
        progress,
        setProgress,
        errors,
        setErrors,
        workoutRoutinePath,
        onWorkoutSortEnd,
        updateWorkoutGroupPosition,
        updateWorkoutpPosition,
        updateExerciseWorkoutPosition
      }}>
      {children}
    </WorkoutRoutineContext.Provider>
  );
}

export const WorkoutRoutineGlobalContext = (ChildComponent) => (props) => (
  <WorkoutRoutineContext.Consumer>
    {(context) => <ChildComponent {...props} global={context} />}
  </WorkoutRoutineContext.Consumer>
);