import React from 'react';


const Checkbox = ({
  id,
  className,
  indeterminate,
  label,
  onChange,
  ...props
}) => {

  const [checked, setChecked] = React.useState(props.checked);
  const _input = React.useRef(null);

  React.useEffect(() => {
    setChecked(props.checked);
    _input.current.checked = props.checked;
  }, [props.checked]);


  return (
    <p>
      <label>
        <input 
          type="checkbox"
          ref={_input}
          checked={checked}
          onChange={e => {
            setChecked(prevChecked => !prevChecked);
            onChange && onChange(e);
          }}
         />
        <span>{label}</span>
      </label>
    </p>
  );
}

export default Checkbox;
