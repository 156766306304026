import styled from 'styled-components';
import {DIMENSION} from '../../constants';

export const TipContainer = styled.div`
  align-items: center;
  border-radius: 5px;
  background-color: ${ ({bgcolor}) => bgcolor ? bgcolor : '#ADD0FF99'};
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  color: ${ ({color}) => color ? color : '#252D33'};
  display: flex;
  font-size: 1.33em;
  font-weight: 300;
  transition: box-shadow .25s;
  margin: 0.5rem 0 1rem 0;
  img{
    ${({imgPosition}) => imgPosition === 'right' ? 'order: 2;' : ''}
    height: 100px;
  }
  @media screen and (max-width: 768px){
    font-size: 1em;
  }
`;

export const TipContent = styled.div`
  margin-left: ${DIMENSION.MARGIN_DEFAULT}px;
  padding: ${DIMENSION.MARGIN_DEFAULT}px;
`;