import * as React from "react";
import {Textarea} from 'react-materialize';
import {SignUpAuthComponent} from '../../../components/auth/SignUpAuthComponent';
import {SignInAuthComponent} from '../../../components/auth/SignInAuthComponent';
import { LoggedInComponent } from "../../../components/auth/LoggedInComponent";

import {
  ReviewHeaderElement,
  ReviewFormContainer,
  ReviewableCoverElement,
  ReviewFormTitle,
  RatingListElement, 
  RatingItemElement,
  ReviewMessageElement,
} from './ReviewForm.style';
import { AuthContext, AuthProvider } from "../../../context/Auth.context";
import { ReviewContext, ReviewProvider } from "../../../context/Review.context";

const ReviewForm = (props: {}) : JSX.Element => {
  return(
    <AuthProvider {...props}>
      <ReviewProvider {...props}>
        <div className="container">
          <div className="row">
            <div className="col s12">
              <HeaderComponent {...props} />
              <FormReviewComponent {...props} />
            </div>
          </div>
        </div>
      </ReviewProvider>
    </AuthProvider>
  )
}

const HeaderComponent = () => {
  const {account} = React.useContext(AuthContext);
  // if(reviewableType !== 'account'){}
  return(<ReviewHeaderElement href={`/${window.account}/reviews`} className="account-header">
      <img src={`${account.avatar.uri}`} alt={`${account.name}`} className='account-header-avatar' />
      <span className="account-header-name">{account.code}</span>
  </ReviewHeaderElement>)
}

const FormReviewComponent = (props) => {
  const {currentUser} = React.useContext(AuthContext);
  const {
    reviewable, 
    reviewableType, 
    rating, 
    setRating, 
    comment, 
    setComment, 
    handleSubmit,
    loading,
    errors,
  } = React.useContext(ReviewContext);
  // stars
  function renderRatingStars(){
    const textHelp = (item) => {
      if(item == 1) {
        return `Ruim`
      } else if (item == 5){
        return `Excelente`
      } else {
        return `\u00A0`
      }
    }
    return [1,2,3,4,5].map((item) => (
    <RatingItemElement key={item} onClick={() => {setRating(item)}} item={item} rating={rating}>
      <span className={`rating-star ${item <= rating ? 'hidden' : ''}`}>
        <i className={`fa-light fa-star fa-3x`}></i>
      </span>
      <span className={`rating-star ${item <= rating ? '' : 'hidden'}`}>
        <i className={`fa-solid fa-star fa-3x`}></i>
      </span>
      <span className="rating-text-help">{textHelp(item)}</span>
    </RatingItemElement>))
  }

  const renderReviewableTitle = () => {
    let pretext = ''
    switch(reviewableType){
      case 'workout_group':
        pretext = 'o treino '
        break;
      case 'course':
        pretext = 'o info produto '
        break;
      case 'program_class':
        pretext = 'a aula às '
        break;
    }
    return `${pretext}${reviewable.title}`
  }
  return(
    <div>
      <div className="card">
        <div className="card-content">
          <ReviewFormContainer>
            <ReviewableCoverElement>
              <img src={reviewable.cover.uri} alt={reviewable.title} />
            </ReviewableCoverElement>
            <ReviewFormTitle>Como foi sua experência com {renderReviewableTitle()}?</ReviewFormTitle>
            <RatingListElement>
              {renderRatingStars()}
            </RatingListElement>
            <ReviewMessageElement>
              <Textarea 
                s={12} 
                value={comment} 
                data-length={140}
                onChange={ ({target: {value}}) => {value.length < 140 ? setComment(value) : null } } 
                placeholder="A minha experiência foi... "
                error={errors.comment}
                className={errors?.comment ? 'invalid' : ' '}
                onSubmit={handleSubmit}
              />
            </ReviewMessageElement>
          </ReviewFormContainer>
        </div>
      </div>
      <AuthComponent />
      {!!currentUser?.name && <div className="col s12 center"><button type="submit" onClick={handleSubmit} onSubmit={handleSubmit} disabled={loading} className="btn btn-primary">Enviar</button></div>}
    </div>
  )

}

const AuthComponent = () => {
  const {account, currentUser, authType} = React.useContext(AuthContext);
  return(
    <div>
      {!!currentUser?.name ? <LoggedInComponent currentUser={currentUser} /> : <AuthSwitchComponent authType={authType} account={account} />}
    </div>
  )
}

const AuthSwitchComponent = () => {
  const {
    account, 
    authType, 
    AuthFormSubmit, 
    loading,
    authType: componentActivated,
    setAuthType: setComponentActivated,
  } = React.useContext(AuthContext);

  return(componentActivated == 'signup' ? (<SignUpAuthComponent 
                                              componentActivated={componentActivated} 
                                              setComponentActivated={setComponentActivated} 
                                              handleSubmit={AuthFormSubmit}
                                              loading={loading}
                                              account={account}
                                            />) : (
                                            <SignInAuthComponent 
                                              componentActivated={componentActivated} 
                                              setComponentActivated={setComponentActivated} 
                                              account={account} 
                                              handleSubmit={AuthFormSubmit} 
                                              loading={loading}
                                            />))
}

export default ReviewForm;