import styled from "styled-components";

export const FormWrapper = styled.form`
  background-color: #fff;
  display: flex;
  flex: 1;
  gap: 2em;
  .input-field > label {
    left: 10px;
    transform: translateY(-14px) scale(0.8);
  }
  width: 100%;
  .form-inputs{
  }
  & select {
    display: none;
  }
  .input-row {
    
  }
  label {
    font-weight: 400 !important;
  }
  .margin-bottom-clear {
    margin-bottom: 0px !important;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    max-width: 500px;
  }
`;

export const View = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 3em auto;
  padding: 1.5em;
  width: 90%;
  max-width: 900px;
  .btn-primary {
    align-items: center;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    padding: 25px 50px;
    width: 100%;
  }
`;

export const AvatarWrapper = styled.div`
  border-color: #DDDDDD;
  border-radius: 1.5em;
  border-style: dashed;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5em;
  padding: 1.5em;
  max-width: 400px;
  `;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;