import * as React from "react";
import { Select, TextInput, Textarea } from "react-materialize";
import { UploadMediaFile } from "../media";
import { TranslateMediaValueToFileProps } from "../../../utils";
import { AccountSettingsContext } from "../../context/AccountSettingsContext";
import { SelectContainer } from "./AccountContainer.style";
import InputMask from 'react-input-mask';
import {ConsultCEP} from '../../../services/Address.service';
import { Controller, useFormContext } from "react-hook-form";
import { InputStyled, SelectStyled } from "../../elements/forms/InputContainer";

const billingTypeValues = [
  {value: 'individual', label: 'Pessoa Física'},
  {value: 'company', label: 'Pessoa Jurídica'}
]

export const AccountBillingComponent = (): JSX.Element => {
  const {scope, STATES} = React.useContext(AccountSettingsContext);
  const [documentLabel, setDocumentLabel] = React.useState('CPF/CNPJ');

  const {
    control, 
    setValue, 
    getValues, 
    watch,
    formState: {errors}
  } = useFormContext();

  const zipcodeValue = watch('accountBilling.zipcode');
  const updateAddressFromZipcode = () => {
    if(zipcodeValue && zipcodeValue.length === 9){
      ConsultCEP(zipcodeValue).then((response) => {
        setValue('accountBilling.neighborhood', response.neighborhood);
        setValue('accountBilling.addressLine1', response.street);
        setValue('accountBilling.city', response.city);
        setValue('accountBilling.state', response.state);
      });
    }
  }
  const billingTypeValue = watch('accountBilling.billingType');
  React.useEffect(() => {
    if(billingTypeValue === 'company'){
      setDocumentLabel('CNPJ');
    } else if(billingTypeValue === 'individual'){
      setDocumentLabel('CPF');
    }
  }, [billingTypeValue]);
  
  return (
    <>
      <div className="row">
        <div className="col s12">
          <h2>Faturamento</h2>
        </div>
      </div>
      <div className="row">
        <div className="col s6">
          <Controller
            name="accountBilling.billingType"
            control={control}
            render={({field}) => (
              <SelectContainer className="row">
                <SelectStyled
                  {...field}
                  onChange={(billingType) => {
                    field.onChange(billingType.value);
                  }}
                  value={billingTypeValues.find(billingType => billingType.value === field.value)}
                  options={billingTypeValues}
                  label="Tipo de Faturamento"
                  placeholder="Selecione um tipo"
                  disabled={scope != 'alderaan' && !!getValues('id')}
                />                
              </SelectContainer>
            )}
          />
        </div>
        <div className="col s6">
          <div className="input-field">
            <Controller
              name="accountBilling.documentId"
              control={control}
              render={({field}) => (
                <InputMask
                  {...field}
                  type="tel"
                  mask={billingTypeValue === 'company' ? '99.999.999/9999-99' : '999.999.999-99'}
                  placeholder={documentLabel}
                  disabled={scope != 'alderaan' && !!getValues('id')}
                />
              )}
            />
            <label>{documentLabel}</label>
          </div>
        </div>
      </div>
      
      <div className="row">
        <div className="col s6 input-field">
          <Controller
            name="accountBilling.phone"
            control={control}
            render={({field}) => (
              <InputMask
                {...field}
                type="tel"
                mask={'(99) 99999-9999'}
                placeholder="Telefone de Suporte"
              />
            )}
          />
          <label>Telefone de Suporte</label>
        </div>  
        <div className="col s6">
          <Controller
            name="accountBilling.email"
            control={control}
            render={({field}) => (
              <div className="row">
                <InputStyled
                  {...field}
                  s={12}
                  error={errors?.email?.message as any}
                  label="E-mail de Suporte"
                  inputClassName={errors?.email ? "invalid" : " "}
                />
              </div>
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <h3>Endereço da Fatura</h3>
        </div>
      </div>
      <div className="row">
        <div className="col s6">
          <div className="input-field">
            <Controller
              name="accountBilling.zipcode"
              control={control}
              render={({field}) => (
                <InputMask
                  {...field}
                  type="tel"
                  mask="99999-999"
                  onBlur={updateAddressFromZipcode}
                  placeholder="CEP"
                />
              )}
            />
            <label>CEP</label>
          </div>
        </div>
      </div>
      <div className="row">
        <Controller
          name="accountBilling.addressLine1"
          control={control}
          render={({field}) => (
            <InputStyled
              {...field}
              s={12}
              error={errors?.addressLine1?.message}
              label="Endereço"
              inputClassName={errors?.addressLine1 ? "invalid" : " "}
            />
          )}
        />
        <Controller
          name="accountBilling.addressLine2"
          control={control}
          render={({field}) => (
            <InputStyled
              {...field}
              s={6}
              error={errors?.addressLine2?.message}
              label="Complemento"
              placeholder="Complemento"
              inputClassName={errors?.addressLine2 ? "invalid" : " "}
            />
          )}
        />
        <Controller
          name="accountBilling.neighborhood"
          control={control}
          render={({field}) => (
            <InputStyled
              {...field}
              s={6}
              error={errors?.neighborhood?.message}
              label="Bairro"
              inputClassName={errors?.neighborhood ? "invalid" : " "}
            />
          )}
        />
        <Controller
          name="accountBilling.city"
          control={control}
          render={({field}) => (
            <InputStyled
              {...field}
              s={8}
              m={10}
              error={errors?.city?.message}
              label="Cidade"
              inputClassName={errors?.city ? "invalid" : " "}
            />
          )}
        />
        <div className="col s4 m2">
            <Controller
              name="accountBilling.state"
              control={control}
              render={({field}) => (
                <SelectContainer>
                <SelectStyled
                  {...field}
                  s={12}
                  onChange={(state: any) => {
                    field.onChange(state.value);
                  }}
                  options={STATES}
                  value={STATES.find(state => state.value == field.value)}
                  label="UF"
                />
              </SelectContainer>
            )}
          />
        </div>
      </div>
    </>
  );
}