import { gql } from "@apollo/client";

export const MUTATION_SIGN_UP = gql`
  mutation signUp(
    $name: String!,
    $lastName: String!,
    $email: String!,
    $password: String!,
    $passwordConfirmation: String!,
  ){
    signUp(
      name: $name,
      lastName: $lastName,
      email: $email,
      password: $password,
      passwordConfirmation: $passwordConfirmation,
    ){
        id,
        name,
        lastName,
        email,
        status,
    }
  }
`;

export const MUTATION_SIGN_IN = gql`
  mutation login(
    $email: String!,
    $password: String!,
  ){
    login(
      email: $email,
      password: $password,
      source: "web",
    ){
        id,
        name,
        lastName,
        email,
        token,
        status,
    }
  }
`;