import { gql } from "@apollo/client";

export const GET_USER_POINTS = gql`
  query GetUserPoints($userId: ID!) {
    getUserPoints(userId: $userId) {
      points {
        id
        value
        obs
        operation
        createdAt
      }
      totalPoints
      totalPages
    }
  }
`;