import * as React from 'react';
import {Textarea, TextInput} from 'react-materialize';
import {MutationCourseCategory} from '../../../services/courseCategories/MutationCourseCategory';

export function CourseCategoriesFormComponent({category}) : JSX.Element {
  const [loading, setLoading] = React.useState(false);
  const [categoryState, setCategoryState] = React.useState(category);
  const [errors, setErrors] = React.useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    MutationCourseCategory(categoryState, setLoading)
    .then(({courseCategory}) => {
      if(!categoryState.id){
        window.history.pushState("object or string", null, `/${window.account}/admin/course-categories/${courseCategory.id}/edit`);
      }
      setCategoryState(courseCategory);
    })
  }

  const handleChange = ({target}) => {
    const newCategory = {...categoryState, [target.name]: target.value};
    setCategoryState(newCategory);
  }

  return(
    <form onSubmit={handleSubmit}>
    <div className="card">
      <div className="card-content">
        <div className="row">
          <TextInput
            s={12}
            id="name"
            name="name"
            onChange={handleChange}
            value={categoryState.name || ''}
            required
            inputClassName={
              !!errors.name ? 'invalid' : ' '
            }
            error={errors.name}
            label="Nome da categoria*"
          />
        </div>
        <div className="row">
          <Textarea
            s={12}
            id={`description`}
            name={`description`}
            onChange={handleChange}
            value={categoryState.description || ''}
            label={'Descrição'}
            placeholder={`Escreva aqui a descrição da categoria`}
            className={errors?.description ? 'invalid' : ' '}
            error={errors?.description}
            style={{maxHeight: 120}}
          />
        </div>
      </div>
    </div>
      <div className="row">
        <div className="col s12">
          <button
            className="btn btn-primary right"
            type="submit"
            onSubmit={handleSubmit}
            disabled={loading}>
            {loading ? 'Salvando...' : `Salvar`}
          </button>
        </div>
      </div>
    </form>
  )
}