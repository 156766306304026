import * as React from 'react';
import { MutationCreateReview } from '../../services/review/MutationReview';

export const ReviewContext = React.createContext({});

export const ReviewProvider = ({children, rating: ratingProps, reviewable, reviewableType, reviewableId, account}) => {
  const [rating, setRating] = React.useState(ratingProps || 0);
  const [comment, setComment] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  function handleSubmit(event){
    event.preventDefault();
    if(loading) return;

    const formulario = {
      rating: `${rating}`,
      comment,
      reviewable: reviewableType,
      reviewableId,
    };
    if(comment.length > 140){
      setErrors({comment: 'Seu comentário precisa ter no máximo 140 caracteres'});
      console.log('formulario', formulario, 'comment length', comment.length);
      return;
    }
    setLoading(true);
    MutationCreateReview(formulario, setLoading)
      .then((response) => {
        console.log('response', response);
        window.location.href= `/${account.code}/reviews`;
      })
      .catch((error) => {
        console.log('error', error);
      }
    );
  }

  return(
    <ReviewContext.Provider 
      value={{
        loading,
        setLoading,
        rating,
        setRating,
        comment,
        setComment,
        errors,
        reviewable,
        reviewableType,
        account,
        handleSubmit,
      }}>
      {children}
    </ReviewContext.Provider>
  )
}