import React from 'react';
import { AvatarComponent } from './components/avatar.component';

const AlunosNaAula = ({alunos}: {alunos: any}) => {
  return (
    <div style={{display: 'flex', flexWrap: 'wrap', flex: 1}}>
      {alunos?.map((aluno: any) => {
        return (
          <AvatarComponent key={aluno.id} aluno={aluno} />
        )
      })}
    </div>
  )
}

export default AlunosNaAula;

/*
<div class="<%= 'gympass' if aluno.gympass_enabled %> aluno-card" style="background-image:url(<%=  aluno.avatar.exists? ? aluno.avatar.url(:medium) : image_url('no-image.jpg') %>)">
			<div class="aluno-nome" style='background-color: <%= aluno.user_plans.ativos_on_account(@account).first.plan&.color if  aluno.user_plans.ativos_on_account(@account).present? %>'>
				<%= link_to aluno.get_short_name, admin_aluno_path(@account.code, aluno) %>
				<% unless params[:mode] == 'full' %>
				<small class="right">
					<%= link_to material_icon.shape(:edit), edit_admin_checkin_path(@account.code, aluno.checkins.today) %>
				</small>
				<% end %>
			</div>

		</div>
  */