import * as React from 'react';
import { RegisterFormContext } from './RegisterForm.context';
import { Column, FormWrapper, View } from './RegisterForm.style';
import { StateEnum } from './Register.schema';
import { Checkbox, RadioGroup } from 'react-materialize';
import { Controller } from 'react-hook-form';
import { InputStyled } from '../../elements/forms/InputContainer';
import { SelectCategory, SelectSubCategory } from '../../components/accounts/SelectCategory.component';

export function RegisterFormStep2() {
  const {
    step, 
    onHandleSubmit, 
    isValid, 
    isSubmitting, 
    control,
    errors,
    accountCategories,
    getValues,
    goBack,
    setStep,
    validateStep
  } = React.useContext(RegisterFormContext);

  React.useEffect(() => {
    if(step == '2'){
      window.scrollTo(0, 0);
    }
  }, [step]);

  const [accountCategory, setAccountCategory] = React.useState<string>('');
  const values = getValues();
  const isValidValue = Object.keys(errors).length === 0 && values.category?.length > 0 && values.atuation_type?.length > 0;
  if(step == '2'){
    const isValidLastStep = validateStep(1);
    if(!isValidLastStep){
      console.log('isValidLastStep', isValidLastStep);
      console.log('step', step);
      setStep(1);
    }
    return (
      <View>
        <h1>Seu perfil profissional</h1>
        <FormWrapper onSubmit={onHandleSubmit} style={{flexDirection: 'column'}}>
          <div style={{display: 'flex', flexDirection: 'row', gap: '1rem'}}>
            <Column className='column1' style={{minWidth: '50%'}}>

              <span>Qual seu tipo de atuação?</span>
              <Controller
                name="atuation_type"
                control={control}
                render={({ field }) => (
                  <RadioGroup 
                    {...field} 
                    label='Qual seu tipo de atuação?'
                    options={[{ label: 'Presencial', value: 'presencial' }, { label: 'On-line', value: 'online' }, { label: 'Presencial e On-line', value: 'presencial_online' }]} 
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                  )}
                />
                <Controller
                name="cref"
                control={control}
                render={({ field }) => (
                  <InputStyled 
                    {...field} 
                    placeholder='CREF'
                    label='CREF'
                    type="tel"
                    className={errors.cref?.message ? 'invalid' : ''}
                    error={errors.cref?.message}
                  />
                  )}
                />
                <Controller
                name="category"
                control={control}
                render={({field: {ref, onChange, value}}) => (
                  <SelectCategory
                    ref={ref}
                    label="Área de atuação"
                    defaultOptions={accountCategories}
                    errors={errors?.category?.message}
                    value={value}
                    onSelect={({value}: {value: string}) => {
                      onChange(value);
                      setAccountCategory(value);
                    }}
                  />
                  )}
                />

                {accountCategory.length > 0 && (
                  <Controller
                    name="subcategory"
                    control={control}
                    render={({field: {ref, onChange, value}}) => (
                      <SelectSubCategory
                        ref={ref}
                        label="Detalhe um pouco mais"
                        categoryCode={accountCategory}
                        errors={errors?.subcategory?.message}
                        onSelect={({value}: {value: string}) => {
                          onChange(value);
                        }}
                      />
                      )}
                    />
                )}
            </Column>
          </div>
          <p>
            <span className="helper thin">{!isValidValue && "Preencha os campos obrigatórios para continuar"}</span>
          </p>
          <div className="center" style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
            <button
              className="btn btn-primary right"
              type="submit"
              onClick={onHandleSubmit}
              disabled={isSubmitting || !isValidValue}>
              {isSubmitting ? 'Enviando...' : `Continuar`}
            </button>
            <a href="#" onClick={(event) => {
              event.preventDefault();
              goBack();
            }}>Voltar</a>
          </div>
        </FormWrapper>
      </View>
    );
  }
}