import {gql} from '@apollo/client';
import {IMAGE_TYPE} from './mediaType';
export const ACCOUNT_TYPE = `
  id
  code
  status
  visibility
  name
  status
  bio
  features
  category
  subcategory
  avatar{
    ${IMAGE_TYPE}
  }
  accountBilling{
    phone
    email
    documentId
    billingType
    addressLine1
    addressLine2
    neighborhood
    city
    state
    country
    zipcode
  }
  senderName
  senderEmail
  athletesLabel
  minimumCheckinsGympass
  minimumCheckinsToPoints
  pointsLabel
  vindiApiKey
  accountNotificationKey
  registerEnabled
  registerStatus
  requiresPlanOnSignup
  hoursAheadCheckin
  daysAheadCheckin
  hoursBeforeRemoveCheckin
  mundipaggSecretKey
  stripeSecretKey
  externalStoreFavorite
  accountType
  recaptchaSecretKey
  securityCheckout
`;

export const MUTATION_ACCOUNT = gql`
  mutation updateAccount(
    $name: String!,
    $avatar: Upload,
    $accountBilling: AccountBillingInput,
    $bio: String,
    $category: String,
    $subcategory: String,
    $senderName: String,
    $senderEmail: String,
    $athletesLabel: String,
    $minimumCheckinsGympass: String,
    $minimumCheckinsToPoints: String,
    $pointsLabel: String,
    $vindiApiKey: String,
    $registerEnabled: Boolean,
    $registerStatus: String,
    $requiresPlanOnSignup: Boolean,
    $hoursAheadCheckin: String,
    $daysAheadCheckin: String,
    $hoursBeforeRemoveCheckin: String,
    $securityCheckout: Boolean,
    $recaptchaSecretKey: String,
    $mundipaggSecretKey: String,
    $stripeSecretKey: String,
    $externalStoreFavorite: String,
  ){
    updateAccount(input: {
      name: $name,
      avatar: $avatar,
      accountBilling: $accountBilling,
      bio: $bio,
      category: $category,
      subcategory: $subcategory,
      senderName: $senderName,
      senderEmail: $senderEmail,
      athletesLabel: $athletesLabel,
      minimumCheckinsGympass: $minimumCheckinsGympass,
      minimumCheckinsToPoints: $minimumCheckinsToPoints,
      pointsLabel: $pointsLabel,
      vindiApiKey: $vindiApiKey,
      registerEnabled: $registerEnabled,
      registerStatus: $registerStatus,
      requiresPlanOnSignup: $requiresPlanOnSignup,
      hoursAheadCheckin: $hoursAheadCheckin,
      daysAheadCheckin: $daysAheadCheckin,
      hoursBeforeRemoveCheckin: $hoursBeforeRemoveCheckin,
      securityCheckout: $securityCheckout,
      recaptchaSecretKey: $recaptchaSecretKey,
      mundipaggSecretKey: $mundipaggSecretKey,
      stripeSecretKey: $stripeSecretKey,
      externalStoreFavorite: $externalStoreFavorite,
    }){
      account{
        ${ACCOUNT_TYPE}
      }
    }
  }
`;

export const MUTATION_ALDERAAN_ACCOUNT = gql`
  mutation createOrUpdateAccount(
    $id: ID,
    $code: String,
    $status: String,
    $visibility: String,
    $name: String!,
    $accountBilling: AccountBillingInput,
    $avatar: Upload,
    $senderName: String,
    $senderEmail: String,
    $features: [String!],
    $athletesLabel: String,
    $minimumCheckinsGympass: String,
    $minimumCheckinsToPoints: String,
    $pointsLabel: String,
    $vindiApiKey: String,
    $registerEnabled: Boolean,
    $registerStatus: String,
    $requiresPlanOnSignup: Boolean,
    $hoursAheadCheckin: String,
    $daysAheadCheckin: String,
    $hoursBeforeRemoveCheckin: String,
    $mundipaggSecretKey: String,
    $stripeSecretKey: String,
    $externalStoreFavorite: String,
    $accountType: String,
    $category: String,
    $subcategory: String,
  ){
    createOrUpdateAccount(input: {
      id: $id,
      code: $code,
      status: $status,
      visibility: $visibility,
      name: $name,
      accountBilling: $accountBilling,
      avatar: $avatar,
      senderName: $senderName,
      senderEmail: $senderEmail,
      features: $features,
      athletesLabel: $athletesLabel,
      minimumCheckinsGympass: $minimumCheckinsGympass,
      minimumCheckinsToPoints: $minimumCheckinsToPoints,
      pointsLabel: $pointsLabel,
      vindiApiKey: $vindiApiKey,
      registerEnabled: $registerEnabled,
      registerStatus: $registerStatus,
      requiresPlanOnSignup: $requiresPlanOnSignup,
      hoursAheadCheckin: $hoursAheadCheckin,
      daysAheadCheckin: $daysAheadCheckin,
      hoursBeforeRemoveCheckin: $hoursBeforeRemoveCheckin,
      mundipaggSecretKey: $mundipaggSecretKey,
      stripeSecretKey: $stripeSecretKey,
      externalStoreFavorite: $externalStoreFavorite,
      accountType: $accountType,
      category: $category,
      subcategory: $subcategory,
    }){
      account{
        ${ACCOUNT_TYPE}
      }
    }
  }
`;