import styled from 'styled-components';

export const SelectDateRange = styled.div`
  background-color: white;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 1em 3.5em 1em 1.33em;
  margin: 0;      
  box-sizing: border-box;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position:
    calc(100% - 20px) calc(1.5em + 2px),
    calc(100% - 15px) calc(1.5em + 2px);
  background-size:
    5px 5px,
    5px 5px;
  background-repeat: no-repeat;
  box-shadow: rgba(0,0,0,0.2) 0 2px 3px;
  cursor: pointer;
  flex: 1;
  margin: 10px 0;
  width: auto;
`;

export const CalendarModalArea = styled.div`
  align-items: center;
  background-color: rgba(0,0,0,0.3);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  z-index: 99;
`;

export const CalendarContainer = styled.div`
  background: #FFFFFF;
  box-shadow: rgba(0,0,0,0.2) 0 2px 5px;
  padding: 10px;
  max-width: 100%;
  button:focus{
    background-color: inherit;
  }
  select{
    display: block !important;
    opacity: 1 !important;
  }
  .rdrDefinedRangesWrapper{
    font-size: inherit;
    width: 200px;
  }
  @media screen and (max-width: 768px){
    .rdrDefinedRangesWrapper{
      display: none;
    }
  }
  .rdrStaticRangeLabel{
    font-family: 'Roboto', 'Open sans', Helvetica, 'Helvetica', sans-serif;
    font-weight: 300;
  }
  .rdrInputRange{
    flex-direction: column;
  }
  .rdrInputRangeInput{
    border:none;
    background-color: #EEEEEE;
    border-radius: 3px;
    box-shadow: inset rgba(0,0,0,0.1) 0 0 5px;
  }
`;

export const CalendarContainerFooter = styled.div`
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;