import styled from 'styled-components';
import {COLOR} from '../../constants';

export const ButtonDeleteContainer = styled.a`
  color: ${COLOR.TEXT}77;
  cursor: pointer;
  height: 100%;
  text-decoration: none;
  &:before{
    content: "\f2ed";
    font-family: 'Font Awesome 6 Pro';
  }
  &:hover{
    color:${({hoverColor}) => hoverColor ? hoverColor : COLOR.CLOSE};
  }
`;