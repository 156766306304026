import {gql} from '@apollo/client';
import {IMAGE_TYPE} from './mediaType';

export const PROGRAM_ADMIN_TYPE = `
  id
  name
  code
  filialId
  passTypeNumber
  programId
  maximumCheckinsPerDay
`

export const MUTATION_PROGRAM = gql`
  mutation createOrUpdateProgram(
    $id: ID,
    $name: String!,
    $code: String,
    $filialId: ID!,
    $passTypeNumber: String,
    $programId: String,
    $maximumCheckinsPerDay: String
  ){
    createOrUpdateProgram(input: {
      id: $id,
      name: $name,
      code: $code,
      filialId: $filialId,
      passTypeNumber: $passTypeNumber,
      programId: $programId,
      maximumCheckinsPerDay: $maximumCheckinsPerDay
    }){
      program {
        ${PROGRAM_ADMIN_TYPE}
      }
    }
  }
`

export const MUTATION_DELETE_PROGRAM = gql`
  mutation deleteProgram(
    $id: ID!
  ){
    deleteProgram(input: {
      id: $id,
    }){
      program {
        ${PROGRAM_ADMIN_TYPE}
      }
    }
  }
`

export const QUERY_GET_PROGRAMS = gql`
  query getPrograms(
    $filialId: ID!
  ) {
    programs(filialId: $filialId){
      ${PROGRAM_ADMIN_TYPE}
    }
  }`;