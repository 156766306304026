import * as React from 'react';
import {useQuery} from '@apollo/client';

import {client} from '../../../core/stores';
import {QUERY_DASHBOARD_COMMENTS} from '../../../core/graphql/types';

import {
  DashboardHeader, 
  DashboardHeaderItem, 
  DashboardLabel,
  DashboardData,
} from './CheckinChartComponent.style';

import {CommentListContainer} from './CommentsDashboardComponent.style';

import CommentTips from '../../../assets/images/tips-dashboard-comments.png';
import {CommentComponent} from '../comments';

import {LoadingContent} from '../LoadingComponent';

export const CommentsDashboardComponent = () : JSX.Element => {
  const [dashboardComments, setDashboardComments] = React.useState({});
  const {loading, error, data} = useQuery(QUERY_DASHBOARD_COMMENTS, {variables: {days: 7}});

  React.useEffect(()=>{
    if(data?.dashboardComments) setDashboardComments(data.dashboardComments);
  },[loading, data])

  const {lastDaysComments, lastDaysCommentsTotal} = dashboardComments || {};

  return(
    <div className="card">
      <div className="card-content">
        {!!loading && <LoadingContent loading={loading} />}
        <DashboardHeader>
          <DashboardHeaderItem>
            <DashboardLabel style={{width: '100%'}}>Comentários nos últimos 7 dias</DashboardLabel>
            <DashboardData style={{width: '100%'}}>{lastDaysCommentsTotal? lastDaysCommentsTotal : '-'}</DashboardData>
          </DashboardHeaderItem>
        </DashboardHeader>
        {!!lastDaysComments && !loading && lastDaysComments.length === 0 && <CommentsDashboardEmpty/>}
        {!!lastDaysComments && !loading && !!lastDaysComments.length &&
        <>
          Últimos comentários:
          <CommentListContainer>
            {lastDaysComments.map( comment => <CommentComponent key={comment.id} comment={comment} showType={true} />)}
          </CommentListContainer>
          <a href={`/${window.account}/mkt/general_comments`}>Gerenciar comentários</a>
        </>
        }
      </div>
    </div>
  )
}

const CommentsDashboardEmpty = () => {
  return(
    <div>
      <img src={CommentTips} />
      <div className="flow-text">Você não recebeu nenhum comentário nos últimos dias.</div>
       <p>
        É importante ter mais engajamento e interações com seus clientes. Eles podem interagir em treinos, posts ou em aulas. Comece agora mesmo a conquistar novos comentários!
       </p> 
    </div>
  )
}