import * as React from 'react';
import {messages} from '../../utils';
import {MutateCourse, MutateUnitByCourseModule, MutateCourseModule} from '../../services';
import {arrayMoveImmutable} from 'array-move';
import {CourseCategory, CourseContextProps, CourseProps, CourseProviderProps} from './course.type';

export const CourseContext = React.createContext({} as CourseContextProps);

export function CourseProvider({children, course, programs, courseCategories = []} : CourseProviderProps){
  const [errors, setErrors] = React.useState({});
  const [values, setValues] = React.useState(course || {} as CourseProps);
  const [programsVisible, setProgramsVisible] = React.useState( !course.premiumAccessThroughAnyPlan || false);
  const [courseActive, setCourseActive] = React.useState(course.status == 'active' || false);
  const [programIds, setProgramIds] = React.useState(course.programIds || []);
  const [programsSelected, setProgramsSelected] = React.useState([]);
  const [categoriesSelected, setCategoriesSelected] = React.useState();
  const [courseModules, setCourseModules] = React.useState(course?.courseModules || [])
  const [units, setUnits] = React.useState(course?.units || []);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [progress,setProgress] = React.useState<number>(0);
  const [loadingMessage, setLoadingMessage] = React.useState(null);
  const [categories, setCategories] = React.useState<CourseCategory[]>([] as CourseCategory[]);
  
  React.useEffect(() => {
    const courseCategoriesTranslated = courseCategories.map(category => ({label: category.name, value: `${category.id}`}));
    setCategories(courseCategoriesTranslated as CourseCategory[])
  }, [courseCategories])

  React.useEffect(()=>{
    if(course.courseCategoryId){
      const categoryFiltered = (courseCategories||[]).filter(category => category.id == course.courseCategoryId)
      if(categoryFiltered.length){
        setCategoriesSelected({label: categoryFiltered[0].name, value: `${course.courseCategoryId}`})
      }
    }
  },[course]);

  React.useEffect(() => {
    const programsFiltered = programs.filter( program => programIds.includes(program.id))
    
    const programs_selected = programsFiltered.map( p => {
      return {
        value: p.id,
        label: `${p.name} - ${p.filial.name}`
      }
    });
    if(programs_selected !== programsSelected) setProgramsSelected(programs_selected);
  }, [course.programIds, units])

  function handleSubmitCourse(event){
    event.preventDefault();
    setLoadingMessage(messages.course.updating_course);
    
    const formatedCourse = {...values, units, premiumAccessThroughAnyPlan: !programsVisible, programIds, courseCategoryId: categoriesSelected?.value, status: courseActive ? 'active' : 'draft'}
    
    MutateCourse(formatedCourse, setLoading, setProgress )
    .then(({course : courseUpdated, course: coursePayload }) => {  
        setLoading(true);
        window.location.href = `/${window.account}/admin/courses/${courseUpdated.id}/`;
        return coursePayload
    } )
    .catch(err => {
      setErrors(err);
      console.log('error => ', err)
    });
  }
  const handleSubmitCourseModules = async (event) => {
    event.preventDefault();
    setLoadingMessage(messages.course_modules.updating_course_module);
    for(let courseModuleIndex = 0; courseModuleIndex < courseModules.length; courseModuleIndex++) {
      const courseModule = courseModules[courseModuleIndex];
      const courseModuleToUpdate = {...courseModule, order: courseModuleIndex}
      const courseModuleUpdated = await MutateCourseModule(values, courseModuleToUpdate, setLoading, setProgress, setErrors)
        .then(({courseModule: courseModulePayload }) => courseModulePayload )
        .catch(mutateErrors => {
          setErrors(mutateErrors);
          const newCourseModule = {...courseModule, errors: mutateErrors};
          setCourseModule(newCourseModule);
        });
      if(courseModuleUpdated){
        await updateUnits({...courseModule, ...courseModuleUpdated, units: courseModule.units});
      } else if(courseModule._destroy) {
        
      } else {
        M.toast({html: messages.form.error_on_form})
      }
    };
  }

  const handleSelectCategory = (values) => {
    const newCourseCategories = values;
    setCategoriesSelected(newCourseCategories);
  }

  async function updateUnits(courseModuleUpdated){
    setLoadingMessage(`Enviando as aulas ${units.length}`);

    const updatedUnits = [];
    const unitsUpdated = courseModuleUpdated.units.map( (u,index) => ({...u, order: index}))
    for(let index = 0; index < unitsUpdated.length; index++){
      const unit = unitsUpdated[index];
      setLoadingMessage(`${messages.units.sending_unit} ... ${index+1}/${courseModuleUpdated.units.length}`);
      const unitFromGraphql = await MutateUnitByCourseModule(courseModuleUpdated, unit, setLoading, setProgress)
      .then(({unit: unitPayload}) => {
        if(unit._destroy){
          return null;
        } else {
          return unitPayload;
        }
      })
      .catch(err => {
        setErrors(err);
        console.log('error => ', err)
        M.toast({html: `${courseModuleUpdated.title} - Erros encontrados, por favor verifique antes de salvar!` })
      })
      if(unitFromGraphql) updatedUnits.push(unitFromGraphql)
    }
    setCourseModule(courseModuleUpdated);
    setUnits(updatedUnits);
  }
  
  
  const setUploadCover = (file) => { setValues({...values, cover: file}) };
  const setUploadPoster = (file) => { setValues({...values, poster: file}) };
  const handleChange = ({target}) => { const newValues = { ...values, [target.name]: target.value}; setValues(newValues) }

  const listOfPrograms = programs.map((program) => ({
    value: program.id,
    label: `${program.name} - ${program.filial.name}`,
  }));

  const handleListPrograms = (values) => {
    const program_ids = (values || [{}]).map((val) => val.value);
    const programs_selected = values;
    setProgramIds(program_ids);
    setProgramsSelected(programs_selected);
  };

  const setCourseModule = (courseModule) => {
    const newCourseModules = [...courseModules];
    const index = courseModules.map(cm => (cm.id||cm.tempId)).indexOf(courseModule.id||courseModule.tempId)
    newCourseModules[index] = courseModule;
    setCourseModules(newCourseModules)
  }

  const createCourseModule = () => {
    const tempId = new Date().getTime()
    const newCourseModule = {
      id: '',
      tempId,
      title: `Módulo ${courseModules.length+1}`,
      description: '',
      order: '',
      units: [{
        id: '',
        tempId: new Date().getTime(),
        title: 'Nova aula',
        unitType: 'Premium',
      }]
    }

    const newCourseModules = [...courseModules, newCourseModule];
    setCourseModules(newCourseModules)
    setTimeout(() => {
      document
        .getElementById(
          `coursemodule-${courseModules.length}`,
        )
        .focus();
    }, 10);
  }  

  const deleteCourseModule = (courseModule, forced = false) => {
    let newCourseModule = {...courseModule};
    const index = courseModules.map(cm => (cm.id || cm.tempId)).indexOf(courseModule.id||courseModule.tempId)
    if (courseModule.id && !forced) {
      newCourseModule = {...courseModule, '_destroy': true}
      const newCourseModules = [...courseModules];
      newCourseModules[index] = newCourseModule;
      setCourseModules(newCourseModules);
    } else {
      const newCourseModules = [...courseModules];
      newCourseModules.splice(index, 1);
      setCourseModules(newCourseModules);
    }
  };

  const onCourseModuleSortEnd = ({oldIndex, newIndex, nodes}) => {
    const newCourseModule = arrayMoveImmutable(courseModules, oldIndex, newIndex);
    setCourseModules(newCourseModule)
  };

  return (
    <CourseContext.Provider 
      value={{
        units,
        values,
        handleSubmitCourse,
        handleSubmitCourseModules,
        setUploadCover,
        setUploadPoster,
        handleChange,
        listOfPrograms,
        handleListPrograms,
        courseModules,
        createCourseModule,
        deleteCourseModule,
        setCourseModule,
        onCourseModuleSortEnd,
        setProgramsVisible,
        setCourseActive,
        courseActive,
        programsVisible,
        loading,
        loadingMessage,
        progress,
        programsSelected,
        programs,
        handleSelectCategory,
        categoriesSelected,
        categories,
        errors
      }}>
        {children}
      </CourseContext.Provider>)
}
