import styled from "styled-components";

export const PointsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const PointsGeneral = styled.div`
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 2.33rem;
  font-weight: 600;
  justify-content: center;
  padding: 1rem;
  small {
    font-size: 1rem;
    font-weight: 400;
  }
`;

export const PointsFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  gap: 1rem;
`;

export const PointsPreview = styled.div`
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`;

export const PointsFormFooter = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
