import {clientAccount} from '../../core/stores';
import {MUTATION_SIGN_UP} from '../../core/graphql/types';

export function MutationSignUp(authForm, setSubmitting): Promise {
  return new Promise((resolve,reject) => {
    const {name, email, password, password_confirmation} = authForm;
    setSubmitting(true);
    if(!name || !email || !password){
      M.toast({html: 'Para se cadastrar, você precisa preencher todos os campos', displayLength: 5000})
      reject(false);
      setSubmitting(false);
      return;
    }
    if(name && name.split(' ').length < 2){
      M.toast({html: 'Para se cadastrar, você precisa preencher seu nome completo', displayLength: 5000})
      reject(false);
      setSubmitting(false);
      return;
    }
    if(password && password.length < 6){
      M.toast({html: 'Para se cadastrar, sua senha precisa ter no mínimo 6 caracteres', displayLength: 5000})
      reject(false);
      setSubmitting(false);
      return;
    }
    if(email && !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email))){
      M.toast({html: 'Para se cadastrar, você precisa preencher um email válido', displayLength: 5000})
      reject(false);
      setSubmitting(false);
      return;
    }
    authForm['name'] = name.split(' ')[0];
    authForm['lastName'] = name.split(' ')[1];
    authForm['passwordConfirmation'] = password;

    clientAccount.mutate({
      mutation: MUTATION_SIGN_UP, 
      variables: authForm,
    })
    .then(response => {
      const {data} = response;
      const {signUp} = data || {};
      M.toast({html: 'Conta criada com sucesso!'});
      setSubmitting(false);
      resolve({status: 'success', user: signUp})
    })
    .catch( err => {
      console.log('ERROR ==> ', err)
      setSubmitting(false);
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro na sua solicitação.' })
      M.toast({ html: errorMessage })
      reject({status: 'error', message: errorMessage})
    });
  })
}