import { gql } from '@apollo/client';

export const GET_PLANS_QUERY = gql`
  query GetPlans($page: Int) {
    getPlans(page: $page) {
      id
      name
      planType
      color
      value
      recurrence
    }
  }
`;