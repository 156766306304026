import {gql} from '@apollo/client';
import {IMAGE_TYPE} from './mediaType';
import {UNIT_TYPE} from './units';


export const COURSE_MODULE_TYPE = `
  id
  title
  description
  order
  units{
    ${UNIT_TYPE}
  }
`;

export const COURSE_TYPE = `
  id
  title
  description
  poster {
    ${IMAGE_TYPE}
  }
  cover {
    ${IMAGE_TYPE}
  }
  units{
    ${UNIT_TYPE}
  }
  courseModules{
    ${COURSE_MODULE_TYPE}
  }
`;

export const MUTATION_COURSE = gql`
  mutation createOrUpdateCourse(
    $id: ID,
    $title: String!,
    $status: String,
    $description: String!,
    $poster: Upload,
    $cover: Upload,
    $premiumAccessThroughAnyPlan: Boolean,
    $courseCategoryId: ID,
    $programIds: [ID!]
  ){
    course(input: {
      id: $id,
      title: $title,
      status: $status,
      description: $description,
      poster: $poster,
      cover: $cover,
      premiumAccessThroughAnyPlan: $premiumAccessThroughAnyPlan,
      courseCategoryId: $courseCategoryId,
      programIds: $programIds,
    }){
      course{
        ${COURSE_TYPE}
      }
    }
  }
`;

export const MUTATION_COURSE_MODULE = gql`
  mutation createOrUpdateCourseModule(
    $courseId: ID!,
    $id: ID,
    $title: String!,
    $description: String,
    $order: Int,
    $_destroy: Boolean,
  ){
    courseModule(input: {
      courseId: $courseId,
      id: $id,
      title: $title,
      description: $description,
      order: $order,
      _destroy: $_destroy,
    }){
      courseModule{
        ${COURSE_MODULE_TYPE}
      }
    }
  }
`;