import * as React from 'react';
import {UploadMediaProps} from '../types';
import {MutationAccount} from '../../services/account/MutationAccount';
import { useNavState } from 'use-nav-state';

interface AccountGeneralType {
  id: string|number;
  code: string;
  name: string;
  bio?: string;
  visibility?: 'public'|'private';
  categoryPrimary?: string;
  categorySecondary?: string;
  status: string;
  avatar?: UploadMediaProps;
  features?: string[];
  senderName?: string;
  senderEmail?: string;
  athletesLabel?: string;
  minimumCheckinsGympass?: number;
  minimumCheckinsToPoints?: number;
  pointsLabel?: string;
  vindiApiKey?: string;
  accountNotificationKey?: string;
  registerEnabled?: boolean;
  registerStatus: string;
  requiresPlanOnSignup?: boolean;
  hoursAheadCheckin?: number;
  daysAheadCheckin?: number;
  hoursBeforeRemoveCheckin?: number;
  mundipaggSecretKey?: string;
  stripeSecretKey?: string;
  securityCheckout?: boolean;
  recaptchaSecretKey?: string;
  externalStoreFavorite?: string;
  accountType?: string;
  accountBilling?: {
    id?: string|number;
    billingType: 'individual'|'company';
    phone?: string;
    email?: string;
    addressLine1: string;
    addressLine2?: string;
    neighborhood: string;
    zipcode: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    documentId: string;
  }
}

interface AccountSettingsContextProps {
  activeTab: string;
  setActiveTab: (value: string) => void;
  account: AccountGeneralType;
  updateAccount: (value: AccountGeneralType) => void;
  updateAccountFromEvent: (event) => void;
  createOrEditAccount: (event) => void;
  loading: boolean;
  scope?: 'alderaan' | 'admin';
  handleTab?: (value: string) => void;
  LIST_TABS?: any[];
  categories?: any[];
  features?: string[];
  featuresEnabled?: string[];
  STATES: {
    value: string;
    label: string;
  }[];
  accountStripeInfo?: string | null;
  accountReauth?: any;
}

const STATES = [
  {value: 'AC', label: 'AC'},
  {value: 'AL', label: 'AL'},
  {value: 'AP', label: 'AP'},
  {value: 'AM', label: 'AM'},
  {value: 'BA', label: 'BA'},
  {value: 'CE', label: 'CE'},
  {value: 'DF', label: 'DF'},
  {value: 'ES', label: 'ES'},
  {value: 'GO', label: 'GO'},
  {value: 'MA', label: 'MA'},
  {value: 'MT', label: 'MT'},
  {value: 'MS', label: 'MS'},
  {value: 'MG', label: 'MG'},
  {value: 'PA', label: 'PA'},
  {value: 'PB', label: 'PB'},
  {value: 'PR', label: 'PR'},
  {value: 'PE', label: 'PE'},
  {value: 'PI', label: 'PI'},
  {value: 'RJ', label: 'RJ'},
  {value: 'RN', label: 'RN'},
  {value: 'RS', label: 'RS'},
  {value: 'RO', label: 'RO'},
  {value: 'RR', label: 'RR'},
  {value: 'SC', label: 'SC'},
  {value: 'SP', label: 'SP'},
  {value: 'SE', label: 'SE'},
  {value: 'TO', label: 'TO'},
]

const LIST_TABS = [
  {
    label: 'Geral',
    value: 'geral',
    scope: 'general',
  },
  {
    label: 'Faturamento',
    value: 'billing',
    scope: 'general',
  },
  {
    label: 'Pagamentos',
    value: 'payments',
    scope: 'general',
  },
  {
    label: 'Envio de e-mails',
    value: 'emails',
    scope: 'general',
  },
  {
    label: 'Novos Cadastros',
    value: 'register',
    scope: 'general',
  },
  {
    label: 'Checkins',
    value: 'checkins',
    scope: 'general',
  },
  {
    label: 'Recursos',
    value: 'resources',
    scope: 'alderaan',
  }
];

export const AccountSettingsContext = React.createContext({} as AccountSettingsContextProps);

interface AccountSettingsProviderProps {
  children: React.ReactNode;
  activeTab?: string;
  account?: AccountGeneralType;
  accountPathState?: 'new' | 'edit';
  postUrl?: string;
  scope: 'alderaan' | 'admin';
  accountStripeInfo?: any;
  accountReauth?: any;
  categories?: any[];
  features?: string[];
}
export function AccountSettingsProvider({ children, activeTab : activeTabProps, account: accountProps, accountPathState, scope, accountStripeInfo, accountReauth, categories, features, featuresEnabled } : AccountSettingsProviderProps){
  const hashProp = window?.location?.hash?.replace('#','');
  
  const [activeTab, setActiveTab] = useNavState('activeTab', hashProp || activeTabProps || 'geral');
  const [loading, setLoading] = React.useState<boolean>(false);
  delete accountProps?.accountBilling?.externalCustomerId;
  delete accountProps?.accountBilling?.originalCustomerJson;
  const [account, setAccount] = React.useState<AccountGeneralType>(accountProps);

  function setFormAction(){
    const {account, scope, path} = props;
    if(scope === 'admin'){
      return `/${account.code}/admin`
    } else if (scope === 'alderaan' && path === 'new'){
      return `/alderaan/accounts`
    } else if (scope === 'alderaan' && path === 'edit'){
      return `/alderaan/${account.id}/`
    }
  }

  function createOrEditAccount(data) : void {
    setLoading(true);
    const accountToMutation = data;
    if(accountToMutation.vindiApiKey == "(chave oculta)"){
      delete accountToMutation.vindiApiKey
    }
    if(accountToMutation.mundipaggSecretKey == "(chave oculta)"){
      delete accountToMutation.mundipaggSecretKey
    }
    if(accountToMutation.stripeSecretKey == "(chave oculta)"){
      delete accountToMutation.stripeSecretKey
    }
    if(scope !== 'alderaan'){
      delete accountToMutation.status
    }
    // remover campos nulos
    Object.keys(accountToMutation).forEach(key => {
      if ([null, undefined, ""].includes(accountToMutation[key])) {
        delete accountToMutation[key];
      }
    });
    
    MutationAccount(accountToMutation, scope, setLoading)
    .then(({account: accountUpdated}) => {
      setAccount(accountUpdated);
      if(accountPathState == 'new' && scope == 'alderaan'){
        window.history.pushState("object or string", null, `/alderaan/accounts/${accountUpdated.id}/edit`);
      }
    })
    .catch( (err) => {
      console.log('error ==> ', err);
    })
    .finally(() => {
      setLoading(false);
    })
  }

  function updateAccount(value){
    const newAccount = {...account, ...value}
    setAccount(newAccount);
  }

  function updateAccountFromEvent({target}){
    const {name,value} = target;
    const newAccount = {...account, [name]: value};
    setAccount(newAccount);
  }

  window.onhashchange = function() {
    const hash = `${window.location.hash}`.replace('#','')
    if(hash) setActiveTab(hash)
  }

  function handleTab(value){
    const selectedTab = LIST_TABS.filter(tab => tab.value == value)[0]
    window.location.hash = value;
    const title = `${account.code}/configurações - ${selectedTab.label}`
    window.history.pushState({}, title, window.location.href)
    document.title = title;
    setActiveTab(value);
  }

  return (
  <AccountSettingsContext.Provider 
    value={{
      activeTab,
      setActiveTab,
      account, 
      updateAccount, 
      updateAccountFromEvent,
      createOrEditAccount, 
      loading,
      scope,
      LIST_TABS,
      handleTab,
      STATES,
      accountStripeInfo,
      accountReauth,
      categories,
      features,
    }}>
      {children}
    </AccountSettingsContext.Provider>)
};
