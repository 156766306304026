import * as React from 'react';
import Breadcrumb from '../../../components/Breadcrumb';

import {CourseCategoriesFormComponent} from '../../../components/courseCategories/CourseCategoryFormComponent';

function CourseCategoriesEdit({courseCategory}) : JSX.Element {
  document.title = `${window.account}/editar categoria de info produto`;

  const BreadcumbsData = [
    {
      url: `/${window.account}/admin/courses/`,
      icon: 'graduation-cap',
      label: 'Info Produtos',
    },
    {
      label: 'Todas categorias',
      url: `/${window.account}/admin/course-categories/`
    },
    {
      label: 'editar categoria',
      url: null
    }
  ]
  return(
    <>
    <div className="row">
      <div className="col s12">
        <Breadcrumb data={BreadcumbsData} />
      </div>
    </div>
    <div className="row">
      <div className="col s12">
        <h1>Nova categoria</h1>
      </div>
    </div>
    <CourseCategoriesFormComponent category={courseCategory} />
    </>
  )
}

export default CourseCategoriesEdit;