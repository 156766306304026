import * as React from 'react';
import {InputEvent} from '../../types';
import {ButtonToggleContainer, ButtonToggleCleanContainer} from './ButtonToggleContent.style';

export enum ButtonState {
  open = 'open',
  closed = 'closed'
}
type ButtonToggleContentProps = {
  onPress: InputEvent;
  buttonState?: ButtonState;
  title?: string;
}
export const ButtonToggleContent = ({onPress, buttonState = ButtonState.open, title} : ButtonToggleContentProps) : JSX.Element => {
  const [internalButtonState, setInternalButtonState] = React.useState(buttonState);

  React.useEffect(()=>{
    if(internalButtonState !== buttonState) setInternalButtonState(buttonState);
  },[buttonState])

  function toggleContent(){
    const newState = (internalButtonState === ButtonState.open ? ButtonState.closed : ButtonState.open);
    setInternalButtonState(newState);
    onPress(newState);
  }
  return <ButtonToggleContainer onClick={toggleContent} buttonState={internalButtonState} title={title} />;
}

export const ButtonToggleContentClean = ({onPress, buttonState = ButtonState.open, title} : ButtonToggleContentProps) : JSX.Element => {
  const [internalButtonState, setInternalButtonState] = React.useState(buttonState);

  React.useEffect(()=>{
    if(internalButtonState !== buttonState) setInternalButtonState(buttonState);
  },[buttonState])

  function toggleContent(){
    const newState = (internalButtonState === ButtonState.open ? ButtonState.closed : ButtonState.open);
    setInternalButtonState(newState);
    onPress(newState);
  }
  return <ButtonToggleCleanContainer onClick={toggleContent} buttonState={internalButtonState} title={title} />;
}