import * as React from 'react';
import {ButtonDeleteContainer} from './ButtonDeleteComponent.style';
import {InputEvent} from '../../types';

type ButtonDeleteComponentProps = {
  onPress: InputEvent;
  hoverColor?: string;
  confirmMessage?: string;
  name?: string;
  title?: string;
}

export const ButtonDeleteComponent = ({onPress, hoverColor, confirmMessage, name, title}:ButtonDeleteComponentProps) : JSX.Element => {
  const onClick = (event) => {
    if(window.confirm(confirmMessage || `Você tem certeza que deseja apagar este ${name}?`)) onPress(event);
  }
  return <ButtonDeleteContainer hoverColor={hoverColor} onClick={onClick} title={title}/>
}