import * as React from "react";
import {Row, Select} from 'react-materialize';

import {GetPlanInfo, SetStyle} from '../../../services/account/AccountCheckout.service';
import {AccountCheckoutForm} from  './AccountCheckout.component';
import {
  LoadingComponent,
  EmptyPlansComponent, 
} from '../../components';
import {SelectContainer} from './AccountCheckout.style'
const AccountCheckout = ({
  accountImage, 
  accountLabel, 
  accountShortDescription, 
  colors, 
  dimensions, 
  fontSizes, 
  plans,
  formToken
}) : JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const [purchased, setPurchased] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const filteredPlans = plans?.filter(plan => plan.code !== null && !!plan.externalPlans?.length) || []
  const urlPlan = new URLSearchParams(window.location.search).get('plan');

  React.useEffect(() => {
    if(accountLabel){
      document.title = `${accountLabel} Checkout - Personal Millbody`;
    }
    if(urlPlan){
      setSelectedPlan(urlPlan);
      GetPlanInfo(urlPlan, setLoading)
      .then(({plan: planConsulted}) => {
        if(planConsulted) {
          setSelectedPlan(planConsulted);
        }
      });
    } else {

    }
  }, []);

  React.useEffect(()=>{
    if(selectedPlan === null) document.title = `${accountLabel} Checkout - Personal Millbody`;
  }, [selectedPlan])

  React.useEffect(() => {
    SetStyle({colors, dimensions, fontSizes});
  },[colors, dimensions, fontSizes]);

  const selectPlan = (event) => {
    GetPlanInfo(event.target.value, setLoading)
    .then(({plan: planConsulted}) => {
      if(planConsulted) setSelectedPlan(planConsulted)
    });
  }

  const PlanSelectedComponent = () => {
    if(selectedPlan){
      return(
        <Row>
          <div className="col s12">
            <h2>{selectedPlan.name}</h2>
          </div>
          <div className="col s12">
            {selectedPlan.description}
          </div>
          <div className="col s12">
            <div className="flow-text">
              {selectedPlan.installments && selectedPlan.installments > 1 ? (
                <><small>até {selectedPlan.installments}x</small> R${ (parseFloat(selectedPlan.value)/(selectedPlan?.installments || 1)).toFixed(2).replace('.',',') }</>
              ) : (
                <small>R${parseFloat(selectedPlan.value).toFixed(2)}</small>  
              ) }
              
            </div>
          </div>
        </Row>
      )
    }
  }

  const PlanOptionsComponent = () => {
    if(filteredPlans){
      return(
        <>
          {!selectedPlan && (<div className="form-select input-field">
            <SelectContainer>
              <Select name="planCode" className="select-material" onChange={selectPlan} value={selectedPlan?.code || ''}>
                <option value='' disabled>Escolha seu plano</option>
                {filteredPlans.map((plan) => <option key={plan.code} value={plan.code}>{plan.name}</option>)}
              </Select>
            </SelectContainer>
          </div>)}
          <PlanSelectedComponent />
          {!!selectedPlan && (<div><a href="#" className="right" onClick={(event) => {event.preventDefault(); setSelectedPlan(null)}}>Trocar plano</a></div>)}
        </>
      )
    }
  }

  const AccountCheckoutFormComponent = () => {
    const CheckoutProps = {
      setPurchased,
      selectedPlan,
      setLoading,
      formToken,
    }
    return(
      <>
        <div className="row">
            <div className="col s12 m8 offset-m2">
              <div className="flow-text">Seu Plano</div>
              {!!filteredPlans && !!filteredPlans.length && <PlanOptionsComponent />}
              {(!filteredPlans || !filteredPlans?.length) && <EmptyPlansComponent />}
            </div>
        </div>
        <div className="row">
          <div className="col s12 m8 offset-m2">
              {!loading && !!selectedPlan && <AccountCheckoutForm {...CheckoutProps} />}
              {!!loading && !!selectedPlan && <LoadingComponent loading={loading} />}
            </div>
        </div>
      </>
    )    
  }

  const AccountCheckoutConfirmation = () => {
    return(
      <>
        <div className="center flow-text">
          Parabéns! Você adquiriu seu plano {selectedPlan?.name || ''}
        </div>
        <p className="center">Enviamos para seu e-mail uma senha para você acessar.</p>
        <p className="center">Baixe o Aplicativo e faça seu login com a senha enviada e altere em seu perfil.</p>
      </>
    )
  }

  return (
    <div id='checkout-app' style={{backgroundColor: colors?.background || "#EEEEEE", minHeight: '100%', color: colors.text || '#333333'}}>
      <div className="container">
        <div className="row">
          <div className="col s12 center">
              <img src={accountImage} style={{height: 150, width: 150}}/>
              <h1>{accountLabel}</h1>
              <p>{accountShortDescription}</p>
          </div>
        </div>
        {purchased ? <AccountCheckoutConfirmation /> : <AccountCheckoutFormComponent />}
      </div>
    </div>
  );

}

export default AccountCheckout;