import {client} from '../../core/stores';

import {QUERY_GET_PROGRAMS} from '../../core/graphql/types';

type QueryProgramsReturn = {
  status: string;
  data: any[]
  }

type QueryProgramsFunction = { ( filialId : string|number) : Promise<QueryProgramsReturn>; }

export function QueryPrograms(filialId) : QueryProgramsFunction<QueryProgramsReturn> {
  const variables = { filialId };
  return new Promise<QueryProgramsReturn>((resolve, reject) => {
    client('account').query({query: QUERY_GET_PROGRAMS, variables})
    .then( ({data: {programs} }) => resolve({status: 'success', programs}) )
    .catch( err => {
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro ao tentar carrega a lista de programas' });
      M.toast({ html: errorMessage });
      return reject(reject({status: 'error', message: errorMessage}));
    })
  })
}