import React from 'react';
import styled from 'styled-components';
import { Row, TextInput } from 'react-materialize';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: ${({flexRow}) => flexRow ? 'row' : 'column' };
  flex: 1;
  gap: 1rem;
  margin-bottom: 1rem;
  input {
    padding-left: 1rem;
  }
`;

export const InputStyled = styled(TextInput)`
  padding: 1.66rem !important;
  & + label {
    font-weight: 400 !important;
  }
`;


export const SelectElement = styled(Select)<{maxSize?: number}>`
  & + label {
    font-weight: 400 !important;
  }
    & > div {
      max-height: ${({maxSize}) => maxSize ? `${maxSize}px` : '55px'};
    }
  & div[id*="-listbox"] {
    height: inherit !important;
    max-height: inherit !important;
    padding: 10px 0 !important;
  }
  
`;

export const SelectElementWrapper = styled.div`
  .input-field > & > label {
    margin-top: -5px;
    width: 100%;
  }
  .input-field > & {
    margin-top: -20px;
  }
  .invalid + .helper-text {
      color: red;
  }
`;

export const SelectStyled = (props: any) => (
  <SelectElementWrapper>
    {!!props.label && <label>{props.label}</label>}
    <SelectElement {...props} className={`${props.className} select-styled`} />
    {!!props.error && <span className="helper-text" data-error={props.error}>{props.error}</span>}
  </SelectElementWrapper>
)

export const AsyncSelectStyled = (props: any) => (
  <>
    {!!props.label && <label>{props.label}</label>}
    <AsyncSelect {...props} />
    {!!props.error && <span className="helper-text" data-error={props.error}></span>}
  </>
)

export const InputMaskStyled = (props: any) => (
  <InputMaskWrapper className={`input-field ${props?.s || props?.m || props?.l || props?.xl ? `col s${props.s} m${props.m} l${props.l} xl${props.xl}` : ''}`} style={{width: '100%', marginTop: 'none'}}>
    {!!props.label && <label>{props.label}</label>}
    <InputMaskElement {...props} />
    {!!props.error && <span className="helper-text" data-error={props.error}></span>}
  </InputMaskWrapper>
)

export const InputMaskElement = styled(InputMask)`
  padding: 1.66rem !important;
  & + label {
    font-weight: 400 !important;
  }
`;

export const InputMaskWrapper = styled.div`
  margin-top: 0 !important;
  input {
    margin-top: 2px !important;
  }
  label {
    position: inherit !important;
    transform: none !important;
    font-size: 0.8rem !important;
  }
`;

const ButtonElement = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.66rem !important;
  & + label {
    font-weight: 400 !important;
  }
`;

export const ButtonStyled = (props: any) => (
  <ButtonElement {...props} className={`${props.className} btn`} />
)