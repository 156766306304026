import {client} from '../../core/stores';
import {InputEvent} from '../../application/types';
import {MUTATION_PROGRAM_CLASS, MUTATION_DELETE_PROGRAM_CLASS} from '../../core/graphql/types';

export function MutationProgramClass(schedule, setSubmitting?: InputEvent, setProgress?:InputEvent ): Promise {
  return new Promise((resolve,reject) => {
    const errors = {};
    const {startTime, endTime} = schedule;
    const startTimeArray = startTime?.split(':');
    const endtTimeArray = endTime.split(':');

    if(!startTimeArray || startTimeArray.length < 2 || startTimeArray[0] > 23 || startTimeArray[1] > 59) errors['startTime'] = "Campo obrigatório"
    if(!endtTimeArray || endtTimeArray.length < 2 || endtTimeArray[0] > 23 || endtTimeArray[1] > 59) errors['endTime'] = "Campo obrigatório"

    if(errors.startTime || errors.endTime){
      M.toast({html: 'Verifique os campos obrigatórios', displayLength: 5000})
      reject(errors);
      return false;
    }

    client('account').mutate({
      mutation: MUTATION_PROGRAM_CLASS, 
      variables: schedule,
      context: {
        fetchOptions: {
          useUpload: true,
          onProgress: (ev: ProgressEvent) => {
            if(setProgress) setProgress(ev.loaded / ev.total)
          },
          onAbortPossible: (abortHandler: any) => {
          }
        }
      },
    })
    .then(response => {
      const {data} = response;
      const {createOrUpdateProgramClass} = data || {};
      const {programClass} = createOrUpdateProgramClass;
      M.toast({html: 'Horário atualizado com sucesso!'});
      !!setSubmitting && setSubmitting(false);
      resolve({status: 'success', programClass})
    })
    .catch( err => {
      console.log('ERROR MutationProgramClass ==> ', err)
      !!setSubmitting && setSubmitting(false);
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro na sua solicitação.' })
      M.toast({ html: errorMessage })
      reject({status: 'error', message: errorMessage})
    });
  })
}

export function MutationDeleteProgramClass(schedule, setSubmitting?: InputEvent, setProgress?:InputEvent ): Promise {
  return new Promise((resolve,reject) => {
    client('account').mutate({
      mutation: MUTATION_DELETE_PROGRAM_CLASS, 
      variables: {id: schedule.id},
      context: {
        fetchOptions: {
          useUpload: true,
          onProgress: (ev: ProgressEvent) => {
            if(setProgress) setProgress(ev.loaded / ev.total)
          },
          onAbortPossible: (abortHandler: any) => {
          }
        }
      },
    })
    .then(response => {
      const {data} = response;
      const {deleteProgramClass} = data || {};
      const {programClass} = deleteProgramClass;
      M.toast({html: 'Horário atualizado com sucesso!'});
      !!setSubmitting && setSubmitting(false);
      resolve({status: 'success', programClass})
    })
    .catch( err => {
      console.log('ERROR MutationDeleteProgramClass ==> ', err)
      !!setSubmitting && setSubmitting(false);
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro na sua solicitação.' })
      M.toast({ html: errorMessage })
      reject({status: 'error', message: errorMessage})
    });
  })
}