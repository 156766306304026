import {client} from '../../core/stores';
import {InputEvent, PlanInterface} from '../../application/types';
import {MUTATION_PLAN} from '../../core/graphql/types';

export function MutationPlan(plan, setSubmitting?: InputEvent, setProgress?:InputEvent ): Promise<{status: string; plan: PlanInterface}> {
  return new Promise((resolve,reject) => {
    const {name} = plan;
    if(!name){
      M.toast({html: 'Para salvar um plano, você precisa preencher o nome', displayLength: 5000})
      const errors = {name: 'Campo obrigatório'}
      reject(errors);
      return false;
    }

    if(!(plan.cover instanceof File)) delete plan.cover;

    Object.keys(plan).forEach( k => {
      if(typeof plan[k] === 'number'){
       return plan[k] = '' + plan[k]; 
      }
    });

    client('account').mutate({
      mutation: MUTATION_PLAN, 
      variables: plan,
      context: {
        fetchOptions: {
          useUpload: true,
          onProgress: (ev: ProgressEvent) => {
            if(setProgress) setProgress(ev.loaded / ev.total)
          },
          onAbortPossible: (abortHandler: any) => {
          }
        }
      },
    })
    .then(response => {
      const {data} = response;
      const {createOrUpdatePlan} = data || {};
      const {plan : planUpdated} = createOrUpdatePlan;
      M.toast({html: 'Plano salvo com sucesso!'});
      !!setSubmitting && setSubmitting(false);
      resolve({status: 'success', plan: planUpdated})
    })
    .catch( err => {
      console.log('ERROR ==> ', err)
      !!setSubmitting && setSubmitting(false);
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro na sua solicitação.' })
      M.toast({ html: errorMessage })
      reject({status: 'error', message: errorMessage})
    });
  })
}

// export function MutationDeleteProgram(program, setSubmitting?: InputEvent, setProgress?: InputEvent) : Promise {
//   return new Promise((resolve, reject) => {
//     client.mutate({
//       mutation: MUTATION_DELETE_PROGRAM, 
//       variables: {id: program.id},
//       context: {
//         fetchOptions: {
//           useUpload: true,
//           onProgress: (ev: ProgressEvent) => {
//             if(setProgress) setProgress(ev.loaded / ev.total)
//           },
//           onAbortPossible: (abortHandler: any) => {
//           }
//         }
//       },
//     })
//     .then(response => {
//       const {data} = response;
//       const {deleteProgram} = data || {};
//       const {program : programUpdated} = deleteProgram;
//       M.toast({html: 'Programa apagado com sucesso!'});
//       !!setSubmitting && setSubmitting(false);
//       resolve({status: 'success', program: programUpdated})
//     })
//     .catch( err => {
//       console.log('ERROR MutationDeleteProgram ==> ', err)
//       !!setSubmitting && setSubmitting(false);
//       const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
//       M.toast({ html: 'Houve um erro na sua solicitação.' })
//       M.toast({ html: errorMessage })
//       reject({status: 'error', message: errorMessage})
//     });
//   });
// }