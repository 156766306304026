import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {createUploadLink} from 'apollo-upload-client';

const parseHeaders = (rawHeaders: any) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, " ");
  preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
    const parts = line.split(":");
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(":").trim();
      headers.append(key, value);
    }
  });
  return headers;
};

export const uploadFetch = (url: string, options: any) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const opts: any = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || "")
      };
      opts.url =
        "responseURL" in xhr
          ? xhr.responseURL
          : opts.headers.get("X-Request-URL");
      const body = "response" in xhr ? xhr.response : (xhr as any).responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError("Network request failed"));
    };
    xhr.ontimeout = () => {
      reject(new TypeError("Network request failed"));
    };
    xhr.open(options.method, url, true);

    Object.keys(options.headers).forEach(key => {
      xhr.setRequestHeader(key, options.headers[key]);
    });

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress;
    }

    options.onAbortPossible(() => {
      xhr.abort();
    });

    xhr.send(options.body);
  });

const customFetch = (uri: any, options: any) => {
  if (options.useUpload) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};

const HOST = process.env.NODE_ENV === 'development' ? 'http://localhost:7777/' : 'https://box.millbody.com/';

// const link = createHttpLink({
//   uri: `/${window.account}/admin/graphql`,
//   credentials: 'include'
// })

const link = (type: string) => {
  if (type === 'global'){
    return createUploadLink({
      uri: `/global/graphql`,
      credentials: 'include',
      fetch: customFetch as any
    })
  } else {
    return createUploadLink({
    uri: `/${window.account}/admin/graphql/`,
    credentials: 'include',
    fetch: customFetch as any
  })};

  }

const linkAlderaan = createUploadLink({
  uri: `/alderaan/graphql`,
  credentials: 'include',
  fetch: customFetch as any
});

const httpLinkAccount = createUploadLink({
  uri: `/${window.account}/graphql`,
  credentials: 'include',
  fetch: customFetch as any
})

const linkAdminAccount = createUploadLink({
  uri: `/${window.account}/admin/graphql`,
  credentials: 'include',
  fetch: customFetch as any
})

const getToken = () => {
  const user_token = localStorage.getItem('user_token');
  return user_token;
}
const authLink = setContext(async (req, {headers}) => {
  const token = getToken();
  return {
    ...headers,
    headers: {
      authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});

const linkAccount = authLink.concat(httpLinkAccount);

const linkAdmin = authLink.concat(linkAdminAccount);
  
export const client = (type: string) => new ApolloClient({
  link: link(type),
  cache: new InMemoryCache()
})

export const clientAlderaan = new ApolloClient({
  link: linkAlderaan,
  cache: new InMemoryCache()
})

export const clientAccount = new ApolloClient({
  link: linkAccount,
  cache: new InMemoryCache()
})

export const clientAdmin = new ApolloClient({
  link: linkAdmin,
  cache: new InMemoryCache()
})