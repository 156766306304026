import styled from 'styled-components';

export const ButtonRowHeaderContainer = styled.div`
  display: flex;
  position: absolute;
  right: ${ ({right}) => right ? right : '0' };
  top: 50%;
  margin-top: -12px;
  a{
    font-size: 1.1em;
    margin-right: 1vw;
  }
`;