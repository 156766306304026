import React, { useMemo } from 'react';
import { createAvatar } from '@dicebear/core';
import { identicon } from '@dicebear/collection';
import { ImageContainer } from './avatar.component.style';

interface ImageProps {
  source: {
    uri: string;
    alt: string;
  }
  size?: number;
  hideName?: boolean;
  noLink?: boolean;
  rounded?: boolean;
}

type StyleProps = {
  maxWidth: number;
  maxHeight: number;
  margin: number;
  display: string;
  alignItems: string;
  justifyContent: string;
  padding: number;
  overflow?: string;
  borderRadius?: string;
}

export const Image = ({source, size = 200, hideName = false, noLink = false, rounded = false}: ImageProps) => {

  const avatar = useMemo(() => {
    return createAvatar(identicon, {
      size: size,
      seed: source.alt || 'millbody'
    }).toDataUri();
  }, []);

  const style: StyleProps = {
    maxWidth: size,
    maxHeight: size,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0
  }

  if(size) {
    style.overflow = 'hidden';
  }

  if(rounded) {
    style.borderRadius = '50%';
  }

  return (
      <div style={{maxWidth: size, maxHeight: size, margin: 0}}>
        <ImageContainer src={source.uri || avatar} alt={source.alt} />
      </div>
  )
}