"use client"
import * as React from 'react';
import {ApolloProvider, useQuery} from '@apollo/client';

import {client} from '../../../core/stores';
import {QUERY_GET_DASHBOARDS} from '../../../core/graphql/types';

import {MainChartComponent} from './Dashboard.style';

import {
  AccountStripeMissing,
  AccountStripePending,
  CheckinChart, 
  CommentsDashboardComponent,
  RecurringPaymentTip,
  AppTipComponent,
  PlansTipComponent,
  AccountBillingPending,
  UserPlanChart,
  UserPlansEmptyTipComponent,
  UserRelationshopsChart,
} from '../../components';

const Dashboard = (props: any) => {
  document.title = `${window.account}/dashboard`;
  const days = 15;
  const {loading, error, data} = useQuery(
    QUERY_GET_DASHBOARDS, 
    {
      variables: {days}, 
    }, 
  );

  React.useEffect(()=>{
    if(error){
      const errorMessage = error.graphQLErrors.map((err) => err.message).join(',');
      M.toast({ html: 'Houve um erro ao tentar carregar a página.' })
      M.toast({ html: errorMessage })
    }
  },[error])
  
  return (
    <div style={{position: 'relative'}}>
      <h1>Dashboard</h1>
      {!!data?.account?.billingPending && !loading && 
        <div className="row">
          <div className="col s12">
            <AccountBillingPending />
          </div>
        </div>
      }
      {!!data?.account?.accountStripeInfo?.requirements?.currentlyDue.length && !loading && (
        <div className="row">
          <div className="col s12">
            <AccountStripePending account={data?.account} />
          </div>
        </div>
      )}
      {!!data?.account?.code && !data?.account?.accountStripeInfo && !loading && (
        <div className="row">
          <div className="col s12">
            <AccountStripeMissing account={data?.account} />
          </div>
        </div>
      )}
      {data?.account?.totalPlans === 0 && !loading && 
        <div className="row">
          <div className="col s12">
            <PlansTipComponent />
          </div>
        </div>
      }
      {data?.account?.externalStoreEnabled === false && !loading &&
        <div className='row'>
          <div className="col s12">
            <RecurringPaymentTip />
          </div>
        </div>
      }
      {!!data?.account?.id && !data?.account?.app && !loading &&
        <div className="row">
          <div className="col s12">
            <AppTipComponent />
          </div>
        </div>
      }
      <MainChartComponent className="row">
        <div className="col s12 l8">
          <CheckinChart days={days} />
        </div>
        <div className="col s12 l4">
          <CommentsDashboardComponent />
        </div>
      </MainChartComponent>
      {data?.account?.totalPlans > 0 && data?.dashboardPlans?.lastDaysUserPlansTotal === 0 && !loading &&
        <div className="row">
          <div className="col s12">
            <UserPlansEmptyTipComponent />
          </div>
        </div>
      }
      <div className="row">
        <div className="col s12 m6">
          <UserPlanChart dashboardPlans={data?.dashboardPlans} loading={loading} />
        </div>
        <div className="col s12 m6">
          <UserRelationshopsChart />
        </div>
      </div>
    </div>
  )
};

export default function DashboardScreen(props: any) {
  return (
    <ApolloProvider client={client('account')}>
      <Dashboard {...props} />
    </ApolloProvider>
  )
}