import React, { useState } from 'react';
import { Row, Table } from 'react-materialize';
import { useProfile } from '../profile.context';
import { useQuery } from '@apollo/client';
import { GET_USER_POINTS } from '../../querys';
import { PointsGeneral } from './Points.style';
import { TableBody, TableData, TableHeader, TableHeaderContainer, TableRow } from '../../../../components';
import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ButtonStyled } from '../../../../elements/forms/InputContainer';
import { PointsForm } from './PointsForm.component';

const TRANSLATE_OPERATION: { [key: string]: string } = {
  add: '+',
  sub: '-'
}

export const ProfilePoints = () => {
  const {aluno} = useProfile();
  const [showPointsForm, setShowPointsForm] = useState(false);

  const { data, loading, refetch, error } = useQuery(GET_USER_POINTS, {
    variables: {
      userId: aluno.id
    }
  });

  const onClosePointsForm = () => {
    setShowPointsForm(false);
    refetch();
  }

  return (
    <div>
      <Row style={{display: 'flex', justifyContent: 'space-between'}}>
        <div className='flow-text' style={{fontWeight: 600}}>Pontos</div>
      </Row>
      <Row>
        <PointsGeneral>
          {data?.getUserPoints?.totalPoints}
          <small>Ponto(s)</small>
        </PointsGeneral>
      </Row>
      {!showPointsForm && <Row>
        <ButtonStyled onClick={() => setShowPointsForm(true)}>Adicionar/Remover Pontos</ButtonStyled>
      </Row>}
      {showPointsForm && <PointsForm onClose={onClosePointsForm} />}
      {!showPointsForm && <Row>
        <div className='flow-text' style={{fontWeight: 600}}>Histórico</div>
        <Table>
          <TableHeaderContainer>
            <TableRow>
              <TableHeader className='center'>Data</TableHeader>
              <TableHeader className='center'>Valor</TableHeader>
              <TableHeader className='center'>Observação</TableHeader>
            </TableRow>
          </TableHeaderContainer>
          <TableBody>
            {data?.getUserPoints?.points.map((point: any) => (
              <TableRow key={point.id}>
                <TableData className='center'>{formatDistance(new Date(point.createdAt), new Date(), {addSuffix: true, locale: ptBR})}</TableData>
                <TableData className='center' basis={100} mobileTitle>{TRANSLATE_OPERATION[point.operation]}{point.value}</TableData>
                <TableData before='Obs:'><small>{point.obs}</small></TableData>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Row>}
    </div>
  )
}