import React from 'react';
import { useProfile } from '../profile.context';
import { useQuery } from '@apollo/client';
import { GET_USER_CHECKIN_HISTORY } from '../../querys';
import { Row } from 'react-materialize';
import { Table, TableBody, TableData, TableHeader, TableHeaderContainer, TableRow } from '../../../../components';
import { format } from 'date-fns';
import { Badge } from '../../../../components/Badge.component';
import { ButtonStyled } from '../../../../elements/forms/InputContainer';
import { CheckinFormComponent } from './CheckinForm.component';
import { ActionButton } from '../index.style';

const TRANSLATE_STATUS: {[key: string]: string} = {
  validated: 'Validado',
  pending: 'Pendente',
  error: 'Erro',
  deleted: 'Deletado',
  'checkin gympass': 'Checkin Gympass',
  'checkin totalpass': 'Checkin Totalpass',
}

const BADGE_TYPE: {[key: string]: 'success' | 'warning' | 'error'} = {
  validated: 'success',
  pending: 'warning',
  error: 'error',
  deleted: 'error',
  'checkin gympass': 'warning',
  'checkin totalpass': 'warning',
}

export const ProfileCheckin = () => {
  const {activeOption, aluno, filials} = useProfile();
  const [showForm, setShowForm] = React.useState(false);

  if (activeOption !== 'checkins') return null;

  const {data, loading, error, refetch} = useQuery(GET_USER_CHECKIN_HISTORY, {
    variables: {
      userId: aluno.id
    }
  });

  const [checkin, setCheckin] = React.useState(null);

  const onCloseForm = () => {
    setShowForm(false);
    setCheckin(null);
    refetch();
  }

  const handleEditCheckin = (checkin: any) => {
    setCheckin(checkin);
    setShowForm(true);
  }
  return (
    <div>
      <Row>
        <div className='flow-text' style={{fontWeight: 600}}>Checkins</div>
      </Row>
      {!showForm && <Row>
        <ButtonStyled onClick={() => setShowForm(true)}>
          <i className="material-icons left">add</i>
          Adicionar Checkin
        </ButtonStyled>
      </Row>}
      {showForm && <CheckinFormComponent filials={filials} userId={aluno.id} onClose={onCloseForm} checkin={checkin} />}
      <Row>
        <div className='flow-text'>
          <strong>Histórico de Checkins</strong>
        </div>
      </Row>
      <Table>
        <TableHeaderContainer>
          <TableRow>
            <TableHeader style={{maxWidth: 100, width: 100}}>Data</TableHeader>
            <TableHeader className="center" style={{maxWidth: 100, width: 100}} width="25%">Filial</TableHeader>
            <TableHeader className="center">Programa</TableHeader>
            <TableHeader className="center">Hora</TableHeader>
            <TableHeader maxWidth={50} className="center" style={{maxWidth: 200, width: 200}}>status</TableHeader>
            <TableHeader className="hide-on-small-only" style={{maxWidth: 100, width: 100}}>Logs</TableHeader>
            <TableHeader maxWidth={50} className="center" style={{maxWidth: 200, width: 200}}>Ações</TableHeader>
          </TableRow>
        </TableHeaderContainer>
        <TableBody>
          {data?.getUserCheckinHistory?.map((checkin: any) => (
              <TableRow key={checkin.id}>
                <TableData basis={100} mobileTitle><strong>{format(new Date(checkin.date), 'dd/MM/yyyy')}</strong></TableData>
                <TableData basis={45} className="center"><strong>{checkin.filial.name}</strong></TableData>
                <TableData basis={45} className="center">{checkin.program.name}</TableData>
                <TableData basis={45} className="center">{checkin.aula.startTime}</TableData>
                <TableData basis={45} style={{paddingTop: 20, paddingBottom: 20}} className="center">
                  <Badge type={BADGE_TYPE[checkin.status]}>
                    {TRANSLATE_STATUS[checkin.status]}
                  </Badge>
                </TableData>
                <TableData basis={100} before='Logs: '>
                  <small style={{fontSize: '0.7rem', color: '#777777'}}>{checkin.logs}</small>
                </TableData>
                <TableData basis={100}>
                  {new Date(checkin.date).toDateString() === new Date().toDateString() && (
                    <ActionButton onClick={() => handleEditCheckin(checkin)} style={{alignSelf: 'flex-end'}}>
                      <i className="fa fa-pen-to-square" />
                      Editar
                    </ActionButton>
                  )}
                </TableData>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  )
}