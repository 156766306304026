import React, { ChangeEvent, useContext, useEffect } from 'react';
import { RegisterFormContext } from './RegisterForm.context';
import { Column, FormWrapper, View } from './RegisterForm.style';
import { StateEnum } from './Register.schema';
import { Checkbox, RadioGroup, Row } from 'react-materialize';
import { Controller } from 'react-hook-form';
import { InputMaskStyled, InputStyled } from '../../elements/forms/InputContainer';
import { SelectCategory, SelectSubCategory } from '../../components/accounts/SelectCategory.component';
import { handleZipcode } from '../../utils/handleZipcode.util';

export function RegisterFormStep3() {
  const {
    step, 
    onHandleSubmit, 
    isSubmitting, 
    control,
    errors,
    setValue,
    goBack,
    watch,
    validateStep,
    setStep
  } = useContext(RegisterFormContext);

  useEffect(() => {
    if(step == '3'){
      window.scrollTo(0, 0);
    }
  }, [step]);

  const setAddressValue = ({neighborhood, city, street, state} : {neighborhood: string, city: string, street: string, state: string}) => {
    setValue('neighborhood', neighborhood);
    setValue('city', city);
    setValue('street', street);
    setValue('state', state);
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>, onChange: (value: string) => void) => {
    const value = event.target.value.replace(/\D/g, '');
    if(value.length == 8){
      handleZipcode({event, callback: setAddressValue, loading: false, setLoading: () => {}});
    }
    onChange(value);
  }

  const cpf = watch('cpf');
  const zipcode = watch('zipcode');
  const street = watch('street');
  const number = watch('number');
  const neighborhood = watch('neighborhood');
  const city = watch('city');
  const state = watch('state');

  const isValid = cpf && zipcode && street && number && neighborhood && city && state;

  if(step == '3'){
    const isValidLastStep = validateStep(2);
    if(!isValidLastStep){
      setStep(2);
  }

    return (
      <View>
        <h1>Dados para se tornar um profissional</h1>
        <FormWrapper onSubmit={onHandleSubmit} style={{flexDirection: 'column'}}>
            <Row style={{width: '100%'}}>
              <Controller
                name="cpf"
                control={control}
                render={({ field }) => (
                  <InputMaskStyled 
                    {...field} 
                    placeholder='CPF'
                    mask="999.999.999-99"
                    label='CPF'
                    type="tel"
                    s={12}
                    className={errors.cref?.message ? 'invalid' : ''}
                    error={errors.cref?.message}
                  />
                  )}
                />
                <Controller
                name="zipcode"
                control={control}
                render={({ field }) => (
                  <InputMaskStyled 
                    {...field} 
                    placeholder='CEP'
                    label='CEP'
                    type="tel"
                    mask="99999-999"
                    onChange={(event) => {
                      handleChange(event, field.onChange)
                    }}
                    s={6}
                    className={errors.cref?.message ? 'invalid' : ''}
                    error={errors.cref?.message}
                  />
                  )}
                />
                <Controller
                name="street"
                control={control}
                render={({field: {ref, onChange, value}}) => (
                  <InputStyled
                    ref={ref}
                    label="Rua"
                    placeholder="Rua"
                    s={8}
                    value={value}
                    onChange={onChange}
                  />
                  )}
                />
                <Controller
                name="number"
                control={control}
                render={({field}) => (
                  <InputStyled
                    {...field}
                    label="Número"
                    type="number"
                    placeholder="Número"
                    s={4}
                    className={errors.cref?.message ? 'invalid' : ''}
                    error={errors.cref?.message}
                  />
                  )}
                />
                <Controller
                name="complement"
                control={control}
                render={({field}) => (
                  <InputStyled
                    {...field}
                    label="Complemento"
                    placeholder="Complemento"
                    className={`${errors.cref?.message ? 'invalid' : ''} margin-bottom-clear`}
                    error={errors.cref?.message}
                    s={6}
                    />
                  )}
                />
                <Controller
                name="neighborhood"
                control={control}
                render={({field}) => (
                  <InputStyled
                    {...field}
                    label="Bairro"
                    placeholder="Bairro"
                    s={6}
                    />
                  )}
                />
                <Controller
                name="city"
                control={control}
                render={({field}) => (
                  <InputStyled
                    {...field}
                    label="Cidade"
                    placeholder="Cidade"
                    s={6}
                    />
                  )}
                />
                <Controller
                name="state"
                control={control}
                render={({field}) => (
                  <InputStyled
                    {...field}
                    label="Estado (UF)"
                    placeholder="Estado (UF)"
                    s={6}
                    />
                  )}
                />
            </Row>
          <p>
            <span className="helper thin">{!isValid && "Preencha os campos obrigatórios para continuar"}</span>
          </p>
          <div className="center" style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
            <button
              className="btn btn-primary right"
              type="submit"
              onClick={onHandleSubmit}
              disabled={isSubmitting || !isValid}>
              {isSubmitting ? 'Enviando...' : `Continuar`}
            </button>
            <a href="#" onClick={(event) => {
              event.preventDefault();
              goBack();
            }}>Voltar</a>
          </div>
        </FormWrapper>
      </View>
    );
  }
}