import * as React from 'react';
import { SelectValueProps } from '../../../types';
import { MutationFilial } from '../../../../services/admin/filials/MutationFilial';

const VISIBILITIES : SelectValueProps[] = [
  {
    label: 'Público',
    value: 'public'
  },
  {
    label: 'Privado',
    value: 'private'
  }
];

const FILIAL_TYPES : SelectValueProps[] = [
  {
    label: 'Física',
    value: 'place'
  },
  {
    label: 'Online',
    value: 'online'
  }
];

interface FilialInterface {
  id: number|string;
  name: string;
  visibility: 'public' | 'private';
  filialType: 'place' | 'online';
  street?: string;
  number?: string;
  complement?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  token?: string;
  gym_id?: string;
  code: string;
  notificationCode: string;
  lat?: string;
  long?: string;
  gympassSlug?: string;
  status: 'activated' | 'archived';
  gympassId?: string;
  $passIntegration?: string;
  $totalpassToken?: string;
  $totalpassServiceProvider?: string;

}

interface FilialContextProps {
  filial: FilialInterface;
  loading: boolean;
  errors: any;
  scope: string;
  setErrors: (event) => void;
  handleChange: (event) => void;
  handleSubmit: (event) => void;
  handleSelect?: (event) => void;
  handleSelectFilialType?: (event) => void;
  handleSelectVisibility?: (event) => void;
  handleZipcode?: (event) => void;
  visibilities?: SelectValueProps[];
  filialTypes?: SelectValueProps[];

}

export const FilialContext = React.createContext({} as FilialContextProps);

export function FilialContextProvider({ children, filial: filialProps, account, scope }){
  const [filial, setFilial] = React.useState<FilialInterface>(filialProps || {} as FilialInterface);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [visibility, setVisibility] = React.useState<SelectValueProps | undefined>({} as SelectValueProps);
  const [filialType, setFilialType] = React.useState<SelectValueProps | undefined>({} as SelectValueProps);

  React.useEffect(() => {
    if(filialProps){
      setFilialType(FILIAL_TYPES.find(({value}) => value == filialProps.filialType));
      setVisibility(VISIBILITIES.find(({value}) => value == filialProps.visibility));
    }
  }, [filialProps])

  const handleChange = ({target}) => {
    const value = target.type == 'tel' ? (target.value.match(/[\d\.|,]+/) || [])[0] || '' : target.value;
    const newFilial = {...filial, [target.name]: value};
    setFilial(newFilial);
  }

  const handleZipcode = (event) => {
    const {value} = event.target;
    const zipcode = (value).replace(/\D/g, '');
    if(zipcode.length == 8 && !loading){
      setLoading(true);
      fetch(`https://viacep.com.br/ws/${zipcode}/json/`)
        .then(response => response.json())
        .then(data => {
          const {bairro, localidade, logradouro, uf} = data;
          const newFilial = {...filial, neighborhood: bairro, city: localidade, street: logradouro, state: uf};
          setFilial(newFilial);
        })
        .finally(() => setLoading(false));
    }
  }

  const handleSelectFilialType = (selected) => {
    const {value} = selected;
    setFilialType(selected);
    setFilial({...filial, filialType: value});
  }

  const handleSelectVisibility = (selected) => {
    const {value} = selected;
    setVisibility(selected);
    setFilial({...filial, visibility: value});
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    MutationFilial(filial, scope, setLoading)
      .then(({data}) => {
        const {filial} = data;
        setFilial(filial);
      })
      .catch(({graphQLErrors}) => {
        const {message, path} = graphQLErrors[0];
        setErrors({...errors, [path[0]]: message});
      })
      .finally(() => setLoading(false));
  
  }
  return (
    <FilialContext.Provider 
      value={{
        filial, 
        loading, 
        errors, 
        setErrors, 
        handleChange,
        handleSubmit,
        account,
        scope,
        visibilities: VISIBILITIES,
        filialTypes: FILIAL_TYPES,
        handleSelectFilialType,
        handleSelectVisibility,
        handleZipcode,
        filialType,
        visibility,
      }}>
      {children}
    </FilialContext.Provider>
  )
}