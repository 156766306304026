import * as React from 'react';

import ImageTipApp from '../../../assets/images/tip-app-millbody.png';
import ImageTipPlans from '../../../assets/images/tip-planos-millbody.png';
import ImageTipBillings from '../../../assets/images/tip-pagamento-recorrente.png';
import ImageSingleHandAlert from '../../../assets/images/tip-mao-amarela-apontando-curvas-dicas.png';
import ImageDoubleHandsAlert from '../../../assets/images/tip-maos-apontando-curvas-dicas.png';

import {TipContainer, TipContent} from './TipsComponents.style';

export const AppTipComponent = (props) : JSX.Element => {
  return(
    <TipContainer bgcolor="#F9F871">
      <img src={ImageTipApp} alt="Confirgure agora seu app personalizado"/>
      <TipContent>
          Envie agora mesmo a solicitação de seu app personalizado! <a href={`/${window.account}/admin/app`}>Comece agora mesmo!</a>
      </TipContent>
    </TipContainer>
  )
}

export const PlansTipComponent = (props) : JSX.Element => {
  return(
    <TipContainer bgcolor="#6CFACD">
      <img src={ImageTipPlans} alt="Cadastre seus planos"/>
      <TipContent>
          Você ainda não cadastrou nenhum plano. Seus planos determinam os valores e parcelamento que você oferece.<br/>
          <a href={`/${window.account}/admin/plans/new`}>Comece agora mesmo!</a>
      </TipContent>
    </TipContainer>
  )
}

export const UserPlansEmptyTipComponent = (props) : JSX.Element => {
  return(
    <TipContainer bgcolor="#F9F871">
      <img src={ImageTipPlans} alt="Você não techou nenhum novo plano"/>
      <TipContent>
          Você não fechou nenhum plano nos últimos 7 dias. Marque uma conversa com nosso time de especialistas para aumentar suas vendas a cada semana.
      </TipContent>
    </TipContainer>
  )
}

export const AccountBillingPending = (props) : JSX.Element => {
  return(
    <TipContainer bgcolor="#F9F871">
      <img src={ImageTipBillings} alt="Você possui faturas atrasadas"/>
      <TipContent>
          Identificamos faturas em atraso. Evite a suspensão de sua conta. Converse com nosso time de especialistas para resolver.
      </TipContent>
    </TipContainer>
  )
}

export const AccountStripePending = ({account}) : JSX.Element => {
  return(
    <TipContainer bgcolor="#f4ff81" imgPosition='right'>
      <img src={ImageSingleHandAlert} alt="Finalize o envio de seus documentos"/>
      <TipContent>
          Falta pouco para você começar a receber automaticamente seus pagamentos!<br/>
          <a href={`/${account.code}/admin/reauth`}>Clique aqui e finalize seu cadastro!</a>
      </TipContent>
    </TipContainer>
  )
} 

export const AccountStripeMissing = ({account}) : JSX.Element => {
  return(
    <TipContainer bgcolor="#F9F871">
      <img src={ImageDoubleHandsAlert} alt="Habilite sua conta de faturamento!"/>
      <TipContent>
          <a href={`/${account.code}/admin/stripe/create`}> Clique aqui e habilite sua conta para receber pagamentos de forma automática agora mesmo!</a>
      </TipContent>
    </TipContainer>
  )
} 