import {clientAlderaan} from '../../core/stores';
import {MUTATION_ACCOUNT_PLAN_RELATIONSHIP} from '../../core/graphql/types';

export function MutationAccountPlanRelationship(plan, scope, setSubmitting): Promise {
  return new Promise((resolve,reject) => {
    const {accountPlanId, store, paymentMethod} = plan;
    if(!accountPlanId || !store || !paymentMethod){
      M.toast({html: 'Preencha todos os campos obrigatórios antes de salvar', displayLength: 5000})
      reject(false);
      return false;
    }
    
    Object.keys(plan).forEach( k => {
      if(typeof plan[k] === 'number'){
       return plan[k] = '' + plan[k]; 
      }
    })
    clientAlderaan.mutate({
      mutation: MUTATION_ACCOUNT_PLAN_RELATIONSHIP, 
      variables: plan,
    })
    .then(response => {
      const {data} = response;
      const {createOrUpdateAccountPlanRelationship} = data || {};
      M.toast({html: 'Plano atualizado com sucesso!'});
      setSubmitting(false);
      const {accountPlanRelationship: plan} = createOrUpdateAccountPlanRelationship;
      resolve({status: 'success', plan})
    })
    .catch( err => {
      console.log('ERROR ==> ', err)
      setSubmitting(false);
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro na sua solicitação.' })
      M.toast({ html: errorMessage })
      reject({status: 'error', message: errorMessage})
    });
  })
}