import {client} from '../../core/stores';

import {QUERY_GET_ACCOUNT_CATEGORIES, QUERY_GET_ACCOUNT_SUBCATEGORIES} from '../../core/graphql/types';


type QueryGetAccountCategoriesReturn = {
  status: string;
  data: any[];
}

type QueryGetAccountCategoriesFunction<T> = { () : Promise<QueryGetAccountCategoriesReturn>; }

export function useAccountCategories() : QueryGetAccountCategoriesFunction<QueryGetAccountCategoriesReturn> {
  return new Promise<QueryGetAccountCategoriesReturn>((resolve, reject) => {
    client('global').query({query: QUERY_GET_ACCOUNT_CATEGORIES})
    .then( ({data: {accountCategories} }) => resolve({status: 'success', data: [...accountCategories]}) )
    .catch( err => {
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro ao tentar carrega a lista de alunos' });
      M.toast({ html: errorMessage });
      return reject(reject({status: 'error', message: errorMessage}));
    })
  })
}

export function useAccountSubCategories(categoryCode: string) : QueryGetAccountCategoriesFunction<QueryGetAccountCategoriesReturn> {
  return new Promise<QueryGetAccountCategoriesReturn>((resolve, reject) => {
    client('global').query({query: QUERY_GET_ACCOUNT_SUBCATEGORIES, variables: {categoryCode}})
    .then( ({data: {accountSubcategories} }) => {
      resolve({status: 'success', data: [...accountSubcategories]})
    })
    .catch( err => {
      const errorMessage = err.graphQLErrors.map((error) => error.message).join(',');
      M.toast({ html: 'Houve um erro ao tentar carrega a lista de alunos' });
      M.toast({ html: errorMessage });
      return reject(reject({status: 'error', message: errorMessage}));
    })
  })
}