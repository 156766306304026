import * as React from 'react';
import { RegisterFormProvider } from './RegisterForm.context';
import { RegisterFormStep1 } from './RegisterForm.step1';
import { RegisterFormStep2 } from './RegisterForm.step2';
import { RegisterFormStep3 } from './RegisterForm.step3';

function RegisterForm(props) {
  return (
    <RegisterFormProvider {...props}>
      <RegisterFormStep1 />
      <RegisterFormStep2 />
      <RegisterFormStep3 />
    </RegisterFormProvider>
  )
}

export default RegisterForm;
