export function TranslateErrorDropzone(error, mediaType = 'video'){
  const MAX_SIZE = {
    image: '10MB',
    video: '900MB',
    attachment: '5MB',
  }
  const messages = {
    'file-too-large': `Seu arquivo é muito grande. Ele precisa ter no máximo ${MAX_SIZE[mediaType]}`,
    'file-invalid-type': 'Esse formato de arquivo não é aceito'
  }

  if(messages[error.code]){
    return messages[error.code]
  } else {
    return error.message
  }
}