import * as React from 'react';
import {Line} from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

import {useQuery} from '@apollo/client';

import {client} from '../../../core/stores';
import {QUERY_DASHBOARD_USERS} from '../../../core/graphql/types';

import {GenerateChartJsToUserRelationships} from './UserRelationshipsChartComponent.service';
import {
  DashboardHeader, 
  DashboardHeaderItem, 
  DashboardLabel, 
  DashboardData, 
  DashboardDataComparative,
} from './CheckinChartComponent.style';

import {LoadingContent} from '../LoadingComponent';

export const UserRelationshopsChart = () : JSX.Element => {
  const {loading, error, data} = useQuery(QUERY_DASHBOARD_USERS, {variables: {days: 14}});
  const [dashboardUsers, setDashboardUsers] = React.useState({});

  React.useEffect(()=>{
    if(data?.dashboardUsers) setDashboardUsers(data.dashboardUsers);
  },[data, loading])

  const {
    lastDaysUserRelationships, 
    lastDaysUserRelationshipsTotal, 
  } = dashboardUsers || {};

  function getComparative(currentValue, lastValue){
    const result = parseInt(currentValue)-parseInt(lastValue);
    let status, signal;
    if(result > 0){
      status = 'increase';
      signal = '+'
    }else if(result < 0){
      status = 'decrease';
      signal = '-'
    }
    return <DashboardDataComparative status={status}>{signal||''} {result}</DashboardDataComparative>
  }

  return(
    <div className="card" style={{minHeight: 300, position: 'relative'}}>
      <div className="card-content">
        {!!loading && <LoadingContent loading={loading} />}
        <DashboardHeader>
          <DashboardHeaderItem>
            <DashboardLabel>Novos cadastros</DashboardLabel>
            <DashboardData>{lastDaysUserRelationshipsTotal || 0}</DashboardData>
          </DashboardHeaderItem>
          <DashboardHeaderItem></DashboardHeaderItem>
        </DashboardHeader>
        <Line 
          data={GenerateChartJsToUserRelationships(lastDaysUserRelationships)} 
          options={
            {
              minHeight: 350,
              responsive: true, 
              defaultFontSize: 10, 
              layout: {padding: 0}, 
              legend: {position: 'bottom'},
              scales: {
                x: {
                  grid: {
                      display:false
                  }

                },
                y: {
                  grid: {
                      display:true
                  }
                }
            }
            }
          }/>
      </div>
    </div>
  )
}