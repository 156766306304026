import * as React from 'react';
import {MutationProgramClass, MutationDeleteProgramClass} from '../../services/programClass/MutationProgramClass';

interface ProgramsContextProps {
  user: any;
  userIsManager: boolean;
  setUser: (value) => void;
  program: any;
  setProgram: (value) => void;
  filials: any[];
  showModal: boolean;
  createOrUpdateSchedule: (scheduleRaw) => void;
  schedule: any;
  setSchedule: (schedule) => void; 
  closeModal: () => void;
  coaches: any[];
  loading: boolean;
  setLoading?: boolean;
  mutationSchedule: (event) => void;
  deleteSchedule: (schedule) => void;
  errors: any;
  setErrors: (errors) => void;
}

interface ProgramsContextProviderProps {
  children: React.ReactNode;
  user: any;
  filials: any[];
  program: any;
  coaches: any[];
}

export const ProgramsContext = React.createContext({} as ProgramsContextProps);

export function ProgramsContextProvider({ children, user: userProps, filials : filialsProps , program : programProps, coaches : coachesProps } : ProgramsContextProviderProps){
  const [user, setUser] = React.useState(userProps || {});
  const [program, setProgram] = React.useState(programProps || {});
  const [filials, setFilials] = React.useState(filialsProps || []);
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [schedule, setSchedule] = React.useState({});
  const [coaches, setCoaches] = React.useState(coachesProps);
  const [errors, setErrors] = React.useState({})

  const userIsManager = React.useMemo(() => {
    return ['admin','manager'].indexOf(user?.relationship?.rule) > -1
  }, [user]);

  const createOrUpdateSchedule = React.useCallback((scheduleRaw) => {
    setSchedule(scheduleRaw);
    setShowModal(true);
  },[schedule]);

  const closeModal = React.useCallback(() => {
    setShowModal(false);
    setSchedule({});
  }, [showModal])

  const mutationSchedule = React.useCallback((event) => {
    event.preventDefault();
    if(loading) return false;
    setLoading(true);
    const {weekDays = []} = schedule;
    
    const variablees = {
      ...schedule, 
      programId: program.id, 
      classDays: weekDays.map(({id, _destroy, weekDayId, value}) => ({id, _destroy, weekDayId, value}))
    }
    
    MutationProgramClass(variablees)
    .then(({programClass}) => {
      setSchedule(programClass);
      setTimeout(() => {
        document.location.reload(true);
      }, 500);
    })
    .catch(err => {
      console.log('err ProgramsContextProvider ===> ', err);
      setErrors(err);
      setLoading(false);
    })
  }, [schedule]);

  const deleteSchedule = React.useCallback((event) => {
    if(loading) return false;
    setLoading(true);
    const confirmed = confirm(`Você tem certeza que quer excluir esse horário?`);
    if(confirmed){
      MutationDeleteProgramClass(schedule)
      .then((program) => {
        document.location.reload(true);
      })
      .catch( err => {
        setLoading(false);
      })
    } else {
      setLoading(false);
    }
  },[schedule]);
  
  return(
    <ProgramsContext.Provider
      value={{
        user,
        userIsManager,
        setUser,
        program,
        setProgram,
        filials,
        showModal,
        createOrUpdateSchedule,
        schedule,
        setSchedule,
        closeModal,
        coaches,
        loading,
        mutationSchedule,
        deleteSchedule,
        errors,
        setErrors,
      }}
    >
      {children}
    </ProgramsContext.Provider>
  )
}

