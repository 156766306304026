import * as React from 'react';

type IconProps = {
  name: string;
  light?: boolean;
  className?: string;
}

export const Icon = ({name, light, className}: IconProps) : JSX.Element => {
  return (
    <div style={{position: 'relative'}}>
      <i className={`fa${light ? 'l' : ''} fa-${name}`}></i>
    </div>
  );
}