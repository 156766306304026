import styled from "styled-components";

export const MessageAlert = styled.div`
  background-color: ${({type}) => type === 'success' ? '#b2ebf2' : '#ffc107'};
  border-radius: 4px;
  font-weight: 400;
  margin: 0 auto;
  padding: 10px 20px;
  text-align: center;
  width: 100%;
`;