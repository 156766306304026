import styled from 'styled-components';
import {COLOR} from '../../constants';

import {ButtonState} from './ButtonToggleContent';

export const ButtonToggleContainer = styled.a`
  color: ${COLOR.TEXT}77;
  cursor: pointer;
  height: 100%;
  text-decoration: none;
  &:before{
    content: "${({buttonState}) => buttonState === ButtonState.closed ? '\f0fe' : '\f146'}";
    font-family: 'Font Awesome 6 Pro';
  }
  &:hover{
    color:${({hoverColor}) => hoverColor ? hoverColor : COLOR.ALERT};
  }
`;

export const ButtonToggleCleanContainer = styled.a`
  color: ${COLOR.TEXT}77;
  cursor: pointer;
  height: 100%;
  text-decoration: none;
  &:before{
    content: "${({buttonState}) => buttonState === ButtonState.closed ? '\f078' : '\f077'}";
    font-family: 'Font Awesome 6 Pro';
  }
  &:hover{
    color:${({hoverColor}) => hoverColor ? hoverColor : COLOR.ALERT};
  }
`;