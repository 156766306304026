import * as React from 'react';
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, RegisterOptions, SubmitHandler, useForm } from "react-hook-form";
import { RegisterFormProps, RegisterFormSchema } from './Register.schema';
import { useMutation } from '@apollo/client';
import { CREATE_ACCOUNT_MUTATION } from '../../../core/graphql/mutation';
import { useNavState } from '../../components/hookts/useNavState';
import { client } from '../../../core/stores';
import { LoadingContent } from '../../components';


interface IRegisterFormContext {
  Controller: typeof Controller;
  register: RegisterOptions;
  handleSubmit: SubmitHandler<RegisterFormProps>;
  control: any;
  errors: any;
  isSubmitting: boolean;
  step: string;
  onHandleSubmit: any;
  accountCategories: any[];
  getValues: () => RegisterFormProps;
  goBack: () => void;
  setValue: (name: string, value: any) => void;
  watch: (name: string) => string;
  validateStep: (step: number) => boolean;
  setStep: (step: number) => void;
}

export const RegisterFormContext = React.createContext<IRegisterFormContext>({} as IRegisterFormContext);

type RegisterFormProviderProps = {
  accountCategories?: any[];
  token?: string;
  children: React.ReactNode;
}

export function RegisterFormProvider({children, accountCategories, token, ...rest}: RegisterFormProviderProps) : JSX.Element {
  const [step, setStep] = useNavState<string | number>('step', '1');
  const [createAccount, {data, loading, error}] = useMutation(CREATE_ACCOUNT_MUTATION, {client: client('global')});
  const dataLayer = global?.window?.dataLayer  || [];
  const [isLoading, setIsLoading] = React.useState(false);

  const { 
    register, 
    handleSubmit, 
    control, 
    getValues,
    setValue,
    formState: { errors, isSubmitting, isValid : isValidForm },
    watch
  } = useForm<RegisterFormProps>({
    resolver: zodResolver(RegisterFormSchema),
    mode: "onChange",
  });
  const values = getValues();
  const atuation_type = watch('atuation_type');

  const goBack = () => {
    setStep(parseInt(`${step}`) > 1 ? parseInt(`${step}`) - 1 : 1);
  }


  const onSubmit: SubmitHandler<RegisterFormProps> = (data) => {
    if(step == 1) {
      dataLayer.push({
        event: 'register',
        step: '1',
        data: data
      });
      setStep(2);
    } else if(step == 2) {
      dataLayer.push({
        event: 'register',
        step: '2',
        data: data
      });
      setStep(3);
    } else if(step == 3) {
      dataLayer.push({
        event: 'register',
        step: '3',
        data: data
      });
      let avatar;
      if(data.avatar?.path){
        avatar = data.avatar;
      }
      const name = data.name.split(' ')[0];
      const lastName = data.name.split(' ').slice(1).join(' ');
      setIsLoading(true);
      const variables = {
        ...data,
        name,
        lastName,
        avatar
      };
      if(!data?.avatar?.path){
        delete variables.avatar;
      }

      createAccount({
        variables
      })
    }
  }

  React.useEffect(() => {
    if(data?.createAccount){
      dataLayer.push({
        event: 'register',
        step: 'success',
        data: data?.createAccount?.account
      });
      setIsLoading(true);
      M.toast({html: 'Conta criada com sucesso! Já pode acessar o sistema.'});
      M.toast({html: 'Entre no sistema com seu email e senha que acabou de criar.'})
      window.location.href = `/${data.createAccount.account.code}/admin`;
    }
  }, [data]);

  React.useEffect(() => {
    if(error){
      setIsLoading(false);
      M.toast({html: 'Erro ao criar conta. Por favor, tente novamente.'});
      console.log('error', error);
    }
  }, [error]);

  const validateStep = React.useCallback((step: number) => {
    if(step == 1) {
      const {name, email, phone, password} = getValues();
      return name?.length > 0 && email?.length > 0 && phone?.length > 0 && password?.length > 0;
    }
    if(step == 2) {
      const {category, subcategory, atuation_type} = getValues();
      return category?.length > 0 && atuation_type?.length > 0;
    }
    if(step == 3) {
      const {cpf, zipcode, street, number, neighborhood, city, state} = getValues();
      return cpf?.length > 0 && zipcode?.length > 0 && street?.length > 0 && number?.length > 0 && neighborhood?.length > 0 && city?.length > 0 && state?.length > 0;
    }
  }, [getValues]);

  const onHandleSubmit: SubmitHandler<RegisterFormProps> = handleSubmit(onSubmit);
  if (loading) return <LoadingContent loading={loading || isLoading} />
  return (
    <RegisterFormContext.Provider 
      value={{
        Controller,
        register, 
        handleSubmit, 
        control, 
        errors, 
        isSubmitting, 
        step,
        setValue,
        onHandleSubmit,
        accountCategories,
        getValues,
        goBack,
        watch,
        token,
        validateStep,
        setStep
      }}>
      {children}
    </RegisterFormContext.Provider>
  )
}
