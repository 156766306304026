import {gql} from '@apollo/client';

export const COURSE_CATEGORY_TYPE = `
  id
  name
  description
`

export const MUTATION_COURSE_CATEGORY = gql`
  mutation createOrUpdateCourseCategory(
    $id: ID,
    $name: String!,
    $description: String
  ){
    createOrUpdateCourseCategory(input: {
      id: $id,
      name: $name,
      description: $description,
    }){
      courseCategory {
        ${COURSE_CATEGORY_TYPE}
      }
    }
  }
`

export const MUTATION_DELETE_COURSE_CATEGORY = gql`
  mutation deleteProgram(
    $id: ID!
  ){
    deleteProgram(input: {
      id: $id,
    }){
      program {
        ${COURSE_CATEGORY_TYPE}
      }
    }
  }
`