import styled from 'styled-components';

export const ProfileControllerContainer = styled.div`
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 3;
  flex-direction: column;
  padding: 10px;
  position: relative;
  width: 100%;
`;

export const ProfileControllerHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid #E0E0E0;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;
`;

export const ProfileControllerOption = styled.div<{active: boolean}>`
  ${({active}) => {
    if (active) {
      return `
        border-bottom: 2px solid #006bf3;
        color: #006bf3;
        font-weight: 600;
      `
    }
  }}
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  justify-content: space-between;
  @media (max-width: 768px) {
    & > span {
      display: none;
    }
  }
`;