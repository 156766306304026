import {gql} from '@apollo/client';
import {IMAGE_TYPE} from './mediaType';

export const AUTHOR_TYPE = `
  id
  avatar{
    ${IMAGE_TYPE}
  }
  name
`;

export const USER_TYPE = `
  id
  name
  shortName
  status
  avatar{
    ${IMAGE_TYPE}
  }
  cpf
  birthdate
  fullName
  gender
  gympassEnabled
  gympassId
  hasPremiumAccess
  maritalStatus
  phone
  email
  points
`;

export const QUERY_GET_ALUNOS = gql`
  query GetAlunos($page: Int, $search: String){
    alunos(page: $page, search: $search){
      list {
        ${USER_TYPE}
      }
      page
      totalEntries
    }
  }
`;