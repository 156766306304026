import {gql} from '@apollo/client';
import {IMAGE_TYPE} from './mediaType';

export const ACCOUNT_CATEGORY_TYPE = `
  id
  code
  name
  description
`;
export const QUERY_GET_ACCOUNT_CATEGORIES = gql`
  query GetAccountCategories{
    accountCategories{
      ${ACCOUNT_CATEGORY_TYPE}
    }
  }
`;

export const QUERY_GET_ACCOUNT_SUBCATEGORIES = gql`
  query GetAccountSubcategories($categoryCode: String!){
    accountSubcategories(categoryCode: $categoryCode){
      ${ACCOUNT_CATEGORY_TYPE}
    }
  }
`;
