import styled from 'styled-components';
import {COLOR, DIMENSION} from '../../constants';

export const WorkoutContainer = styled.div`
  background-color: ${COLOR.BODY};
  border: 1px solid #EDEDED;
  padding: 15px;
  padding-top: calc(1vw + 120px);
  position: relative;
  margin: 0 0 2vh 0;
  &.clean-top{
    padding-top: 15px;
  }
  &.add-new{
    background-color: #E6F4F177;
    cursor: pointer;
  }
`;

export const WorkoutContainerHead = styled.div`
  background: #E6F4F1;
  display: flex;
  left: 0;
  padding: 5px;
  padding-left: 50px;
  padding-right: 70px;
  position: absolute;
  right: 0;
  top: 0;
  input{
    background-color: transparent !important;
    border-bottom: 1px solid #CCC !important;
  }
  &:before{
    color: #CCCCCC;
    cursor: grab;
    font-family: 'Font Awesome 6 Pro';
    left: 10px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
  }
  .select-dropdown{
    background-color: ${COLOR.BODY} !important;
  }
  .select-wrapper .caret{
    z-index: 1;
  }
  select{
    display: none;
  }
  @media screen and (max-width: 768px){
    &{
      padding-right: 40px;
    }
  }
`;

export const WorkoutContainerHeader = styled.div`
  display: flex;
  flex: 1;
  @media screen and (max-width: 768px){
    input, label {
      font-size: 1.55vh;
    }
  }
`;

export const WorkoutContainerEmpty = styled.div`
    color: #777777;    
    font-size: 1.33em;
    text-align: center;
    &:before{
      content: '\f067';
      font-family: 'Font Awesome 6 Pro';
      margin-right: 10px;
    }
`;

export const WorkoutContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const WorkoutExerciseTitle = styled.div`
  font-size: 2.33vh;
  margin-bottom: 2vh;
`;