import * as React from 'react';
import {ProgramForm} from './ProgramForm';
import Breadcrumb from '../../components/Breadcrumb';

const BreadcumbsData = [
  {
    url: `/${window.account}/admin/workout-routines/`,
    icon: 'stopwatch',
    label: 'Treinos',
  },
  {
    url: `/${window.account}/admin/programs/`,
    label: 'Programas de treino',
  },
  {
    label: 'Novo programa de treino',
    url: null
  }
]

function ProgramNew({program, filials}){
  document.title = `${window.account}/admin/novo programa de treino`

  return(
    <>
      <div className="row">
        <div className="col s12">
          <Breadcrumb data={BreadcumbsData} />
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <h1>Programa de treino</h1>
        </div>
      </div>
      <ProgramForm program={program} filials={filials} action={'Criar'} />
    </>
  )
}

export default ProgramNew;