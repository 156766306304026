import { gql } from '@apollo/client';

export const CREATE_OR_UPDATE_PLAN_TO_USER = gql`
  mutation CreateOrUpdatePlanToUser(
    $id: ID,
    $userId: ID!, 
    $planId: ID!, 
    $startDate: ISO8601DateTime, 
    $endDate: ISO8601DateTime, 
    $status: String,
    $installments: Int,
    $paymentMethod: String,
    $discount: Float, 
    $discountType: String,
  ) {
    createOrUpdatePlanToUser(
      input: {
        id: $id,
        userId: $userId, 
        planId: $planId, 
        startDate: $startDate, 
        endDate: $endDate, 
        status: $status,
        installments: $installments,
        paymentMethod: $paymentMethod,
        discount: $discount, 
        discountType: $discountType
      }
    ) {
      userPlan {
        id
      }
    }
  }
`;

export const SET_PAYMENT_RECEIVED_MUTATION = gql`
  mutation SetPaymentReceived($id: ID!, $received: Boolean!) {
    setPaymentReceived(input: {id: $id, received: $received}) {
      planPayment {
        id
        received
      }
    }
  }
`;