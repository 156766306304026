import styled from 'styled-components';
import {COLOR, DIMENSION} from '../../constants';

export const AccountContainerElement = styled.form`
  display: flex;
  max-width: 1000px;
  h2 {
    font-size: 1.88em;
    font-weight: 700;
  }
  h3{
    font-size: 1.33em;
    font-weight: 300;
  }
  select {
    display: none;
  }
  @media screen and (max-width: 768px){
    &{
      flex-direction: column;
    }
    & > div, & ul {
      padding-bottom: 0px;
      padding-top: 0px;
      margin: 0px;
    }
    & ul {
      display: flex;
      overflow: auto;
      max-width: 100%;
    }
  }
`;

export const AccountContainerController = styled.div`
  background-color: ${COLOR.BODY};
  flex:.7 1 0%;
  padding: ${DIMENSION.PADDING_DEFAULT}px 0;
`;

export const ControllerList = styled.ul``;

export const ControllerListItem = styled.li`
  background-color: ${COLOR.BODY};
  /* box-shadow: ${({active}) => active ? 'rgba(0,0,0,0.1) 0 0 10px' : 'none'}; */
  border-bottom: ${({active}) => active ? '3px solid ' + COLOR.PRIMARY : 'none'};
  cursor: pointer;
  font-weight: ${({active}) => active? '500' : 'inherit'};
  padding: ${DIMENSION.PADDING_DEFAULT/2}px ${DIMENSION.PADDING_DEFAULT}px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
  &:hover{
    background-color: ${({active}) => active ? COLOR.BODY : COLOR.BODY + '77'};
  }
`;

export const AccountContainerContent = styled.div`
  background-color: ${COLOR.BODY};
  flex: 3;
  padding: ${DIMENSION.PADDING_DEFAULT}px;
  
  @media screen and (max-width: 768px){
    &{
      box-shadow: rgba(0,0,0,0.2) 0 -2px 15px;
    }
  }
`;

export const SelectContainer = styled.div`
  select {
    display: none;
  }
`;

export const CodeContainer = styled.div`
  background-color: #DDDDDD;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 5px;
  width: 100%;
`;


export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  @media screen and (max-width: 768px){
    &{
      flex-direction: column;
    }
  }
  & > div {
    flex: 1;
  }
`;

