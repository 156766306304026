import * as React from 'react';

import {Select, TextInput, Switch} from 'react-materialize';

import {PickerDate} from '../../DatePicker';

import {AccountPlanProvider, AccountPlanRelationshipContext} from '../../../context/alderaan/AccountPlanRelationship.context';
import {AccountPlanFormElement, SelectContainer} from './AccountPlanRelationshipFormContainer.style';

const AccountPlanFormComponent = ({account, discountTypes, plans, paymentMethods, stores}) : JSX.Element => {
  document.title = `Contas > ${account.name} > Adicionar Plano`;

  const {plan, errors, updateAccountPlanFormEvent, scope, planPreview, planSelected, discountEnabled, setDiscountEnabled} = React.useContext(AccountPlanRelationshipContext);
  const {accountPlanId, paymentMethod, startPlan, discountType, discountValue, store, installments} = plan;

  function renderPreview(){
      return(
        <>
          <TextInput
            s={12} 
            value={`${planPreview.planValue}`} 
            onChange={(event) => event}
            label="Valor do Plano"  
            disabled
            readOnly
          />
          <TextInput
            s={6} 
            value={`${planPreview.recurrenceQuantity}`} 
            onChange={(event) => event}
            label="Quantidade" 
            disabled
            readOnly
          />
          <TextInput 
            s={6} 
            value={`${planPreview.recurrence}`} 
            onChange={(event) => event}
            label="Recorrência" 
            disabled
            readOnly
          />
          <TextInput 
            s={6}
            value={`${planPreview.typeValue}`} 
            onChange={(event) => event}
            label="Quantidade" 
            disabled
            readOnly
          />
          <TextInput 
            s={6} 
            value={`${planPreview.type}`}
            onChange={(event) => event}
            label="Tipo" 
            disabled
            readOnly
          />
          {discountEnabled && (<TextInput 
            s={12} 
            value={`${planPreview.discount}`} 
            onChange={(event) => event}
            label="Desconto" 
            disabled
            readOnly
          />)}
          <TextInput 
            s={12} 
            value={`${planPreview.totalValue}`} 
            onChange={(event) => event}
            label="Total" 
            disabled
            readOnly
          />
        </>
      )
  }
  return(
    <div className="">
      <div className="row">
        <div className="col s12 m6">
          <h2>Plano</h2>
          <div className="row">
            <div className="col s6">
              <SelectContainer>
                <Select
                  id="accountPlanId"
                  name="accountPlanId"
                  onChange={updateAccountPlanFormEvent}
                  value={`${accountPlanId || ''}`}
                  label="Selecione um plano"
                  s={12}
                >
                  <option value="">Selecione um plano</option>
                  {plans.map((accountPlan) => (
                    <option key={accountPlan.code} value={accountPlan.id}>{accountPlan.name}</option>
                  ))}
                </Select>
              </SelectContainer>
            </div>
            <div className="col s6">
              <PickerDate
                id='startPlan'
                value={startPlan}
                name='startPlan'
                label='Início do Plano'
                onChange={updateAccountPlanFormEvent}
                prefilled={true}
              />
            </div>
            <div className="col s12">
              <div className="row">
                <div className="col s12 m6">
                  <SelectContainer>
                    <Select
                      id="paymentMethod"
                      name="paymentMethod"
                      onChange={updateAccountPlanFormEvent}
                      value={`${paymentMethod || paymentMethods[0]}`}
                      label="Tipo de Pagamento"
                      s={12}
                    >
                      {paymentMethods.map((payment) => (
                        <option key={payment} value={payment}>{payment}</option>
                      ))}
                    </Select>
                  </SelectContainer>
                </div>

                <div className="col s12 m3">
                  <SelectContainer>
                    <Select
                      id="store"
                      name="store"
                      onChange={updateAccountPlanFormEvent}
                      value={`${store || stores[0]}`}
                      label="Local de cobrança"
                      s={12}
                    >
                      {stores.map((st) => (
                        <option key={st} value={st}>{st}</option>
                      ))}
                    </Select>
                  </SelectContainer>
                </div>

               {planSelected?.maxInstallments > 1 && ( <div className="col s12 m3">
                  <SelectContainer>
                    <Select
                      id="installments"
                      name="installments"
                      onChange={updateAccountPlanFormEvent}
                      value={`${installments || 1}`}
                      label="Parcelas"
                      s={12}
                    >
                      {Array(planSelected.maxInstallments).fill(0).map((n, indexInstallment) => (
                        <option key={indexInstallment+1} value={indexInstallment+1}>{indexInstallment+1}</option>
                      ))}
                    </Select>
                  </SelectContainer>
                </div>)}
              </div>

              <div className="row">
                <div className="col s6">
                    <label htmlFor="discountEnabled">Aplicar desconto?</label>
                    <Switch
                    offLabel="Off"
                    onLabel="On"
                    id="discountEnabled"
                    name="discountEnabled"
                    checked={discountEnabled}
                    onChange={() => setDiscountEnabled(!discountEnabled)}
                    />
                </div>
              </div>

             {discountEnabled &&( <div className="row">
                <TextInput 
                  s={12} 
                  m={6}
                  id="discountValue" 
                  name="discountValue"
                  value={`${discountValue || ''}`} 
                  error={errors.name}
                  label="Desconto" 
                  inputClassName={errors?.name ? 'invalid' : ' '} 
                  onChange={updateAccountPlanFormEvent}
                />
                <div className="col s12 m6">
                  <SelectContainer>
                    <Select
                      id="discountType"
                      name="discountType"
                      onChange={updateAccountPlanFormEvent}
                      value={`${discountType}`}
                      label="Tipo de Desconto"
                      s={12}
                    >
                      {discountTypes.map((dType) => (
                        <option key={dType} value={dType}>{dType}</option>
                      ))}
                    </Select>
                  </SelectContainer>
                </div>
              </div>)}
              
            </div>
          </div>

        </div>
        <div className="col s12 m6 blue lighten-5">
          <h2>Preview</h2>
          <div className="row">
            <div className="col s12">
              {renderPreview()}
            </div>
          </div>
        </div>
        <div className="col s12">
        </div>
      </div>
    </div>
  )
}

const AccountPlanForm = (props) => {
  const {loading, createOrEditAccountPlanRelationship} = React.useContext(AccountPlanRelationshipContext);
  const {plan, scope, planPathState: path} = props;

  function setFormAction(){
    if (scope === 'alderaan' && path === 'new'){
      return `/alderaan/plans`
    } else if (scope === 'alderaan' && path === 'edit'){
      return `/alderaan/plans/${plan.id}/`
    }
  }
  return(
    <AccountPlanFormElement method="POST" action={setFormAction()} onSubmit={createOrEditAccountPlanRelationship}>
        <AccountPlanFormComponent {...props} />
        <div className="col right"><button type="submit" onSubmit={createOrEditAccountPlanRelationship} disabled={loading} className="btn btn-primary">{path == 'new' ? 'Adicionar' : 'Atualizar'}</button></div>
    </AccountPlanFormElement>
  )
}

export function AccountPlanRelationshipFormContainer(props) : JSX.Element {
  return(
    <AccountPlanProvider {...props}>
      <AccountPlanForm {...props} />
    </AccountPlanProvider>
  )
}