import * as React from 'react';

import {TabsHeaderProps} from './TabsHeader.type';
import {TabsHeaderList, TabHeaderItem} from './TabsHeader.style';

import {WorkoutRoutineContext} from '../context/WorkoutRoutineContext';

export const TabsHeader = (props: TabsHeaderProps) : JSX.Element => {
  const {onPress, tabs, tabActive} = props;
  const [activeTab, setActiveTab] = React.useState('');

  const {
    errors,
  } = React.useContext(WorkoutRoutineContext);

  const setTitleToTab = (tab) => {
    document.title = `${window.account}/admin - ${tab.label}`;
  }
  function checkTabActive(){
    const tabsActive = tabs.filter(tab => tab.active === true);
    if(tabsActive.length){
      setActiveTab(tabsActive[0].value);
      setTitleToTab(tabsActive[0])
    } else {
      setActiveTab(tabs[0].value);
      setTitleToTab(tabs[0])
    }
  }

  React.useEffect( () => {
    if(tabActive) setActiveTab(tabActive)
  }, [tabActive])

  const onChangeTab = (tabValue) => {
    setActiveTab(tabValue);
    tabs.map(tab => {
      const newTab = Object.assign({}, tab);
      if(newTab.value === tabValue){
        newTab.active = true
        setTitleToTab(newTab)
      } else {
        newTab.active = false;
      }
      return newTab;
    });
    onPress(tabValue);
  }
  !activeTab && checkTabActive();
  return(
    <TabsHeaderList className="tabs-header-list">
      {tabs.map( ({icon, label, value},index) => (
        <TabHeaderItem 
          key={index} 
          onClick={() => onChangeTab(value)} 
          active={activeTab === value} 
          errors={ 
            (activeTab === 'edit' && (errors.target || errors.name || errors.startDate || errors.endDate) && 'errors') 
            || (activeTab !== 'edit' && !!errors.workoutGroups && 'errors') 
          }
          className={ `tab-header-item ${activeTab === value && 'active'}`}>
          {!!icon && <span className='tab-header-icon'><i className={`fal fa-${icon}`}></i>&nbsp;</span>}
          <span>{label}</span>
        </TabHeaderItem>
      ))}
    </TabsHeaderList>
  )
}