import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { Controller, Form, useForm } from 'react-hook-form';
import { Row, Switch } from 'react-materialize';
import { z } from 'zod';
import { ButtonStyled, InputMaskStyled, InputStyled, SelectStyled } from '../../../elements/forms/InputContainer';
import { PlanFormRow } from '../alunos/components/PlanForm.style';
import {Editor} from '@tinymce/tinymce-react';
import { CampaignFormContainer, CampaignFormFooter } from './CampignForm.style';
import { useMutation } from '@apollo/client';
import { CREATE_OR_UPDATE_CAMPAIGN_MUTATION } from '../mutations';
import { LoadingContent } from '../../../components';
import { client } from '../../../../core/stores';

const schema = z.object({
  id: z.union([z.number(), z.string()]).nullish(),
  title: z.string({
    invalid_type_error: 'Campo obrigatório',
  }).min(1, {message: 'Campo obrigatório'}),
  description: z.string({
    invalid_type_error: 'Campo obrigatório',
  }).min(1, {message: 'Campo obrigatório'}),
  status: z.string().nullish(),
  activity: z.string().nullish(),
  templateType: z.string().nullish(),
  frequency: z.string().nullish(),
  time: z.string().nullish(),
  notificationChannel: z.enum(['email', 'sms', 'E-mail', 'Push', 'push', 'whatsapp']).nullish(),
  senderEmail: z.string().email().nullish(),
  senderName: z.string().nullish(),
  toBcc: z.string().email().nullish(),
})

const STATUS_OPTIONS = [
  {label: 'Ativo', value: 'active'},
  {label: 'Arquivado', value: 'archived'},
  {label: 'Rascunho', value: 'draft'},
  {label: 'Finalizado', value: 'finished'},
]

const TRIGGER_OPTIONS = [
  {label: 'Checkin', value: 'checkin'},
  {label: 'Fim do Plano', value: 'end_plan'},
  {label: 'Início do Plano', value: 'start_plan'},
  {label: 'Novo Cadastro', value: 'new_register'},
  {label: 'Movimentação de Pontos', value: 'moviment_points'},
  {label: 'Uma vez', value: 'single_dispatch'},
]

const SEGMENTATION_OPTIONS = [
  {label: 'Todos', value: ''},
  {label: 'Status', value: 'status'},
  {label: 'Gympass', value: 'gympass'},
  {label: 'Totalpass', value: 'totalpass'},
  {label: 'Plano Normal', value: 'plan'},
]

const FREQUENCY_OPTIONS = [
  {label: 'Diário', value: 'daily'},
  {label: 'Semanal', value: 'weekly'},
  {label: 'Mensal', value: 'monthly'},
  {label: 'Uma vez', value: 'one_time'},
]

export default function CampaignForm({campaign}: {campaign: any}) {
  const [customSender, setCustomSender] = useState(false);
  const {control, handleSubmit, formState: {errors}, reset, watch, getValues} = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      status: 'draft',
      activity: 'single_dispatch',
      templateType: '',
      frequency: 'one_time',
      time: '',
      notificationChannel: 'email',
    }
  });

  const [submitCampaign, {data, loading, error}] = useMutation(CREATE_OR_UPDATE_CAMPAIGN_MUTATION, {client: client('account')});

  useEffect(() => {
    if(campaign) {
      reset(campaign);
    }
  }, [campaign]);

  useEffect(() => {
    if(campaign?.senderEmail) {
      setCustomSender(true);
    }
  }, [campaign]);

  const onSubmit = (data: any) => {
    submitCampaign({variables: data});
  }
  const activity = watch('activity');

  useEffect(() => {
    if (data) {
      M.toast({html: 'Mensagem salva com sucesso'});
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      console.log("error", error);
      M.toast({html: 'Erro ao salvar mensagem'});
    }
  }, [error]);

  console.log("errors", errors);
  console.log("getValues", getValues());

  if (loading) return <LoadingContent loading={loading} />

  return (
    <div className="col s12">
      <div style={{display: 'flex', alignItems: 'center', gap: 10, marginBottom: 20, cursor: 'pointer'}} onClick={() => window.history.back() }>
        <i className="fa fa-arrow-left"></i>
        Voltar
      </div>
        <Row>
          <div className="col s12">
            <Form control={control} onSubmit={handleSubmit(onSubmit)}>
              <CampaignFormContainer>
                <div className="card" style={{flex: 2}}>
                  <div className="card-content">
                    <div className="card-title">
                      <h5>Mensagem</h5>
                    </div>
                  <PlanFormRow>
                  <Controller
                      control={control}
                      name="title"
                      render={({field}) => (
                        <InputStyled 
                        {...field} 
                        label="Assunto"
                        placeholder="Assunto"
                        error={errors.title?.message}
                        className={errors.title?.message ? 'invalid' : ''}
                        />
                      )}
                    />
                  </PlanFormRow>
                  <PlanFormRow>
                    <Controller
                      control={control}
                      name="description"
                      render={({field}) => (
                        <div className="col s12">
                          <label htmlFor="description">Conteúdo</label>
                          <Editor
                            apiKey="uf8080t2ry6l3tf8a65rx8jewpqri1l0f2xmpht44kfpekud"
                            value={field.value}
                            init={{
                              height: 500,
                              menubar: true,
                              language: 'pt_BR',
                              plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'help', 'wordcount'
                              ],
                              toolbar:
                                'undo redo | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | removeformat | help',
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            }}
                            onEditorChange={field.onChange}
                          />
                        </div>
                      )}
                    />
                  </PlanFormRow>
                  </div>
                </div>
                <div className="card" style={{flex: 1}}>
                  <div className="card-content">
                    <div className="card-title">
                      <h5>Configurações</h5>
                    </div>
                    <PlanFormRow>
                      <Controller
                        control={control}
                        name="status"
                        render={({field}) => (
                          <SelectStyled 
                            {...field}
                            label="Status"
                            placeholder="Status"
                            options={STATUS_OPTIONS}
                            error={errors.status?.message}
                            value={STATUS_OPTIONS.find(option => option.value === field.value)}
                            onChange={({value}) => field.onChange(value)}
                            className={errors.status?.message ? 'invalid' : ''}
                          />
                        )}
                      />
                    </PlanFormRow>
                    <PlanFormRow>
                      <Switch
                        checked={customSender}
                        onLabel="Personalizar Remetente"
                        offLabel=""
                        onChange={() => setCustomSender(!customSender)}
                      />
                    </PlanFormRow>
                    <PlanFormRow>
                      {customSender && (
                        <>
                          <Controller
                            control={control}
                            name="senderName"
                            render={({field}) => (
                              <InputStyled 
                                {...field} 
                                label="Nome" 
                                placeholder="Nome" 
                                error={errors.senderName?.message} 
                                className={errors.senderName?.message ? 'invalid' : ''}
                              />
                              )}
                            />
                          <Controller
                            control={control}
                            name="senderEmail"
                            render={({field}) => (
                              <InputStyled 
                                {...field} 
                                label="E-mail" 
                                placeholder="Email" 
                                error={errors.senderEmail?.message} 
                                className={errors.senderEmail?.message ? 'invalid' : ''}
                              />
                            )}
                          />
                        </>
                      )}
                    </PlanFormRow>
                    <PlanFormRow>
                      <Controller
                        control={control}
                        name="toBcc"
                        render={({field}) => (
                          <InputStyled 
                            {...field}
                            label="Enviar cópia oculta para"
                            placeholder="Enviar cópia oculta para"
                            error={errors.toBcc?.message}
                            className={errors.toBcc?.message ? 'invalid' : ''}
                          />
                        )}
                      />
                    </PlanFormRow>
                    <div className="card-title">
                      <h5>Regras de Envio</h5>
                    </div>
                    <PlanFormRow>
                      <Controller
                        control={control}
                        name="activity"
                        render={({field}) => (
                          <SelectStyled 
                            {...field}
                            label="Atividade para disparar"
                            placeholder="Disparo"
                            options={TRIGGER_OPTIONS}
                            error={errors.activity?.message}
                            value={TRIGGER_OPTIONS.find(option => option.value === field.value)}
                            className={errors.activity?.message ? 'invalid' : ''}
                            onChange={({value}) => field.onChange(value)}
                          />
                        )}
                      />
                    </PlanFormRow>
                    {activity !== 'new_register' && <>
                    <PlanFormRow>
                      <Controller
                        control={control}
                        name="templateType"
                        render={({field}) => (
                          <SelectStyled 
                            {...field}
                            label="Segmentação"
                            placeholder="Segmentação"
                            options={SEGMENTATION_OPTIONS}
                            error={errors.templateType?.message}
                            value={SEGMENTATION_OPTIONS.find(option => option.value === field.value)}
                            className={errors.templateType?.message ? 'invalid' : ''}
                            onChange={({value}) => field.onChange(value)}
                          />
                        )}
                      />
                    </PlanFormRow>
                    <PlanFormRow>
                      <Controller
                        control={control}
                        name="frequency"
                        render={({field}) => (
                          <SelectStyled 
                            {...field}
                            label="Frequência"
                            placeholder="Frequência"
                            options={FREQUENCY_OPTIONS}
                            error={errors.frequency?.message}
                            value={FREQUENCY_OPTIONS.find(option => option.value === field.value)}
                            className={errors.frequency?.message ? 'invalid' : ''}
                            onChange={({value}) => field.onChange(value)}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="time"
                        render={({field}) => (
                          <InputMaskStyled 
                            {...field}
                            mask="99:99"
                            label="Horário (opcional)"
                            placeholder="Horário do disparo"
                            error={errors.time?.message}
                            className={errors.time?.message ? 'invalid' : ''}
                          />
                        )}
                      />
                    </PlanFormRow>
                    </>}
                    {/* Pré-visualizar */}
                  </div>
                </div>
            </CampaignFormContainer>
              <PlanFormRow>
                <CampaignFormFooter className="col s12" >
                <ButtonStyled type="submit">Salvar</ButtonStyled>
                  <a onClick={() => window.history.back()} style={{color: 'red'}}>Cancelar</a>
                </CampaignFormFooter>
              </PlanFormRow>
          </Form>
          </div>
        </Row>
    </div>
  )
}