import * as React from 'react';
import {Bar} from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

import { default as NumberFormat } from 'react-number-format';
import {
  DashboardHeader,
  DashboardHeaderItem,
  DashboardLabel,
  DashboardData,
} from './ReportsComponents.style';
import {GenerateChartJsToBilled} from './ReportMainDash.service';
import { currencyFormat } from '../../utils';

export const ReportMainDash = ({report: {amountBilled, amountBilledPeriod, data}}): JSX.Element => {
  return (
    <>
      <DashboardHeader>
        <DashboardHeaderItem>
          <DashboardLabel>Faturamento total</DashboardLabel>
          <DashboardData><NumberFormat value={amountBilled} prefix={'R$'} displayType={'text'} format={currencyFormat} /></DashboardData>
        </DashboardHeaderItem>
        <DashboardHeaderItem>
          <DashboardLabel>Faturados no período</DashboardLabel>
          <DashboardData><NumberFormat value={amountBilledPeriod} prefix={'R$'} displayType={'text'} format={currencyFormat} /></DashboardData>
        </DashboardHeaderItem>
        <DashboardHeaderItem></DashboardHeaderItem>
      </DashboardHeader>
      <Bar 
          data={GenerateChartJsToBilled(data)} 
          maxHeight={350}
          responsive={true}
          defaultFontSize={10}
          layout={{padding: 0}}
          options={
            {
              plugins: {
                legend: {
                  position: 'bottom',
                }
              },
              scales: {
                x: {
                  grid: {
                      display:false
                  }
                },
                y: {
                  grid: {
                      display:true
                  }
                }
            }
            }
          }/>
    </>
  );
};
