import * as React from 'react';
import {DatePicker as DtPicker} from 'react-materialize';
import {DateRangePicker} from 'react-date-range';
import * as moment from "moment";
import {ptBR} from 'date-fns/locale';
import {formatDate} from '../../services/Date';
import {defaultStaticRanges,defaultInputRanges} from './Date/DateStaticRange';
import {
  CalendarContainer, 
  SelectDateRange,
  CalendarModalArea,
  CalendarContainerFooter,
} from './DatePicker.style';
import {SelectRangeProps} from '../types';

interface OnSelectProps { (selectRange: SelectRangeProps) : void }

type DatePickerProps = {
  selectRange?: SelectRangeProps;
  onSelect: OnSelectProps;
  formatProps?: string;
  handleConfirm?: () => void;
}

interface PickerDateType {
  value: string;
  onChange: (event) => void;
  formatProps?: string;
  name: string;
  id: string | number;
  label?: string;
  prefilled?: boolean;
}

export const PickerDate = ({value, onChange, formatProps, name, id, label, prefilled} : PickerDateType) => {
  const format= formatProps || "YYYY-MM-DD";
  const handleSelect = (valueSelected) => {
    const valueSelectedTranslated = formatDate(valueSelected);
    const valueObject = {
      target: {
        name,
        value: valueSelectedTranslated
      }
    }
    onChange(valueObject)
  }
  return(
    <DtPicker
      id={id}
      name={name}
      label={label}
      value={`${value || (prefilled ? formatDate() : '' )}`}
      onChange={handleSelect}
      options={{
        format,
      }}
    />
    )
}

export const DatePicker = ({selectRange, onSelect, formatProps, handleConfirm}: DatePickerProps) : JSX.Element => {

  const [selectionRange, setSelectionRange] = React.useState( {key: 'selection', ...selectRange} || { startDate: new Date(), endDate: new Date(), key: 'selection' });
  const [displayDate, setDisplayDate] = React.useState(false);
  const format= formatProps || "DD/MM/YYYY";

  React.useEffect(() => {
    if(selectRange) setSelectionRange({...selectionRange, ...selectRange})
  }, []);

  function handleSelect({selection} : { selection: SelectDateRange }) {
    if(selection) {
      setSelectionRange(selection);
      
      onSelect(selection);
    }
  }

  function toggleDate(){
    setDisplayDate(!displayDate);
  }

  const SelectDate = () => {
    return(
      <SelectDateRange onClick={toggleDate} >
        {`${moment(selectionRange.startDate).format(format).toString()}`} - {`${moment(selectionRange.endDate).format(format).toString()}`}
      </SelectDateRange>
    );
  }

  const ClickModalArea = (event) => {
    if(event.target.id === 'CalendarModalArea'){
      toggleDate();
    }
  }

  const confirmClick = (event) => {
    event.preventDefault();
    toggleDate();
    if(handleConfirm) handleConfirm();
  }
  
  return (
      <div>
        {SelectDate()}
        {!!displayDate && (
          <CalendarModalArea id={'CalendarModalArea'} onClick={ClickModalArea}>
            <CalendarContainer>
              <DateRangePicker 
                locale={ptBR} 
                ranges={[selectionRange]} 
                format={format} 
                dateDisplayFormat={'dd / MMM / yyyy'}
                onChange={handleSelect}
                staticRanges={defaultStaticRanges}
                inputRanges={defaultInputRanges}
                dragSelectionEnabled
                />
                <CalendarContainerFooter>
                  <a href="#" onClick={confirmClick} className={'btn btn-primary'}>Ok</a>
                </CalendarContainerFooter>
            </CalendarContainer>
          </CalendarModalArea>
        )}
      </div>
  );
};
