export const featuresSchema = {
  dashboard: {
    icon: 'chart-line',
    label: 'Dashboard',
    order: 1,
    items: [
      {
        label: 'Geral',
        url: '/admin/',
      },
      // {
      //   label: 'Faturamento',
      //   url: '/admin/reports',
      // }
      {
        icon: 'star',
        label: 'Minhas Avaliações',
        url: '/admin/reviews',
      }
    ]
  },
  checkins: {
    icon: 'check',
    label: 'Check-ins',
    order: 2,
    items: [
      {
        label: 'Novo Check-in de Aluno',
        url: '/admin/checkins/new',
      },
      {
        label: 'Check-ins de Alunos',
        url: '/admin/checkins',
      },
      {
        label: 'Novo Check-in de Professor',
        url: '/admin/checkins/coach/new',
      },
      {
        label: 'Check-ins de Professores',
        url: '/admin/checkins/coaches',
      },
    ]
  },
  filials: {
    icon: 'map-marker-alt',
    label: 'Filiais',
    url: '/admin/filials',
    order: 3,
  },
  users: {
    icon: 'users',
    label: 'Usuários',
    order: 4,
    items: [
      {
        label: 'Usuários',
        url: '/admin/alunos',
      },
      {
        label: 'Equipe',
        url: '/admin/users',
      }
    ]
  },
  treinos: {
    icon: 'calendar-days',
    label: 'Rotinas de Treino',
    order: 5,
    items: [
      {
        label: 'Todas Rotinas',
        url: '/admin/workout-routines',
      },
      {
        label: 'Nova Rotina',
        url: '/admin/workout-routines/new',
      },
      {
        label: 'Programas',
        url: '/admin/programs',
      },
      {
        label: 'Exercícios',
        url: '/admin/exercises',
      },
      {
        label: 'Recordes Pessoais',
        url: '/admin/records'
      },
    ]
  },
  'info-products': {
    icon: 'clapperboard-play',
    label: 'Info Produtos',
    order: 6,
    items: [
      {
        label: 'Ver Todos',
        url: '/admin/courses',
      },
      {
        label: 'Criar Novo',
        url: '/admin/courses/new',
      },
      {
        label: 'Categorias',
        url: '/admin/course-categories',
      },
    ]
  },
  points: {
    icon: 'coins',
    label: 'Pontos',
    order: 7,
    items: [
      {
        label: 'Pontos de Fidelidade',
        url: '/admin/points',
      },
      {
        label: 'Configurar Regras',
        url: '/admin/crm-settings',
      }
    ]
  },
  communication: {
    icon: 'envelope',
    label: 'Comunicação',
    order: 8,
    items: [
      {
        label: 'Mensagens Automáticas',
        url: '/admin/campaigns',
      },
      {
        label: 'Caixa de Entrada',
        url: '/admin/messages',
      },
    ]
  },
  social: {
    icon: 'image-polaroid',
    label: 'Social',
    order: 9,
    items: [
      {
        label: 'Ver Posts',
        url: '/admin/posts',
      },
      {
        label: 'Gerenciar Comentários',
        url: '/admin/general_comments',
      }
    ]
  },
  app: {
    icon: 'mobile-alt',
    label: 'Meu Aplicativo',
    order: 11,
    items: [
      {
        label: 'Detalhes para Loja',
        url: '/admin/app',
      },
      {
        label: 'Personalizar App',
        url: '/admin/app/customize',
      },
      {
        label: 'Gerenciar Banners',
        url: '/admin/banners',
      },
    ]
  },
  settings: {
    icon: 'cog',
    label: 'Configurações',
    order: 13,
    items: [
      {
        label: 'Configurações de Conta',
        url: '/admin/edit',
      },
      {
        label: 'Assinatura da conta',
        url: '/admin/account-plans',
      },
      {
        label: 'Histórico de Faturas',
        url: '/admin/account-billings',
      }
    ]
  },
  finance: {
    icon: 'money-bill-wave',
    label: 'Financeiro',
    order: 12,
    items: [
      {
        label: 'Planos para Alunos',
        url: '/admin/plans',
      },
      {
        label: 'Página de Checkout',
        url: '/checkout',
      }
    ]
  }
}