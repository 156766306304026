import {ImageValue, VideoValue, FileProps} from '../application/types';
type TranslateMediaValueToFilePropsProps = { (mediaValue: ImageValue | VideoValue) : FileProps | null }

export function TranslateMediaValueToFileProps(mediaValue, externalVideoUrl) : TranslateMediaValueToFilePropsProps {
  if((mediaValue && mediaValue.uri) || externalVideoUrl ){
    let mediaTranslated;
    mediaTranslated = {
      path: `${mediaValue?.uri}`,
      preview: `${mediaValue?.uri}`,
    }
    if(externalVideoUrl){
      mediaTranslated = {
        path: `${externalVideoUrl}`,
        preview: `${externalVideoUrl}`,
      }
    }
    return mediaTranslated;
  } else if (mediaValue && new RegExp(['video/','image/', 'attachment/'].join("|")).test(mediaValue.type) ){
    return mediaValue
  }
}