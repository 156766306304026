import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavState } from '../../../components/hookts/useNavState';

interface ProfileContextType {
  activeOption: ProfileControllerOption;
  setActiveOption: (option: ProfileControllerOption) => void;
  account: any;
  aluno: any;
  filials: any;
}

type ProfileControllerOption = 'planos' | 'checkins' | 'inbox' | 'pontos' | 'edit';
export const ProfileContext = createContext<ProfileContextType>({} as ProfileContextType);

export const ProfileProvider = ({children, account, aluno, filials}: {children: React.ReactNode, account: any, aluno: any, filials: any}) => {
  
  const [activeOption, setActiveOption] = useNavState<ProfileControllerOption>('option', 'planos');

  useEffect(() => {
    if(!aluno?.id){
      setActiveOption('edit' as ProfileControllerOption);
    }
  }, [aluno]);

  return (
    <ProfileContext.Provider value={{
      activeOption, 
      setActiveOption,
      account,
      aluno,
      filials
    }}>
      {children}
    </ProfileContext.Provider>
  )
}

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
}