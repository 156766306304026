const defaultDatastes = {
  fill: true,
  lineTension: 0.2,
  backgroundColor: "#3cc0f066",
  borderColor: "#3cc0f0",
  borderDash: [],
  borderWidth: 0,
  borderDashOffset: 0.0,
  pointBackgroundColor: "#fff",
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: "rgba(33,150,243,0.4)",
  pointHoverBorderColor: "rgba(220,220,220,1)",
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
  spanGaps: false,
}

export function GenerateChartJsToBilled({days, amountBilledPerDay}){
  const dataObject = {
    labels: days,
    datasets: [
      {
        ...defaultDatastes,
        label: "R$ Faturamento total",
        backgroundColor: "#3cc0f066",
        borderColor: "#3cc0f0",
        pointHoverBackgroundColor: "rgba(0,169,255,0.4)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        data: amountBilledPerDay,
      }
    ]
  }
  return dataObject;
}