import * as React from 'react';
import {ProgramForm} from './ProgramForm';

import Breadcrumb from '../../components/Breadcrumb';

function ProgramEdit({program, filials}){
  document.title = `${window.account}/admin/editar programa${program.code}`;

  const BreadcumbsData = [
    {
      url: `/${window.account}/admin/workout-routines/`,
      icon: 'stopwatch',
      label: 'Treinos',
    },
    {
      url: `/${window.account}/admin/programs/`,
      label: 'Programas de treino',
    },
    {
      label: `Editar ${program.name}`,
      url: null
    }
  ]

  return(
    <>
      <div className="row">
        <div className="col s12">
          <Breadcrumb data={BreadcumbsData} />
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <h1>Programa de treino</h1>
        </div>
      </div>
      <ProgramForm program={program} filials={filials} action={'Atualizar'} />
    </>
  )
}

export default ProgramEdit;