import * as React from 'react';
import {TextInput} from 'react-materialize';

import {AccountSettingsContext} from '../../context/AccountSettingsContext';
import { useFormContext } from 'react-hook-form';

export function AccountEmailComponent() : JSX.Element {
  const {account, updateAccountFromEvent} = React.useContext(AccountSettingsContext)
  
  const {control, formState: {errors}} = useFormContext();

  const {senderEmail, senderName} = account || {};
  return(
    <>
    <div className="row">
      <div className="col s12">
        <h2>E-mails/Notificações</h2>
      </div>
      <div className="col s12">
        <p>Quando e-mails forem enviados para seus alunos/clientes, você pode personalizar como o remetente desses e-mails aparecem.</p>
      </div>
    </div>
    <div className="row">
      <TextInput 
        s={12} 
        id="senderName"
        name="senderName"
        label="Nome no remetente" 
        value={senderName} 
        error={errors.senderName?.message} 
        inputClassName={errors?.senderName ? 'invalid' : ' '} 
        onChange={updateAccountFromEvent}
      />
      <TextInput 
        s={12} 
        id="senderEmail" 
        name="senderEmail"
        label="E-mail do remetente" 
        value={senderEmail} 
        error={errors.senderEmail?.message} 
        inputClassName={errors?.senderEmail ? 'invalid' : ' '} 
        onChange={updateAccountFromEvent}
      />
    </div>
    </>
  )
}